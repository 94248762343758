import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import OtpInput from 'react-otp-input';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import PagesAuthHeader from './common/header';
import { all_routes } from '../../../../core/data/routes/all_routes';

const EmailOtp = () => {
  const routes = all_routes;
  const navigate = useNavigate();
  const { state } = useLocation();
  
  // States
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const email = state?.email || '';

  // Handle form submission
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);  // Start loading

    try {
      // API call to verify OTP
      const response = await axios.post('http://localhost:8000/api/password/verify-otp', {
        email: email,
        otp: otp,
      });

      // If OTP is verified, navigate to the reset password page
      if (response.status === 200) {
        setIsLoading(false);  // Stop loading
        navigate(routes.resetPassword, { state: { email } });
      }
    } catch (error) {
      // Handle error
      setIsLoading(false);  // Stop loading on error
      setError('Failed to verify OTP. Please try again.');
    }
  };

  return (
    <>
      <PagesAuthHeader />
      <div className="content">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6 col-lg-6">
              <div className="login-wrap otp-wrap">
                <div className="otp-img text-center">
                  <ImageWithBasePath src="assets/img/icons/email-otp.svg" alt="image" />
                </div>
                <div className="login-header text-center">
                  <h3>Email OTP Verification</h3>
                  <p>OTP sent to your Email Address <span>{email}</span></p>
                </div>

                {isLoading ? (
                  <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <div className="d-flex justify-content-center mb-4">
                        <OtpInput
                          value={otp}
                          onChange={setOtp}
                          numInputs={6}
                          renderSeparator={<span> </span>}
                          renderInput={(props) => <input {...props} className="otp-input" />}
                          shouldAutoFocus
                          inputStyle={{
                            width: '40px',
                            height: '40px',
                            margin: '0 5px',
                            fontSize: '20px',
                            textAlign: 'center',
                            borderRadius: '8px',
                            border: '1px solid #ced4da',
                          }}
                        />
                      </div>
                    </div>
                    {error && <p className="text-danger text-center">{error}</p>}
                    <div className="text-center">
                      <button type="submit" className="btn btn-primary w-100 login-btn">
                        Verify &amp; Proceed
                      </button>
                    </div>
                  </form>
                )}
                {/* <p className="no-acc text-center mt-3">
                  Didn’t get the OTP? <Link to="#">Resend OTP</Link>
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailOtp;
