import React, { useState } from 'react';
import PagesAuthHeader from './common/header';
import * as Icon from 'react-feather';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { base_url } from '../../../../constant';

const ResetPassword = () => {
  const routes = all_routes;
  const [passwords, setPasswords] = useState(['', '']);
  const [passwordVisibility, setPasswordVisibility] = useState([false, false]);
  const { state } = useLocation();
  const navigate = useNavigate();
  const email = state?.email || '';
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordResponce, setPasswordResponce] = useState({
    passwordResponceText: '',
    passwordResponceKey: '',
  });
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const togglePasswordVisibility = (index: number) => {
    const updatedVisibility = [...passwordVisibility];
    updatedVisibility[index] = !updatedVisibility[index];
    setPasswordVisibility(updatedVisibility);
  };

  const onChangePassword = (password: string) => {
    setPassword(password);
    if (password.match(/^$|\s+/)) {
      setPasswordResponce({
        passwordResponceText: 'Whitespaces are not allowed',
        passwordResponceKey: '',
      });
    } else if (password.length === 0) {
      setPasswordResponce({
        passwordResponceText: '',
        passwordResponceKey: '',
      });
    } else if (password.length < 8) {
      setPasswordResponce({
        passwordResponceText: 'Weak. Must contain at least 8 characters',
        passwordResponceKey: '0',
      });
    } else if (
      password.search(/[a-z]/) < 0 ||
      password.search(/[A-Z]/) < 0 ||
      password.search(/[0-9]/) < 0
    ) {
      setPasswordResponce({
        passwordResponceText:
          'Average. Must contain at least 1 upper case and number',
        passwordResponceKey: '1',
      });
    } else if (password.search(/(?=.*?[#?!@$%^&*-])/) < 0) {
      setPasswordResponce({
        passwordResponceText: 'Almost. Must contain a special symbol',
        passwordResponceKey: '2',
      });
    } else {
      setPasswordResponce({
        passwordResponceText: 'Awesome! You have a secure password.',
        passwordResponceKey: '3',
      });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (passwords[0] !== passwords[1]) {
      setError("Passwords do not match!");
      return;
    }

    try {
      setIsLoading(true);
      const response = await axios.post(`${base_url}api/password/reset-password`, {
        email: email, // Replace with the email you're managing
        password: passwords[0],
        password_confirmation: passwords[1],
      });
      if (response.status === 200) {
        setSuccessMessage('Password reset successful!');
        setError('');
        
        // Redirect to login page
        navigate(routes.login);
        setIsLoading(false);
      }
    } catch (error) {
      setError('Password reset failed. Please try again.');
      setIsLoading(false);
    }
  };

  return (
    <>
      <PagesAuthHeader />
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-6 mx-auto">
            {isLoading && (
                <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              )}
              {!isLoading && (
              <div className="login-wrap">
                <div className="login-header">
                  <h3>Reset Password</h3>
                  <p>
                    Your new password must be different from previous used
                    passwords.
                  </p>
                </div>
                {/* Reset Password Form */}
                <form onSubmit={handleSubmit}>
                  <div className="log-form">
                    <div className="form-group">
                      <label className="col-form-label">Email</label>
                      <input
                        type="email"
                        value={email}
                        className="form-control"
                        placeholder="Enter your email"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label className="col-form-label">New Password</label>
                      <div className="pass-group" id="passwordInput">
                        <input
                          type={passwordVisibility[0] ? 'text' : 'password'}
                          value={passwords[0]}
                          onChange={(e) =>
                            setPasswords([e.target.value, passwords[1]])
                          }
                          className="form-control pass-input"
                          placeholder="*************"
                        />
                        <span
                          className="toggle-password"
                          onClick={() => togglePasswordVisibility(0)}
                        >
                          {passwordVisibility[0] ? (
                            <Icon.Eye className="react-feather-custom" />
                          ) : (
                            <Icon.EyeOff className="react-feather-custom" />
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="col-form-label">Confirm Password</label>
                      <div className="pass-group">
                        <input
                          type={passwordVisibility[1] ? 'text' : 'password'}
                          value={passwords[1]}
                          onChange={(e) =>
                            setPasswords([passwords[0], e.target.value])
                          }
                          className="form-control pass-input"
                          placeholder="*************"
                        />
                        <span
                          className="toggle-password"
                          onClick={() => togglePasswordVisibility(1)}
                        >
                          {passwordVisibility[1] ? (
                            <Icon.Eye className="react-feather-custom" />
                          ) : (
                            <Icon.EyeOff className="react-feather-custom" />
                          )}
                        </span>
                      </div>
                    </div>
                    {error && <div className="alert alert-danger">{error}</div>}
                    {successMessage && (
                      <div className="alert alert-success">
                        {successMessage}
                      </div>
                    )}
                  </div>
                  <button className="btn btn-primary w-100 login-btn" type="submit">
                    Save Change
                  </button>
                </form>
                {/* /Reset Password Form */}
              </div>)}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
