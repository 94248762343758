import React, { useState, useEffect } from 'react';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { Link } from 'react-router-dom';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { Dropdown } from 'primereact/dropdown';
import { image_base_url, base_url } from '../../../../constant';
const routes = all_routes;

const ProviderReview = () => {
  const [selectedValue, setSelectedValue] = useState(null);
  const value = [{ name: 'A - Z' }, { name: 'Most Helpful' }];
  const [isLoading, setIsLoading] = useState(false);
  const [reviewList, setReviewList] = useState([]);

  useEffect(() => {
    getbooklisttechnician();
  }, []);

  const getbooklisttechnician = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('tech_token');
      const user_id = localStorage.getItem('technician_id');
        const response = await fetch(`${base_url}api/ratings/technician/${user_id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setReviewList(data.ratings);
    } catch (error) {
      console.error('Error fetching technicians:', error);
    } finally {
      setIsLoading(false);
    }
  };
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Adjust this number as needed

  // Calculate the number of pages
  const totalPages = Math.ceil(reviewList.length / itemsPerPage);

  // Get current items based on pagination
  const currentItems = reviewList.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageClick = (pageNumber:any) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        {/* Page Header */}
        <div className="page-header">
          <div className="row">
            <div className="col-md-4">						
              <div className="provider-subtitle">
                <h6>Reviews</h6>
              </div>
            </div>
            <div className="col-md-8 d-flex align-items-center justify-content-md-end flex-wrap">
              <div className="review-sort me-2">
                <p>Sort</p>
                <Dropdown
                  value={selectedValue}
                  onChange={(e) => setSelectedValue(e.value)}
                  options={value}
                  optionLabel="name"
                  placeholder="A - Z"
                  className="select provider-select select-space"
                />
              </div>
            </div>
          </div>
        </div>
        {/* /Page Header */}
        {/* Reviews */}
        <div className="row">
          <div className="col-md-12">
            {/* Review List */}
            {isLoading && (
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
    )}
    {!isLoading && (
              currentItems.map((review:any) => (
                <div key={review.id} className="review-list">
                  <div className="review-imgs">
                    <Link to="#">
                      <img 
                        className="rounded img-fluid" 
                        src={`${image_base_url}${review.service?.image}`} 
                        alt={"img"} 
                      />
                    </Link>
                  </div>
                  <div className="review-info">
                    <h5>
                      <Link to="#">
                        {review.service?.specialization?review.service?.specialization:"-"}
                      </Link> 
                    </h5>
                    <div className="review-user">
                      {/* <ImageWithBasePath 
                        className="avatar rounded-circle" 
                        src={`${image_base_url}{review.user.profile_img}`} 
                        alt={review.user.name} 
                      />  */}
                      {review.user?.name},
                      <p style={{fontSize:"20px"}}>{review.review}</p> 
                      <span className="review-date">
                        {new Date(review.created_at).toLocaleString()}
                      </span>
                    </div>
                  </div>
                  <div className="review-count">
                    <div className="rating">
                      {Array.from({ length: review.rating }).map((_, index) => (
                        <i key={index} className="fas fa-star filled" />
                      ))}
                      {Array.from({ length: 5 - review.rating }).map((_, index) => (
                        <i key={index} className="fas fa-star" />
                      ))}
                    </div>
                  </div>
                </div>
              ))
            )}
            {/* /Review List */}
            <div className="row">
              <div className="col-sm-6">
                {/* <div className="review-entries">
                  <span>Show</span>
                  <select>
                    <option>10</option>
                    <option>25</option>
                    <option>50</option>
                    <option>100</option>
                  </select>
                  <span>entries</span>
                </div> */}
              </div>
              <div className='row'>
                <div className='col-sm-6'>
                <p>1 - {reviewList.length} of {reviewList.length}</p>
                </div>
              <div className="col-sm-6">
        <div className="blog-pagination rev-page">
          <nav>
            <ul className="pagination justify-content-end">
              <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                <Link className="page-link page-prev" to="#" onClick={handlePrevPage}>
                  <i className="fa-solid fa-arrow-left me-1" /> PREV
                </Link>
              </li>
              {Array.from({ length: totalPages }, (_, index) => (
                <li
                  key={index + 1}
                  className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}
                >
                  <Link
                    className="page-link"
                    to="#"
                    onClick={() => handlePageClick(index + 1)}
                  >
                    {index + 1}
                  </Link>
                </li>
              ))}
              <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                <Link className="page-link page-next" to="#" onClick={handleNextPage}>
                  NEXT <i className="fa-solid fa-arrow-right ms-1" />
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        </div>
      </div>
            </div>
          </div>
        </div>
        {/* /Reviews */}
      </div>
    </div>
  );
};

export default ProviderReview;
