import React, { useState } from 'react';
import axios from 'axios';
import PagesAuthHeader from './common/header';
import { Link, useNavigate } from 'react-router-dom';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { base_url } from '../../../../constant';

const PasswordRecovery = () => {
  const routes = all_routes;
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false); // Loading state

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true); // Start loading

    try {
      // Make the POST request to send OTP
      const response = await axios.post(`${base_url}api/password/send-otp`, {
        email: email,
      });

      // Handle success
      if (response.status === 200) {
        setMessage('Password recovery email sent. Please check your inbox.');
        setError(''); // Clear any previous errors
        navigate(routes.emailOtp, { state: { email } }); // Navigate to the email OTP page
      }
    } catch (err) {
      // Handle error
      setError('Failed to send recovery email. Please try again.');
      setMessage(''); // Clear success message
    } finally {
      setIsLoading(false); // Stop loading after the request
    }
  };

  return (
    <>
      <PagesAuthHeader />
      <div className="content">
        <div className="container">
          <div className="row">
            {/* Password Recovery */}
            <div className="col-md-6 col-lg-6 mx-auto">
              <div className="login-wrap">
                <div className="login-header">
                  <h3>Password Recovery</h3>
                  <p>Enter your email and we will send you a link to reset your password.</p>
                </div>

                {isLoading ? (
                  <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <form onSubmit={handleSubmit}>
                    <div className="log-form">
                      <div className="form-group">
                        <label className="col-form-label">Email</label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="johndoe@example.com"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)} // Handle input change
                          required
                        />
                      </div>
                    </div>
                    <button type="submit" className="btn btn-primary w-100 login-btn">
                      Submit
                    </button>
                    {message && <p className="text-success mt-3">{message}</p>}
                    {error && <p className="text-danger mt-3">{error}</p>}
                    <p className="no-acc mt-0">
                      Remember Password? <Link to={routes.login}>Login</Link>
                    </p>
                  </form>
                )}
              </div>
            </div>
            {/* /Password Recovery */}
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordRecovery;
