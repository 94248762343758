import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';

const AboutUs = () => {
  const routes = all_routes;
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);
  const clientSlider = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };
  return (
    <>
      <div>
        {/* Breadcrumb */}
        <div className="breadcrumb-bar">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <h2 className="breadcrumb-title">About Us</h2>
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={routes.homeOne}>Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      About Us
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        {/* /Breadcrumb */}
        <div className="content p-0">
          {/* About */}
          <div className="about-sec">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="about-img">
                    <div className="about-exp">
                      <span>12+ years of experiences</span>
                    </div>
                    <div className="abt-img">
                      <ImageWithBasePath
                        src="assets/img/about-01.jpg"
                        className="img-fluid"
                        alt="img"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="about-content">
                    <h6>ABOUT OUR COMPANY</h6>
                    <h2>Best Solution For Cleaning Services</h2>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim minim veniam, quis nostrud exercitation
                      ullamco laboris nisi esse cillum dolore eu fugiat nulla
                      pariatur. Excepteur sint occaecat cupidatat non proident,
                      sunt in culpa qui officia deserunt mollit anim id est
                      laborum.
                    </p>
                    <p>
                      Sed ut perspiciatis unde omnis iste natus error sit
                      voluptatem accusantium doloremque laudantium, totam rem
                      aperiam, eaque ipsa quae ab illo inventore veritatis et
                      quasi architecto beatae vitae dicta sunt explicabo.
                    </p>
                    <div className="row">
                      <div className="col-md-6">
                        <ul>
                          <li>At vero et accusamus iusto dignissimos</li>
                          <li>At vero et accusamus iusto dignissimos</li>
                        </ul>
                      </div>
                      <div className="col-md-6">
                        <ul>
                          <li>Nam libero tempore, cum soluta nobis</li>
                          <li>Nam libero tempore, cum soluta nobis</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /About */}
          {/* Work Section */}
          <section className="work-section work-bg">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <div className="section-heading">
                    <h2>How It Works</h2>
                    <p>Aliquam lorem ante, dapibus in, viverra quis</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="work-box">
                    <div className="work-icon">
                      <span>
                        <ImageWithBasePath src="assets/img/icons/work-icon.svg" alt="img" />
                      </span>
                    </div>
                    <h5>Choose What To Do</h5>
                    <p>
                      Lorem ipsum dolor amet, consectetur adipiscing tempor
                      labore et dolore magna aliqua.
                    </p>
                    <h4>01</h4>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="work-box">
                    <div className="work-icon">
                      <span>
                        <ImageWithBasePath src="assets/img/icons/find-icon.svg" alt="img" />
                      </span>
                    </div>
                    <h5>Find What You Want</h5>
                    <p>
                      Lorem ipsum dolor amet, consectetur adipiscing tempor
                      labore et dolore magna aliqua.
                    </p>
                    <h4>02</h4>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="work-box">
                    <div className="work-icon">
                      <span>
                        <ImageWithBasePath src="assets/img/icons/place-icon.svg" alt="img" />
                      </span>
                    </div>
                    <h5>Amazing Places</h5>
                    <p>
                      Lorem ipsum dolor amet, consectetur adipiscing tempor
                      labore et dolore magna aliqua.
                    </p>
                    <h4>03</h4>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* /Work Section */}
          {/* Choose Us Section */}
          <div className="chooseus-sec">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="choose-content">
                    <h2>Why Choose Us</h2>
                    <p>
                      At vero eos et accusamus et iusto odio dignissimos ducimus
                    </p>
                    <div className="support-card">
                      <h4 className="support-title">
                        <Link
                          className="collapsed"
                          data-bs-toggle="collapse"
                          to="#aboutone"
                          aria-expanded="false"
                        >
                          24/7 Supports
                        </Link>
                      </h4>
                      <div id="aboutone" className="card-collapse collapse">
                        <p>
                          Sed ut perspiciatis unde omnis iste natus error sit
                          voluptatem accusantium doloremque laudantium, totam
                          rem aperiam, eaque ipsa quae ab illo inventore
                          veritatis et quasi architecto beatae vitae dicta sunt
                          explicabo.
                        </p>
                      </div>
                    </div>
                    <div className="support-card">
                      <h4 className="support-title">
                        <Link
                          className="collapsed"
                          data-bs-toggle="collapse"
                          to="#abouttwo"
                          aria-expanded="false"
                        >
                          Client’s reviews
                        </Link>
                      </h4>
                      <div id="abouttwo" className="card-collapse collapse">
                        <p>
                          Sed ut perspiciatis unde omnis iste natus error sit
                          voluptatem accusantium doloremque laudantium, totam
                          rem aperiam, eaque ipsa quae ab illo inventore
                          veritatis et quasi architecto beatae vitae dicta sunt
                          explicabo.
                        </p>
                      </div>
                    </div>
                    <div className="support-card">
                      <h4 className="support-title">
                        <Link
                          className="collapsed"
                          data-bs-toggle="collapse"
                          to="#about3"
                          aria-expanded="false"
                        >
                          Professional Team
                        </Link>
                      </h4>
                      <div id="about3" className="card-collapse collapse">
                        <p>
                          Sed ut perspiciatis unde omnis iste natus error sit
                          voluptatem accusantium doloremque laudantium, totam
                          rem aperiam, eaque ipsa quae ab illo inventore
                          veritatis et quasi architecto beatae vitae dicta sunt
                          explicabo.
                        </p>
                      </div>
                    </div>
                    <div className="support-card">
                      <h4 className="support-title">
                        <Link
                          className="collapsed"
                          data-bs-toggle="collapse"
                          to="#about4"
                          aria-expanded="false"
                        >
                          Best Services
                        </Link>
                      </h4>
                      <div id="about4" className="card-collapse collapse">
                        <p>
                          Sed ut perspiciatis unde omnis iste natus error sit
                          voluptatem accusantium doloremque laudantium, totam
                          rem aperiam, eaque ipsa quae ab illo inventore
                          veritatis et quasi architecto beatae vitae dicta sunt
                          explicabo.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="chooseus-img">
                    <ImageWithBasePath
                      src="assets/img/about-02.png"
                      className="img-fluid"
                      alt="img"
                    />
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-xl-3 col-lg-4 col-sm-6">
                  <div className="choose-icon">
                    <ImageWithBasePath
                      src="assets/img/icons/choose-icon.svg"
                      className="img-fluid"
                      alt="img"
                    />
                    <div className="choose-info">
                      <h5>2583+</h5>
                      <p>Satisfied Clients</p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-sm-6">
                  <div className="choose-icon">
                    <ImageWithBasePath
                      src="assets/img/icons/choose-icon-01.svg"
                      className="img-fluid"
                      alt="img"
                    />
                    <div className="choose-info">
                      <h5>2383+</h5>
                      <p>Expert Team</p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-sm-6">
                  <div className="choose-icon">
                    <ImageWithBasePath
                      src="assets/img/icons/choose-icon.png"
                      className="img-fluid"
                      alt="img"
                    />
                    <div className="choose-info">
                      <h5>2129+</h5>
                      <p>Project Completed</p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-sm-6">
                  <div className="choose-icon border-0">
                    <ImageWithBasePath
                      src="assets/img/icons/choose-icon-03.svg"
                      className="img-fluid"
                      alt="img"
                    />
                    <div className="choose-info">
                      <h5>30+</h5>
                      <p>Years of experience</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /Choose Us Section */}
          {/* Providers Section */}
          <section className="providers-section abt-provider">
            <div className="container">
              <div className="section-heading">
                <div className="row">
                  <div className="col-md-6">
                    <h2>Top Providers</h2>
                    <p>Meet Our Experts</p>
                  </div>
                  <div className="col-md-6 text-md-end">
                    <Link
                      to={routes.provider}
                      className="btn btn-primary btn-view"
                    >
                      View All
                      <i className="feather-arrow-right-circle" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 col-sm-6">
                  <div className="providerset">
                    <div className="providerset-img">
                      <Link to={routes.providerDetails}>
                        <ImageWithBasePath
                          src="assets/img/provider/provider-11.jpg"
                          alt="img"
                        />
                      </Link>
                    </div>
                    <div className="providerset-content">
                      <div className="providerset-price">
                        <div className="providerset-name">
                          <h4>
                            <Link to={routes.providerDetails}>John Smith</Link>
                            <i
                              className="fa fa-check-circle"
                              aria-hidden="true"
                            />
                          </h4>
                          <span>Electrician</span>
                        </div>
                        <div className="providerset-prices">
                          <h6>
                            $20.00<span>/hr</span>
                          </h6>
                        </div>
                      </div>
                      <div className="provider-rating">
                        <div className="rating">
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fa-solid fa-star-half-stroke filled" />
                          <span>(320)</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="providerset">
                    <div className="providerset-img">
                      <Link to={routes.providerDetails}>
                        <ImageWithBasePath
                          src="assets/img/provider/provider-12.jpg"
                          alt="img"
                        />
                      </Link>
                    </div>
                    <div className="providerset-content">
                      <div className="providerset-price">
                        <div className="providerset-name">
                          <h4>
                            <Link to={routes.providerDetails}>Michael</Link>
                            <i
                              className="fa fa-check-circle"
                              aria-hidden="true"
                            />
                          </h4>
                          <span>Carpenter</span>
                        </div>
                        <div className="providerset-prices">
                          <h6>
                            $50.00<span>/hr</span>
                          </h6>
                        </div>
                      </div>
                      <div className="provider-rating">
                        <div className="rating">
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fa-solid fa-star-half-stroke filled" />
                          <span>(228)</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="providerset">
                    <div className="providerset-img">
                      <Link to={routes.providerDetails}>
                        <ImageWithBasePath
                          src="assets/img/provider/provider-13.jpg"
                          alt="img"
                        />
                      </Link>
                    </div>
                    <div className="providerset-content">
                      <div className="providerset-price">
                        <div className="providerset-name">
                          <h4>
                            <Link to={routes.providerDetails}>Antoinette</Link>
                            <i
                              className="fa fa-check-circle"
                              aria-hidden="true"
                            />
                          </h4>
                          <span>Cleaner</span>
                        </div>
                        <div className="providerset-prices">
                          <h6>
                            $25.00<span>/hr</span>
                          </h6>
                        </div>
                      </div>
                      <div className="provider-rating">
                        <div className="rating">
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fa-solid fa-star-half-stroke filled" />
                          <span>(130)</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="providerset">
                    <div className="providerset-img">
                      <Link to={routes.providerDetails}>
                        <ImageWithBasePath
                          src="assets/img/provider/provider-14.jpg"
                          alt="img"
                        />
                      </Link>
                    </div>
                    <div className="providerset-content">
                      <div className="providerset-price">
                        <div className="providerset-name">
                          <h4>
                            <Link to={routes.providerDetails}>Thompson</Link>
                            <i
                              className="fa fa-check-circle"
                              aria-hidden="true"
                            />
                          </h4>
                          <span>Mechanic</span>
                        </div>
                        <div className="providerset-prices">
                          <h6>
                            $25.00<span>/hr</span>
                          </h6>
                        </div>
                      </div>
                      <div className="provider-rating">
                        <div className="rating">
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fas fa-star filled" />
                          <i className="fa-solid fa-star-half-stroke filled" />
                          <span>(95)</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* /Providers Section */}
          {/* Client Section */}
          <section className="client-section">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <div className="section-heading aos" data-aos="fade-up">
                    <h2>What our client says</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur elit</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="testimonial-slider">
                    <Slider {...clientSlider}>
                      <div className="client-widget aos" data-aos="fade-up">
                        <div className="client-img">
                          <Link to={'/'}>
                            <ImageWithBasePath
                              className="img-fluid"
                              alt="Avatar"
                              src="assets/img/profiles/avatar-01.jpg"
                            />
                          </Link>
                        </div>
                        <div className="client-content">
                          <div className="rating">
                            <i className="fas fa-star filled"></i>
                            <i className="fas fa-star filled"></i>
                            <i className="fas fa-star filled"></i>
                            <i className="fas fa-star filled"></i>
                            <i className="fas fa-star filled"></i>
                          </div>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi{' '}
                          </p>
                          <h5>Sophie Moore</h5>
                          <h6>Director</h6>
                        </div>
                      </div>
                      <div className="client-widget aos" data-aos="fade-up">
                        <div className="client-img">
                          <Link to={'/'}>
                            <ImageWithBasePath
                              className="img-fluid"
                              alt="Avat"
                              src="assets/img/profiles/avatar-02.jpg"
                            />
                          </Link>
                        </div>
                        <div className="client-content">
                          <div className="rating">
                            <i className="fas fa-star filled"></i>
                            <i className="fas fa-star filled"></i>
                            <i className="fas fa-star filled"></i>
                            <i className="fas fa-star filled"></i>
                            <i className="fas fa-star filled"></i>
                          </div>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi{' '}
                          </p>
                          <h5>Mike Hussy</h5>
                          <h6>Lead</h6>
                        </div>
                      </div>
                      <div className="client-widget aos" data-aos="fade-up">
                        <div className="client-img">
                          <Link to={'/'}>
                            <ImageWithBasePath
                              className="img-fluid"
                              alt="Avat"
                              src="assets/img/profiles/avatar-03.jpg"
                            />
                          </Link>
                        </div>
                        <div className="client-content">
                          <div className="rating">
                            <i className="fas fa-star filled"></i>
                            <i className="fas fa-star filled"></i>
                            <i className="fas fa-star filled"></i>
                            <i className="fas fa-star filled"></i>
                            <i className="fas fa-star filled"></i>
                          </div>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi{' '}
                          </p>
                          <h5>John Doe</h5>
                          <h6>CEO</h6>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* /Client Section */}
          {/* Service Section */}
          <div className="service-offer">
            <div className="container">
              <div className="col-md-12">
                <div className="offer-paths about-offer">
                  <div className="offer-path-content">
                    <h3>Looking for the Best Service Finder &amp; Bookings</h3>
                    <p>
                      Sed ut perspiciatis unde omnis iste natus error sit
                      voluptatem accusantium doloremque laudantium, totam rem
                      aperiam, eaque ipsa quae ab illo inventore architecto
                      beatae vitae dicta sunt explicabo.
                    </p>
                    <Link
                      to={routes.serviceGrid}
                      className="btn btn-primary btn-views"
                    >
                      Get Started
                      <i className="feather-arrow-right-circle" />
                    </Link>
                  </div>
                  <div className="offer-pathimg">
                    <ImageWithBasePath src="assets/img/service-img.png" alt="img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /Service Section */}
        </div>
      </div>
    </>
  );
};

export default AboutUs;
