import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StripeCheckoutForm from './stripecheckoutform';

// Initialize Stripe with your public key
//const stripePromise = loadStripe('pk_live_51KueO2Ihs1sG6BYS2NaxmNLDAlnYzvguHJ5edf1yhSZDx6iQKJvAsQs9G40yxfe0zwPcZw0Vuw1jAao7VCz0Sntu00Fckv2IW7');
//const stripePromise = loadStripe('pk_test_4eC39HqLyjWDarjtT1zdp7dc');
const stripePromise = loadStripe('pk_test_51KueO2Ihs1sG6BYSgStdFjAOHLiCWMyE8b9eMJfeFHAOZDEu3W5WOCtFrFHdIp5LSawW3YrSgyZIMO9TDzJbmPQ600Rw4EXk6x');

const StripeElement = () => {
  return (
    <Elements stripe={stripePromise}>
      <StripeCheckoutForm />
    </Elements>
  );
};

export default StripeElement;
