import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars-2';
import * as Icon from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { set_mouseoversidebar_data } from '../../../../core/data/redux/action';
import { all_routes } from '../../../../core/data/routes/all_routes';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { AppState } from '../../../../core/models/interface';
import { image_base_url } from '../../../../constant';
import { Modal, Button } from 'react-bootstrap';

const ProviderSidebar = () => {
  const routes = all_routes;

  const location = useLocation();
  const user_name = localStorage.getItem("tech_user_name");
  const user_email = localStorage.getItem("tech_user_email");
  const user_userprofileimage = localStorage.getItem("tech_user_userprofileimage");
  const toggle_data = useSelector((state: AppState) => state.mouseOverSidebar);
  const dispatch = useDispatch();
  const [subdroptoggle, setsubdroptoggle] = useState(false);
  const [subdroptoggle2, setsubdroptoggle2] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const activeRouterPath = (link: string) => {
    console.log('first', location.pathname);
    return link === location.pathname;
  };
  const activeRouterPath2 = () => {
    return location.pathname.includes('settings');
  };
  const navigate = useNavigate();
  
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleConfirmLogout = () => {
    handleLogout();
    handleCloseModal();
  };

  const handleLogout = () => {
    // Remove the token from localStorage
    navigate(routes.homeOne);
    localStorage.removeItem('tech_token'); 
    localStorage.removeItem('technician_id'); 
    localStorage.removeItem('tech_user_name'); 
    localStorage.removeItem('tech_user_email'); 
    localStorage.removeItem('tech_user_userprofileimage'); 
  };
  // useEffect(() => {
  //   activeRouterPath
  // }, [])

  const toogle = () => {
    dispatch(set_mouseoversidebar_data(toggle_data ? false : true));
  };
  return (
    <div
      onMouseEnter={toogle}
      onMouseLeave={toogle}
      className="sidebar"
      id="sidebar"
    >
      <div className="sidebar-inner slimscroll">
        <Scrollbars>
          <div id="sidebar-menu" className="sidebar-menu">
            <ul>
              <li
                className={`${
                  activeRouterPath('/providers/dashboard') ? 'active' : ''
                }`}
              >
                <Link to="/providers/dashboard">
                  <Icon.Grid className="react-feather-icon" />{' '}
                  <span>Dashboard</span>
                </Link>
              </li>
              <li
                className={`${
                  activeRouterPath('/providers/provider-profile')
                    ? 'active'
                    : ''
                }`}
              >
                <Link to="/providers/provider-profile">
                  <Icon.PlusCircle className="react-feather-icon" />{' '}
                  <span>Technician Profile</span>
                </Link>
              </li>
              <li
                className={`${
                  activeRouterPath('/providers/provider-userid-experience')
                    ? 'active'
                    : ''
                }`}
              >
                <Link to="/providers/provider-userid-experience">
                  <Icon.BookOpen className="react-feather-icon" />{' '}
                  <span>User Id Verify</span>
                </Link>
              </li>
              {/* <li
                className={`${
                  activeRouterPath('/providers/home-screen')
                    ? 'active'
                    : ''
                }`}
              >
                <Link to="/providers/home-screen">
                  <Icon.Home className="react-feather-icon" />{' '}
                  <span>Home</span>
                </Link>
              </li> */}
              {/* <li
                className={`${
                  activeRouterPath('/providers/provider-order')
                    ? 'active'
                    : ''
                }`}
              >
                <Link to="/providers/provider-order">
                  <Icon.Home className="react-feather-icon" />{' '}
                  <span>Technician Order</span>
                </Link>
              </li> */}
              <li
                className={`${
                  activeRouterPath('/providers/provider-service')
                    ? 'active'
                    : ''
                }`}
              >
                <Link to="/providers/provider-service">
                  <Icon.Briefcase className="react-feather-icon" />{' '}
                  <span>My Services</span>
                </Link>
              </li>
              <li
                className={`${
                  activeRouterPath('/providers/provider-booking')
                    ? 'active'
                    : ''
                }`}
              >
                <Link to="/providers/provider-booking">
                  <Icon.Calendar className="react-feather-icon" />{' '}
                  <span>Bookings </span>
                </Link>
              </li>
              {/* <li
                className={`${
                  activeRouterPath('/providers/provider-payout') ? 'active' : ''
                }`}
              >
                <Link to={'/providers/provider-payout'}>
                  <Icon.CreditCard className="react-feather-icon" />{' '}
                  <span>Payout</span>
                </Link>
              </li> */}
              <li
                className={`${
                  activeRouterPath('/providers/provider-availability')
                    ? 'active'
                    : ''
                }`}
              >
                <Link to="/providers/provider-availability">
                  <Icon.Clock className="react-feather-icon" />{' '}
                  <span>Availability</span>
                </Link>
              </li>
              {/* <li
                className={`${
                  activeRouterPath('/providers/provider-holiday')
                    ? 'active'
                    : ''
                }`}
              >
                <Link to="/providers/provider-holiday">
                  <Icon.Calendar className="react-feather-icon" />{' '}
                  <span>Holidays &amp; Leave</span>
                </Link>
              </li>
              <li
                className={`${
                  activeRouterPath('/providers/provider-coupons')
                    ? 'active'
                    : ''
                }`}
              >
                <Link to="/providers/provider-coupons">
                  <Icon.Bookmark className="react-feather-icon" />{' '}
                  <span>Coupons</span>
                </Link>
              </li>
              <li
                className={`${
                  activeRouterPath('/providers/provider-subscription')
                    ? 'active'
                    : ''
                }`}
              >
                <Link to="/providers/provider-subscription">
                  <Icon.DollarSign className="react-feather-icon" />
                  <span>Subscription</span>
                </Link>
              </li>
              <li
                className={`${
                  activeRouterPath('/providers/provider-offer') ? 'active' : ''
                }`}
              >
                <Link to="/providers/provider-offer">
                  <Icon.Percent className="react-feather-icon" />{' '}
                  <span>Offers</span>
                </Link>
              </li> */}
              <li
                className={`${
                  activeRouterPath('/providers/provider-review') ? 'active' : ''
                }`}
              >
                <Link to="/providers/provider-review">
                  <Icon.Star className="react-feather-icon" />{' '}
                  <span>Reviews</span>
                </Link>
              </li>
              <li
                className={`${
                  activeRouterPath('/providers/provider-earnings')
                    ? 'active'
                    : ''
                }`}
              >
                <Link to="/providers/provider-earnings">
                  <Icon.DollarSign className="react-feather-icon" />{' '}
                  <span>Earnings</span>
                </Link>
              </li>
              {/* <li
                className={`${
                  activeRouterPath('/providers/provider-chat') ? 'active' : ''
                }`}
              >
                <Link to="/providers/provider-chat">
                  <Icon.MessageCircle className="react-feather-icon" />{' '}
                  <span>Chat</span>
                </Link>
              </li> */}
              <li className={`submenu ${activeRouterPath2() ? 'active' : ''}`}>
                <Link
                  to="#"
                  onClick={() => setsubdroptoggle(!subdroptoggle)}
                  className={`${subdroptoggle ? 'subdrop' : ''}`}
                >
                  <Icon.Settings className="react-feather-icon" />{' '}
                  <span>Settings</span> <span className="menu-arrow" />
                </Link>
                <ul style={{ display: subdroptoggle ? 'block' : 'none' }}>
                  {/* <li
                    className={`${
                      activeRouterPath(
                        '/providers/settings/provider-appointment-settings',
                      )
                        ? 'active'
                        : ''
                    }`}
                  >
                    <Link to="/providers/settings/provider-appointment-settings">
                      Appointment Settings
                    </Link>
                  </li> */}
                  <li
                    className={`${
                      activeRouterPath(
                        // '/providers/settings/provider-profile-settings',
                        '/providers/provider-profile'
                      )
                        ? 'active'
                        : ''
                    }`}
                  >
                    <Link to="/providers/provider-profile">
                    {/* to="/providers/settings/provider-profile-settings"> */}
                      Account Settings
                    </Link>
                  </li>
                  {/* <li
                    className={`${
                      activeRouterPath(
                        '/providers/settings/provider-social-profile',
                      )
                        ? 'active'
                        : ''
                    }`}
                  >
                    <Link to="/providers/settings/provider-social-profile">
                      Social Profiles
                    </Link>
                  </li>
                  <li
                    className={`${
                      activeRouterPath(
                        '/providers/settings/provider-security-settings',
                      )
                        ? 'active'
                        : ''
                    }`}
                  >
                    <Link to="/providers/settings/provider-security-settings">
                      Security Setting
                    </Link>
                  </li>
                  <li
                    className={`${
                      activeRouterPath('/providers/settings/provider-plan')
                        ? 'active'
                        : ''
                    }`}
                  >
                    <Link to="/providers/settings/provider-plan">
                      Plan &amp; Billings
                    </Link>
                  </li>
                  <li
                    className={`${
                      activeRouterPath('/providers/settings/payment-setting')
                        ? 'active'
                        : ''
                    }`}
                  >
                    <Link to="/providers/settings/payment-setting">
                      Payment Settings
                    </Link>
                  </li>
                  <li
                    className={`${
                      activeRouterPath(
                        '/providers/settings/provider-notification',
                      )
                        ? 'active'
                        : ''
                    }`}
                  >
                    <Link to="/providers/settings/provider-notification">
                      Notifications
                    </Link>
                  </li>
                  <li
                    className={`${
                      activeRouterPath(
                        '/providers/settings/provider-connected-apps',
                      )
                        ? 'active'
                        : ''
                    }`}
                  >
                    <Link to="/providers/settings/provider-connected-apps">
                      Connected Apps
                    </Link>
                  </li>
                  <li
                    className={`${
                      activeRouterPath(
                        '/providers/settings/provider-appointment-settings',
                      )
                        ? 'active'
                        : ''
                    }`}
                  >
                    <Link to="/providers/settings/verification">
                      Profile Verification
                    </Link>
                  </li> */}
                </ul>
              </li>
              <li>
                <Link to="#" onClick={handleShowModal}>
                  <Icon.LogOut className="react-feather-icon" />{' '}
                  <span>Logout</span>
                </Link>
              </li>
            </ul>
            <div className="menu-bottom">
              <div className="menu-user">
                <div className="menu-user-img avatar-online">
                  {/* <ImageWithBasePath
                    src="assets/img/profiles/avatar-02.jpg"
                    alt="user"
                  /> */}
                   {user_userprofileimage ? (
    // Render the standard <img> element if user_userprofileimage is available
    <img
      src={`${image_base_url}${user_userprofileimage}`}
      className="rounded-circle"
      alt="User"
    />
  ) : (
    // Render the <ImageWithBasePath> component if user_userprofileimage is not available
    <ImageWithBasePath
      src="assets/img/profiles/avatar-02.jpg"
      className="rounded-circle"
      alt="User Image"
    />
  )}
                </div>
                <div className="menu-user-info">
                  <h6>{user_name}</h6>
                  <p>{user_email}</p>
                </div>
              </div>
              <Link
                to="#"
                onClick={() => setsubdroptoggle2(!subdroptoggle2)}
                className={` select-set ${subdroptoggle2 ? 'subdrop' : ''}`}
              >
                <Icon.Settings className="react-feather-icon" />
              </Link>
              {/* <div className="dropdown-menu user-drop" id="dropboxes" style={{display:subdroptoggle2?'block':'none'}}>
                <div className="menu-user">
                  <div className="menu-user-img avatar-online">
                    <ImageWithBasePath src="assets/img/profiles/avatar-02.jpg" alt="user" />
                  </div>
                  <div className="menu-user-info">
                    <h6>John Smith</h6>
                    <p>Active</p>
                  </div>
                </div>
                <div className="set-user">
                  <p>Set as Away</p>
                  <div className="status-toggle">
                    <input type="checkbox" id="active-user" className="check" />
                    <label htmlFor="active-user" className="checktoggle">
                      checkbox
                    </label>
                  </div>
                </div>
                <ul className="set-menu">
                  <li>
                    <Link to="/provider-security-settings">
                    <Icon.Settings className="react-feather-icon me-2" />  Settings
                    </Link>
                  </li>
                  <li>
                    <Link to="/provider-profile-settings">
                    <Icon.User className="react-feather-icon me-2" /> Your Account
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                    <Icon.CheckCircle className="react-feather-icon me-2" /> Identity
                      Verification
                    </Link>
                  </li>
                </ul>
                <ul className="help-menu">
                  <li>
                    <Link to="#">Help Center</Link>
                  </li>
                  <li>
                    <Link to="/terms-condition">Terms of Condition</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
        </Scrollbars>
        <div
          className="dropdown-menu user-drop"
          id="dropboxes"
          style={{ display: subdroptoggle2 ? 'block' : 'none' }}
        >
          <div className="menu-user">
            <div className="menu-user-img avatar-online">
              <ImageWithBasePath
                src="assets/img/profiles/avatar-02.jpg"
                alt="user"
              />
            </div>
            <div className="menu-user-info">
              <h6>John Smith</h6>
              <p>Active</p>
            </div>
          </div>
          <div className="set-user">
            <p>Set as Away</p>
            <div className="status-toggle">
              <input type="checkbox" id="active-user" className="check" />
              <label htmlFor="active-user" className="checktoggle">
                checkbox
              </label>
            </div>
          </div>
          <ul className="set-menu">
            <li>
              <Link to="/provider-security-settings">
                <Icon.Settings className="react-feather-icon me-2" /> Settings
              </Link>
            </li>
            <li>
              <Link to="/provider-profile-settings">
                <Icon.User className="react-feather-icon me-2" /> Your Account
              </Link>
            </li>
            <li>
              <Link to="#">
                <Icon.CheckCircle className="react-feather-icon me-2" />{' '}
                Identity Verification
              </Link>
            </li>
          </ul>
          <ul className="help-menu">
            <li>
              <Link to="#">Help Center</Link>
            </li>
            <li>
              <Link to="/terms-condition">Terms of Condition</Link>
            </li>
            <li>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
          </ul>
        </div>
        <div
          className="dropdown-menu user-drop"
          id="dropboxes"
          style={{ display: subdroptoggle2 ? 'block' : 'none' }}
        >
          <div className="menu-user">
            <div className="menu-user-img avatar-online">
              <ImageWithBasePath
                src="assets/img/profiles/avatar-02.jpg"
                alt="user"
              />
            </div>
            <div className="menu-user-info">
              <h6>John Smith</h6>
              <p>Active</p>
            </div>
          </div>
          <div className="set-user">
            <p>Set as Away</p>
            <div className="status-toggle">
              <input type="checkbox" id="active-user" className="check" />
              <label htmlFor="active-user" className="checktoggle">
                checkbox
              </label>
            </div>
          </div>
          <ul className="set-menu">
            <li>
              <Link to="/provider-security-settings">
                <Icon.Settings className="react-feather-icon me-2" /> Settings
              </Link>
            </li>
            <li>
              <Link to="/provider-profile-settings">
                <Icon.User className="react-feather-icon me-2" /> Your Account
              </Link>
            </li>
            <li>
              <Link to="#">
                <Icon.CheckCircle className="react-feather-icon me-2" />{' '}
                Identity Verification
              </Link>
            </li>
          </ul>
          <ul className="help-menu">
            <li>
              <Link to="#">Help Center</Link>
            </li>
            <li>
              <Link to="/terms-condition">Terms of Condition</Link>
            </li>
            <li>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
          </ul>
        </div>
      </div>
       {/* Confirmation Modal */}
       <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to logout?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirmLogout}>
            Logout
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ProviderSidebar;
