import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'aos/dist/aos.css';
import Slider from 'react-slick';
import HeaderFive from './header-five';
import FooterFive from './footer-five';
import * as Icon from 'react-feather';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';

const HomeFive = () => {
  const routes = all_routes;
  AOS.init();
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  const handleScroll = () => {
    AOS.refresh();
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const handleItemClick = (index: number) => {
    setSelectedItems((prevSelectedItems) => {
      const updatedSelectedItems = [...prevSelectedItems];
      updatedSelectedItems[index] = !updatedSelectedItems[index];
      return updatedSelectedItems;
    });
  };
  const [selectedItems, setSelectedItems] = useState(Array(10).fill(false));

  const One = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
  };
  const Two = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
  };
  const Three = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
  };
  const Four = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
  };
  const Five = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <HeaderFive />
      {/* Hello world */}
      {/* Feature Section */}
      <section className="featured-categories-section">
        <div className="featured-category-bg-img">
          <ImageWithBasePath
            src="assets/img/car-category-five.png"
            alt="image"
          />
        </div>
        <div className="container">
          <div className="floating-five-main">
            <div className="floating-five-buttom">
              <div className="floating-five-buttom-all">
                <h5>1430 k</h5>
                <p>Cars Washed</p>
              </div>
              <div className="floating-five-buttom-img">
                <i>
                  <Icon.Users />
                </i>
              </div>
            </div>
            <div className="floating-five-buttom">
              <div className="floating-five-buttom-all">
                <h5>987 k</h5>
                <p>Happy Clients</p>
              </div>
              <div className="floating-five-buttom-img floating-fives-buttom-img">
                <i>
                  <Icon.Users />
                </i>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center">
              <div
                className="section-heading car-wash-heading car-wash-feature aos"
                data-aos="fade-up"
              >
                <div className="car-wash-img-five">
                  <ImageWithBasePath
                    src="assets/img/icons/car-wash-header-1.svg"
                    alt="image"
                    className="car-wash-header-one"
                  />
                  <h2>Featured Categories</h2>
                  <ImageWithBasePath
                    src="assets/img/icons/car-wash-header-2.svg"
                    alt="image"
                    className="car-wash-header-two"
                  />
                </div>
                <p>What do you need to find?</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-12">
              <div
                className="service-widget service-two aos"
                data-aos="fade-up"
              >
                <div className="service-img service-four-img">
                  <Link to={routes.serviceDetails1}>
                    <ImageWithBasePath
                      className="img-fluid serv-img"
                      alt="Service Image"
                      src="assets/img/services/service-54.jpg"
                    />
                  </Link>
                </div>
                <div className="service-content service-content-five">
                  <div className="feature-content-bottom">
                    <p>Exterior Washing</p>
                    <Link to="#">
                      <Icon.Users className="feather-users me-2" size={15} />
                      15
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div
                className="service-widget service-two aos"
                data-aos="fade-up"
              >
                <div className="service-img service-four-img">
                  <Link to={routes.serviceDetails1}>
                    <ImageWithBasePath
                      className="img-fluid serv-img"
                      alt="Service Image"
                      src="assets/img/services/service-55.jpg"
                    />
                  </Link>
                </div>
                <div className="service-content service-content-five">
                  <div className="feature-content-bottom">
                    <p>Interior Washing</p>
                    <Link to="#">
                      <Icon.Users className="feather-users me-2" size={15} />
                      05
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div
                className="service-widget service-two aos"
                data-aos="fade-up"
              >
                <div className="service-img service-four-img">
                  <Link to={routes.serviceDetails1}>
                    <ImageWithBasePath
                      className="img-fluid serv-img"
                      alt="Service Image"
                      src="assets/img/services/service-56.jpg"
                    />
                  </Link>
                </div>
                <div className="service-content service-content-five">
                  <div className="feature-content-bottom">
                    <p>Vacuum Cleaning</p>
                    <Link to="#">
                      <Icon.Users className="feather-users me-2" size={15} />
                      95
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div
                className="service-widget service-two aos"
                data-aos="fade-up"
              >
                <div className="service-img service-four-img">
                  <Link to={routes.serviceDetails1}>
                    <ImageWithBasePath
                      className="img-fluid serv-img"
                      alt="Service Image"
                      src="assets/img/services/service-57.jpg"
                    />
                  </Link>
                </div>
                <div className="service-content service-content-five">
                  <div className="feature-content-bottom">
                    <p>Hand Car Wash</p>
                    <Link to="#">
                      <Icon.Users className="feather-users me-2" size={15} />
                      23
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div
                className="service-widget service-two aos"
                data-aos="fade-up"
              >
                <div className="service-img service-four-img">
                  <Link to={routes.serviceDetails1}>
                    <ImageWithBasePath
                      className="img-fluid serv-img"
                      alt="Service Image"
                      src="assets/img/services/service-58.jpg"
                    />
                  </Link>
                </div>
                <div className="service-content service-content-five">
                  <div className="feature-content-bottom">
                    <p>Chemical Car Wash</p>
                    <Link to="#">
                      <Icon.Users className="feather-users me-2" size={15} />
                      10
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div
                className="service-widget service-two aos"
                data-aos="fade-up"
              >
                <div className="service-img service-four-img">
                  <Link to={routes.serviceDetails1}>
                    <ImageWithBasePath
                      className="img-fluid serv-img"
                      alt="Service Image"
                      src="assets/img/services/service-59.jpg"
                    />
                  </Link>
                </div>
                <div className="service-content service-content-five">
                  <div className="feature-content-bottom">
                    <p>Steam Car Wash</p>
                    <Link to="#">
                      <Icon.Users className="feather-users me-2" size={15} />
                      45
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /Feature Section */}
      {/* Featured Services */}
      <section className="feature-category-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div
                className="section-heading car-wash-heading aos"
                data-aos="fade-up"
              >
                <div className="car-wash-img-five">
                  <div className="car-wash-img-five">
                    <ImageWithBasePath
                      src="assets/img/icons/car-wash-header-1.svg"
                      alt="image"
                      className="car-wash-header-one"
                    />
                    <h2>Featured Services</h2>
                    <ImageWithBasePath
                      src="assets/img/icons/car-wash-header-2.svg"
                      alt="image"
                      className="car-wash-header-two"
                    />
                  </div>
                </div>
                <p>What do you need to find?</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-12">
              <div className="service-widget aos" data-aos="fade-up">
                <div className="service-img">
                  <Link to={routes.serviceDetails1}>
                    <ImageWithBasePath
                      className="img-fluid serv-img"
                      alt="Service Image"
                      src="assets/img/services/service-56.jpg"
                    />
                  </Link>
                  <div
                    className="fav-item "
                    key={1}
                    onClick={() => handleItemClick(1)}
                  >
                    <div className="features-service-five">
                      <div className="features-service-rating">
                        <i className="fas fa-star filled" />
                        <span>4.8</span>
                      </div>
                      <h6>Featured</h6>
                    </div>
                    <Link
                      to="#"
                      className={`fav-icon ${
                        selectedItems[1] ? 'selected' : ''
                      }`}
                    >
                      <Icon.Heart size={15} />
                    </Link>
                  </div>
                </div>
                <div className="service-content service-feature-five">
                  <h3 className="title">
                    <Link to={routes.serviceDetails1}>
                      Basic Washing - Compact SUVs
                    </Link>
                  </h3>
                  <p>
                    <Icon.MapPin />
                    New Jersey, USA
                  </p>
                  <div className="feature-services-five">
                    <h6>$50.00</h6>
                    <span>$35.00</span>
                  </div>
                  <div className="feature-service-botton">
                    <div className="feature-service-btn">
                      <Link to={routes.serviceDetails1}>Book Service</Link>
                    </div>
                    <ImageWithBasePath
                      src="assets/img/profiles/avatar-11.jpg"
                      alt="User"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="service-widget aos" data-aos="fade-up">
                <div className="service-img">
                  <Link to={routes.serviceDetails1}>
                    <ImageWithBasePath
                      className="img-fluid serv-img"
                      alt="Service Image"
                      src="assets/img/services/service-57.jpg"
                    />
                  </Link>
                  <div
                    className="fav-item "
                    key={2}
                    onClick={() => handleItemClick(2)}
                  >
                    <div className="features-service-five">
                      <div className="features-service-rating">
                        <i className="fas fa-star filled" />
                        <span>3.8</span>
                      </div>
                      <h6>Featured</h6>
                    </div>
                    <Link
                      to="#"
                      className={`fav-icon ${
                        selectedItems[2] ? 'selected' : ''
                      }`}
                    >
                      <Icon.Heart size={15} />
                    </Link>
                  </div>
                </div>
                <div className="service-content service-feature-five">
                  <h3 className="title">
                    <Link to={routes.serviceDetails1}>
                      Interior Washing - Compact SUV
                    </Link>
                  </h3>
                  <p>
                    <Icon.MapPin />
                    New Jersey, USA
                  </p>
                  <div className="feature-services-five">
                    <h6>$78.00</h6>
                    <span>$25.00</span>
                  </div>
                  <div className="feature-service-botton">
                    <div className="feature-service-btn">
                      <Link to={routes.serviceDetails1}>Book Service</Link>
                    </div>
                    <ImageWithBasePath
                      src="assets/img/profiles/avatar-12.jpg"
                      alt="User"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="service-widget aos" data-aos="fade-up">
                <div className="service-img">
                  <Link to={routes.serviceDetails1}>
                    <ImageWithBasePath
                      className="img-fluid serv-img"
                      alt="Service Image"
                      src="assets/img/services/service-58.jpg"
                    />
                  </Link>
                  <div
                    className="fav-item "
                    key={3}
                    onClick={() => handleItemClick(3)}
                  >
                    <div className="features-service-five">
                      <div className="features-service-rating">
                        <i className="fas fa-star filled" />
                        <span>3.4</span>
                      </div>
                      <h6>Featured</h6>
                    </div>
                    <Link
                      to="#"
                      className={`fav-icon ${
                        selectedItems[3] ? 'selected' : ''
                      }`}
                    >
                      <Icon.Heart size={15} />
                    </Link>
                  </div>
                </div>
                <div className="service-content service-feature-five">
                  <h3 className="title">
                    <Link to={routes.serviceDetails1}>
                      Vacuum Washing - Compact SUV
                    </Link>
                  </h3>
                  <p>
                    <Icon.MapPin />
                    New Jersey, USA
                  </p>
                  <div className="feature-services-five">
                    <h6>$52.00</h6>
                    <span>$35.00</span>
                  </div>
                  <div className="feature-service-botton">
                    <div className="feature-service-btn">
                      <Link to="#">Book Service</Link>
                    </div>
                    <ImageWithBasePath
                      src="assets/img/profiles/avatar-13.jpg"
                      alt="User"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="service-widget aos" data-aos="fade-up">
                <div className="service-img">
                  <Link to={routes.serviceDetails1}>
                    <ImageWithBasePath
                      className="img-fluid serv-img"
                      alt="Service Image"
                      src="assets/img/services/service-59.jpg"
                    />
                  </Link>
                  <div
                    className="fav-item "
                    key={4}
                    onClick={() => handleItemClick(4)}
                  >
                    <div className="features-service-five">
                      <div className="features-service-rating">
                        <i className="fas fa-star filled" />
                        <span>3.9</span>
                      </div>
                      <h6>Featured</h6>
                    </div>
                    <Link
                      to="#"
                      className={`fav-icon ${
                        selectedItems[4] ? 'selected' : ''
                      }`}
                    >
                      <Icon.Heart size={15} />
                    </Link>
                  </div>
                </div>
                <div className="service-content service-feature-five">
                  <h3 className="title">
                    <Link to={routes.serviceDetails1}>
                      Car Wash and Glassing
                    </Link>
                  </h3>
                  <p>
                    <Icon.MapPin />
                    New Jersey, USA
                  </p>
                  <div className="feature-services-five">
                    <h6>$50.00</h6>
                    <span>$35.00</span>
                  </div>
                  <div className="feature-service-botton">
                    <div className="feature-service-btn">
                      <Link to={routes.serviceDetails1}>Book Service</Link>
                    </div>
                    <ImageWithBasePath
                      src="assets/img/profiles/avatar-01.jpg"
                      alt="user"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="service-widget aos" data-aos="fade-up">
                <div className="service-img">
                  <Link to={routes.serviceDetails1}>
                    <ImageWithBasePath
                      className="img-fluid serv-img"
                      alt="Service Image"
                      src="assets/img/services/service-54.jpg"
                    />
                  </Link>
                  <div
                    className="fav-item "
                    key={5}
                    onClick={() => handleItemClick(5)}
                  >
                    <div className="features-service-five">
                      <div className="features-service-rating">
                        <i className="fas fa-star filled" />
                        <span>4.8</span>
                      </div>
                      <h6>Featured</h6>
                    </div>
                    <Link
                      to="#"
                      className={`fav-icon ${
                        selectedItems[5] ? 'selected' : ''
                      }`}
                    >
                      <Icon.Heart size={15} />
                    </Link>
                  </div>
                </div>
                <div className="service-content service-feature-five">
                  <h3 className="title">
                    <Link to={routes.serviceDetails1}>Express Washing</Link>
                  </h3>
                  <p>
                    <Icon.MapPin />
                    New Jersey, USA
                  </p>
                  <div className="feature-services-five">
                    <h6>$50.00</h6>
                    <span>$35.00</span>
                  </div>
                  <div className="feature-service-botton">
                    <div className="feature-service-btn">
                      <Link to={routes.serviceDetails1}>Book Service</Link>
                    </div>
                    <ImageWithBasePath
                      src="assets/img/profiles/avatar-20.jpg"
                      alt="User"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="service-widget aos" data-aos="fade-up">
                <div className="service-img">
                  <Link to={routes.serviceDetails1}>
                    <ImageWithBasePath
                      className="img-fluid serv-img"
                      alt="Service Image"
                      src="assets/img/services/service-55.jpg"
                    />
                  </Link>
                  <div className="fav-item ">
                    <div className="features-service-five">
                      <div className="features-service-rating">
                        <i className="fas fa-star filled" />
                        <span>4.8</span>
                      </div>
                      <h6>Featured</h6>
                    </div>
                    <Link to="#" className="fav-icon fav-icon-five">
                      <Icon.Heart size={15} />
                    </Link>
                  </div>
                </div>
                <div className="service-content service-feature-five">
                  <h3 className="title">
                    <Link to={routes.serviceDetails1}>Exterior Washing</Link>
                  </h3>
                  <p>
                    <Icon.MapPin />
                    New Jersey, USA
                  </p>
                  <div className="feature-services-five">
                    <h6>$50.00</h6>
                    <span>$35.00</span>
                  </div>
                  <div className="feature-service-botton">
                    <div className="feature-service-btn">
                      <Link to={routes.serviceDetails1}>Book Service</Link>
                    </div>
                    <ImageWithBasePath
                      src="assets/img/profiles/avatar-11.jpg"
                      alt="User"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="btn-sec btn-sec-five aos" data-aos="fade-up">
            <Link to={routes.search} className="btn btn-primary btn-view">
              View All
            </Link>
          </div>
        </div>
      </section>
      {/* /Featured Services */}
      {/* Providers Section */}
      <section className="providers-section-five">
        <div className="providers-five-bg-car">
          <ImageWithBasePath src="assets/img/car-provider-bg.png" alt="image" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div
                className="section-heading car-wash-heading aos"
                data-aos="fade-up"
              >
                <div className="car-wash-img-five">
                  <ImageWithBasePath
                    src="assets/img/icons/car-wash-header-1.svg"
                    alt="image"
                    className="car-wash-header-one"
                  />
                  <h2>Top Providers</h2>
                  <ImageWithBasePath
                    src="assets/img/icons/car-wash-header-2.svg"
                    alt="image"
                    className="car-wash-header-two"
                  />
                </div>
                <p>Meet Our Experts</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Slider {...One} className="top-providers-five">
                <div className="providerset">
                  <div className="providerset-img">
                    <Link to={routes.providerDetails}>
                      <ImageWithBasePath
                        src="assets/img/provider/provider-01.jpg"
                        alt="img"
                      />
                    </Link>
                  </div>
                  <div className="providerset-content">
                    <h4>
                      <Link to={routes.providerDetails}>John Smith</Link>
                      <i className="fa fa-check-circle" aria-hidden="true" />
                    </h4>
                    <h5>Electrician</h5>
                    <div className="providerset-prices">
                      <h6>
                        $20.00<span>/hr</span>
                      </h6>
                    </div>
                    <div className="provider-rating">
                      <div className="rating">
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fa-solid fa-star-half-stroke filled" />
                        <span>(320)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="providerset">
                  <div className="providerset-img">
                    <Link to={routes.providerDetails}>
                      <ImageWithBasePath
                        src="assets/img/provider/provider-02.jpg"
                        alt="img"
                      />
                    </Link>
                  </div>
                  <div className="providerset-content">
                    <h4>
                      <Link to={routes.providerDetails}>John Smith</Link>
                      <i className="fa fa-check-circle" aria-hidden="true" />
                    </h4>
                    <h5>Electrician</h5>
                    <div className="providerset-prices">
                      <h6>
                        $20.00<span>/hr</span>
                      </h6>
                    </div>
                    <div className="provider-rating">
                      <div className="rating">
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fa-solid fa-star-half-stroke filled" />
                        <span>(320)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="providerset">
                  <div className="providerset-img">
                    <Link to={routes.providerDetails}>
                      <ImageWithBasePath
                        src="assets/img/provider/provider-03.jpg"
                        alt="img"
                      />
                    </Link>
                  </div>
                  <div className="providerset-content">
                    <h4>
                      <Link to={routes.providerDetails}>John Smith</Link>
                      <i className="fa fa-check-circle" aria-hidden="true" />
                    </h4>
                    <h5>Electrician</h5>
                    <div className="providerset-prices">
                      <h6>
                        $20.00<span>/hr</span>
                      </h6>
                    </div>
                    <div className="provider-rating">
                      <div className="rating">
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fa-solid fa-star-half-stroke filled" />
                        <span>(320)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="providerset">
                  <div className="providerset-img">
                    <Link to={routes.providerDetails}>
                      <ImageWithBasePath
                        src="assets/img/provider/provider-04.jpg"
                        alt="img"
                      />
                    </Link>
                  </div>
                  <div className="providerset-content">
                    <h4>
                      <Link to={routes.providerDetails}>John Smith</Link>
                      <i className="fa fa-check-circle" aria-hidden="true" />
                    </h4>
                    <h5>Electrician</h5>
                    <div className="providerset-prices">
                      <h6>
                        $20.00<span>/hr</span>
                      </h6>
                    </div>
                    <div className="provider-rating">
                      <div className="rating">
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fa-solid fa-star-half-stroke filled" />
                        <span>(320)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="providerset">
                  <div className="providerset-img">
                    <Link to={routes.providerDetails}>
                      <ImageWithBasePath
                        src="assets/img/provider/provider-05.jpg"
                        alt="img"
                      />
                    </Link>
                  </div>
                  <div className="providerset-content">
                    <h4>
                      <Link to={routes.providerDetails}>John Smith</Link>
                      <i className="fa fa-check-circle" aria-hidden="true" />
                    </h4>
                    <h5>Electrician</h5>
                    <div className="providerset-prices">
                      <h6>
                        $20.00<span>/hr</span>
                      </h6>
                    </div>
                    <div className="provider-rating">
                      <div className="rating">
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fas fa-star filled" />
                        <i className="fa-solid fa-star-half-stroke filled" />
                        <span>(320)</span>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
      {/* Providers Section */}
      {/* How It Works */}
      <section className="works-five-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div
                className="section-heading car-wash-heading aos"
                data-aos="fade-up"
              >
                <div className="car-wash-img-five">
                  <ImageWithBasePath
                    src="assets/img/icons/car-wash-header-1.svg"
                    alt="image"
                    className="car-wash-header-one"
                  />
                  <h2>How It Works</h2>
                  <ImageWithBasePath
                    src="assets/img/icons/car-wash-header-2.svg"
                    alt="image"
                    className="car-wash-header-two"
                  />
                </div>
                <p>What do you need to find?</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="works-it-five-button-main">
                <div className="works-it-five-button">
                  <h4>01</h4>
                </div>
                <div className="works-it-dots">
                  <span />
                </div>
                <div className="works-it-lines">
                  <span />
                </div>
              </div>
              <div className="works-five-main">
                <div className="works-five-img">
                  <ImageWithBasePath src="assets/img/works-3.jpg" alt="image" />
                </div>
                <div className="works-five-bottom">
                  <h5>Express Washing</h5>
                  <p>Car wash will take no more than 15 minutes.</p>
                  <i>
                    <Icon.ArrowRight />
                  </i>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="works-it-five-button-main">
                <div className="works-it-five-button">
                  <h4>02</h4>
                </div>
                <div className="works-it-dots">
                  <span />
                </div>
                <div className="works-it-lines">
                  <span />
                </div>
              </div>
              <div className="works-five-main">
                <div className="works-five-img">
                  <ImageWithBasePath src="assets/img/works-2.jpg" alt="image" />
                </div>
                <div className="works-five-bottom">
                  <h5>Quality Assurance</h5>
                  <p>High-quality cleaning agents and premium materials.</p>
                  <i>
                    <Icon.ArrowRight />
                  </i>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="works-it-five-button-main">
                <div className="works-it-five-button">
                  <h4>03</h4>
                </div>
                <div className="works-it-dots">
                  <span />
                </div>
              </div>
              <div className="works-five-main">
                <div className="works-five-img">
                  <ImageWithBasePath src="assets/img/works-1.jpg" alt="image" />
                </div>
                <div className="works-five-bottom">
                  <h5>Reasonable Price</h5>
                  <p>High-quality cleaning agents and premium materials.</p>
                  <i>
                    <Icon.ArrowRight />
                  </i>
                </div>
              </div>
            </div>
          </div>
          <div className="offering-five-all">
            <div className="offering-five-all-img">
              <ImageWithBasePath
                src="assets/img/bubbles-offering-section.png"
                alt="image"
              />
            </div>
            <div className="row">
              <div className="col-lg-8 col-md-12">
                <div className="offering-five-main">
                  <h2>We Are Offering 14 Days Free Trial</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore minim
                    veniam, quis nostrud exercitation ullamco magna aliqua.{' '}
                  </p>
                  <div className="offering-five-button">
                    <Link to={routes.freeTrail} className="btn btn-primary">
                      Try 14 Days Free Trial
                      <i className="feather-arrow-right-circle" />{' '}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12">
                <div className="offering-five-img">
                  <ImageWithBasePath
                    src="assets/img/offering-worker.png"
                    alt="image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /How It Works */}
      {/* Featured Services */}
      <section className="feature-category-section-five">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div
                className="section-heading car-wash-heading aos"
                data-aos="fade-up"
              >
                <div className="car-wash-img-five">
                  <ImageWithBasePath
                    src="assets/img/icons/car-wash-header-1.svg"
                    alt="image"
                    className="car-wash-header-one"
                  />
                  <h2>Featured Services</h2>
                  <ImageWithBasePath
                    src="assets/img/icons/car-wash-header-2.svg"
                    alt="image"
                    className="car-wash-header-two"
                  />
                </div>
                <p>What do you need to find?</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Slider {...Two} className="feature-service-five-slider">
                <div className="service-widget aos" data-aos="fade-up">
                  <div className="service-img">
                    <Link to={routes.serviceDetails1}>
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service Image"
                        src="assets/img/services/service-56.jpg"
                      />
                    </Link>
                    <div
                      className="fav-item "
                      key={6}
                      onClick={() => handleItemClick(6)}
                    >
                      <div className="features-service-five">
                        <div className="features-service-rating">
                          <i className="fas fa-star filled" />
                          <span>4.8</span>
                        </div>
                        <h6>Featured</h6>
                      </div>
                      <Link
                        to="#"
                        className={`fav-icon ${
                          selectedItems[6] ? 'selected' : ''
                        }`}
                      >
                        <Icon.Heart size={15} />
                      </Link>
                    </div>
                  </div>
                  <div className="service-content service-feature-five">
                    <h3 className="title">
                      <Link to={routes.serviceDetails1}>
                        Basic Washing - Compact SUVs
                      </Link>
                    </h3>
                    <p>
                      <Icon.MapPin />
                      New Jersey, USA
                    </p>
                    <div className="feature-services-five">
                      <h6>$50.00</h6>
                      <span>$35.00</span>
                    </div>
                    <div className="feature-service-botton feature-service-botton-five">
                      <div className="feature-service-btn">
                        <Link to="#">Book Service</Link>
                      </div>
                      <ImageWithBasePath
                        src="assets/img/profiles/avatar-11.jpg"
                        alt="User"
                      />
                    </div>
                  </div>
                </div>
                <div className="service-widget aos" data-aos="fade-up">
                  <div className="service-img">
                    <Link to={routes.serviceDetails1}>
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service Image"
                        src="assets/img/services/service-57.jpg"
                      />
                    </Link>
                    <div className="fav-item ">
                      <div className="features-service-five">
                        <div className="features-service-rating">
                          <i className="fas fa-star filled" />
                          <span>4.8</span>
                        </div>
                        <h6>Featured</h6>
                      </div>
                      <Link to="#" className="fav-icon fav-icon-five">
                        <Icon.Heart size={15} />
                      </Link>
                    </div>
                  </div>
                  <div className="service-content service-feature-five">
                    <h3 className="title">
                      <Link to={routes.serviceDetails1}>
                        Basic Washing - Compact SUVs
                      </Link>
                    </h3>
                    <p>
                      <Icon.MapPin />
                      New Jersey, USA
                    </p>
                    <div className="feature-services-five">
                      <h6>$50.00</h6>
                      <span>$35.00</span>
                    </div>
                    <div className="feature-service-botton feature-service-botton-five">
                      <div className="feature-service-btn">
                        <Link to="#">Book Service</Link>
                      </div>
                      <ImageWithBasePath
                        src="assets/img/profiles/avatar-12.jpg"
                        alt="User"
                      />
                    </div>
                  </div>
                </div>
                <div className="service-widget aos" data-aos="fade-up">
                  <div className="service-img">
                    <Link to={routes.serviceDetails1}>
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service Image"
                        src="assets/img/services/service-58.jpg"
                      />
                    </Link>
                    <div className="fav-item ">
                      <div className="features-service-five">
                        <div className="features-service-rating">
                          <i className="fas fa-star filled" />
                          <span>4.8</span>
                        </div>
                        <h6>Featured</h6>
                      </div>
                      <Link to="#" className="fav-icon fav-icon-five">
                        <Icon.Heart size={15} />
                      </Link>
                    </div>
                  </div>
                  <div className="service-content service-feature-five">
                    <h3 className="title">
                      <Link to={routes.serviceDetails1}>
                        Basic Washing - Compact SUVs
                      </Link>
                    </h3>
                    <p>
                      <Icon.MapPin />
                      New Jersey, USA
                    </p>
                    <div className="feature-services-five">
                      <h6>$50.00</h6>
                      <span>$35.00</span>
                    </div>
                    <div className="feature-service-botton feature-service-botton-five">
                      <div className="feature-service-btn">
                        <Link to="#">Book Service</Link>
                      </div>
                      <ImageWithBasePath
                        src="assets/img/profiles/avatar-13.jpg"
                        alt="User"
                      />
                    </div>
                  </div>
                </div>
                <div className="service-widget aos" data-aos="fade-up">
                  <div className="service-img">
                    <Link to={routes.serviceDetails1}>
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service Image"
                        src="assets/img/services/service-56.jpg"
                      />
                    </Link>
                    <div className="fav-item ">
                      <div className="features-service-five">
                        <div className="features-service-rating">
                          <i className="fas fa-star filled" />
                          <span>4.8</span>
                        </div>
                        <h6>Featured</h6>
                      </div>
                      <Link to="#" className="fav-icon fav-icon-five">
                        <Icon.Heart size={15} />
                      </Link>
                    </div>
                  </div>
                  <div className="service-content service-feature-five">
                    <h3 className="title">
                      <Link to={routes.serviceDetails1}>
                        Basic Washing - Compact SUVs
                      </Link>
                    </h3>
                    <p>
                      <Icon.MapPin />
                      New Jersey, USA
                    </p>
                    <div className="feature-services-five">
                      <h6>$50.00</h6>
                      <span>$35.00</span>
                    </div>
                    <div className="feature-service-botton feature-service-botton-five">
                      <div className="feature-service-btn">
                        <Link to="#">Book Service</Link>
                      </div>
                      <ImageWithBasePath
                        src="assets/img/profiles/avatar-11.jpg"
                        alt="User"
                      />
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
      {/* /Featured Services */}
      {/* pricing Section */}
      <section className="pricing-service-five">
        <div className="pricing-service-topimg">
          <ImageWithBasePath
            src="assets/img/bubbles-bottom-section-pricing-1.png"
            alt="image"
            className="pricing-five-img-one"
          />
          <ImageWithBasePath
            src="assets/img/bubbles-bottom-section-pricing-2.png"
            alt="image"
            className="pricing-five-img-two"
          />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div
                className="section-heading car-wash-heading aos"
                data-aos="fade-up"
              >
                <div className="car-wash-img-five">
                  <ImageWithBasePath
                    src="assets/img/icons/car-wash-header-1.svg"
                    alt="image"
                    className="car-wash-header-one"
                  />
                  <h2>Pricing Plans</h2>
                  <ImageWithBasePath
                    src="assets/img/icons/car-wash-header-2.svg"
                    alt="image"
                    className="car-wash-header-two"
                  />
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
              </div>
            </div>
          </div>
          <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-home"
                type="button"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
              >
                MONTHLY
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-profile"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
              >
                YEARLY
              </button>
            </li>
          </ul>
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
            >
              <div
                className="row aos justify-content-center"
                data-aos="fade-up"
              >
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="pricing-plans pricing-plans-five">
                    <div className="pricing-planshead-five">
                      <div className="providerset-prices-five">
                        <h6>$20</h6>
                        <span>/month</span>
                      </div>
                      <h4>Standard</h4>
                    </div>
                    <div className="pricing-planscontent pricing-planscontent-five">
                      <ul>
                        <li>
                          <span>Unleash the power of automation</span>
                        </li>
                        <li>
                          <i className="feather-check me-2 text-success" />
                          <span>Exterior Washing</span>
                        </li>
                        <li>
                          <i className="feather-check me-2 text-success" />
                          <span>Vacuum Cleaning</span>
                        </li>
                        <li>
                          <i className="feather-x me-2 text-danger" />
                          <span>Interior Wet Cleaning</span>
                        </li>
                        <li>
                          <i className="feather-x me-2 text-danger" />
                          <span>Window Wiping</span>
                        </li>
                      </ul>
                      <div className="pricing-btn-five">
                        <Link
                          to={routes.pricingPlan}
                          className="btn btn-primary btn-view"
                        >
                          Choose Plan
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="pricing-plans pricing-plans-five">
                    <div className="pricing-planshead-five">
                      <div className="providerset-prices-five">
                        <h6>$54</h6>
                        <span>/month</span>
                      </div>
                      <h4>Premium</h4>
                    </div>
                    <div className="pricing-planscontent pricing-planscontent-five">
                      <ul>
                        <li>
                          <span>Unleash the power of automation</span>
                        </li>
                        <li>
                          <i className="feather-check me-2 text-success" />
                          <span>Exterior Washing</span>
                        </li>
                        <li>
                          <i className="feather-check me-2 text-success" />
                          <span>Vacuum Cleaning</span>
                        </li>
                        <li>
                          <i className="feather-x me-2 text-danger" />
                          <span>Interior Wet Cleaning</span>
                        </li>
                        <li>
                          <i className="feather-x me-2 text-danger" />
                          <span>Window Wiping</span>
                        </li>
                      </ul>
                      <div className="pricing-btn-five">
                        <Link
                          to={routes.pricingPlan}
                          className="btn btn-primary btn-view"
                        >
                          Choose Plan
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="pricing-plans pricing-plans-five">
                    <div className="pricing-planshead-five">
                      <div className="providerset-prices-five">
                        <h6>$89</h6>
                        <span>/month</span>
                      </div>
                      <h4>Enterprice</h4>
                    </div>
                    <div className="pricing-planscontent pricing-planscontent-five">
                      <ul>
                        <li>
                          <span>Unleash the power of automation</span>
                        </li>
                        <li>
                          <i className="feather-check me-2 text-success" />
                          <span>Exterior Washing</span>
                        </li>
                        <li>
                          <i className="feather-check me-2 text-success" />
                          <span>Vacuum Cleaning</span>
                        </li>
                        <li>
                          <i className="feather-x me-2 text-danger" />
                          <span>Interior Wet Cleaning</span>
                        </li>
                        <li>
                          <i className="feather-x me-2 text-danger" />
                          <span>Window Wiping</span>
                        </li>
                      </ul>
                      <div className="pricing-btn-five">
                        <Link
                          to={routes.pricingPlan}
                          className="btn btn-primary btn-view"
                        >
                          Choose Plan
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              <div className="row aos" data-aos="fade-up">
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="pricing-plans pricing-plans-five">
                    <div className="pricing-planshead-five">
                      <div className="providerset-prices-five">
                        <h6>$20</h6>
                        <span>/month</span>
                      </div>
                      <h4>Standard Pack</h4>
                    </div>
                    <div className="pricing-planscontent pricing-planscontent-five">
                      <ul>
                        <li>
                          <span>Unleash the power of automation</span>
                        </li>
                        <li>
                          <i className="feather-check me-2 text-success" />
                          <span>Exterior Washing</span>
                        </li>
                        <li>
                          <i className="feather-check me-2 text-success" />
                          <span>Vacuum Cleaning</span>
                        </li>
                        <li>
                          <i className="feather-x me-2 text-danger" />
                          <span>Interior Wet Cleaning</span>
                        </li>
                        <li>
                          <i className="feather-x me-2 text-danger" />
                          <span>Window Wiping</span>
                        </li>
                      </ul>
                      <div className="pricing-btn-five">
                        <Link
                          to={routes.pricingPlan}
                          className="btn btn-primary btn-view"
                        >
                          Choose plan
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="pricing-plans pricing-plans-five">
                    <div className="pricing-planshead-five">
                      <div className="providerset-prices-five">
                        <h6>$54</h6>
                        <span>/month</span>
                      </div>
                      <h4>Premium Pack</h4>
                    </div>
                    <div className="pricing-planscontent pricing-planscontent-five">
                      <ul>
                        <li>
                          <span>Unleash the power of automation</span>
                        </li>
                        <li>
                          <i className="feather-check me-2 text-success" />
                          <span>Exterior Washing</span>
                        </li>
                        <li>
                          <i className="feather-check me-2 text-success" />
                          <span>Vacuum Cleaning</span>
                        </li>
                        <li>
                          <i className="feather-x me-2 text-danger" />
                          <span>Interior Wet Cleaning</span>
                        </li>
                        <li>
                          <i className="feather-x me-2 text-danger" />
                          <span>Window Wiping</span>
                        </li>
                      </ul>
                      <div className="pricing-btn-five">
                        <Link
                          to={routes.search}
                          className="btn btn-primary btn-view"
                        >
                          Choose plan
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="pricing-plans pricing-plans-five">
                    <div className="pricing-planshead-five">
                      <div className="providerset-prices-five">
                        <h6>$89</h6>
                        <span>/month</span>
                      </div>
                      <h4>Ultimate Pack</h4>
                    </div>
                    <div className="pricing-planscontent pricing-planscontent-five">
                      <ul>
                        <li>
                          <span>Unleash the power of automation</span>
                        </li>
                        <li>
                          <i className="feather-check me-2 text-success" />
                          <span>Exterior Washing</span>
                        </li>
                        <li>
                          <i className="feather-check me-2 text-success" />
                          <span>Vacuum Cleaning</span>
                        </li>
                        <li>
                          <i className="feather-x me-2 text-danger" />
                          <span>Interior Wet Cleaning</span>
                        </li>
                        <li>
                          <i className="feather-x me-2 text-danger" />
                          <span>Window Wiping</span>
                        </li>
                      </ul>
                      <div className="pricing-btn-five">
                        <Link
                          to={routes.search}
                          className="btn btn-primary btn-view"
                        >
                          Choose plan
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /pricing Section */}
      {/* testimonals section */}
      <section className="testimonals-five-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div
                className="section-heading car-wash-heading aos"
                data-aos="fade-up"
              >
                <div className="car-wash-img-five">
                  <ImageWithBasePath
                    src="assets/img/icons/car-wash-header-1.svg"
                    alt="image"
                    className="car-wash-header-one"
                  />
                  <h2>Our Testimonials</h2>
                  <ImageWithBasePath
                    src="assets/img/icons/car-wash-header-2.svg"
                    alt="image"
                    className="car-wash-header-two"
                  />
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="car-wash-bg-five-main">
                <div className="car-wash-bg-five">
                  <div className="row justify-content-center">
                    <div className="col-md-8">
                      <Slider
                        {...Five}
                        className="car-testimonials-five-slider"
                      >
                        <div className="testimonials-five-top">
                          <div className="test-five-img">
                            <ImageWithBasePath
                              src="assets/img/profiles/avatar-07.jpg"
                              alt="images"
                            />
                          </div>
                          <h2>Best Design and delivered in time</h2>
                          <p>
                            Nay likely her length sooner thrown sex lively
                            income. The expense windows adapted sir. Wrong widen
                            drawn ample eat off doors money. Offending belonging
                            promotion provision an be oh consulted ourselves it.
                            Blessing welcomed ladyship she met humoured sir
                            breeding her. Six curiosity day assurance bed
                            necessary.
                          </p>
                          <div className="rating">
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                          </div>
                          <h5>Mansur</h5>
                          <h6>CEO @ airbnb</h6>
                        </div>
                        <div className="testimonials-five-top">
                          <div className="test-five-img">
                            <ImageWithBasePath
                              src="assets/img/profiles/avatar-08.jpg"
                              alt="image"
                            />
                          </div>
                          <h2>Best Design and delivered in time</h2>
                          <p>
                            Nay likely her length sooner thrown sex lively
                            income. The expense windows adapted sir. Wrong widen
                            drawn ample eat off doors money. Offending belonging
                            promotion provision an be oh consulted ourselves it.
                            Blessing welcomed ladyship she met humoured sir
                            breeding her. Six curiosity day assurance bed
                            necessary.
                          </p>
                          <div className="rating">
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                          </div>
                          <h5>Mansur</h5>
                          <h6>CEO @ airbnb</h6>
                        </div>
                        <div className="testimonials-five-top">
                          <div className="test-five-img">
                            <ImageWithBasePath
                              src="assets/img/profiles/avatar-09.jpg"
                              alt="User"
                            />
                          </div>
                          <h2>Best Design and delivered in time</h2>
                          <p>
                            Nay likely her length sooner thrown sex lively
                            income. The expense windows adapted sir. Wrong widen
                            drawn ample eat off doors money. Offending belonging
                            promotion provision an be oh consulted ourselves it.
                            Blessing welcomed ladyship she met humoured sir
                            breeding her. Six curiosity day assurance bed
                            necessary.
                          </p>
                          <div className="rating">
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                          </div>
                          <h5>Mansur</h5>
                          <h6>CEO @ airbnb</h6>
                        </div>
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /testimonals section */}
      {/* Partners Section */}
      <section className="blog-section blog-five-section">
        <div className="container">
          <div className="col-md-12 text-center">
            <div
              className="section-heading car-wash-heading aos"
              data-aos="fade-up"
            >
              <div className="car-wash-img-five">
                <ImageWithBasePath
                  src="assets/img/icons/car-wash-header-1.svg"
                  alt="image"
                  className="car-wash-header-one"
                />
                <h2>Our Partner</h2>
                <ImageWithBasePath
                  src="assets/img/icons/car-wash-header-2.svg"
                  alt="image"
                  className="car-wash-header-two"
                />
              </div>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
            </div>
          </div>
          <Slider {...Three} className="car-testimonials-five-slider">
            <div className="partner-img">
              <ImageWithBasePath
                src="assets/img/partner/partner1.svg"
                alt="img"
              />
            </div>
            <div className="partner-img">
              <ImageWithBasePath
                src="assets/img/partner/partner2.svg"
                alt="img"
              />
            </div>
            <div className="partner-img">
              <ImageWithBasePath
                src="assets/img/partner/partner3.svg"
                alt="img"
              />
            </div>
            <div className="partner-img">
              <ImageWithBasePath
                src="assets/img/partner/partner4.svg"
                alt="img"
              />
            </div>
            <div className="partner-img">
              <ImageWithBasePath
                src="assets/img/partner/partner5.svg"
                alt="img"
              />
            </div>
            <div className="partner-img">
              <ImageWithBasePath
                src="assets/img/partner/partner6.svg"
                alt="img"
              />
            </div>
          </Slider>
        </div>
      </section>
      {/* Partners Section */}
      {/* Service Section */}
      <section className="car-blogs-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div
                className="section-heading car-wash-heading aos"
                data-aos="fade-up"
              >
                <div className="car-wash-img-five">
                  <ImageWithBasePath
                    src="assets/img/icons/car-wash-header-1.svg"
                    alt="image"
                    className="car-wash-header-one"
                  />
                  <h2>Our Blogs</h2>
                  <ImageWithBasePath
                    src="assets/img/icons/car-wash-header-2.svg"
                    alt="image"
                    className="car-wash-header-two"
                  />
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Slider {...Four} className="car-blog-slider">
                <div className="service-widget aos" data-aos="fade-up">
                  <div className="service-img">
                    <Link to={routes.serviceDetails1}>
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service Image"
                        src="assets/img/services/service-51.jpg"
                      />
                    </Link>
                    <div className="fav-item">
                      <Link to={routes.categories}>
                        <span className="item-cat item-car">22-12-2023</span>
                      </Link>
                    </div>
                  </div>
                  <div className="service-content">
                    <h3 className="title">
                      <Link to={routes.serviceDetails1}>
                        The Benefits Of Keeping Company Cars Clean
                      </Link>
                    </h3>
                    <p>
                      Donec vel pellentesque nisl, molestie tempus ligula. Cras
                      massa turpis, pretium nec placerat ornare, sodales ac
                      urna. Sed commodo semper fermentum.
                    </p>
                    <div className="serv-info">
                      <Link
                        to={routes.serviceDetails1}
                        className="btn btn-book"
                      >
                        Read More
                      </Link>
                      <ImageWithBasePath
                        src="assets/img/profiles/avatar-17.jpg"
                        alt="User"
                      />
                    </div>
                  </div>
                </div>
                <div className="service-widget aos" data-aos="fade-up">
                  <div className="service-img">
                    <Link to={routes.blogDetails}>
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service Image"
                        src="assets/img/services/service-52.jpg"
                      />
                    </Link>
                    <div className="fav-item">
                      <Link to={routes.blogDetails}>
                        <span className="item-cat item-car">17-12-2023</span>
                      </Link>
                    </div>
                  </div>
                  <div className="service-content">
                    <h3 className="title">
                      <Link to={routes.blogDetails}>
                        What Has Changed In The Hand Car Wash Industry?
                      </Link>
                    </h3>
                    <p>
                      Donec vel pellentesque nisl, molestie tempus ligula. Cras
                      massa turpis, pretium nec placerat ornare, sodales ac
                      urna. Sed commodo semper fermentum.
                    </p>
                    <div className="serv-info">
                      <Link to={routes.blogDetails} className="btn btn-book">
                        Read More
                      </Link>
                      <ImageWithBasePath
                        src="assets/img/profiles/avatar-18.jpg"
                        alt="User"
                      />
                    </div>
                  </div>
                </div>
                <div className="service-widget aos" data-aos="fade-up">
                  <div className="service-img">
                    <Link to={routes.blogDetails}>
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service Image"
                        src="assets/img/services/service-53.jpg"
                      />
                    </Link>
                    <div className="fav-item">
                      <Link to={routes.blogDetails}>
                        <span className="item-cat item-car">28-02-2023</span>
                      </Link>
                    </div>
                  </div>
                  <div className="service-content">
                    <h3 className="title">
                      <Link to={routes.blogDetails}>
                        The Benefits Of Keeping Company Cars Clean
                      </Link>
                    </h3>
                    <p>
                      Donec vel pellentesque nisl, molestie tempus ligula. Cras
                      massa turpis, pretium nec placerat ornare, sodales ac
                      urna. Sed commodo semper fermentum.
                    </p>
                    <div className="serv-info">
                      <Link to={routes.blogDetails} className="btn btn-book">
                        Read More
                      </Link>
                      <ImageWithBasePath
                        src="assets/img/profiles/avatar-19.jpg"
                        alt="User"
                      />
                    </div>
                  </div>
                </div>
                <div className="service-widget aos" data-aos="fade-up">
                  <div className="service-img">
                    <Link to={routes.blogDetails}>
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service Image"
                        src="assets/img/services/service-51.jpg"
                      />
                    </Link>
                    <div className="fav-item">
                      <Link to={routes.blogDetails}>
                        <span className="item-cat item-car">17-12-2023</span>
                      </Link>
                    </div>
                  </div>
                  <div className="service-content">
                    <h3 className="title">
                      <Link to={routes.blogDetails}>
                        What Has Changed In The Hand Car Wash Industry?
                      </Link>
                    </h3>
                    <p>
                      Donec vel pellentesque nisl, molestie tempus ligula. Cras
                      massa turpis, pretium nec placerat ornare, sodales ac
                      urna. Sed commodo semper fermentum.
                    </p>
                    <div className="serv-info">
                      <Link to={routes.blogDetails} className="btn btn-book">
                        Read More
                      </Link>
                      <ImageWithBasePath
                        src="assets/img/profiles/avatar-18.jpg"
                        alt="User"
                      />
                    </div>
                  </div>
                </div>
                <div className="service-widget aos" data-aos="fade-up">
                  <div className="service-img">
                    <Link to={routes.blogDetails}>
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service Image"
                        src="assets/img/services/service-52.jpg"
                      />
                    </Link>
                    <div className="fav-item">
                      <Link to={routes.blogDetails}>
                        <span className="item-cat item-car">17-12-2023</span>
                      </Link>
                    </div>
                  </div>
                  <div className="service-content">
                    <h3 className="title">
                      <Link to={routes.blogDetails}>
                        What Has Changed In The Hand Car Wash Industry?
                      </Link>
                    </h3>
                    <p>
                      Donec vel pellentesque nisl, molestie tempus ligula. Cras
                      massa turpis, pretium nec placerat ornare, sodales ac
                      urna. Sed commodo semper fermentum.
                    </p>
                    <div className="serv-info">
                      <Link to={routes.blogDetails} className="btn btn-book">
                        Read More
                      </Link>
                      <ImageWithBasePath
                        src="assets/img/profiles/avatar-18.jpg"
                        alt="User"
                      />
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
      {/* /Service Section */}
      {/* App Section */}
      <section className="app-five-section">
        <div className="container">
          <div className="app-sec app-sec-five">
            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="col-md-12">
                  <div className="heading aos" data-aos="fade-up">
                    <h2>Download Our App</h2>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </p>
                    <h6>Scan the QR code to get the app now</h6>
                  </div>
                </div>
                <div className="downlaod-btn aos" data-aos="fade-up">
                  <div className="scan-img">
                    <ImageWithBasePath
                      src="assets/img/scan-img.png"
                      className="img-fluid"
                      alt="img"
                    />
                  </div>
                  <Link to="#">
                    <ImageWithBasePath
                      src="assets/img/googleplay-five.png"
                      alt="img"
                    />
                  </Link>
                  <Link to="#">
                    <ImageWithBasePath
                      src="assets/img/apple-five.png"
                      alt="img"
                    />
                  </Link>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="appimg-set aos" data-aos="fade-up">
                  <ImageWithBasePath
                    src="assets/img/app-sec-five.png"
                    className="img-fluid"
                    alt="img"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /App Section */}
      <FooterFive />
    </>
  );
};

export default HomeFive;
