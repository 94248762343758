import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import * as Icon from 'react-feather'
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath'
import { Link } from 'react-router-dom';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { base_url } from '../../../../constant';

const routes = all_routes;

const ProviderOrder = () => {

    const navigate = useNavigate();

    const [techorders, settechorders] = useState([]);
    const [neworders, setneworders] = useState([]);
    const [approved, setapproved] = useState([]);
    const [rejected, setrejected] = useState([]);
    const [newenquiry, setnewenquiry] = useState([]);
    const [addressingissue, setaddressingissue] = useState([]);
    const [estimating, setestimating] = useState([]);
    const [negosiating, setnegosiating] = useState([]);
    const [orderconformed, setorderconformed] = useState([]);
    const [workinprogress, setworkinprogress] = useState([]);
    const [workdone, setworkdone] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        gettechorders();
        getMyNeworders();
        getapprovedstatus();
        getrejectstatus();
        getnewenquiry();
        getenqstatus_addressingissue();
        getenqstatus_estimating();
        getenqstatus_negosiatingforprize();
        getenqstatus_orderconformed();
        getenqstatus_workinprogress();
        getenqstatus_workdone();
    }, []);

    const gettechorders = async () => {
        setIsLoading(true);
        try {
          const token = localStorage.getItem('token');
          const response = await fetch(`${base_url}api/gettechorders`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });
          if (!response.ok) {
            setIsLoading(false);
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          setIsLoading(false);
          settechorders(data); // Assuming data is an array of countries
        } catch (error) {
            setIsLoading(false);
          console.error('Error fetching countries:', error);
        }
      };

      const getMyNeworders = async () => {
        setIsLoading(true);
        try {
          const token = localStorage.getItem('token');
          const response = await fetch(`${base_url}api/getMyNeworders/3`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });
          if (!response.ok) {
            setIsLoading(false);
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          setIsLoading(false);
          setneworders(data.new); // Assuming data is an array of countries
        } catch (error) {
            setIsLoading(false);
          console.error('Error fetching countries:', error);
        }
      };

      const getapprovedstatus = async () => {
        setIsLoading(true);
        try {
          const token = localStorage.getItem('token');
          const response = await fetch(`${base_url}api/getapprovedstatus`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });
          if (!response.ok) {
            setIsLoading(false);
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          setIsLoading(false);
          setapproved(data); // Assuming data is an array of countries
        } catch (error) {
            setIsLoading(false);
          console.error('Error fetching countries:', error);
        }
      };

      const getrejectstatus = async () => {
        setIsLoading(true);
        try {
          const token = localStorage.getItem('token');
          const response = await fetch(`${base_url}api/getrejectstatus`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });
          if (!response.ok) {
            setIsLoading(false);
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          setIsLoading(false);
          setrejected(data); // Assuming data is an array of countries
        } catch (error) {
            setIsLoading(false);
          console.error('Error fetching countries:', error);
        }
      };

      const getnewenquiry = async () => {
        setIsLoading(true);
        try {
          const token = localStorage.getItem('token');
          const response = await fetch(`${base_url}api/getnewenquiry`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });
          if (!response.ok) {
            setIsLoading(false);
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          setIsLoading(false);
          setnewenquiry(data); // Assuming data is an array of countries
        } catch (error) {
            setIsLoading(false);
          console.error('Error fetching countries:', error);
        }
      };

      const getenqstatus_addressingissue = async () => {
        setIsLoading(true);
        try {
          const token = localStorage.getItem('token');
          const response = await fetch(`${base_url}api/getenqstatus_addressingissue`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });
          if (!response.ok) {
            setIsLoading(false);
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          setIsLoading(false);
          setaddressingissue(data); // Assuming data is an array of countries
        } catch (error) {
            setIsLoading(false);
          console.error('Error fetching countries:', error);
        }
      };

      const getenqstatus_estimating = async () => {
        setIsLoading(true);
        try {
          const token = localStorage.getItem('token');
          const response = await fetch(`${base_url}api/getenqstatus_estimating`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });
          if (!response.ok) {
            setIsLoading(false);
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          setIsLoading(false);
          setestimating(data); // Assuming data is an array of countries
        } catch (error) {
            setIsLoading(false);
          console.error('Error fetching countries:', error);
        }
      };

      const getenqstatus_negosiatingforprize = async () => {
        setIsLoading(true);
        try {
          const token = localStorage.getItem('token');
          const response = await fetch(`${base_url}api/getenqstatus_negosiatingforprize`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });
          if (!response.ok) {
            setIsLoading(false);
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          setIsLoading(false);
          setnegosiating(data); // Assuming data is an array of countries
        } catch (error) {
            setIsLoading(false);
          console.error('Error fetching countries:', error);
        }
      };

      const getenqstatus_orderconformed = async () => {
        setIsLoading(true);
        try {
          const token = localStorage.getItem('token');
          const response = await fetch(`${base_url}api/getenqstatus_orderconformed`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });
          if (!response.ok) {
            setIsLoading(false);
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          setIsLoading(false);
          setorderconformed(data); // Assuming data is an array of countries
        } catch (error) {
            setIsLoading(false);
          console.error('Error fetching countries:', error);
        }
      };

      const getenqstatus_workinprogress = async () => {
        setIsLoading(true);
        try {
          const token = localStorage.getItem('token');
          const response = await fetch(`${base_url}api/getenqstatus_workinprogress`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });
          if (!response.ok) {
            setIsLoading(false);
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          setIsLoading(false);
          setworkinprogress(data); // Assuming data is an array of countries
        } catch (error) {
            setIsLoading(false);
          console.error('Error fetching countries:', error);
        }
      };

      const getenqstatus_workdone = async () => {
        setIsLoading(true);
        try {
          const token = localStorage.getItem('token');
          const response = await fetch(`${base_url}api/getenqstatus_workdone`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });
          if (!response.ok) {
            setIsLoading(false);
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          setIsLoading(false);
          setworkdone(data); // Assuming data is an array of countries
        } catch (error) {
            setIsLoading(false);
          console.error('Error fetching countries:', error);
        }
      };

    return (
        <>
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
            <>
        <div className="page-wrapper">
        <div className="content container-fluid">
            {/* /Page Header */}
      <div className="row">
        <div className="col-md-12">
          <div className="tab-list">
            <ul className="nav">
              <li>
                <Link to="#" className="active" data-bs-toggle="tab" data-bs-target="#orders-admin">Orders from Admin</Link>
              </li>
              <li>
                <Link to="#" data-bs-toggle="tab" data-bs-target="#new-tech-enquiry">New Tech Enquiry</Link>
              </li>
              <li>
                <Link to="#" data-bs-toggle="tab" data-bs-target="#approved-orders">Approved Orders</Link>
              </li>
              <li>
                <Link to="#" data-bs-toggle="tab" data-bs-target="#rejected-orders">Rejected Orders</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="tab-content pt-0">				
        <div className="tab-pane active" id="orders-admin">				
          <div className="row">	
            {/* content today order */}
            <div className='card'>
                <br />
                <h6>View Orders</h6>
                <p>Count: {techorders.length}</p>
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                            <th>View</th>
                              <th>Order Id</th>
                              <th>Issue Title</th>
                              <th>Specialization</th>
                              <th>Sub Specialization</th>
                              <th>Address_on</th>
                              <th>Contact Number</th>
                              <th>Time Slot</th>
                              <th>Customer Name</th>
                              <th>City</th>
                              <th>Pincode</th>
                            </tr>
                        </thead>
                        <tbody>
                          {techorders.map((item : any) => (
                            <tr key={item.id}>
                            <td><button className="btn btn-info me-2"><Icon.Eye className="react-feather-custom" /></button></td>
                            <td>{item.order_type}</td>
                            <td>{item.issue_title}</td>
                            <td>{item.specializationinfo?.specialization}</td>
                            <td>{item.subspecializationinfo?.sub_specialization}</td>
                            <td>{item.address_on}</td>
                            <td>{item.contact_number}</td>
                            <td>{item.time}</td>
                            <td>{item.created_by_data?.name}</td>
                            <td>{item.cityinfo?.city}</td>
                            <td>{item.pincodeinfo?.pincode}</td>
                          </tr>
                          ))}
                          </tbody>
                        </table>
                        </div>
            </div>
          </div>
          </div>
          <div className="tab-pane fade" id="new-tech-enquiry">				
          <div className="row">
          {/* content tomorrow order	 */}
          <div className='card'>
                <br />
                <h6>View Orders</h6>
                <p>Count: {neworders.length}</p>
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                            <th>View</th>
                              <th>Order Id</th>
                              <th>Issue Title</th>
                              <th>Specializatione</th>
                              <th>Sub Specialization</th>
                              <th>Address_on</th>
                              <th>Contact Number</th>
                              <th>Time Slot</th>
                              <th>Customer Name</th>
                              <th>City</th>
                              <th>Pincode</th>
                            </tr>
                        </thead>
                        <tbody>
                          {neworders.map((item:any) => (
                             <tr key={item.id}>
                             <td><button className="btn btn-info me-2"><Icon.Eye className="react-feather-custom" /></button></td>
                             <td>{item.order_type}</td>
                             <td>{item.issue_title}</td>
                             <td>{item.specializationinfo?.specialization}</td>
                             <td>{item.subspecializationinfo?.sub_specialization}</td>
                             <td>{item.address_on}</td>
                             <td>{item.contact_number}</td>
                             <td>{item.time}</td>
                             <td>{item.created_by_data?.name}</td>
                             <td>{item.cityinfo?.city}</td>
                             <td>{item.pincodeinfo?.pincode}</td>
                           </tr>
                          ))}
                          </tbody>
                        </table>
                        </div>
            </div>
          </div>
          </div>
          <div className="tab-pane fade" id="approved-orders">				
          <div className="row">
          {/* content day after tomorrow order	 */}

          <div className="row">
        <div className="col-md-12">
          <div className="tab-list">
            <ul className="nav">
              <li>
                <Link to="#" className="active" data-bs-toggle="tab" data-bs-target="#new-enquiry">New Enquiry</Link>
              </li>
              <li>
                <Link to="#" data-bs-toggle="tab" data-bs-target="#addressing-issue">Addressing Issue</Link>
              </li>
              <li>
                <Link to="#" data-bs-toggle="tab" data-bs-target="#estimating">Estimating</Link>
              </li>
              <li>
                <Link to="#" data-bs-toggle="tab" data-bs-target="#negosiating-for-price">Negosiating for Price</Link>
              </li>
              <li>
                <Link to="#" data-bs-toggle="tab" data-bs-target="#order-conformed">Order Conformed</Link>
              </li>
              <li>
                <Link to="#" data-bs-toggle="tab" data-bs-target="#work-in-progress">Work in progress</Link>
              </li>
              <li>
                <Link to="#" data-bs-toggle="tab" data-bs-target="#work-done">Work Done</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
          </div>
          <div className="tab-content pt-0">
          <div className="tab-pane active" id="new-enquiry">				
          <div className="row">
          {/* content day after tomorrow order	 */}
          <div className='card'>
            <br />
                <h6>View Orders</h6>
                <p>Count: {newenquiry.length}</p>
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                              <th>View</th>
                              <th>Order Id</th>
                              <th>Issue Title</th>
                              <th>Specialization</th>
                              <th>Sub Specialization</th>
                              <th>Address_on</th>
                              <th>Contact Number</th>
                              <th>Time Slot</th>
                              <th>Customer Name</th>
                              <th>City</th>
                              <th>Pincode</th>
                            </tr>
                        </thead>
                        <tbody>
                          {newenquiry.map((item : any) => (
                            <tr key={item.id}>
                              <td><button className="btn btn-info me-2" onClick={() => navigate(routes.providerOrderView, { state: { id: item.id } })}><Icon.Eye className="react-feather-custom" /></button></td>
                              <td>{item.order_type}</td>
                              <td>{item.issue_title}</td>
                              <td>{item.specializationinfo?.specialization}</td>
                              <td>{item.subspecializationinfo?.sub_specialization}</td>
                              <td>{item.address_on}</td>
                              <td>{item.contact_number}</td>
                              <td>{item.time}</td>
                              <td>{item.created_by_data?.name}</td>
                              <td>{item.cityinfo?.city}</td>
                              <td>{item.pincodeinfo?.pincode}</td>
                            </tr>
                          ))}
                          </tbody>
                        </table>
                        </div>
            </div>
          </div>
          </div>
          <div className="tab-pane fade" id="addressing-issue">				
          <div className="row">
          {/* content day after tomorrow order	 */}
          <div className='card'>
            <br />
                <h6>View Orders</h6>
                <p>Count: {addressingissue.length}</p>
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                              <th>View</th>
                              <th>Order Id</th>
                              <th>Issue Title</th>
                              <th>Specialization</th>
                              <th>Sub Specialization</th>
                              <th>Address_on</th>
                              <th>Contact Number</th>
                              <th>Time Slot</th>
                              <th>Customer Name</th>
                              <th>City</th>
                              <th>Pincode</th>
                            </tr>
                        </thead>
                        <tbody>
                        {addressingissue.map((item : any) => (
                            <tr key={item.id}>
                              <td><button className="btn btn-info me-2"><Icon.Eye className="react-feather-custom" /></button></td>
                              <td>{item.order_type}</td>
                              <td>{item.issue_title}</td>
                              <td>{item.specializationinfo?.specialization}</td>
                              <td>{item.subspecializationinfo?.sub_specialization}</td>
                              <td>{item.address_on}</td>
                              <td>{item.contact_number}</td>
                              <td>{item.time}</td>
                              <td>{item.created_by_data?.name}</td>
                              <td>{item.cityinfo?.city}</td>
                              <td>{item.pincodeinfo?.pincode}</td>
                            </tr>
                          ))}
                          </tbody>
                        </table>
                        </div>
            </div>
          </div>
          </div>
          <div className="tab-pane fade" id="estimating">				
          <div className="row">
          {/* content day after tomorrow order	 */}
          <div className='card'>
            <br />
                <h6>View Orders</h6>
                <p>Count: {estimating.length}</p>
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                              <th>View</th>
                              <th>Order Id</th>
                              <th>Issue Title</th>
                              <th>Specialization</th>
                              <th>Sub Specialization</th>
                              <th>Address_on</th>
                              <th>Contact Number</th>
                              <th>Time Slot</th>
                              <th>Customer Name</th>
                              <th>City</th>
                              <th>Pincode</th>
                            </tr>
                        </thead>
                        <tbody>
                        {estimating.map((item : any) => (
                            <tr key={item.id}>
                              <td><button className="btn btn-info me-2"><Icon.Eye className="react-feather-custom" /></button></td>
                              <td>{item.order_type}</td>
                              <td>{item.issue_title}</td>
                              <td>{item.specializationinfo?.specialization}</td>
                              <td>{item.subspecializationinfo?.sub_specialization}</td>
                              <td>{item.address_on}</td>
                              <td>{item.contact_number}</td>
                              <td>{item.time}</td>
                              <td>{item.created_by_data?.name}</td>
                              <td>{item.cityinfo?.city}</td>
                              <td>{item.pincodeinfo?.pincode}</td>
                            </tr>
                          ))}
                          </tbody>
                        </table>
                        </div>
            </div>
          </div>
          </div>
          <div className="tab-pane fade" id="negosiating-for-price">				
          <div className="row">
          {/* content day after tomorrow order	 */}
          <div className='card'>
            <br />
                <h6>View Orders</h6>
                <p>Count: {negosiating.length}</p>
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                              <th>View</th>
                              <th>Order Id</th>
                              <th>Issue Title</th>
                              <th>Specialization</th>
                              <th>Sub Specialization</th>
                              <th>Address_on</th>
                              <th>Contact Number</th>
                              <th>Time Slot</th>
                              <th>Customer Name</th>
                              <th>City</th>
                              <th>Pincode</th>
                            </tr>
                        </thead>
                        <tbody>
                        {negosiating.map((item : any) => (
                            <tr key={item.id}>
                              <td><button className="btn btn-info me-2"><Icon.Eye className="react-feather-custom" /></button></td>
                              <td>{item.order_type}</td>
                              <td>{item.issue_title}</td>
                              <td>{item.specializationinfo?.specialization}</td>
                              <td>{item.subspecializationinfo?.sub_specialization}</td>
                              <td>{item.address_on}</td>
                              <td>{item.contact_number}</td>
                              <td>{item.time}</td>
                              <td>{item.created_by_data?.name}</td>
                              <td>{item.cityinfo?.city}</td>
                              <td>{item.pincodeinfo?.pincode}</td>
                            </tr>
                          ))}
                          </tbody>
                        </table>
                        </div>
            </div>
          </div>
          </div>
          <div className="tab-pane fade" id="order-conformed">				
          <div className="row">
          {/* content day after tomorrow order	 */}
          <div className='card'>
            <br />
                <h6>View Orders</h6>
                <p>Count: {orderconformed.length}</p>
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                              <th>View</th>
                              <th>Order Id</th>
                              <th>Issue Title</th>
                              <th>Specialization</th>
                              <th>Sub Specialization</th>
                              <th>Address_on</th>
                              <th>Contact Number</th>
                              <th>Time Slot</th>
                              <th>Customer Name</th>
                              <th>City</th>
                              <th>Pincode</th>
                            </tr>
                        </thead>
                        <tbody>
                        {orderconformed.map((item : any) => (
                            <tr key={item.id}>
                              <td><button className="btn btn-info me-2"><Icon.Eye className="react-feather-custom" /></button></td>
                              <td>{item.order_type}</td>
                              <td>{item.issue_title}</td>
                              <td>{item.specializationinfo?.specialization}</td>
                              <td>{item.subspecializationinfo?.sub_specialization}</td>
                              <td>{item.address_on}</td>
                              <td>{item.contact_number}</td>
                              <td>{item.time}</td>
                              <td>{item.created_by_data?.name}</td>
                              <td>{item.cityinfo?.city}</td>
                              <td>{item.pincodeinfo?.pincode}</td>
                            </tr>
                          ))}
                          </tbody>
                        </table>
                        </div>
            </div>
          </div>
          </div>
          <div className="tab-pane fade" id="work-in-progress">				
          <div className="row">
          {/* content day after tomorrow order	 */}
          <div className='card'>
            <br />
                <h6>View Orders</h6>
                <p>Count: {workinprogress.length}</p>
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                              <th>View</th>
                              <th>Order Id</th>
                              <th>Issue Title</th>
                              <th>Specialization</th>
                              <th>Sub Specialization</th>
                              <th>Address_on</th>
                              <th>Contact Number</th>
                              <th>Time Slot</th>
                              <th>Customer Name</th>
                              <th>City</th>
                              <th>Pincode</th>
                            </tr>
                        </thead>
                        <tbody>
                        {workinprogress.map((item : any) => (
                            <tr key={item.id}>
                              <td><button className="btn btn-info me-2"><Icon.Eye className="react-feather-custom" /></button></td>
                              <td>{item.order_type}</td>
                              <td>{item.issue_title}</td>
                              <td>{item.specializationinfo?.specialization}</td>
                              <td>{item.subspecializationinfo?.sub_specialization}</td>
                              <td>{item.address_on}</td>
                              <td>{item.contact_number}</td>
                              <td>{item.time}</td>
                              <td>{item.created_by_data?.name}</td>
                              <td>{item.cityinfo?.city}</td>
                              <td>{item.pincodeinfo?.pincode}</td>
                            </tr>
                          ))}
                          </tbody>
                        </table>
                        </div>
            </div>
          </div>
          </div>
          </div>
          <div className="tab-pane fade" id="work-done">				
          <div className="row">
          {/* content day after tomorrow order	 */}
          <div className='card'>
            <br />
                <h6>View Orders</h6>
                <p>Count: {workdone.length}</p>
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                              <th>View</th>
                              <th>Order Id</th>
                              <th>Issue Title</th>
                              <th>Specialization</th>
                              <th>Sub Specialization</th>
                              <th>Address_on</th>
                              <th>Contact Number</th>
                              <th>Time Slot</th>
                              <th>Customer Name</th>
                              <th>City</th>
                              <th>Pincode</th>
                            </tr>
                        </thead>
                        <tbody>
                        {workdone.map((item : any) => (
                            <tr key={item.id}>
                              <td><button className="btn btn-info me-2"><Icon.Eye className="react-feather-custom" /></button></td>
                              <td>{item.order_type}</td>
                              <td>{item.issue_title}</td>
                              <td>{item.specializationinfo?.specialization}</td>
                              <td>{item.subspecializationinfo?.sub_specialization}</td>
                              <td>{item.address_on}</td>
                              <td>{item.contact_number}</td>
                              <td>{item.time}</td>
                              <td>{item.created_by_data?.name}</td>
                              <td>{item.cityinfo?.city}</td>
                              <td>{item.pincodeinfo?.pincode}</td>
                            </tr>
                          ))}
                          </tbody>
                        </table>
                        </div>
            </div>
          </div>
          </div>
          </div>
          <div className="tab-pane fade" id="rejected-orders">				
          <div className="row">
          {/* content day after tomorrow order	 */}
          <div className='card'>
            <br />
                <h6>View Orders</h6>
                <p>Count: {rejected.length}</p>
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                              <th>View</th>
                              <th>Order Id</th>
                              <th>Issue Title</th>
                              <th>Specialization</th>
                              <th>Sub Specialization</th>
                              <th>Address_on</th>
                              <th>Contact Number</th>
                              <th>Time Slot</th>
                              <th>Customer Name</th>
                              <th>City</th>
                              <th>Pincode</th>
                            </tr>
                        </thead>
                        <tbody>
                          {rejected.map((item : any) => (
                            <tr key={item.id}>
                            <td><button className="btn btn-info me-2"><Icon.Eye className="react-feather-custom" /></button></td>
                            <td>{item.order_type}</td>
                            <td>{item.issue_title}</td>
                            <td>{item.specializationinfo?.specialization}</td>
                            <td>{item.subspecializationinfo?.sub_specialization}</td>
                            <td>{item.address_on}</td>
                            <td>{item.contact_number}</td>
                            <td>{item.time}</td>
                            <td>{item.created_by_data?.name}</td>
                            <td>{item.cityinfo?.city}</td>
                            <td>{item.pincodeinfo?.pincode}</td>
                          </tr>
                          ))}
                          </tbody>
                        </table>
                        </div>
            </div>
          </div>
          </div>
          </div>
          </div>			
    </div>
    </>
)}
</>
    )
}

export default ProviderOrder