import { Dropdown } from 'primereact/dropdown';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';

const FooterFive = () => {
  const routes = all_routes;
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [selectedValue, setselectedValue] = useState(null);

  const language = [
    { name: 'English' },
    { name: 'France' },
    { name: 'Spanish' },
  ];
  const value = [{ name: 'US Dollars' }, { name: 'INR' }, { name: 'Kuwait' }];
  return (
    <>
      <footer className="footer footer-five">
        {/* Footer Top */}
        <div className="footer-top aos" data-aos="fade-up">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                {/* Footer Widget */}
                <div className="footer-widget">
                  <div className="footer-logo">
                    <Link to={routes.homeThree}>
                      <ImageWithBasePath src="assets/img/logo-03.png" alt="logo" />
                    </Link>
                  </div>
                  <div className="footer-content">
                    <p>
                      Lorem ipsum dolor sit consectetur adipiscing elit, sed do
                      eiusmod tempor.
                    </p>
                  </div>
                  <div className="footer-selects">
                    <h2 className="footer-title">Language &amp; Currency</h2>
                    <div className="row">
                      <div className="col-lg-12 d-flex">
                        <div className="footer-select">
                          <ImageWithBasePath src="assets/img/icons/global.svg" alt="img" />
                          <Dropdown
                            value={selectedLanguage}
                            onChange={(e) => setSelectedLanguage(e.value)}
                            options={language}
                            optionLabel="name"
                            placeholder="English"
                            className="select footer-react-select"
                          />
                        </div>
                        <div className="footer-select">
                          <ImageWithBasePath
                            src="assets/img/icons/dropdown.svg"
                            className="footer-dropdown"
                            alt="img"
                          />
                          <Dropdown
                            value={selectedValue}
                            onChange={(e) => setselectedValue(e.value)}
                            options={value}
                            optionLabel="name"
                            placeholder="US Dollars"
                            className="select footer-react-select"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Footer Widget */}
              </div>
              <div className="col-lg-2 col-md-6">
                {/* Footer Widget */}
                <div className="footer-widget footer-menu">
                  <h2 className="footer-title">Quick Links</h2>
                  <ul>
                    <li>
                      <Link to={routes.aboutUs}>About Us</Link>
                    </li>
                    <li>
                      <Link to={routes.blogGrid}>Blogs</Link>
                    </li>
                    <li>
                      <Link to={routes.contactUs}>Contact Us</Link>
                    </li>
                    <li>
                      <Link to={routes.providerSignup}>
                        Become a Professional
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.userSignup}>Become a User</Link>
                    </li>
                  </ul>
                </div>
                {/* /Footer Widget */}
              </div>
              <div className="col-lg-3 col-md-6">
                {/* Footer Widget */}
                <div className="footer-widget footer-contact">
                  <h2 className="footer-title">Contact Us</h2>
                  <div className="footer-contact-info">
                    <div className="footer-address">
                      <p>
                        <span>
                          <i className="feather-map-pin" />
                        </span>{' '}
                        367 Hillcrest Lane, Irvine, California, United States
                      </p>
                    </div>
                    <p>
                      <span>
                        <i className="feather-phone" />
                      </span>{' '}
                      321 546 8764
                    </p>
                    <p className="mb-0">
                      <span>
                        <i className="feather-mail" />
                      </span>{' '}
                      truelysell@example.com
                    </p>
                  </div>
                </div>
                {/* /Footer Widget */}
              </div>
              <div className="col-lg-3 col-md-6">
                {/* Footer Widget */}
                <div className="footer-widget">
                  <h2 className="footer-title">Follow Us</h2>
                  <div className="social-icon">
                    <ul>
                      <li>
                        <Link to="#">
                          <i className="fa-brands fa-facebook" />{' '}
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-twitter" />{' '}
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fa-brands fa-instagram" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fa-brands fa-linkedin" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <h2 className="footer-subtitle">Newsletter Signup</h2>
                  <div className="subscribe-form">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter Email Address"
                    />
                    <button type="submit" className="btn footer-btn">
                      <i className="feather-send" />
                    </button>
                  </div>
                </div>
                {/* /Footer Widget */}
              </div>
            </div>
          </div>
        </div>
        {/* /Footer Top */}
        {/* Footer Bottom */}
        <div className="footer-bottom">
          <div className="container">
            {/* Copyright */}
            <div className="copyright">
              <div className="row align-items-center">
                <div className="col-md-4">
                  <div className="payment-image">
                    <ul>
                      <li>
                        <Link to="#">
                          <ImageWithBasePath src="assets/img/payment/visa.png" alt="img" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <ImageWithBasePath
                            src="assets/img/payment/mastercard.png"
                            alt="img"
                          />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <ImageWithBasePath src="assets/img/payment/stripe.png" alt="img" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <ImageWithBasePath
                            src="assets/img/payment/discover.png"
                            alt="img"
                          />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="copyright-text">
                    <p className="mb-0">
                      Copyright © 2023. All Rights Reserved.
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  {/* Copyright Menu */}
                  <div className="copyright-menu">
                    <ul className="policy-menu">
                      <li>
                        <Link to={routes.privacyPolicy}>Privacy Policy</Link>
                      </li>
                      <li>
                        <Link to={routes.termsCondition}>
                          Terms &amp; Conditions
                        </Link>
                      </li>
                    </ul>
                  </div>
                  {/* /Copyright Menu */}
                </div>
              </div>
            </div>
            {/* /Copyright */}
          </div>
        </div>
        {/* /Footer Bottom */}
      </footer>
    </>
  );
};

export default FooterFive;
