import React, { useEffect,useState } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { base_url, image_base_url } from '../../../../constant';

const Categories = () => {
  const routes = all_routes;
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);
  const [users, setUsers] = useState([]);
  const [searchCity, setSearchCity] = useState('');

  useEffect(() => {
    SpecializationMasterList();
  }, []);

  const SpecializationMasterList = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${base_url}api/SpecializationMasterList`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      // Reverse the array to display in reverse order
      setUsers(data);
    } catch (error) {
      console.error('Error fetching technicians:', error);
    }
  };
  const handleLinkClick = (id:any) => {
    localStorage.setItem("specializationId", id);
  };

  return (
    <>
      <div className="bg-img">
        <ImageWithBasePath src="assets/img/bg/work-bg-03.png" alt="img" className="bgimg1" />
        <ImageWithBasePath src="assets/img/bg/work-bg-03.png" alt="img" className="bgimg2" />
      </div>
      {/* Breadcrumb */}
      <div className="breadcrumb-bar">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <h2 className="breadcrumb-title">Categories</h2>
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={routes.homeOne}>Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Categories
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* /Breadcrumb */}
      <div className="content">
        <div className="container">
        <div className="row">
      {users.map((service:any) => (
        <div key={service.id} className="col-md-6 col-lg-3">
          <Link
            // to={routes.search} // Assuming routes.serviceDetails is defined in your routes
            to={`${routes.search}?city=${searchCity}&specialization=${encodeURIComponent(service.specialization)}`}
            className="feature-box aos"
            data-aos="fade-up"
            onClick={() => handleLinkClick(service.id)}
          >
            <div className="feature-icon">
              <span>
                <img
                  src={`${image_base_url}${service.image}`}
                  alt="Service Icon"
                  width={200}
                  height={100}
                />
              </span>
            </div>
            <h5>{service.specialization}</h5>
            <div className="feature-overlay">
              <img
                src={`${image_base_url}${service.image}`}
                alt="Service Image"
              />
            </div>
          </Link>
        </div>
      ))}
    </div>
        </div>
      </div>
    </>
  );
};

export default Categories;
