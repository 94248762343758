import React, { useState } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import PagesAuthHeader from './common/header';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { base_url } from '../../../../constant';

const UserOtpverify = () => {
  const routes = all_routes;
  const [otp_number, setotp] = useState('');
  const email = localStorage.getItem('email');
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // setIsLoading(true);
    // Determine API endpoint based on userType
  
    try {
      const response = await fetch(`${base_url}api/auth/otpreceivedcust`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          otp_number,
        }),
      });
  
      if (response.status === 201) {
        const result = await response.json();
        console.log('result',result);
        navigate(routes.loginchoose);
      } else {
        const result = await response.json();
        alert(result.message)
        throw new Error('Failed to sign up');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  return (
    <>
      <PagesAuthHeader />
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-6 mx-auto">
              <div className="login-wrap">
                <div className="login-header">
                  <h3>Please Enter Your OTP</h3>
                </div>
                {/* Signup Form */}
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label className="col-form-label">Please Verify Your Email: {email}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your name"
                      value={otp_number}
                      onChange={(e) => setotp(e.target.value)}
                    />
                  </div>
                  <button className="btn btn-primary" style={{width:'420px'}}>Submit</button>
                  {/* <div className="login-or">
                    <span className="or-line" />
                    <span className="span-or">Or, log in with your email</span>
                  </div> */}
                  <div className="social-login">
                    <Link to="#" className="btn btn-google w-100">
                      <ImageWithBasePath
                        src="assets/img/icons/google.svg"
                        className="me-2"
                        alt="img"
                      />
                      Log in with Google
                    </Link>
                    <Link to="#" className="btn btn-google w-100">
                      <ImageWithBasePath
                        src="assets/img/icons/fb.svg"
                        className="me-2"
                        alt="img"
                      />
                      Log in with Facebook
                    </Link>
                  </div>
                  <p className="no-acc">
                    Already have an account ?{' '}
                    <Link to={routes.login}> Sign In</Link>
                  </p>
                </form>
                {/* /Signup Form */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserOtpverify;
