import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import PagesAuthHeader from './common/header';
import { all_routes } from '../../../../core/data/routes/all_routes';

const LoginChoose = () => {
  const routes = all_routes;
  const [isLoading, setIsLoading] = useState(false);

  const handleUserTypeClick = (userType:string) => {
    localStorage.setItem('userType', userType);
  };

  return (
    <>
    {isLoading && (
        <div className="d-flex justify-content-center mt-4">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      {!isLoading && (
        <>
      <PagesAuthHeader />
      <div className="content">
        <div className="container">
        <div className="login-header">
                  <h3>Login</h3>
                </div>
          <div className="row justify-content-center">
            {/* Providers */}
            <div className="col-md-4">
              <div className="choose-signup text-center">
                <h6>Providers</h6>
                <div className="choose-img">
                  <ImageWithBasePath src="assets/img/provider.png" alt="image" />
                </div>
                <Link
                  to={routes.login}
                  className="btn btn-secondary w-100"
                  onClick={() => handleUserTypeClick('Provider')}
                >
                  Login
                  <Icon.ArrowRightCircle className="standard-feather ms-1" />
                </Link>
              </div>
            </div>
            {/* Users */}
            <div className="col-md-4">
              <div className="choose-signup text-center">
                <h6>Users</h6>
                <div className="choose-img">
                  <ImageWithBasePath src="assets/img/user.png" alt="image" />
                </div>
                <Link
                  to={routes.login}
                  className="btn btn-secondary w-100"
                  onClick={() => handleUserTypeClick('User')}
                >
                  Login
                  <Icon.ArrowRightCircle className="standard-feather ms-1" />
                </Link>
              </div>
            </div>
            {/* Admin */}
            {/* <div className="col-md-4">
              <div className="choose-signup text-center">
                <h6>Admin</h6>
                <div className="choose-img">
                  <ImageWithBasePath src="assets/img/profile.jpg" alt="image" />
                </div>
                <Link
                  to={routes.login}
                  className="btn btn-secondary w-100"
                  onClick={() => handleUserTypeClick('Admin')}
                >
                  Login
                  <Icon.ArrowRightCircle className="standard-feather ms-1" />
                </Link>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  )}
    </>
  );
};

export default LoginChoose;
