import React, { useState, useEffect } from 'react';
import { CardElement, useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { base_url } from '../../../../constant';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { useNavigate } from 'react-router-dom';

const StripeCheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [email, setEmail] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [paymentError, setPaymentError] = useState<any>(null);
  const [paymentSuccess, setPaymentSuccess] = useState<any>(null);
  const [paymentMethod, setPaymentMethod] = useState('credit');
  const [postalCode, setPostalCode] = useState('');
  const location = useLocation();
  const { amount, currency, enquiry_id, id, booking_id, techID, customerEmail } = location.state || {};
  // console.log("abcd", booking_id);
  // console.log("techid", techID);
  const [isLoading, setIsLoading] = useState(false);
  const [bookingList, setBookingList] = useState([]);

  // useEffect(() => {
  //   makepayment
  // }, []);

  const routes = all_routes;
  const navigate = useNavigate();

  // const makepayment = async () => {
  //   setIsLoading(true);
  //   try {
  //     const token = localStorage.getItem('token');
  //     const response = await fetch(`${base_url}api/makepayment/${id}`,{
  //       method: 'GET',
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         'Content-Type': 'application/json'
  //       }
  //     });
  //     if (!response.ok) {
  //       throw new Error('Network response was not ok');
  //     }
  //     const data = await response.json();
  //     setBookingList(data);
  //     setIsLoading(false);
  //     navigate(routes.bookingDone);
  //   } catch (error) {
  //     setIsLoading(false);
  //     console.error('Error fetching technicians:', error);
  //   }
  // };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
  
    if (!stripe || !elements) {
      return;
    }
  
    const cardElement = elements.getElement(CardElement);
    console.log("first")
   // console.log(cardElement)
  
    try {
      // Step 1: Call your Laravel API to get the payment intent from the backend
      const response = await fetch(`${base_url}api/makepayment/${id}?postal_code=T6J%205N6&name=Customer%20Name`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch payment intent');
      }
  
      const paymentIntentResponse = await response.json();
      console.log(paymentIntentResponse);
      console.log("test");

      // Get the client_secret from the response
      const clientSecret = paymentIntentResponse.paymentIntent.client_secret;

      //Step 2: Use Stripe to confirm the card payment with client_secret
      const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
         payment_method: {
          card: cardElement,
          billing_details: {
            name: 'Customer Name',  // Send the actual customer name here
          },
        },
      });
      console.log('pri');
  console.log(paymentIntent);
      if (error) {
        setPaymentError("error priya");
        setPaymentSuccess(null);
      } 
      else if (paymentIntent && paymentIntent.status === 'succeeded') {
        setPaymentSuccess('Payment successful!');
        setPaymentError(null);
  
       //Step 3: Optionally, update your backend with the payment result
        await fetch(`${base_url}api/enquiryPaymentStatusUpdate`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            payment_intent: paymentIntent.id,
            redirect_status: 'succeeded',
          }),
        });
       await handleToggleChange();
      }
    } catch (error) {
      setPaymentError('Error during payment priya');
      setPaymentSuccess(null);
    }
  };

   //api/accept-by-technician/${booking_id}/${user_id}
   const handleToggleChange = async () => {

    const payload = {
      id: id,
      status: 2,
    };
  
    const url = `${base_url}api/accept-by-technician/${booking_id}/${techID}`;
  
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      console.log('Response Status:', response.status);
  
      if (response.status === 200) {
        const result = await response.json();
        console.log('Result:', result);
       navigate(routes.customerBooking);
      } else if (response.status === 302) {
        console.log('Redirect Location:', response.headers.get('Location'));
        // Handle redirection if necessary
      } else if (response.status === 404) {
        console.error('Not Found Error: The requested resource was not found.');
      } else {
        const result = await response.json();
        console.error('API Error:', result.message);
        alert(result.message);
        throw new Error('Failed to update time');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

//   return (
//     <div>
//       <form onSubmit={handleSubmit}>
//         <CardElement />
//         <button type="submit" disabled={!stripe}>
//           Pay Now
//         </button>
//       </form>
//       {paymentError && <div className="error">{paymentError}</div>}
//       {paymentSuccess && <div className="success">{paymentSuccess}</div>}
//     </div>
//   );
// };

return (
  <div style={styles.container}>
    <form onSubmit={handleSubmit} style={styles.form}>
      {/* Email Input */}
      <h6 style={{ display: 'flex', justifyContent: 'center' }}>Payment Information</h6>
      <label style={styles.label}>Email</label>
      <input
        type="email"
        value={customerEmail}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Enter your email"
        required
        style={styles.input}
      />

      {/* Payment Method Cards */}
      <label style={styles.label}>Payment Method</label>
      <div style={styles.paymentMethods}>
        <div
          style={{
            ...styles.paymentMethodCard,
            borderColor: paymentMethod === 'credit' ? '#007bff' : '#ddd',
          }}
          onClick={() => setPaymentMethod('credit')}
        >
          <img
            src="https://img.icons8.com/ios-filled/50/000000/bank-card-front-side.png"
            alt="Card Icon"
            style={{ width: '30px', height: '30px', marginBottom: '8px' }} // Reduced size and added margin for spacing
          />
          <span>Card</span>
        </div>
      </div>

      {/* Card Element */}
      <label style={styles.label}>Card Information</label>
      <div style={styles.cardElementWrapper}>
        <CardElement options={cardElementOptions} />
      </div>

      {/* Postal Code */}
      {/* <label style={styles.label}>Postal Code</label>
      <input
        type="text"
        value={postalCode}
        onChange={(e) => setPostalCode(e.target.value)}
        placeholder="Enter your postal code"
        required
        style={styles.input}
      /> */}

      {/* Pay Button */}
      <button type="submit" disabled={!stripe || isProcessing} className='btn btn-primary'>
        {isProcessing ? 'Processing...' : 'Pay Now'}
      </button>
    </form>

    {/* Payment Error & Success Messages */}
    {paymentError && <div style={styles.error}>{paymentError}</div>}
    {paymentSuccess && <div style={styles.success}>{paymentSuccess}</div>}
  </div>
);
};

export default StripeCheckoutForm;

// Custom styles for the form
const styles = {
container: {
  maxWidth: '400px',
  margin: '0 auto',
  padding: '20px',
  border: '1px solid #ddd',
  borderRadius: '8px',
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
},
form: {
  display: 'flex',
  flexDirection: 'column' as const,
  gap: '10px',
},
label: {
  fontSize: '14px',
  color: '#333',
},
input: {
  padding: '5px',
  border: '1px solid #ddd',
  borderRadius: '4px',
  fontSize: '16px',
},
paymentMethods: {
  display: 'flex',
  justifyContent: 'left', // Center the card horizontally
},
paymentMethodCard: {
  display: 'flex',
  flexDirection: 'column', // Set to column to stack icon and text vertically
  alignItems: 'center',    // Center items horizontally
  padding: '5px',         // Reduced padding for a smaller card
  textAlign: 'center' as const,
  border: '2px solid #ddd',
  borderRadius: '8px',
  cursor: 'pointer' as const,
  transition: 'border-color 0.3s',
  width: '130px',          // Reduce card size
  height: '70px',         // Adjust height for a more compact look
},
cardElementWrapper: {
  padding: '10px',
  border: '1px solid #ddd',
  borderRadius: '4px',
},
button: {
  padding: '12px',
  backgroundColor: '#007bff',
  color: '#fff',
  fontSize: '16px',
  borderRadius: '4px',
  border: 'none',
  cursor: 'pointer',
},
error: {
  color: 'red',
  fontSize: '14px',
  marginTop: '10px',
},
success: {
  color: 'green',
  fontSize: '14px',
  marginTop: '10px',
},
};

// Options for customizing the CardElement
const cardElementOptions = {
style: {
  base: {
    color: '#32325d',
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    '::placeholder': {
      color: '#aab7c4',
    },
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a',
  },
},
};