import ProviderOrderView from "../../../feature-module/frontend/providers/provider-order/provider-order-view";

export const all_routes = {
  // home module path
  homeOne: '/home-one',
  homeTwo: '/home-two',
  homeThree: '/home-three',
  homeFour: '/home-four',
  homeFive: '/home-five',
  homeSix: '/home-six',
  homeSeven: '/home-seven',
  homeEight: '/home-eight',
  homeNine: '/home-nine',

  // blog module path
  blog: '/blog/*',
  blogDetails: '/blog/blog-details',
  blogGrid: '/blog/blog-grid',
  blogList: '/blog/blog-list',


  // services module path
  services: '/services/*',
  serviceGrid: '/services/service-grid',
  serviceList: '/services/service-list',
  createService: '/services/create-service',
  providerDetails: '/services/providers/provider-details',
  search: '/services/search',
  searchList: '/services/search-list',
  provider: '/services/providers/provider-list',
  serviceDetails1: '/services/service-details/service-details1',
  serviceDetails2: '/services/service-details/service-details2',

  // customers module path
  customers: '/customers/*',
  customerDashboard: '/customers/customer-dashboard',
  customerBooking: '/customers/customer-booking',
  customerServices: '/customers/customer-services',
  customerFavourite: '/customers/customer-favourite',
  customerNotifications: '/customers/customer-notifications',
  customerProfile: '/customers/customer-profile',
  customerBookingCalendar: '/customers/customer-booking-calendar',
  customerChat: '/customers/customer-chat',
  customerReviews: '/customers/customer-reviews',
  customerWallet: '/customers/customer-wallet',
  deviceManagement: '/customers/settings/device-management',
  invoice: '/customers/invoice',
  commonNotification: '/customers/notification',

  // providers module path
  providers: '/providers/*',
  providerAddon: '/providers/provider-addon',
  verfication: '/providers/settings/verification',
  providerPlan: '/providers/settings/provider-plan',
  providerProfileSettings: '/providers/settings/provider-profile-settings',
  paymentSetting: '/providers/settings/payment-setting',
  connectedApp: '/providers/settings/connected-app',
  providerEditService: '/providers/provider-edit-service',
  providerHoliday: '/providers/provider-holiday',
  providerNotification: '/providers/settings/provider-notification',
  providerOffer: '/providers/provider-offer',
  providerDeviceManagement: '/providers/settings/provider-device-management',
  providerLoginActivity: '/providers/settings/provider-login-activity',
  providerEarnings: '/providers/provider-earnings',
  providerAppointmentSettings:
    '/providers/settings/provider-appointment-settings',
  providerConnectedApps: '/providers/settings/provider-connected-apps',
  providerAvailability: '/providers/provider-availability',
  providerBookDetails: '/providers/provider-book-details',
  providerBooking: '/providers/provider-booking',
  providerChat: '/providers/provider-chat',
  providerCoupons: '/providers/provider-coupons',
  providerDashboard: '/providers/dashboard',
  providerSignup: '/providers/authentication/provider-signup',
  providerotpverify: '/providers/authentication/provider-otpverify',
  providerSignupPayment: '/providers/authentication/provider-signup-payment',
  providerSignupSubscription:
    '/providers/authentication/provider-signup-subscription',
  providerSocialProfile: '/providers/settings/provider-social-profile',
  providerSubscription: '/providers/provider-subscription',
  providerPayout: '/providers/provider-payout',
  providerReview: '/providers/provider-review',
  providerService: '/providers/provider-service',
  providerProfile: '/providers/provider-profile',
  providerServiceList: '/providers/provider-service-list',
  ProviderSecuritySettings: '/providers/settings/provider-security-settings',
  ProviderEarnings: '/providers/provider-earnings',
  providerOrderView: '/providers/provider-order/provider-order-view',
  addSubscription: '/providers/add-subscription',

  // pages module path
  stripecheckout: '/customers/customer-booking/stripecheckout',
  stripeelement: '/customers/customer-booking/stripeelement',
  pages: '/pages/*',
  aboutUs: '/pages/about-us',
  error404: '/error/error-404',
  error500: '/error/error-500',
  faq: '/pages/faq',
  howItWorks: '/pages/how-it-works',
  termsCondition: '/pages/terms-condition',
  booking1: '/pages/booking/booking-1',
  booking2: '/pages/booking/booking-2',
  bookingDetails: '/pages/booking/booking-details',
  bookingDone: '/pages/booking/booking-done',
  bookingPayment: '/pages/booking/booking-payment',
  contactUs: '/pages/contact-us',
  comingSoon: '/pages/coming-soon',
  categories: '/pages/categories',
  chooseSignUp: '/authentication/choose-signup',
  emailOtp: '/authentication/email-otp',
  userSignup: '/authentication/user-signup',
  userOtpverify: '/authentication/user-otpverify',
  loginchoose: '/authentication/loginchoose',
  login: '/authentication/login',
  loginemail: '/authentication/login-email',
  loginphone: '/authentication/login-phone',
  loginphone1: '/authentication/login-phone1',
  freeTrail: '/authentication/free-trail',
  passwordRecovery: '/authentication/password-recovery',
  phoneOtp: '/authentication/phone-otp',
  privacyPolicy: '/pages/privacy-policy',
  pricingPlan: '/pages/pricing-plan',
  sessionExpired: '/pages/session-expired',
  maintenance: '/pages/maintenance',
  installer: '/pages/installer',
  success: '/authentication/success',
  resetPassword: '/authentication/reset-password',

  test: '',

  // Admin Module Path

  admin: '/admin/*',
  abuseReport: '/admin/support/abuse-reports',
  contactMessages: '/admin/support/contact-messages',
  contactMessagesView: '/admin/support/contact-messages-view',
  countries: '/admin/location/countries',
  cities: '/admin/location/cities',
  state: '/admin/location/state',
  connectedApps: '/admin/setting/connected-apps',
  cashOnDelivery: '/admin/finance-accounts/cash-on-delivery',
  chat: '/admin/chat',
  completedBooking: '/admin/booking/completed-booking',
  pendingBooking: '/admin/pending-booking',
  banIpAddress: '/admin/setting/ban-ip-address',
  bankTransferList: '/admin/finance-accounts/bank-transferlist',
  calendarSetting: '/admin/setting/calendar-settings',
  blogCategories: '/blog-categories',
  coupons: '/admin/marketing/coupons',
  cronJob: '/admin/setting/cronjob',
  curriencies: '/admin/setting/currencies',
  createMenu: '/admin/management/create-menu',
  userCustomer: '/admin/user/customers',
  adminEarnings: '/admin/reports/admin-earnings',
  allBlog: '/admin/blog/all-blog',
  approvedTransferlist: '/admin/finance-accounts/approved-transferlist',
  appearance: '/admin/setting/appearance',
  accountSettings: '/admin/setting/account-settings',
  emailSettings: '/admin/setting/email-settings',
  appointmentSettings: '/admin/setting/appointment-settings',
  authenticationSettings: '/admin/setting/authentication-settings',
  announcements: '/admin/support/announcements',
  currencySettings: '/admin/setting/currency-settings',
  customerWallets: '/admin/customer-wallet',
  availablePlugins: '/admin/plugin/available-plugins',
  payoutRequest: '/admin/payouts/payout-request',
  payoutSettings: '/admin/payouts/payout-settings',
  paymentSettings: '/admin/setting/payment-settings',
  paymentGateway: '/admin/setting/payment-gateways',
  headerSettings: '/admin/setting/header-settings',
  inActiveBlog: '/admin/blog/inactive-blog',
  inActiveServices: '/admin/services/inactive-services',
  addServices: '/admin/services/add-service',
  allServices: '/admin/services/all-services',
  editService: '/admin/services/edit-services',
  inProgressBooking: '/admin/booking/inprogress-booking',
  pendingServices: '/admin/services/pending-services',
  dashboard: '/admin/dashboard',
  websiteSettings: '/admin/management/website-settings',
  emailNewsletter: '/admin/marketing/email-newsletter',
  language: '/admin/setting/language',
  loginActivity: '/admin/setting/login-activity',
  localization: '/admin/setting/localization',
  seoSettings: '/admin/setting/seo-settings',
  serviceSettings: '/admin/setting/service-settings',
  siteInformation: '/admin/setting/site-information',
  smsSettings: '/admin/setting/sms-settings',
  menuManagement: '/admin/management/menu-management',
  salesReport: '/admin/reports/service-sales',
  socialAuthentication: '/admin/setting/social-authentication',
  socialProfile: '/admin/setting/social-profile',
  subCategories: '/admin/sub-categories',
  taxRates: '/admin/setting/tax-rates',
  typographytSettings: '/admin/setting/typography-settings',
  testimonials: '/admin/content/testimonials',
  wallet: '/admin/finance-accounts/wallet',
  verificationRequest: '/admin/verification-request',
  systemInformation: '/admin/setting/system-information',
  smsTemplate: '/admin/management/sms-template',
  storageSetting: '/admin/setting/storage-settings',
  pluginManager: '/admin/management/plugin-manager',
  preferenceSettings: '/admin/setting/preference-settings',
  providerEarning: '/admin/reports/provider-earnings',
  userProviders: '/admin/users/providers',
  providerSales: '/admin/reports/provider-sales',
  providerSettings: '/admin/setting/provider-settings',
  providerWallet: '/admin/reports/provider-wallet',
  faqadmin: '/admin/content/faq',
  featureServices: '/admin/marketing/featured-services',
  systemBackup: '/admin/setting/system-backup',
  gdpr: '/admin/setting/gdbr',
  emailTemplates: '/admin/management/email-templates',
  review: '/admin/review',
  reviewType: '/admin/review-type',
  roles: '/admin/roles',
  salestransactions: '/admin/finance-accounts/sales-transactions',
  walletHistory: '/admin/wallet-history',
  membershipTransaction: '/admin/reports/membership-transaction',
  addHome: '/admin/pages/add-home',
  addMembership: '/admin/membership/add-membership',
  activeServices: '/admin/services/active-services',
  deletedServices: '/admin/services/deleted-services',
  security: '/admin/setting/security',
  registerreport: '/admin/reports/register-report',
  successTransferlist: '/admin/finance-accounts/successful-transferlist',
  footerSettings: '/admin/setting/footer-settings',
  membership: '/admin/membership',
  membershipaddons: '/admin/memberships/membership-addon',
  users: '/admin/users',
  usersCustomer: '/admin/customers',
  deleteAccountReqest: '/admin/delete-account-requests',
  viewServices: '/admin/view-service',
  pendingBlog: '/admin/blog/pending-blog',
  booking: '/admin/booking',
  cancelledBooking: '/admin/bookings/cancelled-booking',
  editblog: '/admin/edit-blog',
  pendingTransferlist: '/admin/finance-accounts/pending-transferlist',
  rejectedTransferlist: '/admin/finance-accounts/rejected-tranferlist',
  deleteaccountrequests: '/admin/delete-account-requests',
  databasebackup: '/admin/setting/database-backup',
  adminDevicemanagement: '/admin/management/device-management',
  refundReport: '/admin/reports/refund-report',
  providersList: '/services/providers/provider-list',
  refundRequest:'/admin/finance-accounts/refund-request',
  cacheSystem:'/admin/management/cachesystem',
  signin:'/admin/signin',
  userCustomers:'/admin/users/customers',
  categoriesList:'/admin/categories/categories-list',
  addPages:'/admin/pages/add-page',
  EditPages:'/admin/edit-page',
  viewService:'/admin/view-service',
  permissions:'/admin/permissions',
  offer:'/admin/marketing/offer',
  adminNotification:'/admin/setting/notification',
  pagesList: '/admin/pages-list',
  pageList: '/admin/page-list',
  addBlog: '/admin/blog/add-blog',
  blogsCategories: '/admin/blog/blog-categories',
  blogComments: '/admin/blog/blog-comments',
  adminCustomerWallet: '/admin/reports/customer-wallet',
  addPage: '/admin/pages/add-pages',
  techniciandetails: '/admin/techniciandetails',
  technicianList: '/admin/techniciandetails/technicianList',
  categorymaster: '/admin/master/categorymaster',
  currencymaster: '/admin/master/currencymaster',
  countrymaster: '/admin/master/countrymaster',
  technicianSpec: '/admin/technicianspecapprovelist/technicianSpec',
};