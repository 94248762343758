import React, { useState, useEffect } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import * as Icon from 'react-feather';
import { Dropdown } from 'primereact/dropdown';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { image_base_url, base_url } from '../../../../constant';
import { toast, ToastContainer } from 'react-toastify';

const CustomerFavourite = () => {
  const routes = all_routes;
  const [selectedValue, setSelectedValue] = useState(null);
  const value = [{ name: 'A - Z' }, { name: 'Most Helpful' }];
  const [selectedItems, setSelectedItems] = useState(Array(10).fill(true));
  // const handleItemClick = (providerId: number, serviceId: number) => {
  //   setSelectedItems((prevSelectedItems) => {
  //     const updatedSelectedItems = [...prevSelectedItems];
  //     // Find the index of the item with the given providerId and serviceId
  //     const index = recentBooking.findIndex((item:any) => item.provider_id === providerId && item.service_id === serviceId);
  //     if (index !== -1) {
  //       updatedSelectedItems[index] = !updatedSelectedItems[index];
  //       postfavorite(new MouseEvent('click'), providerId, serviceId);
  //     }
  //     return updatedSelectedItems;
  //   });
  // };
  

  const [isLoading, setIsLoading] = useState(false);
  const [recentBooking, setRecentBooking] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    getfavorite();
  }, []);

  const getfavorite = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const user_id = localStorage.getItem('user_id');
      const response = await fetch(`${base_url}api/get-favorite/${user_id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setRecentBooking(data.data); // Set the state with the 'data' array from the response
    } catch (error) {
      console.error('Error fetching favorites:', error);
    } finally {
      setIsLoading(false);
    }
  };


  const postfavorite = async (e: React.MouseEvent, providerId: number, serviceId: number) => {
    e.preventDefault();
    setIsLoading(true);
  
    try {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      
      const response = await fetch(`${base_url}api/toggle-favorite`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          
        },
        body: JSON.stringify({  
          user_id: parseInt(userId as string),
          provider_id: providerId,
          service_id: serviceId,
          status: 1
        }),
      });
  
      if (response.status === 201) {
        const result = await response.json();
        toast(result.message);
        getfavorite();
      } else if (response.status === 200) {
        const result = await response.json();
        toast(result.message);
        getfavorite();
      } else {
        const result = await response.json();
        toast(result.message || 'Failed to submit');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };
  

  const handleButtonClick = (service: any) => {
    if (localStorage.getItem('token')) {
        console.log('Service Object:', service); // Debugging line
        
        // Log each value to check if any are undefined
        console.log('Specialization:', service.skillsetinfo?.specialization);
        console.log('Wages:', service.skillsetinfo?.wages);
        console.log('User ID:', service.userinfo?.id);
        console.log('Specialization Info:', service.skillsetinfo?.specializationinfo?.specialization);
        console.log('Tech Image:', service.skillsetinfo?.specializationinfo?.image);
        console.log('City ID:', service.cityinfo?.id);
        console.log('City Name:', service.cityinfo?.city);
        console.log('State ID:', service.stateinfo?.id);
        console.log('State Name:', service.stateinfo?.state);
        console.log('Country ID:', service.countryinfo?.id);
        console.log('Country Name:', service.countryinfo?.country);

        const query = `?specializationId=${service.skillsetinfo?.specialization}&wages=${service.skillsetinfo?.wages}&userId=${service.userinfo?.id}
        &spec=${service.skillsetinfo?.specializationinfo?.specialization}&techimg=${service.skillsetinfo?.specializationinfo?.image}
        &cityId=${service.cityinfo?.id}&cityName=${service.cityinfo?.city}&stateId=${service.stateinfo?.id}&stateName=${service.stateinfo?.state}
        &countryId=${service.countryinfo?.id}&countryName=${service.countryinfo?.country}
        &pincodeId=${service.pincodeinfo?.id}&pincode=${service.pincodeinfo?.pincode}`;
        navigate(`${routes.booking1}${query}`);
    } else {
        setShowPopup(true);
        setSelectedService(service);
    }};

    const handleClosePopup = () => {
      setShowPopup(false);
      setSelectedService(null);
    };

    const itemsPerPage = 10; // Number of items to display per page
  const [currentPage, setCurrentPage] = useState(1); // Current page state

  // Calculate total pages
  const totalPages = Math.ceil(recentBooking.length / itemsPerPage);

  // Get the items for the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = recentBooking.slice(indexOfFirstItem, indexOfLastItem);

  // Handle page change
  const handlePageChange = (pageNumber:any) => {
    setCurrentPage(pageNumber);
  };


  return (
    <div className="col-lg-9">
      {/* Sort */}
      <div className="row align-items-center">
        <div className="col-sm-6">
          <div className="widget-title">
            <h4>Favorites</h4>
          </div>
        </div>
        <div className="col-sm-6 d-flex align-items-center justify-content-end">
          <div className="review-sort cus-fav-sort me-2">
            <p>Sort</p>
            <Dropdown
              value={selectedValue}
              onChange={(e) => setSelectedValue(e.value)}
              options={value}
              optionLabel="name"
              placeholder="A - Z"
              className="select cus-fav"
            />
          </div>
          <div className="grid-listview">
            <ul>
              <li>
                <Link to="#">
                  <Icon.Filter className="standard-feather" />
                </Link>
              </li>
              <li>
                <Link to={routes.customerFavourite} className="active">
                  <Icon.Grid className="standard-feather" />
                </Link>
              </li>
              <li>
                <Link to={routes.customerFavourite}>
                  <Icon.List className="standard-feather" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* /Sort */}
      <ToastContainer />
       {isLoading && (
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
    )}
    {!isLoading && (
      <div className="row">
        {currentItems.length > 0 ? ( currentItems.map((favorite:any) => (
          <div className="col-xl-4 col-md-6" key={favorite.id}>
            <div className="service-widget servicecontent">
              <div className="service-img">
                <Link to="#">
                  <img
                    className="img-fluid serv-img"
                    alt="Service Image"
                    // src={`assets/img/services/service-04.jpg`}
                    src={favorite.service?.image ? `${image_base_url}${favorite.service?.image}` : `assets/img/services/service-04.jpg`}
                    style={{height: "250px",}}
                  />
                </Link>
                <div
  className="fav-item"
  // onClick={() => handleItemClick(favorite.provider_id, favorite.service_id)}
>
  <Link
    to="#"
    className={`fav-icon ${favorite.status === 1 ? 'selected' : ''}`}
    onClick={(e) => {
      e.preventDefault(); // Prevent the default link action
      postfavorite(e, favorite.provider_id, favorite.service_id); // Call the postfavorite method
      }}  >
    <Icon.Heart size={15} />
  </Link>
</div>

                <div className="item-info">
                  {/* <Link to={routes.providers}>
                    <span className="item-img">
                      <ImageWithBasePath
                        src={`${image_base_url}${favorite.service.service_image}`}
                        className="avatar"
                        alt="User"
                      />
                    </span>
                  </Link> */}
                </div>
              </div>
              <div className="service-content">
              <h3 className="title">
                  <Link to="#">
                    {favorite.technician.name}
                  </Link>
                </h3>
                <p className="title">
                  <Link to="#">
                    {favorite.service.specialization}
                  </Link>
                </p>
                {/* <p>
                  <Icon.MapPin className="react-feather-custom" />
                  {favorite.service.service_location}
                  <span className="rate">
                    <i className="fas fa-star filled" />
                    {favorite.service.rating || 'N/A'}
                  </span>
                </p> */}
                {/* <div className="serv-info">
                  <h6>${favorite.service.service_amount.toFixed(2)}</h6>
                  <button onClick={() =>handleButtonClick(favorite)} className="btn btn-book">
                    Book Now
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        ))):(<div className="col-12 text-center">
        <h4>No data available</h4>
      </div>)
      }
      </div>)}
      {/* Pagination */}
      <div className="row">
        <div className="col-sm-6">
          <div className="review-pagination">
            <p>
              {indexOfFirstItem + 1} - {Math.min(indexOfLastItem, recentBooking.length)} of {recentBooking.length}
            </p>
            <ul className="pagination">
              {/* Previous Button */}
              <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                <button className="page-link" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                  Prev
                </button>
              </li>

              {/* Page Numbers */}
              {Array.from({ length: totalPages }, (_, index) => (
                <li className={`page-item ${currentPage === index + 1 ? "active" : ""}`} key={index}>
                  <Link className="page-link" to="#" onClick={() => handlePageChange(index + 1)}>
                    {index + 1}
                  </Link>
                </li>
              ))}

              {/* Next Button */}
              <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
                <button className="page-link" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                  Next
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* /Pagination */}
    </div>
    // </>)}
    // </div>
    // </>
  );
};

export default CustomerFavourite;
