import React, { useEffect, useState } from 'react';
import { base_url } from '../../../constant';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';

const CategoryMaster = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false); // State for edit modal
  const [formData, setFormData] = useState({
    currency: '',
  });
  const [editFormData, setEditFormData] = useState({
    currency: '',
  }); // State for edit form data
  const [error, setError] = useState(null);
  const [validationError, setValidationError] = useState('');
  const [deleteModalShow, setDeleteModalShow] = useState(false); // State for delete confirmation modal
  const [deleteCategory, setDeleteCategory] = useState('');
  const [deleteCategoryID, setDeleteCategoryID] = useState('');

  useEffect(() => {
    CurrencyMaster();
  }, []);

  const CurrencyMaster = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${base_url}api/CurrencyMaster`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setIsLoading(false);
      // Reverse the array to display in reverse order
      setUsers(data.reverse());
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching technicians:', error);
    }
  };

  const editButton = (rowData:any) => {
    return (
      <div className="table-actions ">
        <button className="edit-table border-none" onClick={() => handleEdit(rowData)}>
          <Icon.Edit className="react-feather-custom" />
        </button>
      </div>
    );
  };

  const deleteButton = (rowData:any) => {
    return (
      <div className="table-actions ">
        <button className="delete-table border-none" onClick={() => handleDelete(rowData)}>
          <Icon.Trash2 className="react-feather-custom" />
        </button>
      </div>
    );
  };

  const handleEdit = (rowData:any) => {
    setEditFormData(rowData);
    setEditModalShow(true);
  };

  const handleDelete = (rowData:any) => {
    setDeleteCategoryID(rowData.id); 
    setDeleteCategory(rowData.currency); // Set category name to delete
    setDeleteModalShow(true); // Show delete confirmation modal
  };

  const confirmDelete = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${base_url}api/CurrencyMaster/${deleteCategoryID}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      console.log('Category deleted successfully');
      setDeleteModalShow(false);
      setIsLoading(false);
      CurrencyMaster(); // Refresh data after deletion
    } catch (error) {
      setIsLoading(false);
      console.error('Error deleting category:', error);
    }
  };

  const cancelDelete = () => {
    setDeleteModalShow(false);
    setDeleteCategory(''); // Clear delete category state
  };

  const validateForm = () => {
    if (!formData.currency) {
      setValidationError('Category name is required');
      return false;
    }
    setValidationError('');
    return true;
  };

  const resetForm = () => {
    setFormData({ currency: '' });
  };

  const resetFormEdit = () => {
    setEditFormData({ currency: '' });
  };

  const create = async () => {
    if (!validateForm()) {
      return;
    }
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${base_url}api/CurrencyMaster`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log('Category created:', data);
      CurrencyMaster();
      setIsLoading(false);
      setModalShow(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error creating category:', error);
    }
  };

  const update = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${base_url}api/CurrencyMaster`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(editFormData)
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log('Category updated:', data);
      CurrencyMaster();
      setIsLoading(false);
      setEditModalShow(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error updating category:', error);
    }
  };
  const handleInputChange = (e:any) => {
    const { value } = e.target;
    setEditFormData((prevState) => ({
      ...prevState,
      currency: value
    }));
  };

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        {isLoading && (
          <div className="d-flex justify-content-center mt-4">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="text-center flex-grow-1">Currency</h5>
              <button className="btn btn-primary" onClick={() => setModalShow(true)}>
                <Icon.Plus className="react-feather-custom" />
              </button>
            </div>

            <div className="table-responsive table-div">
              <DataTable value={users} showGridlines tableStyle={{ minWidth: '50rem' }}>
                <Column field="edit" header="Edit" body={(rowData) => editButton(rowData)} />
                <Column field="delete" header="Delete" body={(rowData) => deleteButton(rowData)} />
                <Column field="currency" header="Currency" />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${modalShow ? 'show' : ''}`}
        id="add-currency"
        style={{ display: modalShow ? 'block' : 'none' }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Add Currency</h5>
              <button type="button" className="btn-close" onClick={() => setModalShow(false)} aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label htmlFor="currency-name" className="form-label">Currency Name</label>
                <input type="text" className={`form-control ${validationError ? 'is-invalid' : ''}`} id="category-name" value={formData.currency} onChange={(e) => setFormData({ ...formData, currency: e.target.value })} />
                {validationError && (
                  <div className="invalid-feedback">
                    {validationError}
                  </div>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-success" onClick={() => create()}>Create</button>
              <button type="button" className="btn btn-secondary" onClick={() => resetForm()}>Reset</button>
            </div>
          </div>
        </div>
      </div>

      {/* Edit Modal */}
      {editFormData && (
        <div
          className={`modal fade ${editModalShow ? 'show' : ''}`}
          id="edit-category"
          style={{ display: editModalShow ? 'block' : 'none' }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Edit Currency</h5>
                <button type="button" className="btn-close" onClick={() => setEditModalShow(false)} aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="mb-3">
                  <label htmlFor="edit-currency-name" className="form-label">Currency Name</label>
                  <input
                    type="text"
                    className={`form-control ${validationError ? 'is-invalid' : ''}`}
                    id="edit-currency-name"
                    value={editFormData.currency}
                    onChange={handleInputChange}
                  />
                  {validationError && (
                    <div className="invalid-feedback">
                      {validationError}
                    </div>
                  )}
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-success" onClick={update}>Update</button>
                <button type="button" className="btn btn-secondary" onClick={() => resetFormEdit()}>Reset</button>
              </div>
            </div>
          </div>
        </div>
      )}

{/* Delete Confirmation Modal */}
<div
        className={`modal fade ${deleteModalShow ? 'show' : ''}`}
        id="delete-currency"
        style={{ display: deleteModalShow ? 'block' : 'none' }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Delete Currency</h5>
              <button type="button" className="btn-close" onClick={() => cancelDelete()} aria-label="Close"></button>
            </div>
            <div className="modal-body">
            <p>Are you sure you want to delete &quot;{deleteCategory}&quot;?</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-danger" onClick={() => confirmDelete()}>Delete</button>
              <button type="button" className="btn btn-secondary" onClick={() => cancelDelete()}>Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryMaster;
