import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import * as Icon from 'react-feather';
import { base_url, image_base_url } from '../../../../constant';

const CustomerDashboard = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [totalSpend, settotalSpend] = useState([]);
  const [totalOrder, settotalOrder] = useState([]);
  const [recentBooking, setrecentBooking] = useState([]);

  
  useEffect(() => {
    getlisttechnician();
    // getlistrecentcustomer();
  }, []);

  const getlisttechnician = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const user_id = localStorage.getItem('user_id');
      const response = await fetch(`${base_url}api/customer/statistics/${user_id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      settotalSpend(data.total_spend_amount);
      settotalOrder(data.total_orders);
      setrecentBooking(data.services_booked);
    } catch (error) {
      console.error('Error fetching technicians:', error);
    } finally {
      setIsLoading(false);
    }
  };

// const getlistrecentcustomer = async () => {
//   setIsLoading(true);
//   try {
//     const token = localStorage.getItem('token');
//     // const response = await fetch(`${base_url}api/customer-recent-appointments/${cust_id}`, {
//     const response = await fetch('http://127.0.0.1:8000/api/customer-recent-appointments/3', {
//       method: 'GET',
//       headers: {
//         Authorization: `Bearer ${token}`,
//         'Content-Type': 'application/json'
//       }
//     });
//     if (!response.ok) {
//       throw new Error('Network response was not ok');
//     }
//     const data = await response.json();
    
//   } catch (error) {
//     console.error('Error fetching technicians:', error);
//   } finally {
//     setIsLoading(false);
//   }
// };

  return (
    <div className="col-lg-9">
      <div className="widget-title">
        <h4>Dashboard</h4>
      </div>
      {/* Dashboard Widget */}
      <div className="row">
        <div className="col-md-6 col-lg-3">
          <div className="dash-widget">
            <div className="dash-img">
              <span className="dash-icon bg-yellow">
                <ImageWithBasePath
                  src="assets/img/icons/dash-icon-01.svg"
                  alt="image"
                />
              </span>
              <div className="dash-value">
                <ImageWithBasePath
                  src="assets/img/icons/up-icon.svg"
                  alt="image"
                />{' '}
                +16.24%
              </div>
            </div>
            <div className="dash-info">
              <div className="dash-order">
                <h6>Total Orders</h6>
                <h5>{totalOrder}</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-3">
          <div className="dash-widget">
            <div className="dash-img">
              <span className="dash-icon bg-blue">
                <ImageWithBasePath
                  src="assets/img/icons/wallet-icon-01.svg"
                  alt="image"
                />
              </span>
              <div className="dash-value text-danger">
                <ImageWithBasePath
                  src="assets/img/icons/down-icon.svg"
                  alt="image"
                />{' '}
                +18.52%
              </div>
            </div>
            <div className="dash-info">
              <div className="dash-order">
                <h6>Total Spend</h6>
                <h5>{totalSpend}</h5>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-md-6 col-lg-3">
          <div className="dash-widget">
            <div className="dash-img">
              <span className="dash-icon bg-blue">
                <ImageWithBasePath
                  src="assets/img/icons/wallet-add.svg"
                  alt="image"
                />
              </span>
              <div className="dash-value">
                <ImageWithBasePath
                  src="assets/img/icons/up-icon.svg"
                  alt="image"
                />{' '}
                +12.10%
              </div>
            </div>
            <div className="dash-info">
              <div className="dash-order">
                <h6>Wallet</h6>
                <h5>$200</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-3">
          <div className="dash-widget">
            <div className="dash-img">
              <span className="dash-icon bg-light-danger">
                <ImageWithBasePath
                  src="assets/img/icons/wallet-amt.svg"
                  alt="image"
                />
              </span>
              <div className="dash-value">
                <ImageWithBasePath
                  src="assets/img/icons/up-icon.svg"
                  alt="image"
                />{' '}
                +08.15%
              </div>
            </div>
            <div className="dash-info">
              <div className="dash-order">
                <h6>Total Savings</h6>
                <h5>$354</h5>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      {/* /Dashboard Widget */}
      <div className="row">
        {/* Recent Booking */}
        <div className="col-lg-8 d-flex flex-column">
          <h6 className="user-title">Recent Booking</h6>
          <div className="table-responsive recent-booking flex-fill">
            <table className="table mb-0">
              {/* <tbody>
                <tr>
                  <td>
                    <h2 className="table-avatar">
                      <Link to="#" className="avatar avatar-m me-2">
                        <ImageWithBasePath
                          className="avatar-img rounded"
                          src="assets/img/services/service-06.jpg"
                          alt="User Image"
                        />
                      </Link>
                      <Link to="#">
                        Computer Repair
                        <span>
                          <Icon.Calendar color="#74788d" size={14} className='mb-1'/> 22 Sep
                          2023
                        </span>
                      </Link>
                    </h2>
                  </td>
                  <td>
                    <h2 className="table-avatar table-user">
                      <Link to="#" className="avatar avatar-m me-2">
                        <ImageWithBasePath
                          className="avatar-img"
                          src="assets/img/profiles/avatar-02.jpg"
                          alt="User Image"
                        />
                      </Link>
                      <Link to="#">
                        John Smith
                        <span>john@example.com</span>
                      </Link>
                    </h2>
                  </td>
                  <td className="text-end">
                    <Link to="#" className="btn btn-light-danger">
                      Cancel
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h2 className="table-avatar">
                      <Link to="#" className="avatar avatar-m me-2">
                        <ImageWithBasePath
                          className="avatar-img rounded"
                          src="assets/img/services/service-04.jpg"
                          alt="User Image"
                        />
                      </Link>
                      <Link to="#">
                        Car Repair Services
                        <span>
                          <Icon.Calendar color="#74788d" size={14} className='mb-1'/> 20 Sep
                          2023
                        </span>
                      </Link>
                    </h2>
                  </td>
                  <td>
                    <h2 className="table-avatar table-user">
                      <Link to="#" className="avatar avatar-m me-2">
                        <ImageWithBasePath
                          className="avatar-img"
                          src="assets/img/profiles/avatar-03.jpg"
                          alt="User Image"
                        />
                      </Link>
                      <Link to="#">
                        Timothy
                        <span>timothy@example.com</span>
                      </Link>
                    </h2>
                  </td>
                  <td className="text-end">
                    <Link to="#" className="btn btn-light-danger">
                      Cancel
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h2 className="table-avatar">
                      <Link to="#" className="avatar avatar-m me-2">
                        <ImageWithBasePath
                          className="avatar-img rounded"
                          src="assets/img/services/service-07.jpg"
                          alt="User Image"
                        />
                      </Link>
                      <Link to="#">
                        Interior Designing
                        <span>
                          <Icon.Calendar color="#74788d" size={14} className='mb-1'/> 19 Sep
                          2023
                        </span>
                      </Link>
                    </h2>
                  </td>
                  <td>
                    <h2 className="table-avatar table-user">
                      <Link to="#" className="avatar avatar-m me-2">
                        <ImageWithBasePath
                          className="avatar-img"
                          src="assets/img/profiles/avatar-06.jpg"
                          alt="User Image"
                        />
                      </Link>
                      <Link to="#">
                        Jordan
                        <span>jordan@example.com</span>
                      </Link>
                    </h2>
                  </td>
                  <td className="text-end">
                    <Link to="#" className="btn btn-light-danger">
                      Cancel
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h2 className="table-avatar">
                      <Link to="#" className="avatar avatar-m me-2">
                        <ImageWithBasePath
                          className="avatar-img rounded"
                          src="assets/img/services/service-08.jpg"
                          alt="User Image"
                        />
                      </Link>
                      <Link to="#">
                        Steam Car Wash
                        <span>
                          <Icon.Calendar color="#74788d" size={14} className='mb-1'/> 18 Sep
                          2023
                        </span>
                      </Link>
                    </h2>
                  </td>
                  <td>
                    <h2 className="table-avatar table-user">
                      <Link to="#" className="avatar avatar-m me-2">
                        <ImageWithBasePath
                          className="avatar-img"
                          src="assets/img/profiles/avatar-09.jpg"
                          alt="User Image"
                        />
                      </Link>
                      <Link to="#">
                        Armand
                        <span>armand@example.com</span>
                      </Link>
                    </h2>
                  </td>
                  <td className="text-end">
                    <Link to="#" className="btn btn-light-danger">
                      Cancel
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h2 className="table-avatar">
                      <Link to="#" className="avatar avatar-m me-2">
                        <ImageWithBasePath
                          className="avatar-img rounded"
                          src="assets/img/services/service-09.jpg"
                          alt="User Image"
                        />
                      </Link>
                      <Link to="#">
                        House Cleaning Services
                        <span>
                          <Icon.Calendar color="#74788d" size={14} className='mb-1'/> 17 Sep
                          2023
                        </span>
                      </Link>
                    </h2>
                  </td>
                  <td>
                    <h2 className="table-avatar table-user">
                      <Link to="#" className="avatar avatar-m me-2">
                        <ImageWithBasePath
                          className="avatar-img"
                          src="assets/img/profiles/avatar-10.jpg"
                          alt="User Image"
                        />
                      </Link>
                      <Link to="#">
                        Joseph
                        <span>joseph@example.com</span>
                      </Link>
                    </h2>
                  </td>
                  <td className="text-end">
                    <Link to="#" className="btn btn-light-danger">
                      Cancel
                    </Link>
                  </td>
                </tr>
              </tbody> */}
              <tbody>
                {recentBooking.length > 0 ? recentBooking.map((booking:any, index) => (
                  <tr key={index}>
                    <td>
                      <h2 className="table-avatar">
                        <Link to="#" className="avatar avatar-m me-2">
                          <img
                            className="avatar-img rounded"
                            src={`${image_base_url}${booking.specialization_image}`} // Assuming `serviceImage` is a URL or path to the image
                            alt="Img"
                          />
                        </Link>
                        <Link to="#">
                          {booking.specialization_title}
                          <span style={{marginLeft: "10px"}}>
                            <Icon.Calendar color="#74788d" size={14} className='mb-1'/> {booking.appointment_date}
                          </span>
                        </Link>
                      </h2>
                    </td>
                    {/* <td>
                      <h2 className="table-avatar table-user">
                        <Link to="#" className="avatar avatar-m me-2">
                          <ImageWithBasePath
                            className="avatar-img"
                            src={booking.customerImage} // Assuming `customerImage` is a URL or path to the image
                            alt="Customer Image"
                          />
                        </Link>
                        <Link to="#">
                          {booking.customerName}
                          <span>{booking.customerEmail}</span>
                        </Link>
                      </h2>
                    </td>
                    <td className="text-end">
                      <Link to="#" className="btn btn-light-danger">
                        Cancel
                      </Link>
                    </td> */}
                  </tr>
                )) : (
                  <tr>
                    <td colSpan={3} className="text-center">No recent bookings available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {/* /Recent Booking */}
        {/* Recent Transaction */}
        {/* <div className="col-lg-4 d-flex flex-column">
          <h6 className="user-title">Recent Transaction</h6>
          <div className="table-responsive transaction-table flex-fill">
            <table className="table mb-0">
              <tbody>
                <tr>
                  <td>
                    <div className="table-book d-flex align-items-center">
                      <span className="book-img">
                        <ImageWithBasePath
                          src="assets/img/icons/trans-icon-01.svg"
                          alt="Icon"
                        />
                      </span>
                      <div>
                        <h6>Service Booking</h6>
                        <p>
                          <Icon.Calendar color="#C2C9D1" className="me-1 mb-1" size={14} />
                          22 Sep 2023{' '}
                          <span>
                            <Icon.Clock color="#C2C9D1" className="me-1" size={14} /> 10:12 AM
                          </span>
                        </p>
                      </div>
                    </div>
                  </td>
                  <td className="text-end">
                    <h5 className="trans-amt">$280.00</h5>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="table-book d-flex align-items-center">
                      <span className="book-img">
                        <ImageWithBasePath
                          src="assets/img/icons/trans-icon-02.svg"
                          alt="image"
                        />
                      </span>
                      <div>
                        <h6>Service Refund</h6>
                        <p>
                          <Icon.Calendar color="#C2C9D1" className="me-1 mb-1" size={14} />
                          22 Sep 2023{' '}
                          <span>
                            <Icon.Clock color="#C2C9D1" className="me-1" size={14} /> 10:12 AM
                          </span>
                        </p>
                      </div>
                    </div>
                  </td>
                  <td className="text-end">
                    <h5 className="trans-amt">$100.00</h5>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="table-book d-flex align-items-center">
                      <span className="book-img">
                        <ImageWithBasePath
                          src="assets/img/icons/trans-icon-03.svg"
                          alt="image"
                        />
                      </span>
                      <div>
                        <h6>Wallet Topup</h6>
                        <p>
                          <Icon.Calendar color="#C2C9D1" className="me-1 mb-1" size={14} />
                          22 Sep 2023{' '}
                          <span>
                            <Icon.Clock color="#C2C9D1" className="me-1" size={14} /> 10:12 AM
                          </span>
                        </p>
                      </div>
                    </div>
                  </td>
                  <td className="text-end">
                    <h5 className="trans-amt">$1000.00</h5>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="table-book d-flex align-items-center">
                      <span className="book-img">
                        <ImageWithBasePath
                          src="assets/img/icons/trans-icon-01.svg"
                          alt="Icon"
                        />
                      </span>
                      <div>
                        <h6>Service Booking</h6>
                        <p>
                          <Icon.Calendar color="#C2C9D1" className="me-1 mb-1" size={14} />
                          22 Sep 2023{' '}
                          <span>
                            <Icon.Clock color="#C2C9D1" className="me-1" size={14} /> 10:12 AM
                          </span>
                        </p>
                      </div>
                    </div>
                  </td>
                  <td className="text-end">
                    <h5 className="trans-amt">$280.00</h5>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="table-book d-flex align-items-center">
                      <span className="book-img">
                        <ImageWithBasePath
                          src="assets/img/icons/trans-icon-01.svg"
                          alt="Icon"
                        />
                      </span>
                      <div>
                        <h6>Service Booking</h6>
                        <p>
                          <Icon.Calendar color="#C2C9D1" className="me-1 mb-1" size={14} />
                          22 Sep 2023{' '}
                          <span>
                            <Icon.Clock color="#C2C9D1" className="me-1" size={14} /> 10:12 AM
                          </span>
                        </p>
                      </div>
                    </div>
                  </td>
                  <td className="text-end">
                    <h5 className="trans-amt">$280.00</h5>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> */}
        {/* /Recent Transaction */}
      </div>
    </div>
  );
};

export default CustomerDashboard;
