import React, { useState, useEffect,useRef } from 'react';
import { Link } from 'react-router-dom';
import { base_url, image_base_url } from '../../../../constant';
import { Dropdown } from 'primereact/dropdown';
import { TagsInput } from 'react-tag-input-component';
import { Calendar } from 'primereact/calendar';
import * as Icon from 'react-feather';
import { Nullable } from 'primereact/ts-helpers';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import axios from 'axios';

const ProviderProfile = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUser] = useState<any>(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isProfileExist, setIsProfileExist] = useState(false);
  const [countries, setCountries] = useState<any>([]);
  const [states, setState] = useState([]);
  const [cities, setCities] = useState([]);
  const [pincode, setPincode] = useState([]);
  const [isedit, setIsEdit] = useState(true);
  const [isImageSelected, setIsImageSelected] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef<any>(null); 
  const [formData, setFormData] = useState<any>({
    city: 0,
    communication_address: '',
    country: 0,
    current_occupation: '',
    date_of_birth: '',
    education: '',
    gender: '',
    is_active: false,
    joined_date: '',
    language_known: '',
    marital_status: '',
    mobile: '',
    pincode: '',
    state: 0,
    user_id: 0,
  });

  const [selectedGender, setGender] = useState(null);
  const [selectedCurrency, setCurrency] = useState(null);
  const [tags] = useState(['English', 'French']);
  const [date, setDate] = useState<Nullable<Date>>(null);

  const gender = [
    { name: 'Select Gender' },
    { name: 'Male' },
    { name: 'Female' },
  ];
  const marital = [
    { name: 'Select Marital Status' },
    { name: 'Single' },
    { name: 'Married' },
  ];

  const countryOptions = countries.map((country:any) => ({
    label: country.country,
    value: country.id,
  }));

  const stateOptions = states
  .filter((state:any) => state.country_id === formData.country)
  .map((state:any) => ({
    label: state.state,
    value: state.id,
  }));

  const cityOptions = cities
  .filter((city:any) => city.state_id === formData.state)
  .map((city:any) => ({
    label: city.city,
    value: city.id,
  }));

  const pincodeOptions = pincode
    .filter((pincodes:any) => pincodes.city_id === formData.city)
    .map((pincodes:any) => ({
      label: pincodes.pincode,
      value: pincodes.id,
    }));

  useEffect(() => {
    fetchUser();
    fetchtechnician();
    fetchCountries();
    fetchState();
    fetchCity();
    fetchPincode();
  }, []);

//get api user 
const fetchUser = async () => {
  setIsLoading(true);
  try {
    const token = localStorage.getItem('tech_token');
    const response = await fetch(`${base_url}api/user`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    localStorage.setItem("userId", data.id);
    setUser(data); // Assuming data is an array of countries
    setIsDisabled(true);
    setIsLoading(false);
  } catch (error) {
    console.error('Error fetching countries:', error);
  }
};

//getchecktechnicianprofileexistornot
const fetchtechnician = async () => {
  setIsLoading(true);
  try {
    const token = localStorage.getItem('tech_token');
    const response = await fetch(`${base_url}api/getchecktechnicianprofileexistornot`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    if (data.message === "Profile Already exist") {
      setFormData(() => ({
        ...data.result,
      }));
      setIsProfileExist(true);
      setIsEdit(true);
    } else {
      setIsProfileExist(false);
    }
    setIsLoading(false);
  } catch (error) {
    console.error('Error fetching technician profile:', error);
    setIsLoading(false);
  }
};

//get countries
const fetchCountries = async () => {
  try {
    const token = localStorage.getItem('tech_token');
    const response = await fetch(`${base_url}api/CountryMasterr`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    setCountries(data); // Assuming data is an array of countries
  } catch (error) {
    console.error('Error fetching countries:', error);
  }
};

//get state 
const fetchState = async () => {
  try {
    const token = localStorage.getItem('tech_token');
    const response = await fetch(`${base_url}api/StateMasterr`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    setState(data); // Assuming data is an array of countries
  } catch (error) {
    console.error('Error fetching countries:', error);
  }
};

//get city
const fetchCity = async () => {
  try {
    const token = localStorage.getItem('tech_token');
    const response = await fetch(`${base_url}api/CityMaster`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    setCities(data); // Assuming data is an array of countries
  } catch (error) {
    console.error('Error fetching countries:', error);
  }
};

//pincode
const fetchPincode= async () => {
try {
  const token = localStorage.getItem('tech_token');
  const response = await fetch(`${base_url}api/PincodeMaster`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  const data = await response.json();
  setPincode(data); // Assuming data is an array of countries
} catch (error) {
  console.error('Error fetching countries:', error);
}
};

//post
const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
  e.preventDefault();
  setIsLoading(true);
  // Determine API endpoint based on userType

  try {
    const token = localStorage.getItem('tech_token');
    const userId = localStorage.getItem('technician_id');
    setFormData({
      ...formData,
      user_id: userId ? parseInt(userId) : 0, // Convert userId to number if available
    });
    const response = await fetch(`${base_url}api/TechnicianPersonalProfile`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    if (response.status === 200) {
      const result = await response.json();
      console.log('result',result);
      setIsLoading(false);

    } else {
      const result = await response.json();
      setIsLoading(false);
      alert(result.message)
      throw new Error('Failed to sign up');
      
    }
  } catch (error) {
    console.error('Error:', error);
    setIsLoading(false);
  }
};

//put
const handleUpdate = async (e: React.FormEvent<HTMLFormElement>) => {
  e.preventDefault();
  setIsLoading(true);
  // Determine API endpoint based on userType

  try {
    const token = localStorage.getItem('tech_token');
    const userId = localStorage.getItem('technician_id');
    setFormData({
      ...formData,
      user_id: userId ? parseInt(userId) : 0, // Convert userId to number if available
    });
    const response = await fetch(`${base_url}api/TechnicianPersonalProfile`, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    if (response.status === 200) {
      const result = await response.json();
      console.log('result',result);
      await fetchtechnician();
    } else {
      const result = await response.json();
        alert(result.message)
        throw new Error('Failed to update profile');
    }
    setIsLoading(false);
  } catch (error) {
    console.error('Error:', error);
    setIsLoading(false);
  }
};

const handleFileChange = async (event:any) => {
  const file = event.target.files[0];
  if (file) {
    setSelectedFile(file);
    setIsImageSelected(true);
    await handleImageUpload(file); // Automatically upload after selecting the file
  }
};

// Function to handle image upload (POST request)
const handleImageUpload = async (file:any) => {
  if (!file) return;
  const token = localStorage.getItem('tech_token');
const user_id = localStorage.getItem('technician_id')??'';
  const formData = new FormData();
  formData.append('id', user_id);
  formData.append('userprofileimage', file); // Assuming 'profile_image' is the correct key for the file

  try {
    // Post request to update user profile image
    const response = await axios.post(`${base_url}api/updateuserprofileimage`, formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    
    // Check if the response was successful
    if (response.status === 200) {
      alert('Profile image updated successfully!');
    } else {
      alert('Failed to update profile image.');
    }
  } catch (error) {
    console.error('Error updating profile image:', error);
    alert('Error uploading the profile image.');
  }
};

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <>
        {/* Profile settings */}
        <div className="row">
          <div className="col-md-12">
            <div className="widget-title">
              <h4>Account Settings</h4>
            </div>
            <h6 className="user-title">Profile Picture</h6>
            {/* <div className="pro-picture">
              <div className="pro-img">
                <ImageWithBasePath src="assets/img/profiles/avatar-02.jpg" alt="user" />
              </div>
              <div className="pro-info">
                <div className="d-flex">
                  <div className="img-upload">
                    <i className="feather-upload-cloud me-1" />
                    Upload
                    <input type="file" />
                  </div>
                  <Link to="#" className="btn btn-remove">
                    Remove
                  </Link>
                </div>
                <p>
                  *image size should be at least 320px big,and less then 500kb.
                  Allowed files .png and .jpg.
                </p>
              </div>
            </div> */}

          <div className="card">
            <div className="card-body">
              <div className="container">
                <div className="row">
                <div className="col-md-4 ">
                  <div>
                    <img 
                    src={`${image_base_url}${users?.userprofileimage}`}
                    alt="Profile"
                    style={{ width: '100px', height: '100px', borderRadius: '50%', objectFit: 'cover', }}
                    />
                    </div>
                    <div className="mt-4">
                      {/* <button className="btn btn-primary btn-block">Change Profile Image</button> */}
                       <input
        type="file"
        ref={fileInputRef} // Assign the ref to the file input
        style={{ display: 'none' }}
        onChange={handleFileChange}
        accept="image/*"
      />
      
      <button
        className="btn btn-primary btn-block"
        onClick={() => fileInputRef.current?.click()} // Trigger the file input click
      >
        {isImageSelected ? 'Update Profile Image' : 'Change Profile Image'}
      </button>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                            <label>User id</label>
                            <input
                            type="userid"
                            id="emailInput"
                            className="form-control"
                            placeholder=""
                            value={users ? users.id : ''}
                            disabled={isDisabled}
                            />
                            <br />
                            <label>Joined date</label>
                            <input
                            type="text"
                            id="textField"
                            className="form-control"
                            placeholder=""
                            value={formData ? formData.joined_date : ''}
                            disabled={isDisabled}
                            />
                            <br />
                            <label>Badge</label>
                            <input
                            type="text"
                            id="textField"
                            className="form-control"
                            placeholder=""
                            disabled={isDisabled}
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                        <label>Technician id</label>
                            <input
                            type="userid"
                            id="emailInput"
                            className="form-control"
                            placeholder=""
                            value={formData ? formData.id : ''}
                            disabled={isDisabled}
                            />
                            <br />
                            <label>Name</label>
                            <input
                            type="email"
                            id="emailInput"
                            className="form-control"
                            placeholder=""
                            value={users ? users.name : ''}
                            disabled={isDisabled}
                            />
                            <br />
                            <label>Email</label>
                            <input
                            type="text"
                            id="textField"
                            className="form-control"
                            placeholder=""
                            value={users ? users.email : ''}
                            disabled={isDisabled}
                            />
                        </div>
                    </div>
                </div>
            </div>
            </div>
          </div>
          <form onSubmit={isProfileExist ? handleUpdate : handleSubmit}>
            <h6 className="user-title">General Information</h6>
            <div className="general-info">
              <div className="row">
                {/* <div className="col-md-6">
                  <div className="form-group">
                    <label className="col-form-label">
                      Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Your Name"
                    />
                  </div>
                </div> */}
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="col-form-label">Current Occupation</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Your Occupation"
                      value={formData.current_occupation}
                       onChange={(e) => setFormData({ ...formData, current_occupation: e.target.value })}
                       disabled={isedit}
                    />
                  </div>
                </div>
                {/* <div className="col-md-6">
                  <div className="form-group">
                    <label className="col-form-label">
                      Education 
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Education"
                      value={formData.education} onChange={(e) => setFormData({ ...formData, education: e.target.value })}
                      disabled={isedit}
                    />
                  </div>
                </div> */}
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="col-form-label">
                      Primary Mobile Number <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Mobile Number"
                      value={formData.mobile} 
                      onChange={(e) => setFormData({ ...formData, mobile: e.target.value })}
                      disabled={isedit}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="col-form-label">
                      Secondary Mobile Number <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Mobile Number"
                      // value={formData.mobile} 
                      // onChange={(e) => setFormData({ ...formData, mobile: e.target.value })}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="col-form-label">Gender</label>
                    <Dropdown
                      value={formData.gender}
                      onChange={(e) => setFormData({ ...formData, gender: e.target.value })}
                      options={gender}
                      optionLabel="name"
                      placeholder="Select Gender"
                      className="select w-100"
                      disabled={isedit}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="col-form-label">Date of birth</label>
                    <div className="form-icon cus-profile">
                      {/* <input
                          type="text"
                          className="form-control datetimepicker"
                          placeholder="DD/MM/YYYY"
                        /> */}
                      <Calendar
                        // value={date}
                        // onChange={(e) => setDate(e.value)}
                        value={formData.date_of_birth} 
                        onChange={(e) => setFormData({ ...formData, date_of_birth: e.target.value })}
                        placeholder="DD/MM/YYYY"
                        disabled={isedit}
                      />
                      <span className="cus-icon">
                        <Icon.Calendar className="react-feather-custom" />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="col-form-label">Marital Status</label>
                    <Dropdown
                      value={formData.marital_status}
                      onChange={(e) => setFormData({ ...formData, marital_status: e.target.value })}
                      options={marital}
                      optionLabel="name"
                      placeholder="Select"
                      className="select w-100"
                      disabled={isedit}
                    />
                  </div>
                </div>
                {/* <div className="col-md-12">
                  <div className="form-group">
                    <label className="col-form-label d-block">
                      Your Bio{' '}
                      <span className="brief-bio float-end">
                        Brief description for your profile. URLs are
                        hyperlinked.
                      </span>
                    </label>
                    <textarea
                      className="form-control"
                      rows={5}
                      placeholder="Add a Short Bio....."
                      defaultValue={''}
                    />
                  </div>
                </div> */}
              </div>
            </div>
            <h6 className="user-title">Address </h6>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label className="col-form-label">Address</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Your Address"
                    value={formData.communication_address} 
                    onChange={(e) => setFormData({ ...formData, communication_address: e.target.value })}
                    disabled={isedit}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="col-form-label">Country</label>
                  {/* <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Your Country"
                  /> */}
                  <Dropdown 
                  value={formData.country}
                  onChange={(e) => setFormData({ ...formData, country: e.value })}
                  options={countryOptions}
                  placeholder="Select Country"
                  className="w-100"
                  disabled={isedit}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="col-form-label">State</label>
                   <Dropdown 
                  value={formData.state}
                  onChange={(e) => setFormData({ ...formData, state: e.value })}
                  options={stateOptions}
                  placeholder="Select state"
                  className="w-100"
                  disabled={isedit}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="col-form-label">City</label>
                  <Dropdown 
                  value={formData.city}
                  onChange={(e) => setFormData({ ...formData, city: e.value })}
                  options={cityOptions}
                  placeholder="Select City"
                  className="w-100"
                  disabled={isedit}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="col-form-label">Postal Code</label>
                  <Dropdown 
                  value={formData.pincode}
                  onChange={(e) => setFormData({ ...formData, pincode: e.value })}
                  options={pincodeOptions}
                  placeholder="Select Pincode"
                  className="w-100"
                  disabled={isedit}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="col-form-label">Language</label>
                  {/* <TagsInput
                   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    className="input-tags form-control"
                    tags={tags}
                    value={tags}
                  /> */}
                  <input type="text" id="language" className="form-control" placeholder="" value={formData.language_known} onChange={(e) => setFormData({ ...formData, language_known: e.target.value })}  />
                </div>
              </div>
            </div>
            <div className="acc-submit">
            <button className="btn btn-primary" type="submit">
        {isProfileExist ? 'Update' : 'Submit'}
      </button></div>
            </form>

            {/* <div className="acc-submit">
              <Link to="#" className="btn btn-secondary">
                Cancel
              </Link>
              <Link to="#" className="btn btn-primary">
                Save Changes
              </Link>
            </div> */}
          </div>
          {/* /Account Settings */}
        </div>
        {/* /profile-settings */}
        </>
        )}
      </div>
    </div>
  );
};

export default ProviderProfile;
