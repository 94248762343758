import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as Icon from 'react-feather';
import { Dropdown } from 'primereact/dropdown';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { base_url, image_base_url } from '../../../../constant';
import { Modal, Button, Card, Row, Col } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';

const CustomerServices = () => {
  const routes = all_routes;
  const [selectedValue, setSelectedValue] = useState(null);
  const value = [{ name: 'A - Z' }, { name: 'Most Helpful' }];
  const [selectedItems, setSelectedItems] = useState(Array(10).fill(true));
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const handleItemClick = (providerId:number) => {
    setSelectedItems((prevSelectedItems) => {
      const updatedSelectedItems = [...prevSelectedItems];
      // Find the index of the item with the given providerId and serviceId
      // const index = activeTechnicians.findIndex(item => item.provider_id === providerId && item.service_id === serviceId);
      // if (index !== -1) {
      //   updatedSelectedItems[index] = !updatedSelectedItems[index];
      //   postfavorite(new MouseEvent('click'), providerId, serviceId);
      // }
      return updatedSelectedItems;
    });
  };
  
  const [activeTechnicians, setActiveTechnicians] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedService, setSelectedService] = useState<any>(null);

  const navigate = useNavigate();

  useEffect(() => {
    fetchActiveTechnicians();
  }, []);


  const fetchActiveTechnicians = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const user_id = localStorage.getItem('user_id');
      const response = await fetch(`${base_url}api/getbystatus/1/${user_id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setActiveTechnicians(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching active technicians:', error);
    }
  };


const handleIconClick = (technician:any) => {
  // setSelectedTechnician(technician);
  setReviews(technician.rating_reviews || []);
    setShowModal(true);
  };

  const formatDate = (dateString:any) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setSelectedService(null);
  };

  const handleCloseModal = () => setShowModal(false);

  const postfavorite = async (e: React.MouseEvent, providerId: number, serviceId: number) => {
    e.preventDefault();
    setIsLoading(true);
  
    try {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      
      const response = await fetch(`${base_url}api/toggle-favorite`, {
        method: 'POST',
        headers: {
          'Authorization':`Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({  
          user_id: parseInt(userId as string),
          provider_id: providerId,
          service_id: serviceId,
          status: 1
        }),
      });
  
      if (response.status === 201) {
        const result = await response.json();
        toast(result.message);
        fetchActiveTechnicians();
      } else if (response.status === 200) {
        const result = await response.json();
        toast(result.message);
        fetchActiveTechnicians();
      } else {
        const result = await response.json();
        toast(result.message || 'Failed to submit');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };


  const indexOfLastItem = currentPage * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;
const currentItems = activeTechnicians.slice(indexOfFirstItem, indexOfLastItem);

const totalPages = Math.ceil(activeTechnicians.length / itemsPerPage);


  return (
    <div className="col-lg-9">
      {/* Sort */}
      <div className="row align-items-center">
        <div className="col-sm-6">
          <div className="widget-title">
            <h4>Services</h4>
          </div>
        </div>
        <div className="col-sm-6 d-flex align-items-center justify-content-end">
          <div className="review-sort cus-fav-sort me-2">
            <p>Sort</p>
            <Dropdown
              value={selectedValue}
              onChange={(e) => setSelectedValue(e.value)}
              options={value}
              optionLabel="name"
              placeholder="A - Z"
              className="select cus-fav"
            />
          </div>
          <div className="grid-listview">
            <ul>
              <li>
                <Link to="#">
                  <Icon.Filter className="standard-feather" />
                </Link>
              </li>
              <li>
                <Link to={routes.customerFavourite} className="active">
                  <Icon.Grid className="standard-feather" />
                </Link>
              </li>
              <li>
                <Link to={routes.customerBooking}>
                  <Icon.List className="standard-feather" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* /Sort */}
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
      <div className="row">
        <ToastContainer/>
        {/* Service List */}
        {currentItems.length > 0 ? currentItems.map((service:any) => {
          const isFavorited = service.favorites && service.favorites.length > 0 ? service.favorites[0]?.status === 1 : 0;
          console.log('isFavorited',isFavorited);
          return(
                <div key={service.id} className="col-xl-4 col-md-6">
                  <div className="service-widget servicecontent">
                    <div className="service-img">
                      <Link 
                      // to={routes.serviceDetails1}
                      to={routes.booking1}
                      >
                        <img
                          className="img-fluid serv-img"
                          alt="Service Image"
                        //   src="assets/img/provider/provider-11.jpg"
                        //   src={`${image_base_url}${service.userinfo?.userprofileimage}`}
                          src={service.userinfo?.userprofileimage ? `${image_base_url}${service.userinfo?.userprofileimage}` : '/assets/img/provider/provider-11.jpg'}
                          style={{height: "230px",}}
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={1}
                        onClick={() => handleItemClick(service.user_id)}
                      >
                        <Link to={routes.categories}>
                          <span className="item-cat">{service.skillsetinfo?.specializationinfo?.specialization}</span>
                        </Link>
                         <Link to="#" className={`fav-icon ${isFavorited ? 'selected' : ''}`}
                         onClick={(e) => {
                          e.preventDefault(); // Prevent the default link action
                          postfavorite(e, service.user_id, service.skillsetinfo.specialization); // Call the postfavorite method
                          }}
                          ><Icon.Heart size={15} /></Link>
                      </div>
                    </div>
                    <div className="service-content">
                      <h3 className="title">
                        <Link 
                        to={routes.booking1}
                        >{service.userinfo?.name}</Link>
                      </h3>
                      <p>
                        <Icon.MapPin className="react-feather-custom" onClick={() => handleIconClick(service)} />
                        <span onClick={() => handleIconClick(service)}>
                          {service.cityinfo?.city},{service.stateinfo?.state}
                        </span>
                        <span className="rate" onClick={() => handleIconClick(service)}>
                          <i className="fas fa-star filled" />{service.average_rating ? service.average_rating.toFixed(1) : 0}
                        </span>
                      </p>
                      <div className="serv-info">
                        <p style={{fontWeight: "bold", color: "black"}}>
                          {service.skillsetinfo?.currencyinfo?.currency || '-'}
                          {service.skillsetinfo?.wages || '-'}/{service.skillsetinfo?.wages_type || '-'}
                        </p>
                        <Link  to={ `${routes.booking1}?specializationId=${service.skillsetinfo?.specialization}&wages=${service.skillsetinfo?.wages}&userId=${service.userinfo?.id}
            &spec=${service.skillsetinfo?.specializationinfo?.specialization}&techimg=${service.skillsetinfo?.specializationinfo?.image}
            &cityId=${service.cityinfo?.id}&cityName=${service.cityinfo?.city}&stateId=${service.stateinfo?.id}&stateName=${service.stateinfo?.state}
            &countryId=${service.countryinfo?.id}&countryName=${service.countryinfo?.country}&pincodeId=${service.pincodeinfo?.id}&pincode=${service.pincodeinfo?.pincode}&currency=${service.skillsetinfo?.currencyinfo?.currency}&techemail=${service.userinfo?.email}`
                          } className="btn btn-book">
                          { 'Book Now' }
                        </Link>

                          {/* <Link 
                                to={routes.booking1}
                                state={{ service }} // Corrected this part
                                className="btn btn-book"
                              >
                                {'Book Now'}
                              </Link> */}
                      </div>
                    </div>
                  </div>
                </div>);
                }): (
                  <p>No Data available.</p>
                )}

                {/* Guest user popup */}
                <Modal show={showPopup} onHide={handleClosePopup}>
                  <Modal.Header closeButton>
                    <Modal.Title>Login</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="choose-signup text-center">
                          <h6>User</h6>
                          <div className="choose-img">
                            <ImageWithBasePath src="assets/img/user.png" alt="image" />
                          </div>
                          <Link
                            to={routes.login}
                            className="btn btn-secondary w-100">
                            Continue to Login
                            <Icon.ArrowRightCircle className="standard-feather ms-1" />
                          </Link>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="choose-signup text-center">
                          <h6>Guest</h6>
                          <div className="choose-img">
                            <ImageWithBasePath src="assets/img/video-bg.jpg" alt="image" />
                          </div>
                          <button
                            className="btn btn-secondary w-100"
                            onClick={() => {
                              handleClosePopup(); // Close the modal
                              if (selectedService) {
                                navigate(`${routes.booking1}?specializationId=${selectedService.skillsetinfo?.specialization}&wages=${selectedService.skillsetinfo?.wages}&userId=${selectedService.userinfo?.id}`);
                              }         
                            }}
                            >
                            Continue as Guest
                            <Icon.ArrowRightCircle className="standard-feather ms-1" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClosePopup}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
                {/* Review popup */}
                <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Reviews</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {reviews.length > 0 ? (
          reviews.map((review:any, index) => (
            <Card key={index} className="mb-3">
                 <Card.Body>
                  <Row>
                    <Col>
                      <Card.Title style={{ color: 'black' }}>{review.user_id}</Card.Title>
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <Card.Subtitle className="text-muted">
                      {formatDate(review.created_at)}
                      </Card.Subtitle>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Card.Text>{review.review?review.review:'-'}</Card.Text>
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <Card.Text><span className="rate" onClick={handleIconClick}>
                          <i className="fas fa-star filled" style={{ color: 'gold' }} />  {review.rating}/5
                        </span></Card.Text>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
          ))
        ) : (
          <p>No reviews available.</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
      </div>)}
      {/* Pagination */}
      <div className="row d-flex justify-content-end">
        <div className="col-sm-12">
        <div className="review-pagination">
              <p>{`${indexOfFirstItem + 1} - ${Math.min(indexOfLastItem, activeTechnicians.length)} of ${activeTechnicians.length}`}</p>
                <ul className="pagination">
                  <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <Link className="page-link" to="#" onClick={() => setCurrentPage(currentPage - 1)}>Previous</Link>
                  </li>
                  {[...Array(totalPages)].map((_, index) => (
                  <li key={index + 1} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                    <Link className="page-link" to="#" onClick={() => setCurrentPage(index + 1)}>{index + 1}</Link>
                  </li>
                  ))}
                  <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <Link className="page-link" to="#" onClick={() => setCurrentPage(currentPage + 1)}>Next</Link>
                  </li>
                </ul>
            </div>
        </div>
      </div>
      {/* /Pagination */}
    </div>
  );
};

export default CustomerServices;
