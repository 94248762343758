import React, { useEffect, useState } from 'react';
import { base_url } from '../../../constant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

const CountryMaster = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [country, setCountry] = useState([]);
    const [state, setState] = useState([]);
    const [statebyid, setStatebyid] = useState([]);
    const [city, setCity] = useState([]);
    const [currency, setCurrency] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedCountryID, setSelectedCountryID] = useState(null);
    const [selectedCountryCurrency, setSelectedCountryCurrency] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedStateID, setSelectedStateID] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [showCard2, setShowCard2] = useState(false);
    const [showCard3, setShowCard3] = useState(false);
    const [showCard4, setShowCard4] = useState(false);
    const [showAddModalCountry, setShowAddModalCountry] = useState(false);  
    const [showEditModalCountry, setShowEditModalCountry] = useState(false);
    const [newCountry, setNewCountry] = useState('');
    const [newCurrency, setNewCurrency] = useState('');
    const [newCountryEdit, setNewCountryEdit] = useState('');
    const [newCurrencyEdit, setNewCurrencyEdit] = useState('');
    const [editingCountry, setEditingCountry] = useState<any>(null);
    const [citybyid, setCitybyid] = useState([]);
    const [pincodebyid, setPincodebyid] = useState([]);
    const [newState, setNewState] = useState('');
    const [showAddModalState, setShowAddModalState] = useState(false); 
    const [showEditModalState, setShowEditModalState] = useState(false);
    const [newStateEdit, setNewStateEdit] = useState('');
    const [editingState, setEditingState] = useState<any>(null);
    const [showAddModalCity, setShowAddModalCity] = useState(false); 
    const [newCity, setNewCity] = useState(''); 
    const [showEditModalCity, setShowEditModalCity] = useState(false);
    const [newCityEdit, setNewCityEdit] = useState('');
    const [editingCity, setEditingCity] = useState<any>(null);
    const [showAddModalPincode, setShowAddModalPincode] = useState(false); 
    const [newPincode, setNewPincode] = useState(''); 
    const [showEditModalPincode, setShowEditModalPincode] = useState(false);
    const [newPincodeEdit, setNewPincodeEdit] = useState('');
    const [editingPincode, setEditingPincode] = useState<any>(null);
    const [selectedCityID, setSelectedCityID] = useState(null);

    useEffect(() => {
        getCountry();
        getState();
        getCity();
        getCurrency();
      }, []);
    
    const getCountry = async () => {
        setIsLoading(true);
        try {
          const token = localStorage.getItem('token');
          const response = await fetch(`${base_url}api/CountryMasterr`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          setIsLoading(false);
          setCountry(data.reverse());
        } catch (error) {
          setIsLoading(false);
          console.error('Error fetching technicians:', error);
        }
    };

    const getState = async () => {
        setIsLoading(true);
        try {
          const token = localStorage.getItem('token');
          const response = await fetch(`${base_url}api/StateMasterr`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          setIsLoading(false);
          setState(data.reverse());
        } catch (error) {
          setIsLoading(false);
          console.error('Error fetching technicians:', error);
        }
    };

    const getCity = async () => {
        setIsLoading(true);
        try {
          const token = localStorage.getItem('token');
          const response = await fetch(`${base_url}api/CityMaster`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          setIsLoading(false);
          setCity(data.reverse());
        } catch (error) {
          setIsLoading(false);
          console.error('Error fetching technicians:', error);
        }
    };

    const getCurrency = async () => {
        setIsLoading(true);
        try {
          const token = localStorage.getItem('token');
          const response = await fetch(`${base_url}api/CurrencyMaster`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          setIsLoading(false);
          setCurrency(data.reverse());
        } catch (error) {
          setIsLoading(false);
          console.error('Error fetching technicians:', error);
        }
    };

    const getStateByID = async (id:any) => {
        setIsLoading(true);
        try {
          const token = localStorage.getItem('token');
          const response = await fetch(`${base_url}api/getonlystate/${id}`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          setIsLoading(false);
          setStatebyid(data.reverse());
        } catch (error) {
          setIsLoading(false);
          console.error('Error fetching technicians:', error);
        }
    };

    const addCountry = async () => {
        const token = localStorage.getItem('token');
        if (newCountry && newCurrency) {
          try {
            const response = await axios.post(`${base_url}api/CountryMasterr`, {
              country: newCountry,
              default_currency: newCurrency
            }, {
              headers: {
                 Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
              }
            });
    
            if (response.status === 201) {
              getCountry();
              setNewCountry('');
              setNewCurrency('');
              setShowAddModalCountry(false); // Close modal after adding country
            } else {
              console.error('Failed to add country.');
            }
          } catch (error) {
            console.error('Error adding country:', error);
          }
        }
    };

    const updateCountry = async () => {
        const token = localStorage.getItem('token');
        if (editingCountry && newCountryEdit && newCurrencyEdit) {
          try {
           const updatedCountry = {
                    ...editingCountry,
                    country: newCountryEdit,
                    default_currency: newCurrencyEdit
                };
                const response = await axios.put(`${base_url}api/CountryMasterr`, updatedCountry, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });
    
            if (response.status === 200) {
              getCountry();
              setNewCountryEdit('');
              setNewCurrencyEdit('');
              setShowEditModalCountry(false); // Close modal after updating country
              setEditingCountry(null);
            } else {
              console.error('Failed to update country.');
            }
          } catch (error) {
            console.error('Error updating country:', error);
          }
        }
    };

    const deleteCountry = async (id: number) => {
      const token = localStorage.getItem('token');
      try {
          const response = await axios.delete(`${base_url}api/CountryMasterr/${id}`, {
              headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json'
              }
          });

          if (response.status === 200) {
              getCountry(); // Refresh the country list after deletion
          } else {
              console.error('Failed to delete country.');
          }
      } catch (error) {
          console.error('Error deleting country:', error);
      }
  };

  const getCityByID = async (id:any) => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${base_url}api/getonlycity/${id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setIsLoading(false);
      setCitybyid(data.reverse());
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching technicians:', error);
    }
};

const getPincodeByID = async (id:any) => {
  setIsLoading(true);
  try {
    const token = localStorage.getItem('token');
    const response = await fetch(`${base_url}api/getonlypincode/${id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    setIsLoading(false);
    setPincodebyid(data.reverse());
  } catch (error) {
    setIsLoading(false);
    console.error('Error fetching technicians:', error);
  }
};
const addState = async () => {
  const token = localStorage.getItem('token');
  if (newState) {
    try {
      const response = await axios.post(`${base_url}api/StateMasterr`, {
        country: selectedCountry,
        country_id: selectedCountryID,
        state: newState,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        default_currency: selectedCountryCurrency
      }, {
        headers: {
           Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
        }
      });

      if (response.status === 201) {
        const country_id = response.data.country_id;
        getStateByID(country_id);
        setNewState('');
        setShowAddModalState(false); // Close modal after adding country
      } else {
        console.error('Failed to add State.');
      }
    } catch (error) {
      console.error('Error adding State:', error);
    }
  }
};

const updateState = async () => {
  const token = localStorage.getItem('token');
  if (editingState && newStateEdit) {
    try {
     const updatedState = {
              ...editingState,
              state: newStateEdit,
          };
          const response = await axios.put(`${base_url}api/StateMasterr`, updatedState, {
              headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json'
              }
          });

      if (response.status === 200) {
        const id = response.data.country_id;
        getStateByID(id);
        setNewStateEdit('');
        setShowEditModalState(false); // Close modal after updating country
        setEditingState(null);
      } else {
        console.error('Failed to update state.');
      }
    } catch (error) {
      console.error('Error updating state:', error);
    }
  }
};

const deleteState = async (id: number) => {
  const token = localStorage.getItem('token');
  try {
      const response = await axios.delete(`${base_url}api/StateMasterr/${id}`, {
          headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
          }
      });

      if (response.status === 200) {
         getStateByID(selectedCountryID); // Refresh the country list after deletion
      } else {
          console.error('Failed to delete country.');
      }
  } catch (error) {
      console.error('Error deleting country:', error);
  }
};


const addCity = async () => {
  const token = localStorage.getItem('token');
  if (newCity) {
    try {
      const response = await axios.post(`${base_url}api/CityMaster`, {
        country_id: selectedCountryID,
        city: newCity,
       state_id:selectedStateID
      }, {
        headers: {
           Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
        }
      });

      if (response.status === 201) {
        getCityByID(selectedStateID);
        setNewState('');
        setShowAddModalCity(false); // Close modal after adding country
      } else {
        console.error('Failed to add State.');
      }
    } catch (error) {
      console.error('Error adding State:', error);
    }
  }
};

const updateCity = async () => {
  const token = localStorage.getItem('token');
  if (editingCity && newCityEdit) {
    try {
     const updatedState = {
              ...editingCity,
              city: newCityEdit,
          };
          const response = await axios.put(`${base_url}api/CityMaster`, updatedState, {
              headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json'
              }
          });

      if (response.status === 200) {
        getCityByID(selectedStateID);
        setNewCityEdit('');
        setShowEditModalCity(false); // Close modal after updating country
        setEditingCity(null);
      } else {
        console.error('Failed to update state.');
      }
    } catch (error) {
      console.error('Error updating state:', error);
    }
  }
};

const deleteCity = async (id: number) => {
  const token = localStorage.getItem('token');
  try {
      const response = await axios.delete(`${base_url}api/CityMaster/${id}`, {
          headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
          }
      });

      if (response.status === 200) {
        getCityByID(selectedStateID); // Refresh the country list after deletion
      } else {
          console.error('Failed to delete country.');
      }
  } catch (error) {
      console.error('Error deleting country:', error);
  }
};

const addPincode = async () => {
  const token = localStorage.getItem('token');
  if (newPincode) {
    try {
      const response = await axios.post(`${base_url}api/PincodeMaster`, {
        country_id: selectedCountryID,
        city_id: selectedCityID,
       state_id:selectedStateID,
       pincode:newPincode
      }, {
        headers: {
           Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
        }
      });

      if (response.status === 201) {
        getPincodeByID(selectedCityID);
        setNewPincode('');
        setShowAddModalPincode(false); // Close modal after adding country
      } else {
        console.error('Failed to add State.');
      }
    } catch (error) {
      console.error('Error adding State:', error);
    }
  }
};

const updatePincode = async () => {
  const token = localStorage.getItem('token');
  if (editingPincode && newPincodeEdit) {
    try {
     const updatedState = {
              ...editingPincode,
              pincode: newPincodeEdit,
          };
          const response = await axios.put(`${base_url}api/PincodeMaster`, updatedState, {
              headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json'
              }
          });

      if (response.status === 200) {
        getPincodeByID(selectedCityID);
        setNewPincodeEdit('');
        setShowEditModalPincode(false); // Close modal after updating country
        setEditingPincode(null);
      } else {
        console.error('Failed to update state.');
      }
    } catch (error) {
      console.error('Error updating state:', error);
    }
  }
};

const deletePincode = async (id: number) => {
  const token = localStorage.getItem('token');
  try {
      const response = await axios.delete(`${base_url}api/PincodeMaster/${id}`, {
          headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
          }
      });

      if (response.status === 200) {
        getPincodeByID(selectedCityID); // Refresh the country list after deletion
      } else {
          console.error('Failed to delete country.');
      }
  } catch (error) {
      console.error('Error deleting country:', error);
  }
};

    const editCountry = (country:any) => {
        setEditingCountry(country);
        setNewCountryEdit(country.country);
        setNewCurrencyEdit(country.default_currency);
        setShowEditModalCountry(true);
    };

    const editState = (state:any) => {
      setEditingState(state);
      setNewStateEdit(state.state);
      setShowEditModalState(true);
  };
  const editCity = (city:any) => {
    setEditingCity(city);
    setNewCityEdit(city.city);
    setShowEditModalCity(true);
};
const editPincode = (pin:any) => {
  setEditingPincode(pin);
  setNewPincodeEdit(pin.pincode);
  setShowEditModalPincode(true);
};
    const toggleCard2 = (country:any) => {
        setSelectedCountry(country.country);
        setSelectedCountryID(country.id);
        setSelectedCountryCurrency(country.default_currency);
        getStateByID(country.id);
        setShowCard2(true);
    };

    const toggleCard3 = (states:any) => {
        setSelectedState(states.state);
        setSelectedStateID(states.id);
        getCityByID(states.id);
        setShowCard3(true);
    };

    const toggleCard4 = (cities:any) => {
        setSelectedCity(cities.city);
        setSelectedCityID(cities.id);
        getPincodeByID(cities.id)
        setShowCard4(true);
    };

    const resetCountry = () => {
        setNewCountry('');
        setNewCurrency('');
    };
    const resetCountryEdit = () => {
      setNewCountryEdit('');
      setNewCurrencyEdit('');
  };
  const resetStateEdit = () => {
    setNewStateEdit('');
};
  const resetState = () => {
    setNewState('');
};
const resetCityEdit = () => {
  setNewCityEdit('');
};
const resetCity = () => {
  setNewCity('');
};
const resetPincode = () => {
  setNewPincode('');
};
const resetPincodeEdit = () => {
  setNewPincodeEdit('');
};

    return (
        <div className="page-wrapper">
            <div className="content container-fluid">
                {isLoading ? (
                    <div className="d-flex justify-content-center mt-3">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <div className="row mt-3">
                        <div className="col-md-3">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">Country</h5>
                                    <button className="btn btn-primary" onClick={() => setShowAddModalCountry(true)}>
                                        Add Country
                                    </button>
                                    <br />
                                    <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                        <table className="table table-responsive">
                                            <thead>
                                                <tr>
                                                    <th>Country</th>
                                                    <th>Default Currency</th>
                                                    <th>Open</th>
                                                    <th>Edit</th>
                                                    <th>Delete</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {country.map((item:any, index) => (
                                                    <tr key={index}>
                                                        <td>{item.country}</td>
                                                        <td>{item.default_currency}</td>
                                                        <td>
                                                            <button className="btn btn-sm btn-success me-2" onClick={() => toggleCard2(item)}>
                                                                <FontAwesomeIcon icon={faArrowRight} />
                                                            </button>
                                                        </td>
                                                        <td>
                                                            <button className="btn btn-sm btn-primary me-2" onClick={() => editCountry(item)}>
                                                                <FontAwesomeIcon icon={faEdit} />
                                                            </button>
                                                        </td>
                                                        <td>
                                                            <button className="btn btn-sm btn-danger" onClick={() => deleteCountry(item.id)}>
                                                                <FontAwesomeIcon icon={faTrash} />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        {showCard2 && (
                            <div className="col-md-3">
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">States</h5>
                                        <p style={{color:'green'}}>Country: {selectedCountry}</p>
                                        <button className="btn btn-primary" onClick={() => setShowAddModalState(true)}>
                                          Add State
                                        </button>
                                        <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                            <table className="table table-responsive">
                                                <thead>
                                                    <tr>
                                                        <th>State</th>
                                                        <th>Open</th>
                                                        <th>Edit</th>
                                                        <th>Delete</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {statebyid.map((item:any, index) => (
                                                        <tr key={index}>
                                                            <td>{item.state}</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-success me-2" onClick={() => toggleCard3(item)}>
                                                                    <FontAwesomeIcon icon={faArrowRight} />
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary me-2" onClick={() => editState(item)}>
                                                                    <FontAwesomeIcon icon={faEdit} />
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <button className="btn btn-sm btn-danger" onClick={() => deleteState(item.id)}>
                                                                    <FontAwesomeIcon icon={faTrash} />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        
                        {showCard3 && (
                            <div className="col-md-3">
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">Cities</h5>
                                        <p style={{color:'green'}}>Country: {selectedCountry}</p>
                                        <p style={{color:'green'}}>State: {selectedState}</p>
                                        <button className="btn btn-primary" onClick={() => setShowAddModalCity(true)}>
                                          Add City
                                        </button>
                                        <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                            <table className="table table-responsive">
                                                <thead>
                                                    <tr>
                                                        <th>City</th>
                                                        <th>Open</th>
                                                        <th>Edit</th>
                                                        <th>Delete</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {citybyid.map((item:any, index) => (
                                                        <tr key={index}>
                                                            <td>{item.city}</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-success me-2" onClick={() => toggleCard4(item)}>
                                                                    <FontAwesomeIcon icon={faArrowRight} />
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary me-2" onClick={() => editCity(item)}>
                                                                    <FontAwesomeIcon icon={faEdit} />
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <button className="btn btn-sm btn-danger" onClick={() => deleteCity(item.id)}>
                                                                    <FontAwesomeIcon icon={faTrash} />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                         {showCard4 && (  // New Card 4
              <div className="col-md-3">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">PinCode</h5>
                    <p style={{color:'green'}}>Country: {selectedCountry}</p>
                    <p style={{color:'green'}}>State: {selectedState}</p>
                    <p style={{color:'green'}}>City: {selectedCity}</p>
                    <button className="btn btn-primary" onClick={() => setShowAddModalPincode(true)}>
                      Add Pincode
                    </button>
                    <br />
                    <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                    <table className="table table-responsive">
                      <thead>
                        <tr>
                          <th>PinCode</th>
                          <th>Edit</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {pincodebyid.map((item:any, index) => (
                          <tr key={index}>
                            <td>{item.pincode}</td>
                              <td>
                              <button className="btn btn-sm btn-primary me-2"  onClick={() => editPincode(item)}>
                                <FontAwesomeIcon icon={faEdit} />
                              </button>
                              </td>
                              <td>
                              <button className="btn btn-sm btn-danger" onClick={() => deletePincode(item.id)}>
                                <FontAwesomeIcon icon={faTrash} />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                </div>
                  </div>
                </div>
              </div>
            )}
                    </div>
                )}
            </div>
            {/* Add Country Modal */}
            {showAddModalCountry && (
                <div className="modal fade show" style={{ display: 'block' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Add Country</h5>
                                <button type="button" className="btn-close" onClick={() => setShowAddModalCountry(false)}></button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="mb-3">
                                        <label htmlFor="country-name" className="form-label">Country Name</label>
                                        <input type="text" className="form-control" id="country-name" value={newCountry} onChange={(e) => setNewCountry(e.target.value)} />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="default-currency" className="form-label"> Currency</label>
                                        <select
                                        className="form-control"
                                        id="default-currency"
                                        value={newCurrency}
                                        onChange={(e) => setNewCurrency(e.target.value)}
                                        >
                                          <option value=""></option>
                                          {currency.map((option:any) => (
                                            <option key={option.currency} value={option.currency}>
                                              {option.currency}
                                            </option>
                                          ))}
                                        </select>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={addCountry}>Add Country</button>
                                <button type="button" className="btn btn-secondary" onClick={() => resetCountry()}>Reset</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/* Edit Country Modal */}
            {showEditModalCountry && (
                <div className="modal fade show" style={{ display: 'block' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Edit Country</h5>
                                <button type="button" className="btn-close" onClick={() => setShowEditModalCountry(false)}></button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="mb-3">
                                        <label htmlFor="edit-country-name" className="form-label">Country Name</label>
                                        <input type="text" className="form-control" id="edit-country-name" value={newCountryEdit} onChange={(e) => setNewCountryEdit(e.target.value)} />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="edit-default-currency" className="form-label">Currency</label>
                                        {/* <input type="text" className="form-control" id="edit-default-currency" value={newCurrencyEdit} onChange={(e) => setNewCurrencyEdit(e.target.value)} /> */}
                                        <select
  className="form-control"
  id="default-currency"
  value={newCurrencyEdit}
  onChange={(e) => setNewCurrencyEdit(e.target.value)}
>
  <option value=""></option>
  {currency.map((option:any) => (
    <option key={option.currency} value={option.currency}>
      {option.currency}
    </option>
  ))}
</select>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={updateCountry}>Update Country</button>
                                <button type="button" className="btn btn-secondary" onClick={() => resetCountryEdit()}>Reset</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Add State Modal */}
            {showAddModalState && (
                <div className="modal fade show" style={{ display: 'block' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Add State</h5>
                                <button type="button" className="btn-close" onClick={() => setShowAddModalState(false)}></button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="mb-3">
                                        <label htmlFor="state-name" className="form-label">State Name</label>
                                        <input type="text" className="form-control" id="country-name" value={newState} onChange={(e) => setNewState(e.target.value)} />
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={addState}>Create</button>
                                <button type="button" className="btn btn-primary" onClick={resetState}>Reset</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}


            {/* Edit State Modal */}
            {showEditModalState && (
                <div className="modal fade show" style={{ display: 'block' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Edit State</h5>
                                <button type="button" className="btn-close" onClick={() => setShowEditModalState(false)}></button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="mb-3">
                                        <label htmlFor="edit-state-name" className="form-label">State Name</label>
                                        <input type="text" className="form-control" id="edit-state-name" value={newStateEdit} onChange={(e) => setNewStateEdit(e.target.value)} />
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={updateState}>Update State</button>
                                <button type="button" className="btn btn-secondary" onClick={() => resetStateEdit()}>Reset</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Add City Modal */}
            {showAddModalCity && (
                <div className="modal fade show" style={{ display: 'block' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Add City</h5>
                                <button type="button" className="btn-close" onClick={() => setShowAddModalCity(false)}></button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="mb-3">
                                        <label htmlFor="city-name" className="form-label">City Name</label>
                                        <input type="text" className="form-control" id="country-name" value={newCity} onChange={(e) => setNewCity(e.target.value)} />
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={addCity}>Create</button>
                                <button type="button" className="btn btn-primary" onClick={resetCity}>Reset</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Edit City Modal */}
            {showEditModalCity && (
                <div className="modal fade show" style={{ display: 'block' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Edit City</h5>
                                <button type="button" className="btn-close" onClick={() => setShowEditModalCity(false)}></button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="mb-3">
                                        <label htmlFor="edit-city-name" className="form-label">City Name</label>
                                        <input type="text" className="form-control" id="edit-city-name" value={newCityEdit} onChange={(e) => setNewCityEdit(e.target.value)} />
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={updateCity}>Update City</button>
                                <button type="button" className="btn btn-secondary" onClick={() => resetCityEdit()}>Reset</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}


{/* Add City Modal */}
{showAddModalPincode && (
                <div className="modal fade show" style={{ display: 'block' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Add PinCode</h5>
                                <button type="button" className="btn-close" onClick={() => setShowAddModalPincode(false)}></button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="mb-3">
                                        <label htmlFor="pincode-name" className="form-label">Pincode</label>
                                        <input type="text" className="form-control" id="country-name" value={newPincode} onChange={(e) => setNewPincode(e.target.value)} />
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={addPincode}>Create</button>
                                <button type="button" className="btn btn-primary" onClick={resetPincode}>Reset</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Edit City Modal */}
            {showEditModalPincode && (
                <div className="modal fade show" style={{ display: 'block' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Edit Pincode</h5>
                                <button type="button" className="btn-close" onClick={() => setShowEditModalPincode(false)}></button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="mb-3">
                                        <label htmlFor="edit-pincode-name" className="form-label">Pincode</label>
                                        <input type="text" className="form-control" id="edit-pincode-name" value={newPincodeEdit} onChange={(e) => setNewPincodeEdit(e.target.value)} />
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={updatePincode}>Update City</button>
                                <button type="button" className="btn btn-secondary" onClick={() => resetPincodeEdit()}>Reset</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CountryMaster;
