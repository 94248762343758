import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import * as Icon from 'react-feather';
import { Dropdown } from 'primereact/dropdown';
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import DefaultEditor from 'react-simple-wysiwyg';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { base_url } from '../../../../constant';
import axios from 'axios';

const routes = all_routes;

const CreateService = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [skillsetdropdown, setSkillsetdropdown] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [formDataskillset, setFormDataskillset] = useState({
    specialization: '',
    upload_certificate_1: '',
    upload_certificate_2: '',
    upload_certificate_3: '',
    currency: '',
    wages: '',
    description: '',
    is_active: false,
    sub_specialization:'0',
    wages_type:""
  });
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    skillsetDropdown();
    CurrencyMaster();
  }, []);

  

//get api for skillset 
const skillsetDropdown = async () => {
  try {
    const token = localStorage.getItem('tech_token');
    const response = await fetch(`${base_url}api/SpecializationMasterList`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    setSkillsetdropdown(data); // Assuming data is an array of countries
  } catch (error) {
    console.error('Error fetching countries:', error);
  }
};

//get api for skillset 
const CurrencyMaster = async () => {
  try {
    const token = localStorage.getItem('tech_token');
    const response = await fetch(`${base_url}api/CurrencyMaster`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    setCurrency(data); // Assuming data is an array of countries
  } catch (error) {
    console.error('Error fetching countries:', error);
  }
};

const handleSubmitskillset = async (e:any) => {
  e.preventDefault();
  setIsLoading(true);
  const token = localStorage.getItem('tech_token');
  const data = new FormData();
  data.append('specialization', formDataskillset.specialization);
  data.append('upload_certificate_1', formDataskillset.upload_certificate_1);
  data.append('upload_certificate_2', formDataskillset.upload_certificate_2);
  data.append('upload_certificate_3', formDataskillset.upload_certificate_3);
  data.append('currency', formDataskillset.currency);
  data.append('wages', formDataskillset.wages);
  data.append('description', formDataskillset.description);
  data.append('is_active', formDataskillset.is_active ? '1' : '0');
  data.append('sub_specialization', '0');
  data.append('wages_type', '');
  try {
    const response = await axios.post(`${base_url}api/TechnicianSkillSet`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
      }
    });
    if (response.status === 200) {
      resetFormSkillset();
      navigate(routes.providerService);
      setIsLoading(false);
    }
    console.log('Response:', response.data);
  } catch (error) {
    console.error('Error:', error);
    setIsLoading(false);
  }
};

const skillOptions = skillsetdropdown.map((specialization:any) => ({
  label: specialization.specialization,
  value: specialization.id,
}));

const currencyOptions = currency.map((curr:any) => ({
  label: curr.currency,
  value: curr.id,
}));

const handleFileChange = (e:any) => {
  const { name, files } = e.target;
  setFormDataskillset({
    ...formDataskillset,
    [name]: files[0]
  });
};

const handleCheckboxChange = (e:any) => {
  setFormDataskillset({
    ...formDataskillset,
    is_active: e.target.checked,
  });
};

const resetFormSkillset = () => {
  setFormDataskillset({
    specialization: '',
    upload_certificate_1: '',
    upload_certificate_2: '',
    upload_certificate_3: '',
    currency: '',
    wages: '',
    description: '',
    is_active: false,
    sub_specialization: '0', // Default value
    wages_type: '' // Default value
  });
};

  return (
    <>
      <>
        {/* Breadcrumb */}
        <div className="breadcrumb-bar">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <h2 className="breadcrumb-title">Create Service</h2>
                <nav aria-label="breadcrumb" className="page-breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to={routes.homeOne}>Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Create Service
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        {/* /Breadcrumb */}
      </>

      <div className="page-wrapper">
        <div className="content">
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <>
          <div className="container">
            <div className="row">
              <div className="col-md-12 mx-auto">
                {/* Service List */}
                <div className="service-wizard">
                <div className="col-md-4 mx-auto">
                  <ul id="progressbar">
                    <li
                      className={
                        currentStep === 1
                          ? 'active'
                          : currentStep > 1
                          ? 'activated'
                          : ''
                      }
                    >
                      <div className="multi-step-icon span-info">
                        <span>
                          <i className="fa-regular fa-circle-check" />
                        </span>
                      </div>
                      <div className="multi-step-info">
                        <h6>Information</h6>
                      </div>
                    </li>
                  </ul>
                  </div>
                </div>
                {/* /Service List */}
                <div className="service-inform-fieldset">
                  {/* Service Information */}
                  {currentStep === 1 && (
                    <fieldset id="first-field">
                      <div className="card add-service">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="col-form-label">Specialization</label>
                              <div>
                              <Dropdown
                              options={skillOptions}
                              optionLabel="label"
                              placeholder="Select Category"
                              className="select service-select"
                              value={formDataskillset.specialization}
                              onChange={(e) => setFormDataskillset({ ...formDataskillset, specialization: e.value })}
                              />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="col-form-label">
                               Certificate 1
                              </label>
                              <input className="form-control" type="file" name="upload_certificate_1" onChange={handleFileChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="col-form-label">
                               Certificate 2
                              </label>
                              <input className="form-control" type="file" name="upload_certificate_2" onChange={handleFileChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="col-form-label">
                               Certificate 3
                              </label>
                              <input className="form-control" type="file" name="upload_certificate_3" onChange={handleFileChange}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="col-form-label">
                                Currency
                              </label>
                              <div>
                                <Dropdown
                                  options={currencyOptions}
                                  optionLabel="label"
                                  placeholder="Select Currency"
                                  className="select service-select"
                                  value={formDataskillset.currency}
                                  onChange={(e) => setFormDataskillset({ ...formDataskillset, currency: e.value })}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="col-form-label d-block">
                                Price
                              </label>
                              <input type="text" className="form-control" value={formDataskillset.wages} onChange={(e) => setFormDataskillset({ ...formDataskillset, wages: e.target.value })}/>
                            </div>
                          </div>
                          <div className="col-sm-6">
                              <div className="status-toggle sml-status float-sm-end">
                              <input
            type="checkbox"
            id="status_1"
            className="check"
            checked={formDataskillset.is_active}
            onChange={handleCheckboxChange}
          />
          <label
            htmlFor="status_1"
            className="checktoggle"
          >
          </label>
          <span>Is Active</span>
                              </div>
                            </div>
                          <div className="col-md-12">
                            <div className="form-group mb-0">
                              <label className="col-form-label">
                                Description
                              </label>
                              <DefaultEditor
                                value={formDataskillset.description} 
                                onChange={(e) => setFormDataskillset({ ...formDataskillset, description: e.target.value })}
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // onChange={onChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="field-bottom-btns">
                            <div className="field-btns">
                              <button
                                className="btn btn-primary"
                                type="button"
                                onClick={handleSubmitskillset}
                              >
                                Add Specialization
                              </button>
                            </div>
                            <div className="field-btns">
                              <button
                                className="btn btn-primary"
                                type="button"
                                onClick={resetFormSkillset}
                              >
                                Reset 
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  )}
                </div>
              </div>
            </div>
          </div>
          </>
        )}
        </div>
      </div>

      <>
        {/* Modal Succss */}
        <div
          className="modal fade add-service-modal"
          id="successmodal"
          tabIndex={-1}
          aria-labelledby="successmodal"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <span>
                  <i className="fa-regular fa-circle-check" />
                </span>
                <h3>Success</h3>
                <p>Service has been created succeessfully</p>
                <div className="popup-btn">
                  <a href={routes.providerService} className="btn btn-primary">
                    Go to Dashboard <i className="fa-solid fa-arrow-right" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Modal Succss */}
      </>
    </>
  );
};

export default CreateService;
