import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { base_url } from '../../../constant';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import * as Icon from 'react-feather';
import { Modal, Button } from 'react-bootstrap';

const RequestedSpec = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<any>(null);


  useEffect(() => {
    fetchAllTechnician();
  }, []);

  const fetchAllTechnician = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${base_url}api/getallskillset`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setIsLoading(false);
      // Reverse the array to display in reverse order
      setUsers(data.reverse());
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching technicians:', error);
    }
  };

//   const actionButton = () => {
//     return (
//       <div className="table-actions d-flex">
//         <Link className="delete-table me-2" to="#" data-bs-toggle="modal" data-bs-target="#edit-category">
//           <Icon.Edit className="react-feather-custom" />
//         </Link>
//         <Link className="delete-table border-none" to="#" data-bs-toggle="modal" data-bs-target="#delete-category">
//           <Icon.Trash2 className="react-feather-custom" />
//         </Link>
//       </div>
//     );
//   };
const approveButton = (technician:any) => {
    return (
      <div className="table-actions d-flex">
        <button className="btn btn-success me-2" onClick={() => approveTechnician(technician)}>Approve</button>
      </div>
    );
  };
  const rejectButton = (technician:any) => {
    return (
      <div className="table-actions d-flex">
        <button className="btn btn-danger me-2" onClick={() => rejectTechnician(technician)}>Reject</button>
      </div>
    );
  };
  const eyeButton = (technician:any) => {
    return (
      <div className="table-actions d-flex">
        <button className="btn btn-primary me-2" onClick={() => handleViewClick(technician)}><Icon.Eye className="react-feather-custom" /></button>
      </div>
    );
  };
  const handleViewClick = (rowdata:any) => {
    setSelectedRowData(rowdata);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedRowData(null);
  };

  const profileImageTemplate = (users:any) => {
    const imageSrc = users.userinfo?.userprofileimage;
    return (
      <img src={`${base_url}${imageSrc}`} alt="Profile" style={{ width: '50px', height: 'auto' }} />
    );
  };

  const approveTechnician = async (technician:any) => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${base_url}api/skillsetapprovedupdate/`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify( technician ) // Example payload
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log('Technician approved:', data);
      setIsLoading(false);
      fetchAllTechnician(); // Refresh the list
    } catch (error) {
      setIsLoading(false);
      console.error('Error approving technician:', error);
    }
  };


  const rejectTechnician = async (technician:any) => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${base_url}api/skillsetrejectedupdate/`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify( technician ) // Example payload
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log('Technician approved:', data);
      setIsLoading(false);
      fetchAllTechnician(); // Refresh the list
    } catch (error) {
      setIsLoading(false);
      console.error('Error approving technician:', error);
    }
  };

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        {isLoading && (
          <div className="d-flex justify-content-center mt-4">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-12">
            <div className="table-responsive table-div">
            <h6>Requested Specialization count: {users.length}</h6>
              <DataTable value={users} showGridlines tableStyle={{ minWidth: '50rem' }}>
                <Column  field="Approve" header="Approve" body={(rowData:any) => approveButton(rowData)} />
                <Column  field="Reject" header="Reject" body={(rowData:any) => rejectButton(rowData)} />
                <Column  field="View" header="View"  body={(rowData:any) => eyeButton(rowData)} />
                <Column  field="userinfo.id" header="User Id" />
                <Column  field="userinfo.name" header="User Name" />
                <Column  field="userinfo.userprofileimage" header="photo" body={profileImageTemplate} />
                <Column  field="specializationinfo.specialization" header="Specialization" />
                <Column  field="subspecializationinfo.description" header="Sub-Specialization" />
                <Column  field="wages_type" header="Wages Type" />
                <Column  field="wages" header="Wages" />
                <Column  field="currencyinfo.currency" header="Currency" />
              </DataTable>
            </div>
          </div>
        </div>

        <Modal show={showModal} onHide={handleCloseModal}>
  <Modal.Header closeButton>
    <Modal.Title>Skill Set Details</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    {selectedRowData && (
      <div>
        <h5>Skill Set</h5>
        <p><strong>Status:</strong> {selectedRowData.status === 1 ? "Approved" : "Rejected"}</p>
        <p><strong>Specialization:</strong> {selectedRowData.specializationinfo.specialization}</p>
        <p><strong>Sub Specialization:</strong> {selectedRowData?.subspecializationinfo?.sub_specialization ? selectedRowData.subspecializationinfo.sub_specialization : '-'}</p>
        <p><strong>Upload Certificate 1:</strong> {selectedRowData.upload_certificate_1 && <img src={selectedRowData.upload_certificate_1} alt="Certificate 1" height="50" />}</p>
        <p><strong>Upload Certificate 2:</strong> {selectedRowData.upload_certificate_2}</p>
        <p><strong>Upload Certificate 3:</strong> {selectedRowData.upload_certificate_3}</p>
        <p><strong>Wages Type:</strong> {selectedRowData.wages_type}</p>
        <p><strong>Wages:</strong> {selectedRowData.wages}</p>
        <p><strong>Currency:</strong> {selectedRowData.currencyinfo.currency}</p>
        <p><strong>Description:</strong> {selectedRowData.description}</p>
        <p><strong>Is Active:</strong> {selectedRowData.is_active}</p>

        <h5>User Info</h5>
        <p><strong>User Photo:</strong> <img src={selectedRowData.userinfo.userprofileimage} alt="User" /></p>
        <p><strong>User ID:</strong> {selectedRowData.userinfo.id}</p>
        <p><strong>Name:</strong> {selectedRowData.userinfo.name}</p>
        <p><strong>Email:</strong> {selectedRowData.userinfo.email}</p>
        <p><strong>Wages Type:</strong> {selectedRowData.wages_type}</p>
      </div>
    )}
  </Modal.Body>
  {/* <Modal.Footer>
    <Button variant="secondary" onClick={handleCloseModal}>
      Close
    </Button>
  </Modal.Footer> */}
</Modal>

      </div>
    </div>
  );
};

export default RequestedSpec;
