import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';
import * as Icon from 'react-feather';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { base_url, image_base_url } from '../../../../constant'; 
import { useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Button, Card, Row, Col } from 'react-bootstrap';
import { setemail } from '../../../../core/data/redux/action';

const Booking1 = () => {
  const routes = all_routes;
  const navigate = useNavigate();
  const [value, setValue] = useState(new Date()); 
  const [selectedPincode, setselectedPincode] = useState<any>(null);
  // const [selectedCity, setselectedCity] = useState<any>(null);
  // const [selectedState, setselectedState] = useState<any>(null);
  // const [selectedCountry, setselectedCountry] = useState<any>(null);
  const [countries, setCountries] = useState([]);
  const [states, setState] = useState([]);
  const [cities, setCities] = useState([]);
  const [pincode, setPincode] = useState([]);
  const [selectedTime, setSelectedTime] = useState('');
  const [phone, setphone] = useState('');
  const [address, setaddress] = useState('');
  const [description, setdescription] = useState('');
  const [issuetitle, setissuetitle] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [availableDay, setavailableDay] = useState([]);
  const [enquiry, setenquiry] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedDay, setSelectedDay] = useState(null);
  const [message, setMessage] = useState('');
  const [bookedSlots, setBookedSlots] = useState([]);
  const [fromTime, setFromTime] = useState('');
  const [toTime, setToTime] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [issueTitleError, setIssueTitleError] = useState('');
  const [addressError, setAddressError] = useState('');
  const [hoursError, setHoursError] = useState('');
  const [showModal, setshowModal] = useState(false);
  const [email, setEmail] = useState('');
  const [phoneno, setphoneno] = useState('');
  const [countdown, setCountdown] = useState(null);
  const [showCountdownModal, setShowCountdownModal] = useState(false);
  const [selectedEstimation, setSelectedEstimation] = useState<any>("");
  const [selectedTimeSlots, setSelectedTimeSlots] = useState([]);
  const [minDate, setMinDate] = useState(new Date());
  const [timeModal, setTimeModal] = useState(false);
const [modalMessage, setModalMessage] = useState('');
const [selectedDateObj, setSelectedDateObj] = useState(null);

  const location = useLocation();
  const params = new URLSearchParams(window.location.search);

  // Extract parameters
  const specializationId = params.get('specializationId') || '';
  const wages = parseFloat(params.get('wages')) || 0;
  const currency = params.get('currency') || '';
  const techid = (params.get('userId') || '').trim(); // Trim spaces from userId
  const techemail = (params.get('techemail') || ''); // Trim spaces from userId

  const techimg = params.get('techimg') || '';
  const spec = params.get('spec') || '';
  const selectedCity = params.get('cityName') || '';
  const selectedState = params.get('stateName') || '';
  const selectedCountry = params.get('countryName') || '';
  const selectedCityID = parseInt(params.get('cityId'), 10) || 0;
  const selectedStateID = parseInt(params.get('stateId'), 10) || 0;
  const selectedCountryID = parseInt(params.get('countryId'), 10) || 0;
  const selectedPincodeID = parseInt(params.get('pincodeId'), 10) || 0;
  
 

  const estimationOptions = [
    { label: "1 hour", value: "1 hour" },
    { label: "2 hours", value: "2 hours" },
    { label: "Can't estimate (Default 1 hour)", value: "1 hour" }
  ];

  useEffect(() => {
    fetchCountries();
    fetchState();
    fetchCity();
    fetchPincode();
    gettechdatedetails();
    const currentDate = new Date().toISOString().split('T')[0]; // Format: YYYY-MM-DD
    gettimebyId(currentDate);
  }, []);
  
  //get countries
  const fetchCountries = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${base_url}api/CountryMasterr`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setCountries(data); // Assuming data is an array of countries
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching countries:', error);
    }
  };

 //get state 
  const fetchState = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${base_url}api/StateMasterr`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setState(data); // Assuming data is an array of countries
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching countries:', error);
    }
  };

//get city
  const fetchCity = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${base_url}api/CityMaster`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setCities(data); // Assuming data is an array of countries
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching countries:', error);
    }
  };

//pincode
const fetchPincode= async () => {
  setIsLoading(true);
  try {
    const token = localStorage.getItem('token');
    const response = await fetch(`${base_url}api/PincodeMaster`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    setPincode(data); // Assuming data is an array of countries
    setIsLoading(false);
  } catch (error) {
    setIsLoading(false);
    console.error('Error fetching countries:', error);
  }
};

const gettechdatedetails = async () => {
  setIsLoading(true);
  try {
    const token = localStorage.getItem('token');
    // const userid = localStorage.getItem('userId');
    const response = await fetch(`${base_url}api/gettechdatedetails/${techid}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        
      })
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    console.log('Post successful:', data);
    setIsLoading(false);
    // Redirect or handle success as needed
  } catch (error) {
    console.error('Error posting data:', error);
    // Handle error state or display error message
  }
};

// const handleSubmit = async () => {
//   setIsLoading(true);
//   try {
//     const token = localStorage.getItem('token');
//     const response = await fetch(`${base_url}api/Enquiry`, {
//       method: 'POST',
//       headers: {
//         Authorization: `Bearer ${token}`,
//         'Content-Type': 'application/json'
//       },
//       body: JSON.stringify({
//         address: address,
//         address_on: value,
//         city: selectedCity?.id,
//         contact_number: phone,
//         contract_id: "",
//         country: selectedCountry?.id,
//         created_by: "",
//         description: description,
//         discount_amount: "",
//         final_price: "",
//         gst_tax_amount: "",
//         issue_addressing_cost: "",
//         issue_title: issuetitle,
//         markup_price: "",
//         order_price: "",
//         order_type: 1,
//         past_service_id: "",
//         pincode: selectedPincode?.id,
//         process_status: 0,
//         share_contact_number: 1,
//         skillsetinfo_id: "13",
//         specialization: "6",
//         specialization_choose_myself: "7",
//         specialization_type: 3,
//         specialized_choose_from_past: "",
//         state: selectedState?.id,
//         sub_specialization: "9",
//         technician_cost: "",
//         time: '',
//         // time: selectedTime,
//         total_cost: "",
//         total_price: ""
//       })
//     });
//     if (!response.ok) {
//       throw new Error('Network response was not ok');
//     }
//     const data = await response.json();
//     console.log('Post successful:', data);
//     setIsLoading(false);
//     // Redirect or handle success as needed
//   } catch (error) {
//     console.error('Error posting data:', error);
//     setIsLoading(false);
//     // Handle error state or display error message
//   }
// };
// 5 technisian id

const gettimebyId = async (date:any) => {
  setIsLoading(true);
  try {
    const token = localStorage.getItem('token');
    const user_id = localStorage.getItem('user_id');
    // const response = await fetch(`${base_url}api/gettimebyId/${techid}`
    const response = await fetch(`${base_url}api/gettimebyId/${techid}/${date}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    setavailableDay(data.days_and_times);
    setIsLoading(false);
  } catch (error) {
    setIsLoading(false);
    console.error('Error fetching technicians:', error);
  }
};

const handleDateChange = (value) => {
  const offset = value.getTimezoneOffset() * 60000;
  const adjustedDate = new Date(value.getTime() - offset);
  const selectedDate = adjustedDate.toISOString().split('T')[0]; // Format: YYYY-MM-DD
  console.log("date", selectedDate)
  setSelectedDate(value);
  gettimebyId(selectedDate); // Assuming this updates availableDay
  setMinDate(new Date());
  setSelectedTimeSlots([]);

  // After fetching the times for the selected date, update the day and message
  const dayName = getDayName(value);
  const day = availableDay.find((day:any) => day.day === dayName);
};

 const getDayName = (date:any) => {
    return date.toLocaleDateString('en-US', { weekday: 'long' });
  };

  const tileClassName = ({ date }) => {
    const dayName = getDayName(date);
    const day = availableDay.find(day => day.day === dayName);
    if (day) {
      return day.is_active === 1 ? 'active-day' : 'inactive-day';
    }
    return null;
  };


  useEffect(() => {
    const dayOfWeek = selectedDate.toLocaleString('en-US', { weekday: 'long' });
    setSelectedDay(dayOfWeek);
  }, [selectedDate]);

  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      clearInterval(timer);
      setShowCountdownModal(false); // Hide the countdown modal when the timer ends
    }
    return () => clearInterval(timer);
  }, [countdown]);

  const formatTime = (seconds:any) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  const timersubmit = async (e: any) => {
    // Prevent default form submission
    e.preventDefault();
  
    // Hide the initial modal and show the countdown modal
    setshowModal(false);
    setShowCountdownModal(true); // Show the countdown modal
    setCountdown(1 * 60);
    
    try {
      // Call the POST API to book the appointment
      const appointmentData = await bookappointment(e);

    if (appointmentData) {
      // Set a timeout for 5 minutes to call the PUT method
      setTimeout(async () => {
        await handleToggleChange(appointmentData); // Call the PUT API function here with the appointment id
        setShowCountdownModal(false); // Close the countdown modal after PUT request
      }, 1 * 60 * 1000);
    }
    } catch (error) {
      console.error('Error during appointment booking or status update:', error);
    }
  };
  


  const bookappointment = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
  
    let isValid = true;
  
    // Clear previous errors
    setPhoneError('');
    setIssueTitleError('');
    setAddressError('');
  
    // Validate phone
    if (!phone) {
      setPhoneError('Please enter a valid phone number.');
      isValid = false;
    }
  
    // Validate issue title
    if (!issuetitle) {
      setIssueTitleError('Please enter an issue title.');
      isValid = false;
    }
  
    // Validate address
    if (!address) {
      setAddressError('Please enter an address.');
      isValid = false;
    }
  
    if (!isValid) {
      setIsLoading(false);
      return;
    }
  
    try {
      const token = localStorage.getItem('token');
      const user_id = localStorage.getItem('user_id');
  
      const response = await fetch(`${base_url}api/booking_appointment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          cust_id: parseInt(user_id ?? "0"),
          phone_no: phone,
          address: address,
          issue_title: issuetitle,
          description: description,
          city_id: parseInt(selectedCityID),
          state_id: parseInt(selectedStateID),
          country_id: parseInt(selectedCountryID),
          pincode_id: selectedPincodeID ? parseInt(selectedPincodeID) : null,
          appointment_date: selectedDateObj,  // Ensure date is in correct format
          day: selectedDay,
          from_time: fromTime,
          to_time: toTime,
          service_id: parseInt(specializationId),
          service_amount: wages,
          tech_id: parseInt(techid),
          status: 0,
          customer_type: token ? 1 : 2,
          email: techemail,
          rating: 0,
          review: "",
          review_status: 1,
          delete_status: 0,
          currency: currency,
        }),
      });
  
      // Check the response status and log it for debugging
      console.log('Response Status:', response.status);
      
      const result = await response.json();
  
      // Log the response body for further inspection
      console.log('API Response:', result);
  
      if (response.ok) {
        // Successful booking logic
        const id = result.booking_appointment.id;
        const appointmentData = {
          id,
          amount: result.booking_appointment.service_amount,
          currency: result.booking_appointment.currency,
          customerEmail: result.booking_appointment.email,
        };
   // gettimebyId(currentDate);
      
      if (token) {
        navigate(routes.customerBooking);
      }
        toast('Booking Confirmed');
  
        // Reset form fields after booking
        setEmail("");
        setphone("");
        setaddress("");
        setdescription("");
        setissuetitle("");
        setSelectedEstimation("");
       //  setshowModal(true);
        return appointmentData;
      } else {
        // Handle unsuccessful API response
        console.log('Error response:', result);
        toast(result.message || 'Failed to submit');
      }
    } catch (error) {
      // Log unexpected errors and display a user-friendly message
      console.error('test');
      console.error('Unexpected Error:', error);
      toast(error.message || 'An error occurred');
    } finally {
      setIsLoading(false);
    }
  };
  
  
  

const getenquirydetailsBybookingid = async (id:any) => {
  setIsLoading(true);
  try {
    const token = localStorage.getItem('token');
    const response = await fetch(`${base_url}api/getallenquirydetails/${id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    setenquiry(data);
    postenquiryPaymentRequestCreate(data);
    setIsLoading(false);
  } catch (error) {
    setIsLoading(false);
    console.error('Error fetching technicians:', error);
  }
};

const postenquiryPaymentRequestCreate = async (data:any) => {
  setIsLoading(true);

  try {
    const token = localStorage.getItem('token');
    
    const response = await fetch(`${base_url}api/enquiryPaymentRequestCreate`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({  
        enquiry_id:data.id,
        amount:data.wage,
        currency:data.wage_currency,
        status:0,
        booking_id:data.booking_id,
        payment_reference_id:"",
        payment_mode:""
      }),
    });

    if (response.status === 201) {
      const result = await response.json();
      toast(result.message);
    } else {
      const result = await response.json();
      toast(result.message || 'Failed to submit');
    }
  } catch (error) {
    console.error('Error:', error);
  } finally {
    setIsLoading(false);
  }
};

// const bookappointmentGuest = async (e:any) => {
//   e.preventDefault();
//   setIsLoading(true);

//   let isValid = true;

//   // Clear previous errors
//   setPhoneError('');
//   setIssueTitleError('');
//   setAddressError('');

//   // Validate phone
//   if (!phone ) {
//     setPhoneError('Please enter a valid phone number.');
//     isValid = false;
//   }

//   // Validate issue title
//   if (!issuetitle) {
//     setIssueTitleError('Please enter an issue title.');
//     isValid = false;
//   }

//   // Validate address
//   if (!address) {
//     setAddressError('Please enter an address.');
//     isValid = false;
//   }

//   if (!isValid) {
//     setIsLoading(false);
//     return;
//   }

//   try {
//     const token = localStorage.getItem('token');
//     const userId = localStorage.getItem('userId');
//     const response = await fetch(`${base_url}api/booking_appointment`, {
//       method: 'POST',
//       headers: {
//         // 'Authorization': `Bearer ${token}`,
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({ 
//         cust_id: 0,
//         phone_no: phone,
//         address: address,
//         issue_title: issuetitle,
//         description: description,
//         city_id: parseInt(selectedCityID),
//         state_id: parseInt(selectedStateID),
//         country_id: parseInt(selectedCountryID),
//         pincode_id: parseInt(selectedPincodeID),
//         appointment_date: selectedDateObj,  // Format date as 'YYYY-MM-DD'
//         day: selectedDay,  
//         from_time: fromTime,  // Set selected from time
//         to_time: toTime, 
//         service_id: parseInt(specializationId),  
//         service_amount: wages,  
//         // tech_id: parseInt(techid), 
//         tech_id: 5, 
//         status: 0, 
//         customer_type:2,
//         email:email?email:"",
//         rating: 0,
//         review: "", 
//         review_status: 1,
//         delete_status: 0 
//        }),
//     });

//     if (response.status === 201) {
//       const result = await response.json();
//       console.log('Result:', result);
//       const currentDate = new Date().toISOString().split('T')[0]; // Format: YYYY-MM-DD
//       gettimebyId(currentDate);
//       if (token) {
//         navigate(routes.customerBooking);
//       }
//       toast('Booking Confirmed');
      
//       setEmail("");
//       setphone("");
//       setaddress("");
//       setdescription("");
//       setissuetitle("");
//       setSelectedEstimation("");
//       // setshowModal(true);
//     } else {
//       const result = await response.json();
//       toast(result.message || 'Failed to submit');
//     }
//   } catch (error) {
//     console.error('Error:', error);
//     // toast('An error occurred');
//   } finally {
//     setIsLoading(false);
//   }
// };

const handleToggleChange = async (appointmentData:any) => {
  const { id, amount, currency, customerEmail } = appointmentData;

  const payload = {
    id: id,
    status: 1,
  };

  const url = `${base_url}api/accept-by-technician/${id}/${techid}`;

  setIsLoading(true);
  try {
    const token = localStorage.getItem('token');
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    console.log('Response Status:', response.status);

    if (response.status === 200) {
      const result = await response.json();
      console.log('Result:', result);
      // const currentDate = new Date().toISOString().split('T')[0]; // Format: YYYY-MM-DD
      // gettimebyId(currentDate);
      getenquirydetailsBybookingid(id);
      if (token) {
        navigate(routes.customerBooking);
      } else{
        // navigate(routes.bookingPayment)
        navigate(routes.bookingPayment, {
          state: {
            booking_id: id,
            techID: techid,
            customerEmail: customerEmail // Data from appointment booking
          }
        });
      }
    } else if (response.status === 302) {
      console.log('Redirect Location:', response.headers.get('Location'));
      // Handle redirection if necessary
    } else if (response.status === 404) {
      console.error('Not Found Error: The requested resource was not found.');
    } else {
      const result = await response.json();
      console.error('API Error:', result.message);
      alert(result.message);
      throw new Error('Failed to update time');
    }
  } catch (error) {
    console.error('Error:', error);
  } finally {
    setIsLoading(false);
  }
};

const handleClick = (e: any) => {
  // Retrieve user type and token from localStorage
  const userType = localStorage.getItem('usertype');
  const token = localStorage.getItem('token');

  // Check if the user is a Guest and does not have a token
  if (userType === "Guest" && !token) {
    setshowModal(true);
  } else {
    // Proceed with booking the appointment
   //bookappointment(e);
    timersubmit(e);
  }
};


const handleTimeSlotChange = (time, selectedDate) => {
  if (!selectedEstimation) {
    setModalMessage('Please select an estimation from the dropdown.');
    setTimeModal(true);
    return;
  }

  const formatTime = (timeStr) => {
    const parts = timeStr.split(':');
    const hours = parts[0].padStart(2, '0');
    const minutes = parts[1].padStart(2, '0');
    const seconds = parts[2] ? parts[2].padStart(2, '0') : '00';
    return `${hours}:${minutes}:${seconds}`;
  };

  const selectedTimeParts = time.split(':');
  const selectedHour = parseInt(selectedTimeParts[0], 10);
  const selectedMinute = parseInt(selectedTimeParts[1], 10);
  const now = new Date();
  const currentDate = now.toLocaleDateString('en-CA'); // Format YYYY-MM-DD
  const currentHour = now.getHours();
  const currentMinute = now.getMinutes();
  
  // Calculate the current time in minutes
  const currentTimeInMinutes = currentHour * 60 + currentMinute;
  // Calculate the selected time in minutes
  const selectedTimeInMinutes = selectedHour * 60 + selectedMinute;

  // Ensure selectedDate is a Date object
  // const selectedDateObj = new Date(selectedDate);
  const currentDateObj = new Date(currentDate);

  // console.log(selectedDateObj);
  // console.log(currentDateObj);

  // Strip out the time component for comparison
  selectedDate.setHours(0, 0, 0, 0);
  currentDateObj.setHours(0, 0, 0, 0);

  const dateDifferenceInDays = (selectedDate - currentDateObj) / (1000 * 60 * 60 * 24); // Difference in days
   // Print or log the selected date in desired format
   const selectedDateObj = new Date(selectedDate);
   if (isNaN(selectedDateObj.getTime())) {
     console.error('Invalid selected date:', selectedDate);
     return;
   }
 
   // Create a Date object with the selected date and time
   const selectedDateTime = new Date(selectedDateObj);
   selectedDateTime.setHours(selectedHour);
   selectedDateTime.setMinutes(selectedMinute);
   selectedDateTime.setSeconds(0); // Set seconds to 0
 
   const formattedSelectedDate = selectedDateObj.toLocaleDateString('en-CA'); // yyyy-mm-dd format
   const formattedSelectedDateTime = selectedDateTime.toLocaleString('en-CA', { timeZone: 'UTC' });
   setSelectedDateObj(formattedSelectedDate);
 
   // Log the date and time for debugging
   console.log('Selected Date:', formattedSelectedDate);
   console.log('Selected DateTime:', formattedSelectedDateTime);
 
   now.setHours(0, 0, 0, 0); // Strip time part
  //  const currentDate = now.toLocaleDateString('en-CA'); // yyyy-mm-dd format
 

  // Check if the selected time is at least 4 hours ahead
  if (dateDifferenceInDays === 0 && selectedTimeInMinutes < currentTimeInMinutes + 240) { // 240 minutes = 4 hours
    setModalMessage('The selected time slot must be at least 4 hours ahead of the current time.');
    setTimeModal(true);
    return;
  }


  const selectedTimeSlot = availableDay
    .flatMap((day) => day.available_times)
    .flatMap((availableTime) => availableTime.time_intervals)
    .find((interval) => interval.from_time === time);

  if (selectedTimeSlot) {
    let { from_time, to_time } = selectedTimeSlot;
    from_time = formatTime(from_time);

    const durationInHours = parseInt(selectedEstimation, 10);
    const toTimeHours = selectedHour + durationInHours;
    to_time = formatTime(`${toTimeHours}:${selectedMinute.toString().padStart(2, '0')}:00`);

    // Check if there is an available time slot exactly one hour before and one hour after
    const hasOneHourBefore = availableDay
      .flatMap(day => day.available_times)
      .flatMap(availableTime => availableTime.time_intervals)
      .some(interval => {
        const intervalHour = parseInt(interval.from_time.split(':')[0], 10);
        return intervalHour === (selectedHour - 1);
      });

    const hasOneHourAfter = availableDay
      .flatMap((day) => day.available_times)
      .flatMap((availableTime) => availableTime.time_intervals)
      .some((interval) => {
        const intervalHour = parseInt(interval.from_time.split(':')[0], 10);
        return intervalHour === (durationInHours === 2 ? selectedHour + 2 : selectedHour + 1);
      });

    // Check if the selected time slot is the first slot
    const isFirstSlot = () => {
      const firstSlot = availableDay
        .flatMap(day => day.available_times)
        .flatMap(availableTime => availableTime.time_intervals[0])
        .find(interval => interval.from_time === time);
      return !!firstSlot;
    };

    // Handle selection based on time slot validity
    if (isFirstSlot()) {
      setFromTime(from_time);
      setToTime(to_time);
      setSelectedTimeSlots([{ from_time, to_time }]);
      setMessage('');
      return; // Skip buffer check if the first time slot is selected
    } else if ((!hasOneHourBefore || !hasOneHourAfter)) {
      setModalMessage('The selected time slot requires a 1-hour buffer before and after.');
      setTimeModal(true);
      return;
    }

    // Handle duration-based logic
    if (durationInHours === 2) {
      const nextTimeSlot = availableDay
        .flatMap((day) => day.available_times)
        .flatMap((availableTime) => availableTime.time_intervals)
        .find((interval) => {
          const intervalHour = parseInt(interval.from_time.split(':')[0], 10);
          return intervalHour === selectedHour + 1 && interval.from_time !== time;
        });

      if (nextTimeSlot) {
        const nextFromTime = formatTime(nextTimeSlot.from_time);
        const nextToTime = formatTime(nextTimeSlot.to_time);
        setSelectedTimeSlots([{ from_time, to_time }, { from_time: nextFromTime, to_time: nextToTime }]);
        setFromTime(from_time);
        setToTime(to_time);
        setMessage('');
      } else {
        setModalMessage('No valid time slot available for the selected duration.');
        setTimeModal(true);
      }
    } else if (durationInHours === 1) {
      setSelectedTimeSlots([{ from_time, to_time }]);
      setFromTime(from_time);
      setToTime(to_time);
      setMessage('');
    } else {
      setModalMessage('Selected time slot not valid for the given duration.');
      setTimeModal(true);
    }
  } else {
    setModalMessage('Selected time slot not found.');
    setTimeModal(true);
  }
};


// Utility function to determine if the hour is active or inactive
const isHourAvailable = (time, availableTimes) => {
  return availableTimes.some((interval:any) => interval.from_time === time);
};

const resetForm = () => {
  setphone('');
  setissuetitle('');
  setaddress('');
  setdescription('');
  setSelectedEstimation(null);
  setSelectedDate(new Date());
  setSelectedTimeSlots([]);
  setPhoneError('');
  setIssueTitleError('');
  setAddressError('');
  setHoursError('');
};


//accept-by-technician/{id}/{tech_id}


  return (
    <>
    <ToastContainer />
    <div>
       {isLoading && (
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
    )}
    {!isLoading && (
      <>
      <div className="bg-img">
        <ImageWithBasePath src="assets/img/bg/work-bg-03.png" alt="img" className="bgimg1" />
        <ImageWithBasePath src="assets/img/bg/work-bg-03.png" alt="img" className="bgimg2" />
        <ImageWithBasePath
          src="assets/img/bg/feature-bg-03.png"
          alt="img"
          className="bgimg3"
        />
      </div>
      <div className="content">
        <div className="container">
          <div className="row">
            {/* Booking */}
            <div className="col-lg-10 mx-auto">
              {/* Booking Step */}
              <ul className="step-register row">
                <li className="active col-md-4">
                  <div className="multi-step-icon">
                    <ImageWithBasePath src="assets/img/icons/calendar-icon.svg" alt="img" />
                  </div>
                  <div className="multi-step-info">
                    <h6>Appointment</h6>
                    <p>Choose time &amp; date for the service</p>
                  </div>
                </li>
                <li className="col-md-4">
                  <div className="multi-step-icon">
                    <ImageWithBasePath src="assets/img/icons/wallet-icon.svg" alt="img" />
                  </div>
                  <div className="multi-step-info">
                    <h6>Payment</h6>
                    <p>Select Payment Gateway</p>
                  </div>
                </li>
                <li className="col-md-4">
                  <div className="multi-step-icon">
                    <ImageWithBasePath src="assets/img/icons/book-done.svg" alt="img" />
                  </div>
                  <div className="multi-step-info">
                    <h6>Done </h6>
                    <p>Completion of Booking</p>
                  </div>
                </li>
              </ul>
              {/* /Booking Step */}
              {/* Appointment */}
              <div className="booking-service">
                <div className="row align-items-center">
                  <div className="col-lg-5">
                    <div className="service-book">
                      <div className="service-book-img">
                        {/* <ImageWithBasePath src="assets/img/booking.jpg" alt="img" /> */}
                        <img
  className="img-fluid serv-img"
  alt="Service Image"
  src={techimg ? `${image_base_url}${techimg}` : '/assets/img/provider/provider-11.jpg'}
  style={{ height: "230px" }}
/>

                      </div>
                      <div className="serv-profile">
                        {/* <span className="badge">Car Wash</span> */}
                        <h2>{spec}</h2>
                        {/* <ul>
                          <li className="serv-pro">
                            <ImageWithBasePath
                              src="assets/img/profiles/avatar-01.jpg"
                              alt="img"
                            />
                            <div className="serv-pro-info">
                              <h6>Thomas Herzberg</h6>
                              <p className="serv-review">
                                <i className="fa-solid fa-star" />{' '}
                                <span>4.9 </span>(255 reviews)
                              </p>
                            </div>
                          </li>
                        </ul> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="row align-items-center">
                      <div className="col-md-7 col-sm-6">
                        <div className="provide-box">
                          <span>
                            <Icon.Phone className="react-feather-custom" />
                          </span>
                          <div className="provide-info">
                            <h6>Phone</h6>
                            {/* <p>+1 888 888 8888</p> */}
                            <input type="number" className="form-control" placeholder="Enter Phone number" value={phone} onChange={(e) => setphone(e.target.value)}/>
                            {phoneError && <p className="text-danger">{phoneError}</p>}
                          </div>
                        </div>
                        <div className="provide-box">
                          <span>
                            <Icon.Mail className="react-feather-custom" />
                          </span>
                          <div className="provide-info">
                            <h6>Issue Title</h6>
                            {/* <p>thomasherzberg@example.com</p> */}
                            <input type="text" className="form-control" placeholder="Enter Issue" value={issuetitle} onChange={(e) => setissuetitle(e.target.value)}/>
                            {issueTitleError && <p className="text-danger">{issueTitleError}</p>}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5 col-sm-6">
                        <div className="provide-box">
                          <span>
                            <Icon.MapPin className="react-feather-custom" />
                          </span>
                          <div className="provide-info">
                            <h6>Address</h6>
                            {/* <p>Hanover, Maryland</p> */}
                            <input type="text" className="form-control" placeholder="Enter Address"  value={address} onChange={(e) => setaddress(e.target.value)}  />
                            {addressError && <p className="text-danger">{addressError}</p>}
                          </div>
                        </div>
                        <div className="provide-box">
                          <span>
                            <ImageWithBasePath
                              src="assets/img/icons/service-icon.svg"
                              alt="img"
                            />
                          </span>
                          <div className="provide-info">
                            {/* <h6>Service Amount</h6> */}
                            {/* <h5>$150.00 </h5> */} 
                            <h6>Description</h6>
                            <input type="text" className="form-control" placeholder="Enter Description" value={description} onChange={(e) => setdescription(e.target.value)} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="book-form">
                <div className="row">
                <div className="col-md-3">
                    <div className="form-group">
                      <label className="col-form-label">City</label>
                      <input
                      type="text"
                      className="form-control"
                      value={selectedCity}
                      disabled
                      style={{color:'black'}}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="col-form-label">State</label>
                      <input
                      type="text"
                      className="form-control"
                      value={selectedState}
                      disabled
                      style={{color:'black'}}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="col-form-label">Country</label>
                       <input 
                       type="text"
                       className="form-control"
                       value={selectedCountry}
                       disabled
                       style={{color:'black'}}
                       />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="col-form-label">Select Hours</label>
                      <Dropdown
      value={selectedEstimation}
      onChange={(e) => setSelectedEstimation(e.value)}
      options={estimationOptions}
      optionLabel="label"
      placeholder="Select Estimation"
      className="select w-100"
      style={{color: 'black'}}
    />
                    </div>
                    {hoursError && <p className="text-danger">{hoursError}</p>}
                  </div>
                  
                </div>
              </div>
              {/* /Appointment */}
              {/* Appointment Date & Time */}
              <div className="row">
              {/* <div className="col-lg-4">
              <div className="book-title">
                <h5>Appointment Date</h5>
              </div>
              <div id="datetimepickershow">
                <Calendar
                  style={{ width: '100%' }}
                  onChange={setValue}
                  value={value}
                  tileContent={tileContent}
                />
              </div>
              </div> */}
              <div className="col-lg-4">
                <div className="book-title">
                  <h5>Appointment Date</h5>
                  </div>
                  <div id="datetimepickershow">
                    <Calendar
                    style={{ width: '100%' }}
                    // onChange={setSelectedDate}
                    onChange={handleDateChange}
                    value={selectedDate}
                    tileClassName={tileClassName}
                    minDate={minDate}
                    />
                    </div>
                  </div>
                  <div className="col-lg-6">
  <div className="row">
    <div className="col-md-12">
      <div className="book-title">
        <h5>Appointment Time</h5>
      </div>
    </div>
  </div>
  <div className="token-slot mt-2">
    {availableDay.some((day:any) => day.day === selectedDay && day.is_active === 0) ? (
      // If the technician is not available, display this message
      <div className="not-available-message">
        Technician not available
      </div>
    ) : (
      <select 
        className="form-select" 
        onChange={(e) => handleTimeSlotChange(e.target.value, selectedDate)} 
        disabled={availableDay.filter((day:any) => day.day === selectedDay && day.is_active === 1).length === 0}
      >
        <option value="">Select a time slot</option>
        {availableDay
          .filter((day:any) => day.day === selectedDay && day.is_active === 1)
          .flatMap((day:any) =>
            day.available_times.flatMap((availableTime) =>
              availableTime.time_intervals.map((interval) => {
                const fromTime = interval.from_time?interval.from_time:'';
                const fromHour = parseInt(fromTime.split(':')[0], 10);

                // Determine if this slot is within the restricted range
                const isTimeWithinBlockedRange = selectedTimeSlots.some(
                  ({ from_time }) => {
                    const selectedHour = parseInt(from_time.split(':')[0], 10);
                    return Math.abs(selectedHour - fromHour) <= 1;
                  }
                );

                const isDisabled = bookedSlots.includes(fromTime) || isTimeWithinBlockedRange;

                return (
                  <option 
                    key={fromTime} 
                    value={fromTime} 
                    disabled={isDisabled}
                    className={bookedSlots.includes(fromTime) ? 'disabled-slot' : ''}
                  >
                    {fromTime}
                  </option>
                );
              })
            )
          )}
      </select>
    )}
  </div>
              </div>
                  <div className="book-submit text-end">
                    <button  className="btn btn-secondary" onClick={resetForm}>
                      Cancel
                    </button>
                    <button className="btn btn-primary" onClick={handleClick}>Book Appointment</button>
                  </div>
                </div>
              {/* /Appointment Date & Time */}
            </div>
            {/* /Booking */}
            <Modal show={timeModal} onHide={() => setTimeModal(false)}>
  <Modal.Header closeButton>
    <Modal.Title>Time Slot Error</Modal.Title>
  </Modal.Header>
  <Modal.Body>{modalMessage}</Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setTimeModal(false)}>
      Close
    </Button>
  </Modal.Footer>
</Modal>

            {/* Review popup */}
            <Modal show={showModal} onHide={()=>setshowModal(false)}>
                  <Modal.Header closeButton>
                    <Modal.Title>Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Card className="mb-3">
                        <Card.Body>
                          <Row>
                            <Col>
                              <div className="provide-info">
                                <h6>Email</h6>
                                <input type="email" className="form-control" placeholder="Enter Email" value={email} onChange={(e) => setEmail(e.target.value)}/>
                              </div>
                            </Col>
                          </Row>
                          <br/>
                          <Row>
                            <Col>
                              <div className="provide-info">
                                <h6>Phone</h6>
                                <input type="number" className="form-control" placeholder="Enter Phone number" value={phone} onChange={(e) => setphone(e.target.value)}/>
                              </div>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                  </Modal.Body>
                    <Modal.Footer>
                      <Button variant="danger" >
                        Cancel
                      </Button>
                      <Button variant="secondary" onClick={timersubmit}>
                        Submit
                      </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showCountdownModal} onHide={() => setShowCountdownModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Processing</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <h6>Do not refresh the page</h6>
          {countdown !== null && (
            <h5><Icon.Clock className="react-feather-custom" /> {formatTime(countdown)}</h5>
          )}
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={() => setShowCountdownModal(false)}>
            Close
          </Button> */}
        </Modal.Footer>
      </Modal>
          </div>
        </div>
      </div>
      </>)}
    </div>
    </>
  );
};

export default Booking1;
