import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import CustomerBookingCalendar from './customer-booking-calendar/customer-booking-calendar';
import CustomerBooking from './customer-booking/customer-booking';
import CustomerChat from './customer-chat/customer-chat';
import CustomerDashboard from './customer-dashboard/customer-dashboard';
import CustomerServices from './customer-services/customer-services';
import CustomerFavourite from './customer-favourite/customer-favourite';
import CustomerNotifications from './customer-notifications/customer-notifications';
import CustomerProfile from './customer-profile/customer-profile';
import CustomerReviews from './customer-reviews/customer-reviews';
import CustomerWallet from './customer-wallet/customer-wallet';
import DeviceManagement from './settings/device-management';
import Invoice from './invoice/invoice';
import LoginActivity from './settings/login-activity';
import Notification from './notification/notification';
import SecuritySetting from './settings/security-setting';
import StripeCheckoutForm from './customer-booking/stripecheckoutform';

const CustomersRoutes = () => {
  const all_customers_routes = [
    {
      path: '/customer-booking',
      name: 'customer-booking',
      element: <CustomerBooking />,
      route: Route,
    },
    {
      path: '/customer-chat',
      name: 'customer-chat',
      element: <CustomerChat />,
      route: Route,
    },
    {
      path: '/customer-booking-calendar',
      name: 'customer-booking-calendar',
      element: <CustomerBookingCalendar />,
      route: Route,
    },
    {
      path: '/customer-dashboard',
      name: 'customer-dashboard',
      element: <CustomerDashboard />,
      route: Route,
    },
    {
      path: '/customer-services',
      name: 'customer-services',
      element: <CustomerServices />,
      route: Route,
    },
    {
      path: '/customer-favourite',
      name: 'customer-favourite',
      element: <CustomerFavourite />,
      route: Route,
    },
    {
      path: '/customer-notifications',
      name: 'customer-notifications',
      element: <CustomerNotifications />,
      route: Route,
    },
    {
      path: '/customer-reviews',
      name: 'customer-reviews',
      element: <CustomerReviews />,
      route: Route,
    },
    {
      path: '/customer-wallet',
      name: 'customer-reviews',
      element: <CustomerWallet />,
      route: Route,
    },
    {
      path: '/customer-profile',
      name: 'customer-profile',
      element: <CustomerProfile />,
      route: Route,
    },
    {
      path: '/settings/device-management',
      name: 'device-management',
      element: <DeviceManagement />,
      route: Route,
    },
    {
      path: '/settings/login-activity',
      name: 'login-activity',
      element: <LoginActivity />,
      route: Route,
    },
    {
      path: '/invoice',
      name: 'invoice',
      element: <Invoice />,
      route: Route,
    },
    {
      path: '/notification',
      name: 'notification',
      element: <Notification />,
      route: Route,
    },
    {
      path: '/setting/security-settings',
      name: 'SecuritySetting',
      element: <SecuritySetting />,
      route: Route,
    },
    {
      path: '/customer-booking/stripecheckoutform',
      name: 'Stripecheckoutform',
      element: <StripeCheckoutForm />,
      route: Route,
    },
    {
      path: '/invoice',
      name: 'invoice',
      element: <Invoice />,
      route: Route,
    },
    {
      path: '*',
      name: 'NotFound',
      element: <Navigate to="/" />,
      route: Route,
    },
  ];
  return (
    <>
      <Routes>
        <Route>
          {all_customers_routes.map((route, idx) => (
            <Route path={route.path} element={route.element} key={idx} />
          ))}
        </Route>
      </Routes>
    </>
  );
};

export default CustomersRoutes;
