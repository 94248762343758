import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as Icon from 'react-feather';
import { Dropdown } from 'primereact/dropdown';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { base_url, image_base_url } from '../../../../constant';
import { Modal, Button, Card, Row, Col } from 'react-bootstrap';
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js';
import StripeCheckoutForm from './stripecheckoutform';

const CustomerBooking = () => {
  const routes = all_routes;
  const navigate = useNavigate();
  const [selectedSub, setselectedSub] = useState(null);
  const sub = [
    { name: 'Recent' },
    { name: 'Older' },
    { name: 'A to  Z ' },
    { name: 'Z to A ' },
  ];
  const [selectedItems, setSelectedItems] = useState(Array(10).fill(false));
  const handleItemClick = (index: number) => {
    setSelectedItems((prevSelectedItems) => {
      const updatedSelectedItems = [...prevSelectedItems];
      updatedSelectedItems[index] = !updatedSelectedItems[index];
      return updatedSelectedItems;
    });
  };
  const [isLoading, setIsLoading] = useState(false);
  const [bookingList, setBookingList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [paynowModal, setPayNowModal] = useState(false);
  //const stripePromise = loadStripe('pk_live_51KueO2Ihs1sG6BYS2NaxmNLDAlnYzvguHJ5edf1yhSZDx6iQKJvAsQs9G40yxfe0zwPcZw0Vuw1jAao7VCz0Sntu00Fckv2IW7'); // Replace with your Stripe Publishable Key
  //const stripePromise = loadStripe('pk_test_4eC39HqLyjWDarjtT1zdp7dc');
  const stripePromise = loadStripe('pk_test_51KueO2Ihs1sG6BYSgStdFjAOHLiCWMyE8b9eMJfeFHAOZDEu3W5WOCtFrFHdIp5LSawW3YrSgyZIMO9TDzJbmPQ600Rw4EXk6x');

  useEffect(() => {
    getbooklisttechnician();
  }, []);

//get
// 5 technisian id
const getbooklisttechnician = async () => {
  setIsLoading(true);
  try {
    const token = localStorage.getItem('token');
    const user_id = localStorage.getItem('user_id');
    const response = await fetch(`${base_url}api/customer-appointments/${user_id}`,{
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    setBookingList((data.appointments).reverse());
    setIsLoading(false);
  } catch (error) {
    setIsLoading(false);
    console.error('Error fetching technicians:', error);
  }
};

const formatDate = (dateString:any) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};
const getStatusText = (status:any) => {
  switch (status) {
    case 0:
      return 'New';
    case 1:
      return 'Accepted';
    case 2:
      return 'Confirmed';
    case 3:
      return 'Rejected';
    case 4:
        return 'Completed';
    default:
      return 'Unknown';
  }
};

const getStatusBadgeClass = (status:any) => {
  switch (status) {
    case 0:
      return 'badge-info';
    case 1:
      return 'badge-warning';
    case 2:
      return 'badge-success';
    case 3:
      return 'badge-danger';
    case 4:
      return 'badge-success';
    default:
      return 'badge-secondary';
  }
};

const indexOfLastItem = currentPage * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;
const currentItems = bookingList.slice(indexOfFirstItem, indexOfLastItem);

const totalPages = Math.ceil(bookingList.length / itemsPerPage);

// const handleBookingClick = () => {
//     navigate(routes.bookingPayment);
// };
const handleBookingClick = (bookingId:any, tech_id:any, customerEmail:any, enquiryPaymentRequests:any) => {
  navigate(routes.bookingPayment, { 
    state: {
      amount: enquiryPaymentRequests.amount,
      currency: enquiryPaymentRequests.currency,
      enquiry_id: enquiryPaymentRequests.enquiry_id,
      id: enquiryPaymentRequests.id,
      booking_id:bookingId,
      techID:tech_id,
      customerEmail:customerEmail,
    }
  });
};


  return (
    <div className="col-lg-9">
      {/* Sort */}
      <div className="row align-items-center">
        <div className="col-md-4">
          <div className="widget-title">
            <h4>Booking List</h4>
          </div>
        </div>
        <div className="col-md-8 d-flex align-items-center justify-content-md-end flex-wrap">
          <div className="review-sort  cus-fav-sort me-2">
            <p>Sort</p>

            <Dropdown
              value={selectedSub}
              onChange={(e) => setselectedSub(e.value)}
              options={sub}
              optionLabel="name"
              placeholder="English"
              className="select provider-select select-space"
            />
          </div>
          <div className="grid-listview">
            <ul>
              <li>
                <Link to="#">
                  <Icon.Calendar size={15} />
                </Link>
              </li>
              <li>
                <Link to="#">
                  <Icon.Filter size={15} />
                </Link>
              </li>
              <li>
                <Link to="#">
                  <Icon.Grid size={15} />
                </Link>
              </li>
              <li>
                <Link to={routes.customerBooking} className="active">
                  <Icon.List size={15} />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* /Sort */}
       {isLoading && (
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
    )}
    {!isLoading && (
      <div>
      {currentItems.length > 0 ? (currentItems.map((booking:any) => (
        <div className="booking-list" key={booking.id}
        //  onClick={() => handleBookingClick(booking.status)}
         >
              <div className="booking-widget">
                <div className="booking-img">
                  <Link to={""}>
                    <img
                      src={booking.service?.service_image ? `${image_base_url}${booking.service?.service_image}` : '/assets/img/services/service-06.jpg'}
                      // src="assets/img/services/service-06.jpg"
                      alt="User Image" className={''} />
                  </Link>
                </div>
                <div className="booking-det-info">
                <div style={{ display: 'flex', alignItems: 'center' }}>
  <h3 style={{ marginLeft: 5 }}>
    {/* <Link to={routes.serviceDetails1}> */}
      {booking.service?.service_title ? booking.service?.service_title : ""}
    {/* </Link>{' '} */}
    <span style={{ marginLeft: '40px' }} className={`badge ${getStatusBadgeClass(booking.status)}`}>
      {getStatusText(booking.status)}
    </span>
  </h3>

  {booking.status === 1 && booking.enquiry_payment_requests.length > 0 && (
    <button 
      className="btn btn-success btn-sm" 
      style={{ marginLeft: '10px' }} onClick={()=>{console.log(booking.enquiry_payment_requests);
      handleBookingClick(booking.id, booking.tech_id, booking.customer?.email, booking.enquiry_payment_requests[0])}}>
      Pay Now
    </button>
  )}
</div>


                  <ul className="booking-details">
                    <li>
                      <span className="book-item">Booking Date</span> : {formatDate(booking.appointment_date)}, {booking.from_time} - {booking.to_time}
                    </li>
                    <li>
                      <span className="book-item">Amount</span> : ${booking.service_amount}{' '}
                      {/* <span className="badge-grey">COD</span> */}
                    </li>
                    <li>
                      <span className="book-item">Location</span> : {booking.address}
                    </li>
                    <li>
                      <span className="book-item">Technician</span> : {booking.technician?.name ? booking.technician?.name : "-"} 
                      <div className="user-book">
                      </div>
                      <p>{booking.technician?.email}</p>
                      <p>{booking.technician?.mobileno}</p>
                    </li>
                  </ul>
                  
                </div>
              </div>
            </div>

            ))):(<div> <p style={{fontWeight:'bold'}}>No Data available.</p></div>)}
            </div>
            
          )}

<Modal show={paynowModal} onHide={() => setPayNowModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Payment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Elements stripe={stripePromise}>
          <StripeCheckoutForm/>
        </Elements>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setPayNowModal(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
      {/* Pagination */}
      <div className="row justify-content-end">
        <div className="col-sm-12">
            <div className="review-pagination">
              <p>{`${indexOfFirstItem + 1} - ${Math.min(indexOfLastItem, bookingList.length)} of ${bookingList.length}`}</p>
                <ul className="pagination">
                  <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <Link className="page-link" to="#" onClick={() => setCurrentPage(currentPage - 1)}>Previous</Link>
                  </li>
                  {[...Array(totalPages)].map((_, index) => (
                  <li key={index + 1} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                    <Link className="page-link" to="#" onClick={() => setCurrentPage(index + 1)}>{index + 1}</Link>
                  </li>
                  ))}
                  <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <Link className="page-link" to="#" onClick={() => setCurrentPage(currentPage + 1)}>Next</Link>
                  </li>
                </ul>
            </div>
          </div>
      </div>
      {/* /Pagination */}
    </div>
  );
};

export default CustomerBooking;
