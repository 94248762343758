import React, { useState, useEffect } from 'react';
import * as Icon from 'react-feather';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { TimePicker } from 'antd';
import { Link } from 'react-router-dom';
import { base_url } from '../../../../constant';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

dayjs.extend(customParseFormat);

const ProviderAvailability = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [changedTimes, setChangedTimes] = useState({});
  const [hidePostButton, setHidePostButton] = useState(false);
  const [newlyAddedTimes, setNewlyAddedTimes] = useState<{ [key: string]: boolean }>({});
  const [addingField, setAddingField] = useState(''); // Track which field is being added
  const user_id = localStorage.getItem('technician_id');

  

  useEffect(() => { 
    gettimebyId();
 }, []);

  const gettimebyId = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('tech_token');
      const user_id = localStorage.getItem('technician_id');
      // const currentDate = new Date().toISOString().split('T')[0];
      const response = await fetch(`${base_url}api/gettimebyId/${user_id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setData(data.days_and_times);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching technicians:', error);
    }
  };
  
  const handleTimeChange = (dayIndex:any, timeIndex:any, timeType:any, time:any) => {
    setChangedTimes(prevState => ({
      ...prevState,
      [`${dayIndex}_${timeIndex}_${timeType}`]: time
    }));
  };

  const handleUpdate = async (dayIndex:any, timeIndex:any) => {
    const user_id = localStorage.getItem('user_id');
    const updatedFromTime = changedTimes[`${dayIndex}_${timeIndex}_from_time`];
    const updatedToTime = changedTimes[`${dayIndex}_${timeIndex}_to_time`];

    const dayData = data[dayIndex];
    const timeData = dayData.available_times[timeIndex];
console.log(timeData);
console.log(dayData);
    const payload = {
      user_id: parseInt(user_id),
      day_id: timeData.day_id,
      from_time: updatedFromTime ? updatedFromTime.format('HH:mm:ss') : timeData.from_time,
      to_time: updatedToTime ? updatedToTime.format('HH:mm:ss') : timeData.to_time,
      remarks: ""
    };

    setIsLoading(true);
    try {
      const token = localStorage.getItem('tech_token');
      const response = await fetch(`${base_url}api/timeput/${timeData.id}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.status === 200) {
        const result = await response.json();
        console.log('result', result);
        await gettimebyId();
        setHidePostButton(prevState => ({
          ...prevState,
          [`${dayIndex}_${timeIndex}`]: true
        }));
      } else {
        const result = await response.json();
        alert(result.message);
        throw new Error('Failed to update time');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggleChange = async (dayIndex:any) => {
    const user_id = localStorage.getItem('technician_id');
    const dayData = data[dayIndex];
    const updatedIsActive = !dayData.is_active;
  
    const payload = {
      user_id: parseInt(user_id),
      day: dayData.day,
      is_active: updatedIsActive ? 1 : 0,
      remarks: "",
    };
  
    const url = `${base_url}api/updateDay/${dayData.id}`;
    console.log('Request URL:', url);
    console.log('Payload:', payload);
  
    setIsLoading(true);
    try {
      const token = localStorage.getItem('tech_token');
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      console.log('Response Status:', response.status);
  
      if (response.status === 200) {
        const result = await response.json();
        console.log('Result:', result);
        await gettimebyId();
      } else if (response.status === 302) {
        console.log('Redirect Location:', response.headers.get('Location'));
        // Handle redirection if necessary
      } else if (response.status === 404) {
        console.error('Not Found Error: The requested resource was not found.');
      } else {
        const result = await response.json();
        console.error('API Error:', result.message);
        alert(result.message);
        throw new Error('Failed to update time');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // const handleAddHours = (dayIndex: any) => {
  //   const day = data[dayIndex];
  
  //   if (day.is_active === 0) {
  //     toast.info('Please turn on the day to enable time selection.');
  //     return; // Prevent adding new time slots if the day is inactive
  //   }
  
  //   setData(prevData => {
  //     const newData = [...prevData]; // Make a copy of the existing data
  //     const newTimeSlot = {
  //       id: newData[dayIndex].available_times.length + 1, // Generate a new ID
  //       day_id: day.id, // Include the day_id
  //       from_time: '00:00:00',
  //       to_time: '23:59:59',
  //       is_active: 1, // Assuming it's active by default
  //     };
  
  //     newData[dayIndex].available_times.push(newTimeSlot); // Add new time slot
  //     setNewlyAddedTimes(prevState => ({
  //       ...prevState,
  //       [`${dayIndex}_${newTimeSlot.id}`]: true // Mark the time slot as newly added
  //     }));
  //     return newData; // Return the updated data
  //   });
  // };

  const handleAddHours = (dayIndex: any) => {
    const day = data[dayIndex];
  
    if (day.is_active === 0) {
      toast.info('Please turn on the day to enable time selection.');
      return; // Prevent adding new time slots if the day is inactive
    }
  
    // Add only the first time slot or toggle between from_time and to_time
    setData(prevData => {
      const newData = [...prevData];
      const dayData = newData[dayIndex];
      const availableTimes = dayData.available_times;
  
      // Determine the new time slot
      const newTimeSlot = {
        id: availableTimes.length + 1, // Generate a new ID
        day_id: day.id, // Include the day_id
        from_time: addingField === 'from' ? '00:00:00' : availableTimes[availableTimes.length - 1]?.from_time || '00:00:00',
        to_time: addingField === 'to' ? '23:59:59' : availableTimes[availableTimes.length - 1]?.to_time || '23:59:59',
        is_active: 1, // Assuming it's active by default
      };
  
      // Push the new time slot to available_times
      if (addingField === 'from') {
        dayData.available_times.push(newTimeSlot);
        setAddingField('to'); // Set the next field to add
      } else if (addingField === 'to') {
        dayData.available_times.push(newTimeSlot);
        setAddingField(''); // Reset the field after adding both
      }
  
      setNewlyAddedTimes(prevState => ({
        ...prevState,
        [`${dayIndex}_${newTimeSlot.id}`]: true // Mark the time slot as newly added
      }));
  
      return newData; // Return the updated data
    });
  };
  
  
  
  const handlePost = async (dayIndex:any, timeIndex:any) => {
    const user_id = localStorage.getItem('technician_id')??"";
    const updatedFromTime = changedTimes[`${dayIndex}_${timeIndex}_from_time`];
    const updatedToTime = changedTimes[`${dayIndex}_${timeIndex}_to_time`];

    const dayData = data[dayIndex];
    const timeData = dayData.available_times[timeIndex];

    const payload = {
      user_id: parseInt(user_id),
      day_id: timeData.day_id,
      from_time: updatedFromTime ? updatedFromTime.format('HH:mm:ss') : timeData.from_time,
      to_time: updatedToTime ? updatedToTime.format('HH:mm:ss') : timeData.to_time,
      remarks: ""
    };

    setIsLoading(true);
    try {
      const token = localStorage.getItem('tech_token');
      const response = await fetch(`${base_url}api/timepost`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.status === 200) {
        const result = await response.json();
        console.log('result', result);
        await gettimebyId();

        setHidePostButton(prevState => ({
          ...prevState,
          [`${dayIndex}_${timeIndex}`]: true
        }));
      } else {
        const result = await response.json();
        alert(result.message);
        throw new Error('Failed to update time');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePostDay = async () => {
    const user_id = localStorage.getItem('technician_id');

    const payload = {
      user_id: parseInt(user_id),
      day: "",
      is_active:1,
      remarks: ""
    };

    setIsLoading(true);
    try {
      const token = localStorage.getItem('tech_token');
      const response = await fetch(`${base_url}api/daypost`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.status === 201) {
        const result = await response.json();
        console.log('result', result);
        await gettimebyId();

      } else {
        const result = await response.json();
        alert(result.message);
        throw new Error('Failed to update time');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
    <ToastContainer />
    {isLoading && (
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
    )}
    {!isLoading && (
      <>
        <div className="row">
          <div className="col-md-12">
            <div className="tab-list">
              <ul className="nav">
                <li>
                  <Link to="#" className="active" data-bs-toggle="tab" data-bs-target="#userid-verification">Availability</Link>
                </li>
                <li>
                  <Link to="#" data-bs-toggle="tab" data-bs-target="#experience">Location</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row">
                <div className="col-lg-10 mx-auto d-flex align-items-center justify-content-between">
                  <div className="provider-subtitle">
                    <h6>Availability</h6>
                  </div>
                </div>
              </div>
            </div>
            <br/>

            <div className="availability-sec">
              <div className="row">
                <div className="col-lg-10 mx-auto">
                  <div className="row">
                  {data && data.length > 0 ? (
          data.map((dayData: any, dayIndex: any) => (
                      <React.Fragment key={dayData.id}>
                        <div className="col-sm-6">
                          <h6>{dayData.day}</h6>
                        </div>
                        <div className="col-sm-6">
                          <div className="status-toggle sml-status d-flex justify-content-sm-end align-items-center">
                          <input
            type="checkbox"
            id={`status_${dayIndex}`}
            className="check"
            checked={dayData.is_active === 1}
            onChange={() => handleToggleChange(dayIndex)}
          />
          <label htmlFor={`status_${dayIndex}`} className="checktoggle">
            checkbox
          </label>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="day-info">
                            <div className="row day-cont">
                              {dayData.available_times.map((timeData:any, timeIndex:any) => (
                                <React.Fragment key={timeData.id}>
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label className="col-form-label">From</label>
                                      <div className="form-icon">
                                      <div 
                                      onClick={() => {
                                        if (dayData.is_active === 0) {
                                          toast.info('Please turn on the day to enable time selection.');
                                        }
                                      }}
                                      style={{ cursor: dayData.is_active === 0 ? 'not-allowed' : 'default' }} // Change cursor style when disabled
                                      >
                                        <TimePicker
                                        placeholder="Select Time"
                                        className="form-control timepicker"
                                        onChange={(time) => handleTimeChange(dayIndex, timeIndex, 'from_time', time)}
                                        defaultValue={dayjs(timeData.from_time, 'HH:mm:ss')}
                                        disabled={dayData.is_active === 0} // Disables the actual TimePicker
                                        />
                                        </div>
                                        <span className="cus-icon">
                                          <Icon.Clock className="react-feather-custom" />
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="d-flex">
                                      <div className="form-group w-100">
                                        <label className="col-form-label">To</label>
                                        <div className="form-icon">
                                          <TimePicker
                                            placeholder="Select Time"
                                            className="form-control timepicker"
                                            onChange={(time) => handleTimeChange(dayIndex, timeIndex, 'to_time', time)}
                                            defaultValue={dayjs(timeData.to_time, 'HH:mm:ss')}
                                            disabled={dayData.is_active === 0}
                                            onClick={() => {
                                              if (dayData.is_active === 0) {
                                                toast.info('Please turn on the day to enable time selection.');
                                              }
                                            }}
                                            
                                          />
                                          <span className="cus-icon">
                                            <Icon.Clock className="react-feather-custom" />
                                          </span>
                                        </div>
                                      </div>
                                      {(changedTimes[`${dayIndex}_${timeIndex}_from_time`] || changedTimes[`${dayIndex}_${timeIndex}_to_time`]) && !hidePostButton[`${dayIndex}_${timeIndex}`] && (
                                        <div className="col-md-2 d-flex align-items-end" style={{margin:"10px"}}>
                                          <button
                                            className="btn btn-primary"
                                            onClick={() => {
                                              if (newlyAddedTimes[`${dayIndex}_${timeData.id}`]) {
                                                handlePost(dayIndex, timeIndex); // Call a function to handle POST
                                              } else {
                                                handleUpdate(dayIndex, timeIndex); // Call the existing handleUpdate
                                              }
                                            }}
                                          >
                                            {newlyAddedTimes[`${dayIndex}_${timeData.id}`] ? 'Post' : 'Update'}
                                          </button>
                                        </div>
                                      )}
                                      
                                    </div>
                                  </div>
                                </React.Fragment>
                              ))}
                            </div>
                            {/* <div className="form-group">
      <Link to="#" className="add-text add-day" onClick={() => handleAddHours(dayIndex)}>
        <Icon.PlusCircle className="react-feather-custom" /> Add Hours
      </Link>
    </div> */}
    <div className="form-group">
  <Link
    to="#"
    className="add-text add-day"
    onClick={() => {
      if (addingField === '') {
        setAddingField('from');
      } else {
        handleAddHours(dayIndex);
        setAddingField(''); // Reset after adding
      }
    }}
  >
    <Icon.PlusCircle className="react-feather-custom" /> Add Hours
  </Link>
</div>

                          </div>
                        </div>
                      </React.Fragment>
                   ))
                   ): (
                    <div className="col-12">
                      <button className="btn btn-primary" onClick={handlePostDay}>Add Availability</button>
                      <br />
                      <br />
                      <p>No data available</p>
                    </div>
                  )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )}
    </>
  );
};

export default ProviderAvailability;


{/* <div className="form-group">
                <label className="col-form-label">&nbsp;</label>
                <button
                  type="button"
                  className="btn btn-danger-outline trash"
                  onClick={() => deleteHours(`hoursArray${dayIndex}`, index)}
                >
                  <i className="far fa-trash-alt" />
                </button>
              </div> */}
