import React from 'react';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { Link } from 'react-router-dom';
import { all_routes } from '../../../../core/data/routes/all_routes';

const FooterThree = () => {
  const routes = all_routes;
  return (
    <>
      <footer className="footer footer-three">
        {/* Footer Top */}
        <div className="footer-top footer-top-three aos" data-aos="fade-up">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-6 col-md-6">
                {/* Footer Widget */}
                <div className="footer-widget">
                  <div className="footer-logo">
                    <Link to={routes.homeOne}>
                      <ImageWithBasePath src="assets/img/logo-02.svg" alt="logo" />
                    </Link>
                  </div>
                  <div className="footer-content footer-content-three">
                    <p>
                      Contrary to popular belief, Lorem Ipsum is not simply
                      random text. It has roots in a piece of classical Latin
                      literature from 45 BC, making it over 2000 years old.
                      Richard...
                    </p>
                  </div>
                </div>
                {/* /Footer Widget */}
              </div>
              <div className="col-xl-2 col-lg-3 col-md-6">
                {/* Footer Widget */}
                <div className="footer-widget footer-menu footer-menu-three">
                  <h2 className="footer-title footer-title-three">Discover</h2>
                  <ul>
                    <li>
                      <Link to="#">Chicago</Link>
                    </li>
                    <li>
                      <Link to="#">Los Angeles</Link>
                    </li>
                    <li>
                      <Link to="#">Miami</Link>
                    </li>
                    <li>
                      <Link to="#">New York</Link>
                    </li>
                  </ul>
                </div>
                {/* /Footer Widget */}
              </div>
              <div className="col-xl-2 col-lg-3 col-md-6">
                {/* Footer Widget */}
                <div className="footer-widget footer-menu footer-menu-three">
                  <h2 className="footer-title footer-title-three">Category</h2>
                  <ul>
                    <li>
                      <Link to="#">Hair style </Link>
                    </li>
                    <li>
                      <Link to="#">Trimming</Link>
                    </li>
                    <li>
                      <Link to="#">Hair makeup</Link>
                    </li>
                    <li>
                      <Link to="#">Clothes selection</Link>
                    </li>
                  </ul>
                </div>
                {/* /Footer Widget */}
              </div>
              <div className="col-xl-2 col-lg-3 col-md-6">
                {/* Footer Widget */}
                <div className="footer-widget footer-menu footer-menu-three">
                  <h2 className="footer-title footer-title-three">
                    Quick Links
                  </h2>
                  <ul>
                    <li>
                      <Link to={routes.aboutUs}>About Us </Link>
                    </li>
                    <li>
                      <Link to={routes.termsCondition}>Terms &amp; Conditions</Link>
                    </li>
                    <li>
                      <Link to="#">Support Center</Link>
                    </li>
                    <li>
                      <Link to="#">Contact Us</Link>
                    </li>
                  </ul>
                </div>
                {/* /Footer Widget */}
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6">
                {/* Footer Widget */}
                <div className="footer-widget footer-menu footer-menu-three">
                  <h2 className="footer-title footer-subtitle-three">
                    Our Total Visitors
                  </h2>
                  <p>4,56,6597</p>
                </div>
                <div className="footer-widget footer-menu footer-menu-three">
                  <h2 className="footer-title footer-subtitle-three">
                    Need live support?
                  </h2>
                  <p>truelysell@example.com</p>
                </div>
                <div className="footer-widget footer-menu footer-menu-three">
                  <h2 className="footer-title footer-subtitle-three">
                    Toll Free Customer Care
                  </h2>
                  <p>+91 26447 99875</p>
                </div>
                {/* /Footer Widget */}
              </div>
            </div>
          </div>
        </div>
        {/* /Footer Top */}
        {/* Footer Bottom */}
        <div className="footer-bottom">
          <div className="container">
            {/* Copyright */}
            <div className="copyright">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <div className="copyright-text copyright-color">
                    <p className="mb-0">
                      © 2023 Truelysell. All Rights Reserved.
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  {/* Copyright Menu */}
                  <div className="copyright-menu">
                    <ul className="policy-menu">
                      <li>
                        <Link to="#">Side Map</Link>
                      </li>
                      <li>
                        <Link to={routes.privacyPolicy}>Privacy policy</Link>
                      </li>
                      <li>
                        <Link to={routes.privacyPolicy}>Cookie Policy</Link>
                      </li>
                    </ul>
                  </div>
                  {/* /Copyright Menu */}
                </div>
              </div>
            </div>
            {/* /Copyright */}
          </div>
        </div>
        {/* /Footer Bottom */}
      </footer>
    </>
  );
};

export default FooterThree;
