import React, { useState,useEffect } from 'react'
import * as Icon from 'react-feather'
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath'
import { Link } from 'react-router-dom';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { Dropdown } from 'primereact/dropdown';
import { base_url, image_base_url } from '../../../../constant';
import { Modal, Button, Row, Col, Form  } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useSelector } from 'react-redux';
import axios from 'axios';
const routes = all_routes;

const ProviderUseridVerification = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [verificationData, setverificationData] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [editItemId, setEditItemId] = useState(null);
    const [errors, setErrors] = useState({ name_of_verification_id: '' });
    const [fileShowModalDelete, setfileShowModalDelete] = useState(false);
    const [deletefileId, setdeletefileId] = useState<any>('');
    const [deletefilename, setdeletefilename] = useState<any>('');
    const [fileData, setFileData] = useState({
        name_of_verification_id: '', // Default value for testing
        attachment_1: '',
        attachment_2: '',
        attachment_3: '',
      });

    const [experienceData, setexperienceData] = useState([]);
    const [experienceModalEdit, setexperienceModalEdit] = useState(false);
    const [editDataexperience, setEditDataexperience] = useState({
        company_name: '',
        department: '',
        designation: '',
        experience: '',
        others: '',
        id:'',
        user_id:'',
        created_at:'',
        updated_at:''
      });

      const [formDataexperience, setFormDataexperience] = useState({
        company_name: '',
        department: '',
        designation: '',
        experience: '',
        others: ''
      });

    useEffect(() => { 
        fetchTechnicianUserId();
        // TechnicianExperience();
     }, []);
    //get api/TechnicianUserId 
const fetchTechnicianUserId= async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('tech_token');
      const response = await fetch(`${base_url}api/TechnicianUserId`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setIsLoading(false);
      const data = await response.json();
      setverificationData(data.reverse()); // Assuming data is an array of countries
    } catch (error) {
        setIsLoading(false);
      console.error('Error fetching countries:', error);
    }
  };

  const handleEditClick = (item:any) => {
    setEditMode(true);
    setEditItemId(item.id);
    setFileData({
      name_of_verification_id: item.name_of_verification_id,
      attachment_1: '',
    attachment_2: '',
    attachment_3: '',
    });
  };

  const resetfile = () => {
    setFileData({
      name_of_verification_id: '',
    attachment_1: '',
    attachment_2: '',
    attachment_3: '',
    })
  };

  const validate = () => {
    let valid = true;
    const errors = { name_of_verification_id: '' };
  
    if (!fileData.name_of_verification_id) {
      errors.name_of_verification_id = 'Name of Verification Id is required';
      valid = false;
    }
  
    setErrors(errors);
    return valid;
  };

  const handleDeletefile = (item:any)=> {
    setfileShowModalDelete(true);
    setdeletefileId(item.id);
    setdeletefilename(item.name_of_verification_id);
  };

//update post
const handleFileUpdate = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validate()) {
    try {
      const token = localStorage.getItem('tech_token');
      const userId = localStorage.getItem('technician_id');
  
      // Create FormData object
      const data = new FormData();
      data.append('name_of_verification_id', fileData.name_of_verification_id);
      if (editItemId) {
        data.append('id', editItemId);
      }
      if (userId) {
        data.append('user_id', userId);
      }
      data.append('attachment_1', fileData.attachment_1 || '');
      data.append('attachment_2', fileData.attachment_2 || '');
      data.append('attachment_3', fileData.attachment_3 || '');
  
      // Send POST request
      const response = await fetch(`${base_url}api/TechnicianUserIdupdate`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: data,
      });
  
      if (!response.ok) {
        await fetchTechnicianUserId();
        throw new Error('Network response was not ok');
        
      }
      resetfile();
      setEditMode(false);
      fetchTechnicianUserId();
      const result = await response.json();
      console.log('Result:', result);
  
      // Optionally handle success feedback or state updates
    } catch (error) {
      console.error('Error:', error);
      // Optionally handle error feedback or state updates
    }
  }
  };

  const handleFileSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validate()) {
    try {
      const token = localStorage.getItem('tech_token');
      // const userId = localStorage.getItem('userId');
  
      // Create FormData object
      const data = new FormData();
      data.append('name_of_verification_id', fileData.name_of_verification_id);
      data.append('attachment_1', fileData.attachment_1 || '');
      data.append('attachment_2', fileData.attachment_2 || '');
      data.append('attachment_3', fileData.attachment_3 || '');
      // if (fileData.attachment_1) {
      //   data.append('attachment_1', fileData.attachment_1);
      // }
      // if (fileData.attachment_2) {
      //   data.append('attachment_2', fileData.attachment_2);
      // }
      // if (fileData.attachment_3) {
      //   data.append('attachment_3', fileData.attachment_3);
      // }
      // Send POST request
      const response = await fetch(`${base_url}api/TechnicianUserId`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: data,
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      resetfile();
      fetchTechnicianUserId();
      const result = await response.json();
      console.log('Result:', result);
  
      // Optionally handle success feedback or state updates
    } catch (error) {
      console.error('Error:', error);
      // Optionally handle error feedback or state updates
    }
  }
  };

  const TechnicianUserIdDeletefile = async () => {

    try {
      const token = localStorage.getItem('tech_token');
      // Send POST request
      const response = await fetch(`${base_url}api/TechnicianUserId/${deletefileId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        await fetchTechnicianUserId();
        throw new Error('Network response was not ok');
        
      }
      fetchTechnicianUserId();
      const result = await response.json();
      console.log('Result:', result);
  
      // Optionally handle success feedback or state updates
    } catch (error) {
      console.error('Error:', error);
      // Optionally handle error feedback or state updates
    }
  };

  const actionBodyTemplate = (rowData:any) => {
    return (
      <div>
        <Link
          className="action-set confirm-text"
          to="#"
        //   data-bs-toggle="modal"
        //   data-bs-target="#edit-coupon"
          onClick={() => handleEditClick(rowData)}
        >
          <Icon.Edit className="react-feather-custom" />
        </Link>
        <Link className="action-set confirm-text" to="#" onClick={() => handleDeletefile(rowData)}>
          <Icon.Trash2 className="react-feather-custom" />
        </Link>
      </div>
    );
  };

  //get api/TechnicianExperience 
const TechnicianExperience= async () => {
    try {
      const token = localStorage.getItem('tech_token');
      const response = await fetch(`${base_url}api/TechnicianExperience`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setexperienceData(data); // Assuming data is an array of countries
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  //post
const handleSubmitExperience = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    // Determine API endpoint based on userType
  
    try {
      const token = localStorage.getItem('tech_token');
      setFormDataexperience({
        ...formDataexperience,
      });
      
      const response = await fetch(`${base_url}api/TechnicianExperience`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formDataexperience),
      });
  
      if (response.status === 200) {
        const result = await response.json();
        console.log('result',result);
        setIsLoading(false);
        TechnicianExperience();
      } else {
        const result = await response.json();
        setIsLoading(false);
        alert(result.message)
        throw new Error('Failed to sign up');
        
      }
    } catch (error) {
      console.error('Error:', error);
      setIsLoading(false);
    }
  };

  const handleEditClickExperience = (technician:any) => {
    setEditDataexperience({
      company_name: technician.company_name || '',
      department: technician.department || '',
      designation: technician.designation || '',
      experience: technician.experience || '',
      others: technician.others || '',
      id:technician.id,
      user_id:technician.user_id,
      created_at:technician.created_at,
      updated_at:technician.updated_at
    });
    setexperienceModalEdit(true);
  };

  //put experience
const updateTechnicianExperience = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    // Determine API endpoint based on userType
  
    try {
      const token = localStorage.getItem('tech_token');
      setEditDataexperience({
        ...editDataexperience,
  
      });
      
      const response = await fetch(`${base_url}api/TechnicianExperience`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(editDataexperience),
      });
  
      if (response.status === 200) {
        const result = await response.json();
        console.log('result',result);
        setIsLoading(false);
        TechnicianExperience();
        setexperienceModalEdit(false)
      } else {
        const result = await response.json();
        setIsLoading(false);
        alert(result.message)
        throw new Error('Failed to sign up');
        
      }
    } catch (error) {
      console.error('Error:', error);
      setIsLoading(false);
    }
  };
  
  const handleDeleteExperience = async (id:any) => {
  
    try {
      const token = localStorage.getItem('tech_token');
      // Send DELETE request
      const response = await fetch(`${base_url}api/TechnicianExperience/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        // await TechnicianExperience();
        throw new Error('Network response was not ok');
        
      }
      TechnicianExperience();
      const result = await response.json();
      console.log('Result:', result);
  
      // Optionally handle success feedback or state updates
    } catch (error) {
      console.error('Error:', error);
      // Optionally handle error feedback or state updates
    }
  };
  

  const actionBodyTemplateExperience = (rowData:any) => {
    return (
      <div className="d-flex">
        <Link
          className="action-set confirm-text"
          to="#"
          onClick={() => handleEditClickExperience(rowData)}
        >
          <Icon.Edit className="react-feather-custom" />
        </Link>
        <Link className="action-set confirm-text" to="#" onClick={() => handleDeleteExperience(rowData.id)}>
          <Icon.Trash2 className="react-feather-custom" />
        </Link>
      </div>
    );
  };

  const approvedTemplate = () => {
    return <span>Approved</span>;
};

  return (
    <div className="page-wrapper">
    <div className="content container-fluid">
      {/* Page Header */}
      {isLoading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <>
          <div className="row">
        <div className="col-md-12">
          <div className="tab-list">
            <ul className="nav">
              {/* <li>
                <Link to="#" className="active" data-bs-toggle="tab" data-bs-target="#userid-verification">User ID Verification</Link>
              </li> */}
              {/* <li>
                <Link to="#" data-bs-toggle="tab" data-bs-target="#experience">Experience</Link>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
      <div className="tab-content pt-0">				
        <div className="tab-pane active" id="userid-verification">				
          <div className="row">		
          <div className="container">
          <div className="card" style={{padding:'10px'}}>
                        <form onSubmit={editMode ? handleFileUpdate : handleFileSubmit}>
                          <div className="row md-12 mx-auto">
                            <h6>ID Verification</h6>
                          <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="verificationId" className="form-label">Name Of Document</label>
                            <input type="text"  className={`form-control ${errors.name_of_verification_id ? 'is-invalid' : ''}`} id="verificationId" placeholder="Enter verification ID" value={fileData.name_of_verification_id}
                    onChange={(e) => setFileData({ ...fileData, name_of_verification_id: e.target.value })} />
                    {errors.name_of_verification_id && (
            <div className="invalid-feedback">{errors.name_of_verification_id}</div>
          )}
                           
                           </div>
                           </div>
                            <div className="col-md-6">
                            <div className="form-group">
                            <label htmlFor="file1" className="form-label">File 1 (front side)</label>
                              <input type="file" className="form-control" id="file1" value={fileData.attachment_1}
                    onChange={(e) => setFileData({ ...fileData, attachment_1: e.target.value })}/>
                            </div>
                             </div>
                            <div className="col-md-6">
                            <div className="form-group">
                            <label htmlFor="file2" className="form-label">File 2 (front side)</label>
                              <input type="file" className="form-control" id="file2" value={fileData.attachment_2}
                    onChange={(e) => setFileData({ ...fileData, attachment_2: e.target.value })}/>
                            </div> 
                            </div>
                            {/* <div className="col-md-6">
                            <div className="form-group">
                            <label htmlFor="file3" className="form-label">File 3</label>
                              <input type="file" className="form-control" id="file3" value={fileData.attachment_3}
                    onChange={(e) => setFileData({ ...fileData, attachment_3: e.target.value })}/>
                            </div>
                          </div>  */}
                          </div>
                          <div className="d-flex justify-content-center">
                          <button type="submit" className="btn btn-primary ">
                            {editMode ? 'Update' : 'Add'}
                          </button>
                          {editMode && (
                            <button className="btn btn-warning ms-2" onClick={resetfile}>Reset</button>
                          )}
                          </div>
                        </form>
                        </div>
                        <br />
                        <div className="table-responsive">
            <DataTable
              value={verificationData}
            //   paginator
            //   rows={10}
            //   rowsPerPageOptions={[5, 10, 25, 50]}
            //   paginatorTemplate="RowsPerPageDropdown CurrentPageReport PrevPageLink PageLinks NextPageLink"
            //   currentPageReportTemplate="{first} to {last} of {totalRecords}"
            //   className="custom-table datatable mb-0"
            //   id="data-table"
              style={{ minWidth: '50rem' }}
            >
              <Column field="name_of_verification_id" header="Name Of Verification Id"></Column>
              <Column header="Attachment 1" body={(rowData) => <img src={`${image_base_url}${rowData.attachment_1}`} alt="Attachment 1" style={{ maxWidth: '100px' }} />} />
              <Column header="Attachment 2" body={(rowData) => <img src={`${image_base_url}${rowData.attachment_2}`} alt="Attachment 2" style={{ maxWidth: '100px' }} />} />
              {/* <Column header="Attachment 3" body={(rowData) => <img src={`${image_base_url}${rowData.attachment_3}`} alt="Attachment 3" style={{ maxWidth: '100px' }} />} /> */}
              <Column header="Status" body={approvedTemplate} />

              {/* <Column header="Action" body={actionBodyTemplate}></Column> */}
            </DataTable>
          </div>
        </div>
          </div>
          <div
        className={`modal fade ${fileShowModalDelete ? 'show' : ''}`}
        id="delete-city"
        style={{ display: fileShowModalDelete ? 'block' : 'none' }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Delete City</h5>
              <button type="button" className="btn-close" onClick={() => setfileShowModalDelete(false)} aria-label="Close"></button>
            </div>
            <div className="modal-body">
            <p>Are you sure you want to delete &quot;{deletefilename}&quot;?</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-danger" onClick={() => TechnicianUserIdDeletefile()}>Delete</button>
              <button type="button" className="btn btn-secondary" onClick={() => setfileShowModalDelete(false)}>Cancel</button>
            </div>
          </div>
        </div>
      </div>
        </div>


        {/* <div className="tab-pane fade" id="experience">				
        <div className="row">
          <div className="col-md-12">
          <div className="card" style={{padding:'10px'}}>
                        <form onSubmit={ handleSubmitExperience}>
                          <div className="row md-12 mx-auto">
                            <h6>Experience</h6>
                          <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="verificationId" className="form-label">Name Of Verification Id</label>
                            <input type="text" className="form-control" name="companyname" value={formDataexperience.company_name} onChange={(e) => setFormDataexperience({ ...formDataexperience, company_name: e.target.value })} placeholder="" />
                           </div>
                           </div>
                            <div className="col-md-6">
                            <div className="form-group">
                            <label htmlFor="file1" className="form-label">Department</label>
                              <input type="text" className="form-control" name="Department" value={formDataexperience.department} onChange={(e) => setFormDataexperience({ ...formDataexperience, department: e.target.value })} placeholder="" />
                            </div>
                             </div>
                            <div className="col-md-6">
                            <div className="form-group">
                            <label htmlFor="file2" className="form-label">Desination</label>
                              <input type='text' className="form-control" value={formDataexperience.designation} onChange={(e) => setFormDataexperience({ ...formDataexperience, designation: e.target.value })} placeholder="" />
                            </div> 
                            </div>
                            <div className="col-md-6">
                            <div className="form-group">
                            <label htmlFor="file3" className="form-label">Experience</label>
                              <input type="text" className="form-control" value={formDataexperience.experience} onChange={(e) => setFormDataexperience({ ...formDataexperience, experience: e.target.value })} placeholder=""/>
                            </div>
                          </div> 
                          <div className="col-md-6">
                            <div className="form-group">
                            <label htmlFor="file3" className="form-label">Others</label>
                              <input type='text' className="form-control"  value={formDataexperience.others} onChange={(e) => setFormDataexperience({ ...formDataexperience, others: e.target.value })} placeholder=""/>
                            </div>
                          </div> 
                          </div>
                          <div className="d-flex justify-content-center">
                          <button type="submit" className="btn btn-primary ">
                            Add Experience
                          </button>
                          </div>
                        </form>
                        </div>

            <div className="provide-table manage-table">
              <div className="table-responsive">
                <DataTable
                  value={experienceData}
                //   paginator
                //   rows={10}
                //   rowsPerPageOptions={[5, 10, 25, 50]}
                //   paginatorTemplate="RowsPerPageDropdown CurrentPageReport PrevPageLink PageLinks NextPageLink"
                //   currentPageReportTemplate="{first} to {last} of {totalRecords}"
                  className="custom-table datatable mb-0"
                  id="data-table"
                  style={{ minWidth: '50rem' }}
                >
                  <Column field="id" header="S No" body={(rowData, index:any) => index + 1}></Column>
                  <Column field="company_name" header="Company Name"></Column>
                  <Column field="department" header="Department"></Column>
                  <Column field="designation" header="Designation"></Column>
                  <Column field="experience" header="Experience"></Column>
                  <Column field="others" header="Others"></Column>
                  <Column header="Action" body={actionBodyTemplateExperience}></Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
        </div> */}
      </div>
          
          </>)}
    </div>
    {/* <Modal show={experienceModalEdit} onHide={() => setexperienceModalEdit(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Update Experience</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form  onSubmit={() => updateTechnicianExperience}>
          <Col>
          <Form.Group controlId="formCompanyname">
                <Form.Label>Company Name</Form.Label>
                <Form.Control type="text" name="companyname" value={editDataexperience.company_name} onChange={(e) => setEditDataexperience({ ...editDataexperience, company_name: e.target.value })} placeholder="" />
              </Form.Group>
              <Form.Group controlId="formDepartment">
                <Form.Label>Department</Form.Label>
                <Form.Control type="text" name="Department" value={editDataexperience.department} onChange={(e) => setEditDataexperience({ ...editDataexperience, department: e.target.value })} placeholder="" />
              </Form.Group>
              <Form.Group controlId="formDesignation">
                <Form.Label>Designation</Form.Label>
                <Form.Control type="text" name="designation" value={editDataexperience.designation} onChange={(e) => setEditDataexperience({ ...editDataexperience, designation: e.target.value })} placeholder="" />
              </Form.Group>
              <Form.Group controlId="formExperience">
                <Form.Label>Experience(years)</Form.Label>
                <Form.Control type="text" name="experience" value={editDataexperience.experience} onChange={(e) => setEditDataexperience({ ...editDataexperience, experience: e.target.value })} placeholder="" />
              </Form.Group>
              <Form.Group controlId="formOther">
                <Form.Label>Other</Form.Label>
                <Form.Control type="text" name="other" value={editDataexperience.others} onChange={(e) => setEditDataexperience({ ...editDataexperience, others: e.target.value })} placeholder="" />
              </Form.Group>
          </Col>
          <Modal.Footer>
            <Button variant="outline-primary" type='submit'>
              Update Experience
            </Button>
            <Button variant="outline-warning" type="reset" >
              Reset
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal> */}
</div>
  )
}

export default ProviderUseridVerification