import React, { useEffect, useState } from 'react'
import * as Icon from 'react-feather'
import { Link } from 'react-router-dom';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { base_url } from '../../../../constant';
import { useLocation } from 'react-router-dom';

const routes = all_routes;

const ProviderOrderView = () => {
    const location = useLocation();
    const { id } = location.state || {};
    const [isLoading, setIsLoading] = useState(false);
    const [checkenquiry, setcheckenquiry] = useState<any>([]);
    const [countries, setCountries] = useState([]);
    const [states, setState] = useState([]);
    const [cities, setCities] = useState([]);
    const [pincode, setPincode] = useState([]);
    const [specializationMaster, setSpecializationMaster] = useState([]);
    const [subSpecializationMaster, setSubSpecializationMaster] = useState([]);
    const [order, setorder] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(()=>{
        getcheckenquiryisexist();
    },[]);

    const handleOnChange = () => {
        setorder(true);
        getcheckenquiryisexist();
        fetchCountries();
        fetchState();
        fetchCity();
        fetchPincode();
        SpecializationMaster();
        SubSpecializationMaster();
      };

    const getcheckenquiryisexist = async () => {
        setIsLoading(true);
        try {
          const token = localStorage.getItem('token');
          const response = await fetch(`${base_url}api/getcheckenquiryisexist/${id}`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });
          if (!response.ok) {
            setIsLoading(false);
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          setIsLoading(false);
          setcheckenquiry(data.result); // Assuming data is an array of countries
          setIsDisabled(true)
        } catch (error) {
            setIsLoading(false);
          console.error('Error fetching countries:', error);
        }
      };

             //get SpecializationMaster
  const SpecializationMaster = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${base_url}api/SpecializationMaster`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setSpecializationMaster(data); // Assuming data is an array of countries
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching countries:', error);
    }
  };

         //get countries
         const SubSpecializationMaster = async () => {
            setIsLoading(true);
            try {
              const token = localStorage.getItem('token');
              const response = await fetch(`${base_url}api/SubSpecializationMaster`, {
                method: 'GET',
                headers: {
                  'Authorization': `Bearer ${token}`,
                  'Content-Type': 'application/json'
                }
              });
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              const data = await response.json();
              setSubSpecializationMaster(data); // Assuming data is an array of countries
              setIsLoading(false);
            } catch (error) {
              setIsLoading(false);
              console.error('Error fetching countries:', error);
            }
          };

       //get countries
  const fetchCountries = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${base_url}api/CountryMasterr`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setCountries(data); // Assuming data is an array of countries
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching countries:', error);
    }
  };

 //get state 
  const fetchState = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${base_url}api/StateMasterr`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setState(data); // Assuming data is an array of countries
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching countries:', error);
    }
  };

//get city
  const fetchCity = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${base_url}api/CityMaster`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setCities(data); // Assuming data is an array of countries
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching countries:', error);
    }
  };

//pincode
const fetchPincode= async () => {
  setIsLoading(true);
  try {
    const token = localStorage.getItem('token');
    const response = await fetch(`${base_url}api/PincodeMaster`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    setPincode(data); // Assuming data is an array of countries
    setIsLoading(false);
  } catch (error) {
    setIsLoading(false);
    console.error('Error fetching countries:', error);
  }
};


    return (
        <>
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
            <>
        <div className="page-wrapper">
        <div className="content container-fluid">
            {/* /Page Header */}
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title">Order Detail</h5>
                    <p className="card-text">
                        <strong>Enquiry ordered Date:</strong> {checkenquiry.address_on} & <strong>Time slot:</strong> {checkenquiry.time}<br />
                        <strong>Price:</strong> {checkenquiry.wage} {checkenquiry.wage_currency} - [ {checkenquiry.wage_type}]
                    </p>
                </div>
            </div>
            <div className="d-flex">
                <button className="btn btn-success me-2">Approved and Payment Req</button>
                <button className="btn btn-danger">Reject</button>
            </div>
            <br />
            <div className="row">
                <div className="col-md-12">
                    <div className="tab-list">
                        <ul className="nav">
                            <li>
                                <Link to="#" className="active" data-bs-toggle="tab" data-bs-target="#tracker">Tracker</Link>
                            </li>
                            <li>
                                <Link to="#" data-bs-toggle="tab" data-bs-target="#invoice">Invoice</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="tab-content pt-0">				
            <div className="tab-pane active" id="today-orders">
      <p style={{ color: 'green' }}>[Status: New Orders]</p>
      <div className="row">
        {/* Content for today order */}
        <div className='col-lg-4'>
          <div className='card' style={{ padding:'10px' }}>
            <h5>Tracker</h5>
            <br />
            <div className="table-responsive">
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>Tracker</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><button className="btn btn-info me-2" onClick={handleOnChange}><Icon.ArrowRightCircle className="react-feather-custom" /></button></td>
                    <td>Order Received Details</td>
                  </tr>
                  <tr>
            <td><button className="btn btn-info me-2"><Icon.ArrowRightCircle className="react-feather-custom" /></button></td>
            <td>Addressing Issue</td>
          </tr>
          <tr>
            <td><button className="btn btn-info me-2"><Icon.ArrowRightCircle className="react-feather-custom" /></button></td>
            <td>Consideration</td>
          </tr>
          <tr>
            <td><button className="btn btn-info me-2"><Icon.ArrowRightCircle className="react-feather-custom" /></button></td>
            <td>Required Document</td>
          </tr>
          <tr>
            <td><button className="btn btn-info me-2"><Icon.ArrowRightCircle className="react-feather-custom" /></button></td>
            <td>Estimation</td>
          </tr>
          <tr>
            <td><button className="btn btn-info me-2"><Icon.ArrowRightCircle className="react-feather-custom" /></button></td>
            <td>Quoting and Offers Finalizing</td>
          </tr>
          <tr>
            <td><button className="btn btn-info me-2"><Icon.ArrowRightCircle className="react-feather-custom" /></button></td>
            <td>Payment Collection</td>
          </tr>
          <tr>
            <td><button className="btn btn-info me-2"><Icon.ArrowRightCircle className="react-feather-custom" /></button></td>
            <td>Schedule the Job Event</td>
          </tr>
          <tr>
            <td><button className="btn btn-info me-2"><Icon.ArrowRightCircle className="react-feather-custom" /></button></td>
            <td>Job Status Update and Do Issue</td>
          </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {/* Show order details card if order state is true */}
        {order && (
          <div className="col-lg-8">
            <div className="card">
              <div className="card-body" style={{ padding:'10px' }}>
                {/* Content for today orders */}
                <h6>Place The Orders</h6>
                <br />
                <p style={{textAlign:'center'}}>Service Walkin</p>
                <div className="row">
                    <div className='col-lg-6'>
                        <div className='card' style={{ padding:'10px' }}>
                        <p>Issue Details</p>
                        <p>Order Id: 1</p>
                        <label>Issue Title</label>
                        <input
                            type="userid"
                            id="issuetitle"
                            className="form-control"
                            placeholder=""
                            value={checkenquiry ? checkenquiry.issue_title : ''}
                            disabled={isDisabled}
                            />
                            <label>Specialization</label>
                        <input
                            type="userid"
                            id="specialization"
                            className="form-control"
                            placeholder=""
                            value={checkenquiry ? checkenquiry.specializationinfo.specialization : ''}
                            disabled={isDisabled}
                            />
                            <label>Sub Specialization</label>
                        <input
                            type="userid"
                            id="subspecialization"
                            className="form-control"
                            placeholder=""
                            value={checkenquiry ? checkenquiry.subspecializationinfo.sub_specialization : ''}
                            disabled={isDisabled}
                            />
                            <label>Description</label>
                        <input
                            type="userid"
                            id="description"
                            className="form-control"
                            placeholder=""
                            value={checkenquiry ? checkenquiry.description : ''}
                            disabled={isDisabled}
                            />
                            <label>Address On</label>
                        <input
                            type="userid"
                            id="addresson"
                            className="form-control"
                            placeholder=""
                            value={checkenquiry ? checkenquiry.address_on : ''}
                            disabled={isDisabled}
                            />
                            <label>Time Slot</label>
                        <input
                            type="userid"
                            id="timeslot"
                            className="form-control"
                            placeholder=""
                            value={checkenquiry ? checkenquiry.time : ''}
                            disabled={isDisabled}
                            />
                            <label>Contact Number</label>
                        <input
                            type="userid"
                            id="contactnumber"
                            className="form-control"
                            placeholder=""
                            value={checkenquiry ? checkenquiry.contact_number : ''}
                            disabled={isDisabled}
                            />
                            <label>Country</label>
                        <input
                            type="userid"
                            id="country"
                            className="form-control"
                            placeholder=""
                            value={checkenquiry ? checkenquiry.countryinfo?.country : ''}
                            disabled={isDisabled}
                            />
                            <label>State</label>
                        <input
                            type="userid"
                            id="state"
                            className="form-control"
                            placeholder=""
                            value={checkenquiry ? checkenquiry.stateinfo?.state : ''}
                            disabled={isDisabled}
                            />
                            <label>City</label>
                        <input
                            type="userid"
                            id="city"
                            className="form-control"
                            placeholder=""
                            value={checkenquiry ? checkenquiry.cityinfo?.city : ''}
                            disabled={isDisabled}
                            />
                            <label>Pincode</label>
                        <input
                            type="userid"
                            id="pincode"
                            className="form-control"
                            placeholder=""
                            value={checkenquiry ? checkenquiry.pincodeinfo?.pincode : ''}
                            disabled={isDisabled}
                            />
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className='card' style={{padding:'10px'}}></div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
          <div className="tab-pane fade" id="invoice">				
          <div className="row">
          {/* content tomorrow order	 */}
          <div className='card'>
                <br />
                <h6>View Orders</h6>
                
            </div>
          </div>
          </div>
          </div>
        </div>
    </div>
</>
)}
</>
    )
}

export default ProviderOrderView