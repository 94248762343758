import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import StickyBox from 'react-sticky-box';
import * as Icon from 'react-feather';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { image_base_url } from '../../../../constant';
import { Modal, Button } from 'react-bootstrap';

const CustomerSideBar = () => {
  const routes = all_routes;
  
  const user_name = localStorage.getItem("user_name");
  const user_email = localStorage.getItem("user_email");
  const user_userprofileimage = localStorage.getItem("user_userprofileimage");
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleConfirmLogout = () => {
    handleLogout();
    handleCloseModal();
  };

  const handleLogout = () => {
    // Remove the token from localStorage
    navigate(routes.homeOne);
    localStorage.removeItem('token');
    localStorage.removeItem('user_id'); 
    localStorage.removeItem('user_name'); 
    localStorage.removeItem('user_email'); 
    localStorage.removeItem('user_userprofileimage');  
  };

  const activeRouterPath = (link: string) => {
    return link === location.pathname;
  };

  return (
    <>
      <div className="col-lg-3 theiaStickySidebar">
        <StickyBox>
          {/* Customer Menu */}
          <div className="settings-widget">
            <div className="settings-header">
              <div className="settings-img">
                {user_userprofileimage ? (
                  <img
                    src={`${image_base_url}${user_userprofileimage}`}
                    className="rounded-circle"
                    alt="User"
                  />
                ) : (
                  <ImageWithBasePath
                    src="assets/img/profiles/avatar-02.jpg"
                    className="rounded-circle"
                    alt="User Image"
                  />
                )}
              </div>
              <h6>{user_name}</h6>
              <p>{user_email}</p>
            </div>
            <div className="settings-menu">
              <ul>
                <li className={`${activeRouterPath(routes.customerDashboard) ? 'active' : ''}`}>
                  <Link to={routes.customerDashboard}>
                    <Icon.Grid className="react-feather-icon me-3" />
                    <span>Dashboard</span>
                  </Link>
                </li>
                <li className={`${activeRouterPath(routes.customerServices) ? 'active' : ''}`}>
                  <Link to={routes.customerServices}>
                    <Icon.Activity className="react-feather-icon me-3" />
                    <span>Services</span>
                  </Link>
                </li>
                <li className={`${activeRouterPath(routes.customerBooking) ? 'active' : ''}`}>
                  <Link to={routes.customerBooking}>
                    <Icon.Smartphone className="react-feather-icon me-3" />
                    <span>Bookings</span>
                  </Link>
                </li>
                <li className={`${activeRouterPath(routes.customerFavourite) ? 'active' : ''}`}>
                  <Link to={routes.customerFavourite}>
                    <Icon.Heart className="react-feather-icon me-3" />
                    <span>Favorites</span>
                  </Link>
                </li>
                <li className={`${activeRouterPath(routes.customerProfile) ? 'active' : ''}`}>
                  <Link to={routes.customerProfile}>
                    <Icon.Settings className="react-feather-icon me-3" />
                    <span>Settings</span>
                  </Link>
                </li>
                <li>
                  <a
                    href="#"
                    onClick={handleShowModal}
                    style={{ cursor: 'pointer' }}
                  >
                    <Icon.LogOut className="react-feather-icon me-3" />
                    <span>LogOut</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </StickyBox>
        {/* Confirmation Modal */}
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Logout</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to logout?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleConfirmLogout}>
              Logout
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default CustomerSideBar;
