import React, { useEffect, useState } from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';
import * as Icon from 'react-feather';
import { Slider } from 'primereact/slider';
import { Dropdown } from 'primereact/dropdown';
import { Link, useNavigate } from 'react-router-dom';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { base_url, image_base_url } from '../../../../constant';
import { useLocation } from 'react-router-dom';
import { Modal, Button, Card, Row, Col } from 'react-bootstrap';


const Search = () => {
  const routes = all_routes;
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const cityFilter = query.get('city') || ''; // Retrieve the city query parameter
  const specializationFilter = query.get('specialization') || '';
  
  // const [value, setValue] = useState([20, 80]);
  const [value, setValue] = useState<[number, number]>([20, 80]);
  const [activeTechnicians, setActiveTechnicians] = useState([]);
  const [selectedValue1, setSelectedValue1] = useState(null);
  const [selectedValue2, setSelectedValue2] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [users, setUsers] = useState([]);
  const [specializationlist, setSpecializationlist] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [citylocation, setCitylocation] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [selectedService, setSelectedService] = useState<any>(null);
  const [filteredCities, setFilteredCities] = useState([]);

  const navigate = useNavigate();

  const toggleHeight = () => {
    setIsExpanded(!isExpanded);
  };
  const filterCheckboxStyle = {
    height: isExpanded ? 'auto' : '180px'
  };
  const value1 = [
    { name: 'All Sub Category' },
    { name: 'Computer' },
    { name: 'Construction' },
  ];
  const value2 = [{ name: 'Price Low to High' }, { name: 'Price High to Low' }];

  const [showModal, setShowModal] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [city, setCity] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Adjust this number as needed

  // Calculate the number of pages
  const totalPages = Math.ceil(activeTechnicians.length / itemsPerPage);

  // Get current items based on pagination
  const currentItems = activeTechnicians.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageClick = (pageNumber:any) => {
    setCurrentPage(pageNumber);
  };


  const [currentPagereview, setCurrentPagereview] = useState(1);
  const reviewsPerPage = 5;

  // Calculate the index of the last review and the first review on the current page
  const indexOfLastReview = currentPagereview * reviewsPerPage;
  const indexOfFirstReview = indexOfLastReview - reviewsPerPage;
  const currentReviews = reviews.slice(indexOfFirstReview, indexOfLastReview);

  // Calculate total pages
  const totalPagesreview = Math.ceil(reviews.length / reviewsPerPage);

  // Handle page change
  const handlePageChange = (pageNumber:any) => {
    setCurrentPagereview(pageNumber);
  };

  // const handleIconClick = () => {
  //   // Fetch reviews data here if needed
  //   // For now, we'll use dummy data
  //   const fetchedReviews = [
  //     { user: 'John Doe', comment: 'Great service!', date: '2024-08-13', rating: 4.5 },
  //     { user: 'Jane Smith', comment: 'Highly recommend!', date: '2024-08-10', rating: 5 },
  //     // Add more reviews as needed
  //   ];
  //   setReviews(fetchedReviews);
  //   setShowModal(true);
  // };
  const handleIconClick = (technician:any) => {
    // setSelectedTechnician(technician);
    setReviews(technician.rating_reviews || []);
      setShowModal(true);
    };
  

  const handleCloseModal = () => setShowModal(false);

  const formatDate = (dateString:any) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
    getsubspecializationByid();
    SpecializationMasterList();
    fetchActiveTechnicians();
    if (specializationFilter) {
      setSelectedSpecializations(specializationFilter.split(','));
    }
    // Fetch active technicians with the current filters
    fetchActiveTechnician(cityFilter, specializationFilter);
  }, [citylocation]);
  const [selectedItems, setSelectedItems] = useState(Array(10).fill(false));

  const handleItemClick = (index: number) => {
    setSelectedItems((prevSelectedItems) => {
      const updatedSelectedItems = [...prevSelectedItems];
      updatedSelectedItems[index] = !updatedSelectedItems[index];
      return updatedSelectedItems;
    });
  };

  const [selectedSpecializations, setSelectedSpecializations] = useState<any[]>([]);

  const handleCheckboxChange = (specialization: any) => {
    setSelectedSpecializations(prevState => {
      const updatedSpecializations = prevState.includes(specialization)
        ? prevState.filter(item => item !== specialization)
        : [...prevState, specialization];
  
      // Call API with updated specialization filter
      fetchActiveTechnician(cityFilter, updatedSpecializations.join(','));
      return updatedSpecializations;
    });
  };
  

  const fetchActiveTechnicians = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const user_id = localStorage.getItem('user_id')||0;
      const response = await fetch(`${base_url}api/getbystatus/1/${user_id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      // const filteredTechnicians = data.reverse().filter((service:any) => {
      //   if (cityFilter) {
      //     // Check if the city filter is present
      //     return service.cityinfo?.city.toLowerCase().includes(cityFilter.toLowerCase());
      //   }
      //   // Show all technicians if no city filter is provided
      //   return true;
      // });
      
      const filteredTechnicians = data.reverse().filter((service:any) => {
        const matchesCityLocation = citylocation
          ? service.cityinfo?.city.toLowerCase().includes(citylocation.toLowerCase())
          : true;
        const matchesCity = cityFilter
          ? service.cityinfo?.city.toLowerCase().includes(cityFilter.toLowerCase())
          : true;
        const matchesSpecialization = specializationFilter
          ? service.skillsetinfo?.specializationinfo?.specialization.toLowerCase().includes(specializationFilter.toLowerCase())
          : true;

        // Include technician if either filter matches
        return matchesCityLocation && matchesCity && matchesSpecialization;
      });
      setActiveTechnicians(filteredTechnicians); // Reverse the array to display in reverse order
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching active technicians:', error);
    }
  };

  const fetchActiveTechnician = async (cityFilter = '', specializationFilter = '') => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const user_id = localStorage.getItem('user_id') || 0;
  
      // Construct query string
      const query = new URLSearchParams({
        city: cityFilter,
        specialization: specializationFilter
      }).toString();
  
      const response = await fetch(`${base_url}api/getbystatus/1/${user_id}?${query}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      setActiveTechnicians(data.reverse()); // Reverse the array to display in reverse order
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching active technicians:', error);
    }
  };
  

  const SpecializationMasterList = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${base_url}api/SpecializationMasterList`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      // Reverse the array to display in reverse order
      setSpecializationlist(data);
    } catch (error) {
      console.error('Error fetching technicians:', error);
    }
  };

  const getsubspecializationByid = async () => {
    const specializationId = localStorage.getItem('specializationId');
    try {
      const response = await fetch(`${base_url}api/getsubspecializationByid/${specializationId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      // Reverse the array to display in reverse order
      setUsers(data);
    } catch (error) {
      console.error('Error fetching technicians:', error);
    }
  };

  const getCity = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${base_url}api/CityMaster`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setIsLoading(false);
      setCity(data);
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching technicians:', error);
    }
};

  const handleButtonClick = (service: any) => {
    if (localStorage.getItem('token')) {
        console.log('Service Object:', service); // Debugging line
        
        // Log each value to check if any are undefined
        console.log('Specialization:', service.skillsetinfo?.specialization);
        console.log('Wages:', service.skillsetinfo?.wages);
        console.log('Currency:', service.skillsetinfo?.currencyinfo?.currency);
        console.log('User ID:', service.userinfo?.id);
        console.log('Specialization Info:', service.skillsetinfo?.specializationinfo?.specialization);
        console.log('Tech Image:', service.skillsetinfo?.specializationinfo?.image);
        console.log('City ID:', service.cityinfo?.id);
        console.log('City Name:', service.cityinfo?.city);
        console.log('State ID:', service.stateinfo?.id);
        console.log('State Name:', service.stateinfo?.state);
        console.log('Country ID:', service.countryinfo?.id);
        console.log('Country Name:', service.countryinfo?.country);

        const query = `?specializationId=${service.skillsetinfo?.specialization}&wages=${service.skillsetinfo?.wages}&userId=${service.userinfo?.id}
        &spec=${service.skillsetinfo?.specializationinfo?.specialization}&techimg=${service.skillsetinfo?.specializationinfo?.image}
        &cityId=${service.cityinfo?.id}&cityName=${service.cityinfo?.city}&stateId=${service.stateinfo?.id}&stateName=${service.stateinfo?.state}
        &countryId=${service.countryinfo?.id}&countryName=${service.countryinfo?.country}
        &pincodeId=${service.pincodeinfo?.id}&pincode=${service.pincodeinfo?.pincode}&currency=${service.skillsetinfo?.currencyinfo?.currency}`;
        navigate(`${routes.booking1}${query}`);
    } else {
        setShowPopup(true);
        setSelectedService(service);
    }
};

const handleInputChange = (e:any) => {
  const value = e.target.value;
  setCitylocation(value);
  if (value) {
    setFilteredCities(city.filter((cityItem:any) =>
      cityItem.toLowerCase().includes(value.toLowerCase())
    ));
  } else {
    setFilteredCities([]);
  }
};

const handleCitySelect = (selectedCity:any) => {
  setCitylocation(selectedCity);
  setFilteredCities([]);
};

  const handleClosePopup = () => {
    setShowPopup(false);
    setSelectedService(null);
  };

  return (
    <>
    {isLoading && (
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
    )}
    {!isLoading && (
      <>
    <div>
      <div className="bg-img">
        <ImageWithBasePath src="assets/img/bg/work-bg-03.png" alt="img" className="bgimg1" />
        <ImageWithBasePath src="assets/img/bg/work-bg-03.png" alt="img" className="bgimg2" />
        <ImageWithBasePath
          src="assets/img/bg/feature-bg-03.png"
          alt="img"
          className="bgimg3"
        />
      </div>
      {/* Breadcrumb */}
      <div className="breadcrumb-bar">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12">
              <h2 className="breadcrumb-title">Find a Professional</h2>
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={routes.homeOne}>Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Find a Professional
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* /Breadcrumb */}
      <div className="content">
        <div className="container">
          <div className="row">
            {/* Filter */}
            <div className="col-lg-3 col-sm-12 theiaStickySidebar">
              <div className="stickybar">
                <div className="filter-div">
                  <div className="filter-head">
                    <h5>Filter by</h5>
                    <Link to="#" className="reset-link">
                      Reset Filters
                    </Link>
                  </div>
                  <div className="filter-content">
                    <h2>Keyword</h2>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="What are you looking for?"
                    />
                  </div>
                  <div className="filter-content">
                    <h2>
                      Categories{' '}
                      <span>
                        <Icon.ChevronDown className="react-feather-custom" />
                      </span>
                    </h2>
                    <div className="filter-checkbox" id="fill-more" style={filterCheckboxStyle}>
  {specializationlist.map((category: any) => (
    <ul key={category.specialization}>
      <li>
        <label className="checkboxs">
          <input
            type="checkbox"
            checked={selectedSpecializations.includes(category.specialization)}
            onChange={() => handleCheckboxChange(category.id)}
          />
          <span>
            <i />
          </span>
          <b className="check-content">{category.specialization}</b>
        </label>
      </li>
    </ul>
  ))}
</div>

                  </div>
                  {/* <div className="filter-content">
                    <h2>Sub Category</h2>
                    <Dropdown
                      value={selectedValue1}
                      onChange={(e) => setSelectedValue1(e.value)}
                      options={value1}
                      optionLabel="name"
                      placeholder="All Sub Category"
                      className="w-100 select"
                    />
                  </div> */}
                  <div className="filter-content">
                    <h2>Location</h2>
                    <div className="group-img">
        <input
          type="text"
          className="form-control"
          placeholder="Select Location"
          value={citylocation}
          onChange={handleInputChange}
        />
        <Icon.MapPin className="react-feather-custom" />
      </div>
      {filteredCities.length > 0 && (
        <ul className="city-dropdown">
          {filteredCities.map((cityItem, index) => (
            <li key={index} onClick={() => handleCitySelect(cityItem)}>
              {cityItem}
            </li>
          ))}
        </ul>
      )}
                  </div>
                  <div className="filter-content">
                    <h2 className="mb-4">Price Range</h2>
                    <div className="filter-range">
                      <Slider
                        value={value}
                        onChange={(e: any) => setValue(e.value)}
                        className="w-14rem"
                        range
                      />
                    </div>
                    <div className="filter-range-amount">
                      <h5>
                        Price: <span>$5 - $210</span>
                      </h5>
                    </div>
                  </div>
                  <div className="filter-content">
                    <h2>
                      By Rating{' '}
                      <span>
                        <Icon.ChevronDown className="react-feather-custom" />
                      </span>
                    </h2>
                    <ul className="rating-set">
                      <li>
                        <label className="checkboxs d-inline-flex">
                          <input type="checkbox" />
                          <span>
                            <i />
                          </span>
                        </label>
                        <Link className="rating" to="#">
                          <i className="fa-regular fa-star filled me-1" />
                          <i className="fa-regular fa-star filled me-1" />
                          <i className="fa-regular fa-star filled me-1" />
                          <i className="fa-regular fa-star filled me-1" />
                          <i className="fa-regular fa-star" />
                          <span className="d-inline-block average-rating float-end">
                            (35)
                          </span>
                        </Link>
                      </li>
                      <li>
                        <label className="checkboxs d-inline-flex">
                          <input type="checkbox" />
                          <span>
                            <i />
                          </span>
                        </label>
                        <Link className="rating" to="#">
                          <i className="fa-regular fa-star filled me-1" />
                          <i className="fa-regular fa-star filled me-1" />
                          <i className="fa-regular fa-star filled me-1" />
                          <i className="fa-regular fa-star filled me-1" />
                          <i className="fa-regular fa-star" />
                          <span className="d-inline-block average-rating float-end">
                            (40)
                          </span>
                        </Link>
                      </li>
                      <li>
                        <label className="checkboxs d-inline-flex">
                          <input type="checkbox" />
                          <span>
                            <i />
                          </span>
                        </label>
                        <Link className="rating" to="#">
                          <i className="fa-regular fa-star filled me-1" />
                          <i className="fa-regular fa-star filled me-1" />
                          <i className="fa-regular fa-star filled me-1" />
                          <i className="fa-regular fa-star me-1" />
                          <i className="fa-regular fa-star" />
                          <span className="d-inline-block average-rating float-end">
                            (40)
                          </span>
                        </Link>
                      </li>
                      <li>
                        <label className="checkboxs d-inline-flex">
                          <input type="checkbox" />
                          <span>
                            <i />
                          </span>
                        </label>
                        <Link className="rating" to="#">
                          <i className="fa-regular fa-star filled me-1" />
                          <i className="fa-regular fa-star filled me-1" />
                          <i className="fa-regular fa-star me-1" />
                          <i className="fa-regular fa-star me-1" />
                          <i className="fa-regular fa-star" />
                          <span className="d-inline-block average-rating float-end">
                            (20)
                          </span>
                        </Link>
                      </li>
                      <li>
                        <label className="checkboxs d-inline-flex">
                          <input type="checkbox" />
                          <span>
                            <i />
                          </span>
                        </label>
                        <Link className="rating" to="#">
                          <i className="fa-regular fa-star filled me-1" />
                          <i className="fa-regular fa-star me-1" />
                          <i className="fa-regular fa-star me-1" />
                          <i className="fa-regular fa-star me-1" />
                          <i className="fa-regular fa-star" />
                          <span className="d-inline-block average-rating float-end">
                            (5)
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <button className="btn btn-primary">Search</button>
                </div>
              </div>
            </div>
            {/* /Filter */}
            {/* Service */}
            <div className="col-lg-9 col-sm-12">
              <div className="row sorting-div">
                <div className="col-lg-4 col-sm-12 ">
                  <div className="count-search">
                    <h6>Found {currentItems.length} Services On Search</h6>
                  </div>
                </div>
                <div className="col-lg-8 col-sm-12 d-flex justify-content-end ">
                  {/* <div className="sortbyset">
                    <div className="sorting-select">
                      <Dropdown
                        value={selectedValue2}
                        onChange={(e) => setSelectedValue2(e.value)}
                        options={value2}
                        optionLabel="name"
                        placeholder="Price Low to High"
                        className="select"
                      />
                    </div>
                  </div> */}
                  {/* <div className="grid-listview">
                    <ul>
                      <li>
                        <Link to={routes.search} className="active">
                          <Icon.Grid className="react-feather-custom" />
                        </Link>
                      </li>
                      <li>
                        <Link to={routes.searchList}>
                          <Icon.List className="react-feather-custom" />
                        </Link>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </div>
              <div className="row">
                {/* Service List */}
                {currentItems.map((service:any) => (
                <div key={service.id} className="col-xl-4 col-md-6">
                  <div className="service-widget servicecontent">
                    <div className="service-img">
                      <Link 
                      // to={routes.serviceDetails1}
                      to={routes.booking1}
                      >
                         <img
                          className="img-fluid serv-img"
                          alt="Service Image"
                        //   src="assets/img/provider/provider-11.jpg"
                        //   src={`${image_base_url}${service.userinfo?.userprofileimage}`}
                          src={service.userinfo?.userprofileimage ? `${image_base_url}${service.userinfo?.userprofileimage}` : '/assets/img/provider/provider-11.jpg'}
                          style={{height: "230px",}}
                        />
                      </Link>
                      <div className="item-info">
                        <Link to="/categories">
                          <span className="item-cat">{service.skillsetinfo?.specializationinfo?.specialization}</span>
                        </Link>
                      </div>
                      <div
                        className="fav-item"
                        key={1}
                        onClick={() => handleItemClick(1)}
                      >
                        {/* <Link to={routes.categories}>
                          <span className="item-cat">Car Wash</span>
                        </Link> */}
                        {/* <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[1] ? 'selected' : ''
                          }`}
                        >
                          <Icon.Heart size={15} />
                        </Link> */}
                      </div>
                    </div>
                    <div className="service-content">
                      <h3 className="title">
                        <Link 
                        to="{routes.booking1}"
                        >{service.userinfo?.name}</Link>
                      </h3>
                      <p>
                        <Icon.MapPin className="react-feather-custom" onClick={() => handleIconClick(service)} />
                        <span onClick={() => handleIconClick(service)}>
                          {service.cityinfo?.city},{service.stateinfo?.state}
                        </span>
                        <span className="rate" onClick={() => handleIconClick(service)}>
                          <i className="fas fa-star filled" />{service.average_rating ? service.average_rating.toFixed(1) : 0}
                        </span>
                      </p>
                      <div className="serv-info">
                        <p style={{fontWeight: "bold", color: "black"}}>
                          {service.skillsetinfo?.currencyinfo?.currency || '-'} 
                          {service.skillsetinfo?.wages || '-'}/{service.skillsetinfo?.wages_type || '-'}
                        </p>
                        {/* <Link  to={
                          localStorage.getItem('token')
                          ? `${routes.booking1}?specializationId=${service.skillsetinfo?.specialization}&wages=${service.skillsetinfo?.wages}&userId=${service.userinfo?.id}`
                          : routes.loginchoose
                          } className="btn btn-book">
                          {localStorage.getItem('token') ? 'Book Now' : 'Login to Book Now'}
                        </Link> */}
                        <Button  // Keep the `to` attribute empty since navigation is handled in onClick
                          key={service.userinfo.id} 
                          onClick={() => handleButtonClick(service)} 
                          className="btn btn-book">{'Book Now'}
                        </Button>

                      </div>
                    </div>
                  </div>
                </div>
                ))}
                {/* Guest user popup */}
                <Modal show={showPopup} onHide={handleClosePopup} size="lg">
                  <Modal.Header closeButton>
                    <Modal.Title>Login</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="choose-signup text-center">
                          <h6>User</h6>
                          <div className="choose-img">
                            <ImageWithBasePath src="assets/img/user.png" alt="image" />
                          </div>
                          <Link
                            to={routes.login}
                            className="btn btn-secondary w-100"
                            onClick={() => localStorage.setItem('usertype', 'users')}>
                            Continue to Login
                            <Icon.ArrowRightCircle className="standard-feather ms-1" />
                          </Link>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="choose-signup text-center">
                          <h6>Guest</h6>
                          <div className="choose-img">
                            <ImageWithBasePath src="assets/img/services/service-001.jpg" alt="image" />
                          </div>
                          <button
    className="btn btn-secondary w-100"
    onClick={() => {
        handleClosePopup(); // Close the modal
        if (selectedService) {
            // Build the query string with the selected technician's details
            const query = `?specializationId=${selectedService.skillsetinfo?.specialization}&wages=${selectedService.skillsetinfo?.wages}&userId=${selectedService.userinfo?.id}
            &spec=${selectedService.skillsetinfo?.specializationinfo?.specialization}&techimg=${selectedService.skillsetinfo?.specializationinfo?.image}
            &cityId=${selectedService.cityinfo?.id}&cityName=${selectedService.cityinfo?.city}&stateId=${selectedService.stateinfo?.id}&stateName=${selectedService.stateinfo?.state}
            &countryId=${selectedService.countryinfo?.id}&countryName=${selectedService.countryinfo?.country}&pincodeId=${selectedService.pincodeinfo?.id}&pincode=${selectedService.pincodeinfo?.pincode}&currency=${selectedService.skillsetinfo?.currencyinfo?.currency}`;
            navigate(`${routes.booking1}${query}`);
            localStorage.setItem('usertype', 'Guest');
        }
    }}
>
    Continue as Guest
    <Icon.ArrowRightCircle className="standard-feather ms-1" />
</button>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClosePopup}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
                {/* Review popup */}
                <Modal show={showModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Reviews</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {currentReviews.length > 0 ? (
          currentReviews.map((review:any, index) => (
            <Card key={index} className="mb-2">
                 <Card.Body>
                  <Row>
                    <Col>
                      <Card.Title style={{ color: 'black' }}>{review.user?.name}</Card.Title>
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <Card.Subtitle className="text-muted">
                      {formatDate(review.created_at)}
                      </Card.Subtitle>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Card.Text>{review.review?review.review:'-'}</Card.Text>
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <Card.Text><span className="rate" onClick={handleIconClick}>
                          <i className="fas fa-star filled" style={{ color: 'gold' }} />  {review.rating}/5
                        </span></Card.Text>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
          ))
        ) : (
          <p>No reviews available.</p>
        )}
      </Modal.Body>
      <Modal.Footer>
  <div className="col-sm-12">
    <div className="blog-pagination rev-page">
      <nav>
        <ul className="pagination justify-content-end">
          <li className={`page-item ${currentPagereview === 1 ? 'disabled' : ''}`}>
            <Link className="page-link page-prev" to="#" onClick={handlePrevPage}>
              <i className="fa-solid fa-arrow-left me-1" /> PREV
            </Link>
          </li>
          {Array.from({ length: totalPagesreview }, (_, index) => (
            <li
              key={index + 1}
              className={`page-item ${currentPagereview === index + 1 ? 'active' : ''}`}
            >
              <Link
                className="page-link"
                to="#"
                onClick={() => handlePageClick(index + 1)}
              >
                {index + 1}
              </Link>
            </li>
          ))}
          <li className={`page-item ${currentPagereview === totalPagesreview ? 'disabled' : ''}`}>
            <Link className="page-link page-next" to="#" onClick={handleNextPage}>
              NEXT <i className="fa-solid fa-arrow-right ms-1" />
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</Modal.Footer>

    </Modal>
              </div>
              {/* Pagination */}
              {/* <div className="row">
                <div className="col-sm-12">
                  <div className="blog-pagination rev-page">
                    <nav>
                      <ul className="pagination justify-content-center">
                        <li className="page-item disabled">
                          <Link
                            className="page-link page-prev"
                            to="#"
                            tabIndex={-1}
                          >
                            <i className="fa-solid fa-arrow-left me-1" /> PREV
                          </Link>
                        </li>
                        <li className="page-item active">
                          <Link className="page-link" to="#">
                            1
                          </Link>
                        </li>
                        <li className="page-item">
                          <Link className="page-link" to="#">
                            2
                          </Link>
                        </li>
                        <li className="page-item">
                          <Link className="page-link" to="#">
                            3
                          </Link>
                        </li>
                        <li className="page-item">
                          <Link className="page-link page-next" to="#">
                            NEXT <i className="fa-solid fa-arrow-right ms-1" />
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div> */}
<div className="col-sm-12 d-flex justify-content-end">
        <div className="blog-pagination rev-page">
          <nav>
            <ul className="pagination justify-content-center">
              <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                <Link className="page-link page-prev" to="#" onClick={handlePrevPage}>
                  <i className="fa-solid fa-arrow-left me-1" /> PREV
                </Link>
              </li>
              {Array.from({ length: totalPages }, (_, index) => (
                <li
                  key={index + 1}
                  className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}
                >
                  <Link
                    className="page-link"
                    to="#"
                    onClick={() => handlePageClick(index + 1)}
                  >
                    {index + 1}
                  </Link>
                </li>
              ))}
              <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                <Link className="page-link page-next" to="#" onClick={handleNextPage}>
                  NEXT <i className="fa-solid fa-arrow-right ms-1" />
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
              {/* /Pagination */}
            </div>
            {/* /Service */}
          </div>
        </div>
      </div>
    </div>
    </>
  )}
  </>
  );
};

export default Search;
