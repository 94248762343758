import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ApprovedSpec from './approvedSpec';
import RequestedSpec from './requestedSpec';
import RejectedSpec from './rejectedSpec';

const TechnicianSpec = () => {
  const [activeTab, setActiveTab] = useState('requested');
  const [isLoading, setIsLoading] = useState(false);

  // Function to render the appropriate list component based on activeTab state
  const renderList = () => {
    switch (activeTab) {
      case 'requested':
        return <RequestedSpec />;
      case 'approved':
        return <ApprovedSpec />;
      case 'rejected':
        return <RejectedSpec />;
      default:
        return null;
    }
  };

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        {isLoading && (
          <div className="d-flex justify-content-center mt-4">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
        {/* Page Header */}
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <h5>View Specialization</h5>
                <div className="col-md-12">
                  <div className="tab-list">
                    <ul className="nav nav-tabs">
                      <li className="nav-item">
                        <Link
                          to="#"
                          className={`nav-link ${activeTab === 'requested' ? 'active' : ''}`}
                          onClick={() => setActiveTab('requested')}
                        >
                          Requested Specialization
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="#"
                          className={`nav-link ${activeTab === 'approved' ? 'active' : ''}`}
                          onClick={() => setActiveTab('approved')}
                        >
                          Approved Specialization
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="#"
                          className={`nav-link ${activeTab === 'rejected' ? 'active' : ''}`}
                          onClick={() => setActiveTab('rejected')}
                        >
                          Rejected Specialization
                        </Link>
                      </li>
                    </ul>
                    {/* Render the selected list component */}
                    {renderList()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechnicianSpec;
