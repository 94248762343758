import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { base_url } from '../../../constant';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import * as Icon from 'react-feather';
import { Modal, Button } from 'react-bootstrap';

const ApprovedSpec = () => {
  const [activeTechnicians, setActiveTechnicians] = useState([]);
  const [inactiveTechnicians, setInactiveTechnicians] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('active');
  const [showModalactive, setShowModalactive] = useState(false);
  const [showModalinactive, setShowModalinactive] = useState(false);
  const [selectedRowDataactive, setSelectedRowDataactive] = useState<any>(null);
  const [selectedRowDatainactive, setSelectedRowDatainactive] = useState<any>(null);


  useEffect(() => {
    fetchActiveTechnicians();
    fetchInactiveTechnicians();
    getskillsetinactive();
  }, []);


  const handleViewClickactive = (rowdata:any) => {
    setSelectedRowDataactive(rowdata);
    setShowModalactive(true);
  };

  const handleCloseModalactive = () => {
    setShowModalactive(false);
    setSelectedRowDataactive(null);
  };

  const handleViewClickinactive = (rowdata:any) => {
    setSelectedRowDatainactive(rowdata);
    setShowModalinactive(true);
  };

  const handleCloseModalinactive = () => {
    setShowModalinactive(false);
    setSelectedRowDatainactive(null);
  };

  const eyeButtonactive = (rowdata:any) => (
    <div className="table-actions d-flex">
        <button className="btn btn-info me-2" onClick={() => handleViewClickactive(rowdata)}><Icon.Eye className="react-feather-custom" /></button>
    </div>
  );
  const eyeButtoninactive = (rowdata:any) => (
    <div className="table-actions d-flex">
        <button className="btn btn-info me-2" onClick={() => handleViewClickinactive(rowdata)}><Icon.Eye className="react-feather-custom" /></button>
    </div>
  );

  const fetchActiveTechnicians = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${base_url}api/getbyskillsetstatus/1`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setActiveTechnicians(data.reverse()); // Reverse the array to display in reverse order
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching active technicians:', error);
    }
  };

  const fetchInactiveTechnicians = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${base_url}api/getbyskillsetstatus/2`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      // setInactiveTechnicians(data.reverse()); // Reverse the array to display in reverse order
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching inactive technicians:', error);
    }
  };

  const getskillsetinactive = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${base_url}api/getskillsetinactive`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setInactiveTechnicians(data.reverse()); // Reverse the array to display in reverse order
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching inactive technicians:', error);
    }
  };

  const activeButton = (rowdata:any) => (
    <div className="table-actions d-flex">
      <button className="btn btn-danger me-2" onClick={() => profilapprovedinactiveupdate(rowdata)}>To InActive</button>
    </div>
  );

  const inactiveButton = (rowdata:any) => (
    <div className="table-actions d-flex">
        <button className="btn btn-success me-2" onClick={() => profileapprovedactiveupdate(rowdata)}>To Active</button>
    </div>
  );

  const profileImageTemplate = (users:any) => {
    const imageSrc = users.userinfo?.userprofileimage;
    return <img src={`${base_url}${imageSrc}`} alt="Profile" style={{ width: '50px', height: 'auto' }} />;
  };

  const profilapprovedinactiveupdate = async (id:any) => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${base_url}api/profilapprovedinactiveupdate`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify( {id} ) // Example payload
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log('Technician approved:', data);
      setIsLoading(false);
      fetchInactiveTechnicians(); // Refresh the list
    } catch (error) {
      setIsLoading(false);
      console.error('Error approving technician:', error);
    }
  };


  const profileapprovedactiveupdate = async (id:any) => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${base_url}api/skillsetrejectedinactiveupdate`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify( {id} ) // Example payload
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log('Technician approved:', data);
      setIsLoading(false);
      fetchActiveTechnicians();
      fetchInactiveTechnicians(); // Refresh the list
    } catch (error) {
      setIsLoading(false);
      console.error('Error approving technician:', error);
    }
  };

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        {isLoading && (
          <div className="d-flex justify-content-center mt-4">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-12">
            <div className="table-responsive table-div">
              <ul className="nav nav-tabs mb-3">
                <li className="nav-item">
                  <button
                    className={`nav-link ${activeTab === 'active' ? 'active' : ''}`}
                    onClick={() => setActiveTab('active')}
                  >
                    Active Specialization
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`nav-link ${activeTab === 'inactive' ? 'active' : ''}`}
                    onClick={() => setActiveTab('inactive')}
                  >
                    Inactive Specialization
                  </button>
                </li>
              </ul>
              {activeTab === 'active' && (
                <DataTable value={activeTechnicians} showGridlines tableStyle={{ minWidth: '50rem' }}>
                  <Column field="Active" header="Active" body={(rowData:any) => activeButton(rowData.id)} />
                <Column  field="View" header="View"  body={(rowData:any) => eyeButtonactive(rowData)} />
                  <Column field="user_id" header="User Id" />
                  <Column field="userinfo.name" header="Name" />
                  <Column field="specializationinfo.specialization" header="Specialization" />
                  <Column field="subspecializationinfo.sub_specialization" header="Sub-Specialization" />
                  <Column field="wages_type" header="Wages Type" />
                  <Column field="wages" header="Wages" />
                  <Column field="currencyinfo.currency" header="Currency" />
                </DataTable>
              )}
              {activeTab === 'inactive' && (
                <DataTable value={inactiveTechnicians} showGridlines tableStyle={{ minWidth: '50rem' }}>
                    <Column field="Active" header="Active" body={(rowData:any) =>  inactiveButton (rowData.id)} />
                  <Column field="View" header="View" body={(rowData:any) => eyeButtoninactive(rowData)} />
                  <Column field="user_id" header="User Id" />
                  <Column field="userinfo.name" header="Name" />
                  <Column field="specializationinfo.specialization" header="Specialization" />
                  <Column field="subspecializationinfo.sub_specialization" header="Sub-Specialization" />
                  <Column field="wages_type" header="Wages Type" />
                  <Column field="wages" header="Wages" />
                  <Column field="currencyinfo.currency" header="Currency" />
                </DataTable>
              )}
            </div>
          </div>
        </div>
        <Modal show={showModalactive} onHide={handleCloseModalactive}>
        <Modal.Header closeButton>
          <Modal.Title>Order Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedRowDataactive ? (
            <div>
              <h5>Skill Set</h5>
              <p><strong>Status:</strong> {selectedRowDataactive.status == 1 ? "Approve" : "Reject"}</p>
              <p><strong>Specialization:</strong> {selectedRowDataactive.specializationinfo.specialization}</p>
              <p><strong>Sub Specialization:</strong> {selectedRowDataactive?.subspecializationinfo?.sub_specialization ? selectedRowDataactive.subspecializationinfo.sub_specialization : '-'}</p>
              <p><strong>Upload Certificate 1:</strong> {selectedRowDataactive.upload_certificate_1}</p>
              <p><strong>Upload Certificate 2:</strong> {selectedRowDataactive.upload_certificate_2}</p>
              <p><strong>Upload Certificate 3:</strong> {selectedRowDataactive.upload_certificate_3}</p>
              <p><strong>Wages Type:</strong> {selectedRowDataactive.wages_type}</p>
              <p><strong>Wages:</strong> {selectedRowDataactive.wages}</p>
              <p><strong>Currency:</strong> {selectedRowDataactive.currencyinfo.currency}</p>
            </div>
          ) : (
            <p>No details available</p>
          )}
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModalactive}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>

      <Modal show={showModalinactive} onHide={handleCloseModalinactive}>
        <Modal.Header closeButton>
          <Modal.Title>Order Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedRowDatainactive ? (
            <div>
              <h5>Skill Set</h5>
              <p><strong>Status:</strong> {selectedRowDatainactive.status == 1 ? "Approve" : "Reject"}</p>
              <p><strong>Specialization:</strong> {selectedRowDatainactive.specializationinfo.specialization}</p>
              <p><strong>Sub Specialization:</strong> {selectedRowDataactive?.subspecializationinfo?.sub_specialization ? selectedRowDataactive.subspecializationinfo.sub_specialization : '-'}</p>
              <p><strong>Upload Certificate 1:</strong> {selectedRowDatainactive.upload_certificate_1 && <img src={selectedRowDatainactive.upload_certificate_1} alt="Certificate 1" height="50" />}</p>
              <p><strong>Upload Certificate 2:</strong> {selectedRowDatainactive.upload_certificate_2}</p>
              <p><strong>Upload Certificate 3:</strong> {selectedRowDatainactive.upload_certificate_3}</p>
              <p><strong>Wages Type:</strong> {selectedRowDatainactive.wages_type}</p>
              <p><strong>Wages:</strong> {selectedRowDatainactive.wages}</p>
              <p><strong>Currency:</strong> {selectedRowDatainactive.currencyinfo.currency}</p>
            </div>
          ) : (
            <p>No details available</p>
          )}
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModalactive}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>
      </div>
    </div>

  );
};

export default ApprovedSpec;
