import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useState, useEffect } from 'react';
import * as Icon from 'react-feather';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { Link } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { base_url, image_base_url } from '../../../../constant';

const ProviderEarnings = () => {
  const [selectedValue, setSelectedValue] = useState(null);
  const value = [{ name: 'A - Z' }, { name: 'Z - A' }];
  const actionBodyTemplate = () => {
    return (
      <td>
        <div className="table-actions">
          <Link className="action-set" to="#">
            <Icon.Edit size={15} />
          </Link>
          <Link className="action-set confirm-text" to="#">
            <Icon.Trash2 size={15} />
          </Link>
        </div>
      </td>
    );
  };
  
  const [isLoading, setIsLoading] = useState(false);
  const [bookingList, setReviewList] = useState([]);

  useEffect(() => {
    getbooklisttechnician();
  }, []);

  const getbooklisttechnician = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('tech_token');
      const user_id = localStorage.getItem('technician_id');
      const response = await fetch(`${base_url}api/technician-earnings/${user_id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setReviewList((data.earnings).reverse()); // Update state with the earnings field
    } catch (error) {
      console.error('Error fetching technicians:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Serial number body template
  const serialNumberBodyTemplate = (rowData, { rowIndex }) => {
    return rowIndex + 1; // Return the serial number as index + 1
  };

  // Service with image body template
  const serviceBodyTemplate = (rowData:any) => {
    return (
      <div className="d-flex align-items-center">
        <img
          className="avatar-img rounded me-2"
          src={`${image_base_url}${rowData.service_image}`} // Make sure `img` is the key for the image URL in your API response
          alt={"img"}
          style={{ width: '40px', height: '40px' }} // Adjust size as needed
        />
        <Link to="#">{rowData.service_title || '-'}</Link>
      </div>
    );
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-md-4">
                <div className="provider-subtitle">
                  <h6>Earnings</h6>
                </div>
              </div>
              <div className="col-md-8 d-flex align-items-center justify-content-md-end flex-wrap">
                <div className="review-sort me-2">
                  <p>Sort</p>
                  <Dropdown
                    value={selectedValue}
                    onChange={(e) => setSelectedValue(e.value)}
                    options={value}
                    optionLabel="name"
                    placeholder="A - Z"
                    className="select provider-select select-space"
                  />
                </div>
                <div className="grid-listview me-2">
                  <ul>
                    <li>
                      <Link to="#">
                        <Icon.Filter className="react-feather-custom me-2" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          {/* Earnings Table */}
          <div className="row">
          {isLoading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) :  bookingList && bookingList.length > 0 ? (
            <div className="col-md-12">
              <div className="provide-table manage-table">
                <div className="table-responsive">
                  <DataTable
                    paginatorTemplate="RowsPerPageDropdown CurrentPageReport PrevPageLink PageLinks NextPageLink  "
                    currentPageReportTemplate="{first} to {last} of {totalRecords}"
                    value={bookingList} // Use bookingList state here
                    paginator
                    rows={10}
                    // rowsPerPageOptions={[5, 10, 25, 50]}
                    loading={isLoading}
                  >
                    <Column
                      header="#"
                      body={serialNumberBodyTemplate} // Set body to serialNumberBodyTemplate
                    />
                    <Column
                      field="service"
                      header="Service"
                      body={serviceBodyTemplate} // Use serviceBodyTemplate for image and title
                    />
                    <Column
                      sortable
                      field="appointment_date"
                      header="Date"
                    />
                    <Column
                      sortable
                      field="service_amount"
                      header="Amount"
                    />
                    {/* <Column
                      header="Action"
                      body={actionBodyTemplate}
                    /> */}
                  </DataTable>
                </div>
              </div>
            </div>): (
  <div>No data available</div>
)}
          </div>
          {/* /Earnings Table */}
        </div>
      </div>
    </>
  );
};

export default ProviderEarnings;
