import React from 'react';
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';

const FooterNine = () => {
  return (
    <footer className="footer footer-nine">
      {/* Footer Top */}
      <div className="footer-top aos" data-aos="fade-up">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              {/* Footer Widget */}
              <div className="footer-widget">
                <div className="footer-logo">
                  <Link to="/index">
                    <ImageWithBasePath src="assets/img/logo-02.svg" alt="logo" />
                  </Link>
                </div>
                <div>
                  <p>
                    We ara a lorem ipsum dolor sit amet, consectetur adipiscing
                    elit, Ut enim ad minim veniam, quis nostrud equip
                    consectetur adipiscing...{' '}
                    <Link to="#" className="read-more">
                      Read More
                    </Link>
                  </p>
                </div>
                {/* Footer Widget */}
                <div className="footer-widget footer-contact">
                  <div className="footer-contact-info">
                    <div className="footer-address">
                      <span>
                        <Icon.PhoneCall className="standard-feather" />
                      </span>
                      <div>
                        <h6>Phone Number</h6>
                        <p>310-437-2766</p>
                      </div>
                    </div>
                    <div className="footer-address">
                      <span>
                        <Icon.Mail className="standard-feather" />
                      </span>
                      <div>
                        <h6>Mail Address</h6>
                        <p>support@truelysell.com</p>
                      </div>
                    </div>
                    <div className="footer-address">
                      <span>
                        <Icon.MapPin className="standard-feather" />
                      </span>
                      <div>
                        <h6>Address</h6>
                        <p>706 Campfire Ave. Meriden, CT 06450</p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Footer Widget */}
              </div>
              {/* /Footer Widget */}
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="footer-widget footer-menu">
                    <h2 className="footer-title">About</h2>
                    <ul>
                      <li>
                        <Link to="/about-us">About Us</Link>
                      </li>
                      <li>
                        <Link to="/blog-details">Blog</Link>
                      </li>
                      <li>
                        <Link to="#">Careers</Link>
                      </li>
                      <li>
                        <Link to="#">Jobs</Link>
                      </li>
                      <li>
                        <Link to="#">In Press</Link>
                      </li>
                      <li>
                        <Link to="#">Gallery</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="footer-widget footer-menu">
                    <h2 className="footer-title">Support</h2>
                    <ul>
                      <li>
                        <Link to="/contact-us">Contact us</Link>
                      </li>
                      <li>
                        <Link to="/customer-chat">Online Chat</Link>
                      </li>
                      <li>
                        <Link to="#">Whatsapp</Link>
                      </li>
                      <li>
                        <Link to="#">Telegram</Link>
                      </li>
                      <li>
                        <Link to="#">Ticketing</Link>
                      </li>
                      <li>
                        <Link to="#">Call Center</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <ul className="social-media-icon-img">
                    <li>
                      <Link to="#">
                        <i className="fa-brands fa-facebook" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fab fa-twitter" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fa-brands fa-instagram" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fa-brands fa-linkedin" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fa-brands fa-youtube" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              {/* Footer Widget */}
              <div className="footer-widget footer-menu">
                <h2 className="footer-title">Popular Tags</h2>
                <ul className="footer-tags">
                  <li>
                    <Link to="#">FILTERS</Link>
                  </li>
                  <li>
                    <Link to="#">BRAKES</Link>
                  </li>
                  <li>
                    <Link to="#">DIAGNOSTICS</Link>
                  </li>
                  <li>
                    <Link to="#">STEERING</Link>
                  </li>
                  <li>
                    <Link to="#">BELTS</Link>
                  </li>
                  <li>
                    <Link to="#">SUSPENSION</Link>
                  </li>
                  <li>
                    <Link to="#">OILS</Link>
                  </li>
                  <li>
                    <Link to="#">TIRES</Link>
                  </li>
                  <li>
                    <Link to="#">ENGINE</Link>
                  </li>
                  <li>
                    <Link to="#">STEERING</Link>
                  </li>
                  <li>
                    <Link to="#">TIRES</Link>
                  </li>
                  <li>
                    <Link to="#">ENGINE</Link>
                  </li>
                  <li>
                    <Link to="#">STEERING</Link>
                  </li>
                  <li>
                    <Link to="#">OILS</Link>
                  </li>
                </ul>
              </div>
              {/* /Footer Widget */}
            </div>
          </div>
        </div>
      </div>
      {/* /Footer Top */}
      {/* Footer Bottom */}
      <div className="footer-bottom">
        <div className="container">
          {/* Copyright */}
          <div className="copyright">
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="copyright-text text-center">
                  <p className="mb-0">
                    Copyright 2023 © TruelySell. All rights reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* /Copyright */}
        </div>
      </div>
      {/* /Footer Bottom */}
    </footer>
  );
};

export default FooterNine;
