import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { base_url } from '../../../constant';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import * as Icon from 'react-feather';

const ApprovedList = () => {
  const [activeTechnicians, setActiveTechnicians] = useState([]);
  const [inactiveTechnicians, setInactiveTechnicians] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('active');

  useEffect(() => {
    fetchActiveTechnicians();
  }, []);

  useEffect(() => {
    fetchInactiveTechnicians();
  }, []);

  const fetchActiveTechnicians = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const user_id = localStorage.getItem('user_id');
      const response = await fetch(`${base_url}api/getbystatus/1/${user_id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setActiveTechnicians(data.reverse()); // Reverse the array to display in reverse order
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching active technicians:', error);
    }
  };

  const fetchInactiveTechnicians = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${base_url}api/getinactivetech`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setInactiveTechnicians(data.reverse()); // Reverse the array to display in reverse order
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching inactive technicians:', error);
    }
  };

  const activeButton = (rowdata:any) => (
    <div className="table-actions d-flex">
      <Link className="delete-table me-2" to="#" data-bs-toggle="modal" data-bs-target="#edit-category">
        <button className="btn btn-danger me-2" onClick={() => profilapprovedinactiveupdate(rowdata)}>To InActive</button>
      </Link>
    </div>
  );

  const inactiveButton = (rowdata:any) => (
    <div className="table-actions d-flex">
      <Link className="delete-table me-2" to="#" data-bs-toggle="modal" data-bs-target="#edit-category">
        <button className="btn btn-success me-2" onClick={() => profileapprovedactiveupdate(rowdata)}>To Active</button>
      </Link>
    </div>
  );

  const profileImageTemplate = (users:any) => {
    const imageSrc = users.userinfo?.userprofileimage;
    return <img src={`${base_url}${imageSrc}`} alt="Profile" style={{ width: '50px', height: 'auto' }} />;
  };


  const profilapprovedinactiveupdate = async (id:any) => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${base_url}api/profilapprovedinactiveupdate`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify( {id} ) // Example payload
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log('Technician approved:', data);
      setIsLoading(false);
      fetchInactiveTechnicians(); // Refresh the list
    } catch (error) {
      setIsLoading(false);
      console.error('Error approving technician:', error);
    }
  };


  const profileapprovedactiveupdate = async (id:any) => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${base_url}api/profileapprovedactiveupdate`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify( {id} ) // Example payload
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log('Technician approved:', data);
      setIsLoading(false);
      fetchActiveTechnicians();
      fetchInactiveTechnicians(); // Refresh the list
    } catch (error) {
      setIsLoading(false);
      console.error('Error approving technician:', error);
    }
  };

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        {isLoading && (
          <div className="d-flex justify-content-center mt-4">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-12">
            <div className="table-responsive table-div">
              <ul className="nav nav-tabs mb-3">
                <li className="nav-item">
                  <button
                    className={`nav-link ${activeTab === 'active' ? 'active' : ''}`}
                    onClick={() => setActiveTab('active')}
                  >
                    Active Technicians
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`nav-link ${activeTab === 'inactive' ? 'active' : ''}`}
                    onClick={() => setActiveTab('inactive')}
                  >
                    Inactive Technicians
                  </button>
                </li>
              </ul>
              {activeTab === 'active' && (
                <DataTable value={activeTechnicians} showGridlines tableStyle={{ minWidth: '50rem' }}>
                  <Column field="Active" header="Active" body={(rowData:any) => activeButton(rowData.id)} />
                  <Column field="created_at" header="Requested Date" />
                  {/* <Column field="View" header="View" /> */}
                  <Column field="id" header="Tech Id" />
                  <Column field="user_id" header="User Id" />
                  <Column field="userinfo.name" header="User Name" />
                  <Column field="userinfo.userprofileimage" header="photo" body={profileImageTemplate} />
                  <Column field="userinfo.email" header="Mail" />
                  <Column field="mobile" header="Mobile" />
                  <Column field="stateinfo.state" header="State" />
                </DataTable>
              )}
              {activeTab === 'inactive' && (
                <DataTable value={inactiveTechnicians} showGridlines tableStyle={{ minWidth: '50rem' }}>
                    <Column field="Active" header="Active" body={(rowData:any) =>  inactiveButton (rowData.id)} />
                  <Column field="created_at" header="Requested Date" />
                  <Column field="id" header="Tech Id" />
                  <Column field="user_id" header="User Id" />
                  <Column field="userinfo.name" header="User Name" />
                  <Column field="userinfo.userprofileimage" header="photo" body={profileImageTemplate} />
                  <Column field="userinfo.email" header="Mail" />
                  <Column field="mobile" header="Mobile" />
                  <Column field="stateinfo.state" header="State" />
                </DataTable>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApprovedList;
