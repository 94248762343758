import React, { useState,useEffect } from 'react'
import * as Icon from 'react-feather'
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath'
import { Link } from 'react-router-dom';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { Dropdown } from 'primereact/dropdown';
import { base_url, image_base_url } from '../../../../constant';
import { Modal, Button, Row, Col, Form  } from 'react-bootstrap';
import axios from 'axios';
const routes = all_routes;

const ProviderServices = () => {
  const [selectedValue, setSelectedValue] = useState(null);
  const value = [{ name: 'A - Z' }, { name: 'Most Helpful' }];
  const [skillset, setSkillset] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedTechnicianId, setSelectedTechnicianId] = useState<any>(null);
  const [selectedTechnicianUserId, setSelectedTechnicianUserId] = useState<any>(null);
  const [selectedTechnicianStatus, setSelectedTechnicianStatus] = useState<any>(null);
  const [skillsetdropdown, setSkillsetdropdown] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [editFormData, setEditFormData] = useState({
    specialization: '',
    upload_certificate_1: '',
    upload_certificate_2: '',
    upload_certificate_3: '',
    currency: '',
    wages: '',
    description: '',
    is_active: false,
    sub_specialization: '',
    wages_type: ''
  });
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Adjust this number as needed

  // Calculate the number of pages
  const totalPages = Math.ceil(skillset.length / itemsPerPage);

  // Get current items based on pagination
  const currentItems = skillset.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageClick = (pageNumber:any) => {
    setCurrentPage(pageNumber);
  };

useEffect(() => {
  TechnicianSkillSet();
  skillsetDropdown();
  CurrencyMaster();
}, []);

  //get api for skillset 
const TechnicianSkillSet = async () => {
  setIsLoading(true);
  try {
    const token = localStorage.getItem('tech_token');
    const response = await fetch(`${base_url}api/TechnicianSkillSet`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    setSkillset(data.reverse()); // Assuming data is an array of countries
    setIsLoading(false);
  } catch (error) {
    setIsLoading(false);
    console.error('Error fetching countries:', error);
  }
};

const handleEditClickSkillset = (technician:any) => {
  setEditFormData({
    specialization: technician.specialization || '',
    upload_certificate_1: technician.upload_certificate_1 || null,
    upload_certificate_2: technician.upload_certificate_2 || null,
    upload_certificate_3: technician.upload_certificate_3 || null,
    currency: technician.currency || '',
    wages: technician.wages || '',
    description: technician.description || '',
    is_active: technician.is_active || false,
    sub_specialization: technician.sub_specialization || '',
    wages_type: technician.wages_type || ''
  });
  setSelectedTechnicianId(technician.id);
  setSelectedTechnicianUserId(technician.user_id);
  setSelectedTechnicianStatus(technician.status);
    setShowEditModal(true);
};

const handleEditSubmit = async (e: any) => {
  e.preventDefault();
  setIsLoading(true);
  const data = new FormData();
  data.append('id', selectedTechnicianId);
  data.append('user_id', selectedTechnicianUserId);
  data.append('status', selectedTechnicianStatus);
  data.append('specialization', editFormData.specialization);
  data.append('upload_certificate_1', editFormData.upload_certificate_1);
  data.append('upload_certificate_2', editFormData.upload_certificate_2);
  data.append('upload_certificate_3', editFormData.upload_certificate_3);
  data.append('currency', editFormData.currency);
  data.append('wages', editFormData.wages);
  data.append('description', editFormData.description);
  data.append('is_active', editFormData.is_active ? '1' : '0');
  data.append('sub_specialization', '0');
  data.append('wages_type', '');

  try {
    const response = await axios.post(`${base_url}api/TechnicianSkillSetupdate`, data, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('tech_token')}`,
        'Content-Type': 'multipart/form-data'
      }
    });
    console.log('Response:', response.data);
    TechnicianSkillSet();
    setShowEditModal(false);
    setIsLoading(false);
  } catch (error) {
    console.error('Error updating technician:', error);
    setIsLoading(false);
  }
};

const handleEditChange = (e:any) => {
  const { name, value, type, files } = e.target;
  
  if (type === 'file') {
    setEditFormData((prevData) => ({
      ...prevData,
      [name]: files[0] // or `files` if handling multiple files
    }));
  } else {
    setEditFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  }
};

//get api for skillset 
const skillsetDropdown = async () => {
  setIsLoading(true);
  try {
    const token = localStorage.getItem('tech_token');
    const response = await fetch(`${base_url}api/SpecializationMasterList`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    setIsLoading(false);
    const data = await response.json();
    setSkillsetdropdown(data); // Assuming data is an array of countries
  } catch (error) {
    setIsLoading(false);
    console.error('Error fetching countries:', error);
  }
};

//get api for skillset 
const CurrencyMaster = async () => {
  setIsLoading(true);
  try {
    const token = localStorage.getItem('tech_token');
    const response = await fetch(`${base_url}api/CurrencyMaster`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    setIsLoading(false);
    const data = await response.json();
    setCurrency(data); // Assuming data is an array of countries
  } catch (error) {
    setIsLoading(false);
    console.error('Error fetching countries:', error);
  }
};


  return (
    <div className="page-wrapper">
    <div className="content container-fluid">
      {/* Page Header */}
      {isLoading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <>
      <div className="page-header">
        <div className="row">
          <div className="col-md-4">						
            <div className="provider-subtitle">
              <h6>My Services</h6>
            </div>
          </div>
          <div className="col-md-8 d-flex align-items-center justify-content-md-end flex-wrap">
            <div className="review-sort me-2">
              <p>Sort</p>
              <Dropdown
                  value={selectedValue}
                  onChange={(e) => setSelectedValue(e.value)}
                  options={value}
                  optionLabel="name"
                  placeholder="A - Z"
                  className="select provider-select select-space" />
            </div>
            <div className="grid-listview me-2">
              <ul>
                <li>
                  <Link to="#">
                    <Icon.Filter className="react-feather-icon" />
                  </Link>
                </li>
                <li>
                  <Link to={routes. providerService} className="active">
                  <Icon.Grid className="react-feather-icon" />
                  </Link>
                </li>
                <li>
                  <Link to={routes. providerServiceList}>
                  <Icon.List className="react-feather-icon" />
                  </Link>
                </li>
              </ul>
            </div>
            <Link to={routes.createService} className="btn btn-primary add-set"><Icon.Plus className="react-feather-icon me-2" />Add Service</Link>
          </div>
        </div>
      </div>
      <div className="tab-content pt-0">				
        <div className="tab-pane active" id="active-service">				
          <div className="row">				
            {/* Favorite Item */}

            {currentItems.length > 0 ? (currentItems.map((service:any) => (
        <div className="col-xl-3 col-md-4" key={service.id}>
          <div className="service-widget pro-service">
            <div className="service-img">
              <Link to={`/serviceDetails/${service.id}`}>
                <img
                  className="img-fluid serv-img"
                  alt="Service Image"
                  src={service?.specializationinfo?.image 
                    ? `${image_base_url}${service.specializationinfo.image}` 
                    : 'default-image.jpg'}
                />
              </Link>
              {/* <div className="fav-item">
                <div className="item-info">
                  <Link to="/categories">
                    <span className="item-cat">{service.specializationinfo.specialization}</span>
                  </Link>
                </div>
                <span className="serv-rating">
                  <i className="fa-solid fa-star" />
                  {service.rating || '4.9'}
                </span>
              </div> */}
            </div>
            <div className="service-content">
              <h3 className="title">
                <Link to={`/serviceDetails/${service.id}`}>{service.description}</Link>
              </h3>
              <div className="addrs-item">
                {/* <p>
                  <Icon.MapPin className="react-feather-icon" /> Maryland City, MD, USA
                </p> */}
                <h6 className="price">
                  {service.currencyinfo?.currency ? service.currencyinfo?.currency : "-"} {service.wages ? service.wages : '-'}  /Hr
                  {/* <span className="old-price">$35.00</span>
                  <span className="price-hr">/hr</span> */}
                </h6>
              </div>
              <div className="serv-info">
                <div>
                  <Link to="#"  className="serv-edit" onClick={()=>handleEditClickSkillset(service)}>
                    <Icon.Edit className="react-feather-icon" /> Edit
                  </Link>
                  <Link to="#" data-bs-toggle="modal" data-bs-target="#in-active">
                    <span>
                      <Icon.AlertCircle className="react-feather-icon" /> {service.is_active ? 'Active' : 'Inactive'}
                    </span>
                  </Link>
                </div>
                <Link to="/providerOffer" className="btn btn-book">
                  Apply Offer
                </Link>
              </div>
            </div>
          </div>
        </div>
      ))): (
        <div>No data available</div>
      )}

            {/* <div className="col-xl-4 col-md-6">
              <div className="service-widget pro-service">
                <div className="service-img">
                  <Link to={routes.serviceDetails1}>
                    <ImageWithBasePath className="img-fluid serv-img" alt="Service Image" src="assets/img/services/service-04.jpg" />
                  </Link>
                  <div className="fav-item">
                    <div className="item-info">
                      <Link to={routes.categories}><span className="item-cat">Car Wash</span></Link>
                    </div>
                    <span className="serv-rating"><i className="fa-solid fa-star" />4.9</span>
                  </div>
                </div>
                <div className="service-content">
                  <h3 className="title">
                    <Link to={routes.serviceDetails1}>Car Repair Services</Link>
                  </h3>
                  <div className="addrs-item">
                    <p><Icon.MapPin className="react-feather-icon" /> Maryland City, MD, USA</p>
                    <h6 className="price">$20.00<span className="old-price">$35.00</span><span className="price-hr">/hr</span></h6>
                  </div>
                  <div className="serv-info">
                    <div>
                      <Link to={routes.providerEditService} className="serv-edit"><Icon.Edit className="react-feather-icon" /> Edit</Link>
                      <Link to="#" data-bs-toggle="modal" data-bs-target="#in-active"><span><Icon.AlertCircle className="react-feather-icon" /> Active</span></Link>
                    </div>
                    <Link to={routes.providerOffer} className="btn btn-book">Apply Offer</Link>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="col-xl-4 col-md-6">
              <div className="service-widget pro-service">
                <div className="service-img">
                  <Link to={routes.serviceDetails1}>
                    <ImageWithBasePath className="img-fluid serv-img" alt="Service Image" src="assets/img/services/service-10.jpg" />
                  </Link>
                  <div className="fav-item">
                    <div className="item-info">
                      <Link to={routes.categories}><span className="item-cat">Construction</span></Link>
                    </div>
                    <span className="serv-rating"><i className="fa-solid fa-star" />4.9</span>
                  </div>
                </div>
                <div className="service-content">
                  <h3 className="title">
                    <Link to={routes.serviceDetails1}>Building Construction Services</Link>
                  </h3>
                  <div className="addrs-item">
                    <p><Icon.MapPin className="react-feather-icon" />Montana, USA</p>
                    <h6 className="price">$25.00<span className="old-price">$30.00</span><span className="price-hr">/hr</span></h6>
                  </div>
                  <div className="serv-info">
                    <div>
                      <Link to="#" className="serv-edit" data-bs-toggle="modal" data-bs-target="#del-service"><i className="feather-trash-2" />Delete</Link>
                      <Link to="#" data-bs-toggle="modal" data-bs-target="#active"><span><Icon.AlertCircle className="react-feather-icon" /> Inactive</span></Link>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/* Pagination */}
       <div className="col-sm-12">
        <div className="blog-pagination rev-page">
          <nav>
            <ul className="pagination justify-content-center">
              <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                <Link className="page-link page-prev" to="#" onClick={handlePrevPage}>
                  <i className="fa-solid fa-arrow-left me-1" /> PREV
                </Link>
              </li>
              {Array.from({ length: totalPages }, (_, index) => (
                <li
                  key={index + 1}
                  className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}
                >
                  <Link
                    className="page-link"
                    to="#"
                    onClick={() => handlePageClick(index + 1)}
                  >
                    {index + 1}
                  </Link>
                </li>
              ))}
              <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                <Link className="page-link page-next" to="#" onClick={handleNextPage}>
                  NEXT <i className="fa-solid fa-arrow-right ms-1" />
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      </>
        )}
      {/* /Pagination */}
    </div>
     {/* Inactive Service */}
     <div className="modal fade custom-modal" id="in-active">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header border-bottom-0 justify-content-between">
                <h5 className="modal-title">Inactive Service</h5>
                <button type="button" className="close-btn" data-bs-dismiss="modal" aria-label="Close"><i className="feather-x" /></button>
              </div>
              <div className="modal-body pt-0">
                <div className="write-review">
                  <form action={routes. providerService}>
                    <p>Are you sure want to inactive this service?</p>
                    <div className="modal-submit text-end">
                      <Link to="#" className="btn btn-secondary me-2" data-bs-dismiss="modal">Cancel</Link>
                      <button type="submit" className="btn btn-primary">Yes</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Inactive Service */}
        {/* Active Service */}
        <div className="modal fade custom-modal" id="active">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header border-bottom-0 justify-content-between">
                <h5 className="modal-title">Active Service</h5>
                <button type="button" className="close-btn" data-bs-dismiss="modal" aria-label="Close"><i className="feather-x" /></button>
              </div>
              <div className="modal-body pt-0">
                <div className="write-review">
                  <form action={routes. providerService}>
                    <p>Are you sure want to active this service?</p>
                    <div className="modal-submit text-end">
                      <Link to="#" className="btn btn-secondary me-2" data-bs-dismiss="modal">Cancel</Link>
                      <button type="submit" className="btn btn-primary">Yes</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Inactive Service */}

{/* Edit service */}
<Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Skill Set</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleEditSubmit}>
            <Row>
              <Col>
              <Form.Group controlId="formSpecialization">
                <Form.Label>Specialization</Form.Label>
                <Form.Control
                as="select"
                name="specialization"
                value={editFormData.specialization}
                onChange={handleEditChange}
                >
                <option value=""></option>
                {skillsetdropdown.map((specialization:any) => (
                <option key={specialization.id} value={specialization.id}>
                  {specialization.specialization}
                </option>
               ))}
              </Form.Control>
              </Form.Group>
              </Col>
                <Col>
                <Form.Group controlId="formUploadCertificate1">
                  <Form.Label>Upload Certificate 1</Form.Label>
                  <Form.Control
                    type="file"
                    name="upload_certificate_1"
                    onChange={handleEditChange}
                  />
                </Form.Group>
                </Col>
                </Row>
                <Row>
                  <Col>
                <Form.Group controlId="formUploadCertificate2">
                  <Form.Label>Upload Certificate 2</Form.Label>
                  <Form.Control
                    type="file"
                    name="upload_certificate_2"
                    onChange={handleEditChange}
                  />
                </Form.Group>
                </Col>
                <Col>
                <Form.Group controlId="formUploadCertificate3">
                  <Form.Label>Upload Certificate 3</Form.Label>
                  <Form.Control
                    type="file"
                    name="upload_certificate_3"
                    onChange={handleEditChange}
                  />
                </Form.Group>
                </Col>
                </Row>
                <Row>
                  <Col>
                  <Form.Group controlId="formCurrency">
                    <Form.Label>Currency</Form.Label>
                    <Form.Control as="select"
                    name="currency"
                    value={editFormData.currency}
                    onChange={handleEditChange}
                    >
                      <option value=""></option>
                      {currency.map((currencyOption:any) => (
                        <option key={currencyOption.id} value={currencyOption.id}>
                          {currencyOption.currency}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  </Col>
                <Col>
                <Form.Group controlId="formWages">
                  <Form.Label>Wages</Form.Label>
                  <Form.Control
                    type="text"
                    name="wages"
                    value={editFormData.wages}
                    onChange={handleEditChange}
                    placeholder="Enter wages"
                  />
                </Form.Group>
                </Col>
                </Row>
                <Row>
                  <Col>
                <Form.Group controlId="formDescription">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    type="text"
                    name="description"
                    value={editFormData.description}
                    onChange={handleEditChange}
                    placeholder="Enter description"
                  />
                </Form.Group>
                </Col>
                <Col>
                <Form.Group controlId="formIsActive">
                  <Form.Check
                    type="checkbox"
                    label="Is Active"
                    name="is_active"
                    checked={editFormData.is_active}
                    onChange={handleEditChange}
                  />
                </Form.Group>
                </Col>
                </Row>
            <Modal.Footer>
            <Button variant="outline-primary" type="submit">
                Update Specialization
              </Button>
              <Button variant="outline-warning" onClick={() => setShowEditModal(false)}>
                Reset
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
        {/* Edit service */}

        {/* Delete Service */}
        <div className="modal fade custom-modal" id="del-service">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header border-bottom-0 justify-content-between">
                <h5 className="modal-title">Delete Service</h5>
                <button type="button" className="close-btn" data-bs-dismiss="modal" aria-label="Close"><i className="feather-x" /></button>
              </div>
              <div className="modal-body pt-0">
                <div className="write-review">
                  <form action={routes. providerService}>
                    <p>Are you sure want to Delete this service?</p>
                    <div className="modal-submit text-end">
                      <Link to="#" className="btn btn-secondary me-2" data-bs-dismiss="modal">Cancel</Link>
                      <button type="submit" className="btn btn-primary">Yes</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Delete Service */}
  </div>
  )
}

export default ProviderServices