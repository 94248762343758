import React, { useState, useEffect } from 'react';
import * as Icon from 'react-feather';
import { Link,useNavigate } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import ProviderBookingModal from '../../common/modals/provider-booking-modal';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { base_url, image_base_url } from '../../../../constant';


const ProviderBooking = () => {
  const routes = all_routes;
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState(null);
  const value = [{ name: 'A - Z' }, { name: 'Most Helpful' }];
  const [selectedItems, setSelectedItems] = useState(Array(10).fill(false));
  const handleItemClick = (index: number) => {
    setSelectedItems((prevSelectedItems) => {
      const updatedSelectedItems = [...prevSelectedItems];
      updatedSelectedItems[index] = !updatedSelectedItems[index];
      return updatedSelectedItems;
    });
  };
  const [isStarted, setIsStarted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [bookingList, setBookingList] = useState([]);
const [currentPage, setCurrentPage] = useState(1);
const [itemsPerPage, setItemsPerPage] = useState(10);

  useEffect(() => {
    getbooklisttechnician();
  }, []);

//get
// 5 technisian id
const getbooklisttechnician = async () => {
  setIsLoading(true);
  try {
    const token = localStorage.getItem('tech_token');
    const user_id = localStorage.getItem('technician_id');
    // const response = await fetch(`${base_url}api/gettimebyId/${techid}`
    const response = await fetch(`${base_url}api/technician-appointments/${user_id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    setBookingList((data.appointments.reverse()));
    setIsLoading(false);
  } catch (error) {
    setIsLoading(false);
    console.error('Error fetching technicians:', error);
  }
};

const formatDate = (dateString:any) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};
const getStatusText = (status:any) => {
  switch (status) {
    case 0:
      return 'New';
    case 1:
      return 'Pending';
    case 2:
      return 'Confirmed';
    case 3:
      return 'Cancelled';
    case 4:
      return 'Completed';
    case 5:
      return 'InProgress';
    default:
      return 'Unknown';
  }
};

const getStatusBadgeClass = (status:any) => {
  switch (status) {
    case 0:
      return 'badge-info';
    case 1:
      return 'badge-warning';
    case 2:
      return 'badge-success';
    case 3:
      return 'badge-danger';
    case 4:
      return 'badge-success';
    case 5:
      return 'badge-info';
    default:
      return 'badge-secondary';
  }
};


const handleToggleChangeAccept = async (id:any) => {
  const user_id = localStorage.getItem('technician_id');
  const payload = {
    id: id,
    status: 1,
  };

  const url = `${base_url}api/accept-by-technician/${id}/${user_id}`;

  setIsLoading(true);
  try {
    const token = localStorage.getItem('token');
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    console.log('Response Status:', response.status);

    if (response.status === 200) {
      const result = await response.json();
      console.log('Result:', result);
      getbooklisttechnician();
    } else {
      const result = await response.json();
      console.error('API Error:', result.message);
      alert(result.message);
      throw new Error('Failed to update time');
    }
  } catch (error) {
    console.error('Error:', error);
  } finally {
    setIsLoading(false);
  }
};

const handleToggleChangeReject = async (id:any) => {
  const user_id = localStorage.getItem('technician_id');
  const payload = {
    id: id,
    status: 3,
  };

  const url = `${base_url}api/accept-by-technician/${id}/${user_id}`;

  setIsLoading(true);
  try {
    const token = localStorage.getItem('token');
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    console.log('Response Status:', response.status);

    if (response.status === 200) {
      const result = await response.json();
      console.log('Result:', result);
      getbooklisttechnician();
    } else {
      const result = await response.json();
      console.error('API Error:', result.message);
      alert(result.message);
      throw new Error('Failed to update time');
    }
  } catch (error) {
    console.error('Error:', error);
  } finally {
    setIsLoading(false);
  }
};

const handleToggleChangeCompleted = async (id:any) => {
  const user_id = localStorage.getItem('technician_id');
  const payload = {
    id: id,
    status: 4,
  };

  const url = `${base_url}api/accept-by-technician/${id}/${user_id}`;

  setIsLoading(true);
  try {
    const token = localStorage.getItem('token');
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    console.log('Response Status:', response.status);

    if (response.status === 200) {
      const result = await response.json();
      console.log('Result:', result);
      getbooklisttechnician();
    } else {
      const result = await response.json();
      console.error('API Error:', result.message);
      alert(result.message);
      throw new Error('Failed to update time');
    }
  } catch (error) {
    console.error('Error:', error);
  } finally {
    setIsLoading(false);
  }
};

const handleToggleChangeStart = async (id:any) => {
  const user_id = localStorage.getItem('technician_id');
  const payload = {
    id: id,
    status: 5,
  };

  const url = `${base_url}api/accept-by-technician/${id}/${user_id}`;

  setIsLoading(true);
  try {
    const token = localStorage.getItem('token');
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    console.log('Response Status:', response.status);

    if (response.status === 200) {
      const result = await response.json();
      console.log('Result:', result);
      getbooklisttechnician();
    } else {
      const result = await response.json();
      console.error('API Error:', result.message);
      alert(result.message);
      throw new Error('Failed to update time');
    }
  } catch (error) {
    console.error('Error:', error);
  } finally {
    setIsLoading(false);
  }
};

const indexOfLastItem = currentPage * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;
const currentItems = bookingList.slice(indexOfFirstItem, indexOfLastItem);

const totalPages = Math.ceil(bookingList.length / itemsPerPage);




  return (
    <>
    <div>
    {isLoading && (
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
    )}
    {!isLoading && (
      <>
    <div className="page-wrapper">
      <div className="content container-fluid">
        {/* Page Header */}
        <div className="page-header">
          <div className="row">
            <div className="col-md-4">
              <div className="provider-subtitle">
                <h6>Booking List</h6>
              </div>
            </div>
            <div className="col-md-8 d-flex align-items-center justify-content-md-end flex-wrap">
              <div className="review-sort me-2">
                <p>Sort</p>
                <Dropdown
                  value={selectedValue}
                  onChange={(e) => setSelectedValue(e.value)}
                  options={value}
                  optionLabel="name"
                  placeholder="A - Z"
                  className="select provider-select select-space" />
              </div>
              <div className="grid-listview">
                <ul>
                  <li>
                    <Link to="#">
                      <Icon.Filter className="standard-feather" />
                    </Link>
                  </li>
                  <li>
                    <Link to={routes.providerBookDetails}>
                      <Icon.Calendar className="standard-feather" />
                    </Link>
                  </li>
                  <li>
                    <Link to={routes.providerService}>
                      <Icon.Grid className="standard-feather" />
                    </Link>
                  </li>
                  <li>
                    <Link to={routes.providerBooking} className="active">
                      <Icon.List className="standard-feather" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* /Page Header */}
        <div className="row">
          <div className="col-md-12">
            {/* Booking List */}
            {/* <div className="booking-list">
              <div className="booking-widget">
                <div className="booking-img">
                  <Link to={routes.serviceDetails1}>
                    <ImageWithBasePath
                      src="assets/img/services/service-08.jpg"
                      alt="User Image" className={''} />
                  </Link>
                  <div
                    className="fav-item"
                    key={1}
                    onClick={() => handleItemClick(1)}
                  >
                    <Link
                      to="#"
                      className={`fav-icon ${selectedItems[1] ? 'selected' : ''}`}
                    >
                      <Icon.Heart size={15} />
                    </Link>
                  </div>
                </div>
                <div className="booking-det-info">
                  <h3>
                    <Link to={routes.serviceDetails1}>Computer Services</Link>
                    <span className="badge badge-danger">Cancelled</span>
                  </h3>
                  <ul className="booking-details">
                    <li>
                      <span className="book-item">Booking Date</span> : 27 Sep
                      2023, 17:00-18:00
                    </li>
                    <li>
                      <span className="book-item">Amount</span> : $100.00{' '}
                      <span className="badge-grey">COD</span>
                    </li>
                    <li>
                      <span className="book-item">Location</span> : Newyork, USA
                    </li>
                    <li>
                      <span className="book-item">Customer</span> :
                      <div className="user-book">
                        <div className="avatar avatar-xs">
                          <ImageWithBasePath
                            className="avatar-img rounded-circle"
                            alt="User Image"
                            src="assets/img/profiles/avatar-02.jpg" />
                        </div>
                        John Doe
                      </div>
                      <p>info@johndoe.com</p>
                      <p>+1 888 888 8888</p>
                    </li>
                  </ul>
                </div>
              </div>
            {/* Booking List */}
            {currentItems.length > 0 ? (currentItems.map((booking:any) => (
            <div className="booking-list" key={booking.id}>
              <div className="booking-widget">
                <div className="booking-img">
                  <Link to={routes.serviceDetails1}>
                    <img
                      src={booking.service?.service_image ? `${image_base_url}${booking.service?.service_image}` : '/assets/img/services/service-06.jpg'}
                      // src="assets/img/services/service-06.jpg"
                      alt="User Image" className={''} />
                  </Link>
                  <div
                    className="fav-item"
                    key={5}
                    onClick={() => handleItemClick(5)}
                  >
                    {/* <Link
                      to="#"
                      className={`fav-icon ${selectedItems[5] ? 'selected' : ''}`}
                    >
                      <Icon.Heart size={15} />
                    </Link> */}
                  </div>
                </div>
                <div className="booking-det-info">
                <div className="row align-items-center">
  <div className="col">
    <h3>
      <Link to={routes.serviceDetails1}>
        {booking.service?.service_title ? booking.service?.service_title : ""}
      </Link>{' '}
      <span className={`badge ${getStatusBadgeClass(booking.status)}`}>
        {getStatusText(booking.status)}
      </span>
    </h3>
  </div>

  {/* Conditionally render the button */}
  <div className="col-auto">
    {booking.status === 0 && (
      <>
      <button onClick={() => handleToggleChangeAccept(booking.id)} className="btn btn-success" style={{marginRight:"10px"}}>
        Accept
      </button>
      <button onClick={() => handleToggleChangeReject(booking.id)} className="btn btn-danger">
        Reject
      </button>
    </>
    )}
  </div>

  <div className="col-auto">
      {booking.status === 2 && (
        <button 
          onClick={() => handleToggleChangeStart(booking.id)} 
          className="btn btn-primary" 
          style={{ marginRight: "10px" }}
        >
          Start Now
        </button>
      )}
    </div>

    <div className="col-auto">
      {booking.status === 5 && (
          <button 
            onClick={() => handleToggleChangeCompleted(booking.id)} 
            className="btn btn-success"
          >
            Completed
          </button>
      )}
</div>
</div>


               <ul className="booking-details">
                    <li>
                      <span className="book-item">Booking Date</span> : {formatDate(booking.appointment_date)}, {booking.from_time} - {booking.to_time}
                    </li>
                    <li>
                      <span className="book-item">Amount</span> : ${booking.service_amount}{' '}
                      {/* <span className="badge-grey">COD</span> */}
                    </li>
                    <li>
                      <span className="book-item">Location</span> : {booking.address}
                    </li>
                    <li>
                      <span className="book-item">Customer</span> : {booking.customer.name} 
                      <div className="user-book">
                        {/* <div className="avatar avatar-xs">
                          <ImageWithBasePath
                            className="avatar-img rounded-circle"
                            alt="User Image"
                            src="assets/img/profiles/avatar-02.jpg" />
                        </div> */}
                      </div>
                      <p>{booking.customer.email}</p>
                      <p>{booking.customer.mobileno}</p>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <div className="booking-action">
                <Link to={routes.booking1} className="btn btn-primary">
                  Rebook
                </Link>
                <Link
                  to="#"
                  className="btn btn-secondary"
                  data-bs-toggle="modal"
                  data-bs-target="#add-review"
                >
                  <Icon.PlusCircle className="react-feather-custom" /> Add
                  Review
                </Link>
                <div className="view-action">
                  <div className="rating">
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                  </div>
                  <Link to={routes.serviceDetails1} className="view-btn">
                    View Details
                  </Link>
                </div>
              </div> */}
            </div>))): (
              <div>No data available</div>
              )}
            {/* /Booking List */}
          </div>
        </div>
        {/* Pagination */}
        <div className="row d-flex justify-content-end">
          {/* <div className="col-sm-6">
            <div className="review-entries">
              <span>Show</span>
              <select onChange={(e) => setItemsPerPage(parseInt(e.target.value))} value={itemsPerPage}>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
              </select>
              <span>entries</span>
            </div>
          </div> */}
          <div className="col-sm-12">
            <div className="review-pagination">
              <p>{`${indexOfFirstItem + 1} - ${Math.min(indexOfLastItem, bookingList.length)} of ${bookingList.length}`}</p>
                <ul className="pagination">
                  <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <Link className="page-link" to="#" onClick={() => setCurrentPage(currentPage - 1)}>Previous</Link>
                  </li>
                  {[...Array(totalPages)].map((_, index) => (
                  <li key={index + 1} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                    <Link className="page-link" to="#" onClick={() => setCurrentPage(index + 1)}>{index + 1}</Link>
                  </li>
                  ))}
                  <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <Link className="page-link" to="#" onClick={() => setCurrentPage(currentPage + 1)}>Next</Link>
                  </li>
                </ul>
            </div>
          </div>
        </div>
        {/* /Pagination */}
      </div>
    </div>
    <ProviderBookingModal />
    </>)}
    </div>
    </>
  );
};

export default ProviderBooking;
