import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { base_url } from '../../../constant';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import * as Icon from 'react-feather';

const RequestedList = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchAllTechnician();
  }, []);

  const fetchAllTechnician = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${base_url}api/getalltechnician`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setIsLoading(false);
      // Reverse the array to display in reverse order
      setUsers(data.reverse());
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching technicians:', error);
    }
  };

//   const actionButton = () => {
//     return (
//       <div className="table-actions d-flex">
//         <Link className="delete-table me-2" to="#" data-bs-toggle="modal" data-bs-target="#edit-category">
//           <Icon.Edit className="react-feather-custom" />
//         </Link>
//         <Link className="delete-table border-none" to="#" data-bs-toggle="modal" data-bs-target="#delete-category">
//           <Icon.Trash2 className="react-feather-custom" />
//         </Link>
//       </div>
//     );
//   };
const approveButton = (technician:any) => {
    return (
      <div className="table-actions d-flex">
        <button className="btn btn-success me-2" onClick={() => approveTechnician(technician)}>Approve</button>
      </div>
    );
  };
  const rejectButton = (technician:any) => {
    return (
      <div className="table-actions d-flex">
        <button className="btn btn-danger me-2" onClick={() => rejectTechnician(technician)}>Reject</button>
      </div>
    );
  };

  const profileImageTemplate = (users:any) => {
    const imageSrc = users.userinfo?.userprofileimage;
    return (
      <img src={`${base_url}${imageSrc}`} alt="Profile" style={{ width: '50px', height: 'auto' }} />
    );
  };

  const approveTechnician = async (technician:any) => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${base_url}api/approvedupdate/`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify( technician ) // Example payload
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log('Technician approved:', data);
      setIsLoading(false);
      fetchAllTechnician(); // Refresh the list
    } catch (error) {
      setIsLoading(false);
      console.error('Error approving technician:', error);
    }
  };


  const rejectTechnician = async (technician:any) => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${base_url}api/rejectedupdate/`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify( technician ) // Example payload
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log('Technician approved:', data);
      setIsLoading(false);
      fetchAllTechnician(); // Refresh the list
    } catch (error) {
      setIsLoading(false);
      console.error('Error approving technician:', error);
    }
  };

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        {isLoading && (
          <div className="d-flex justify-content-center mt-4">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-12">
            <div className="table-responsive table-div">
                <h6>Requested Tech count: {users.length}</h6>
              <DataTable value={users} showGridlines tableStyle={{ minWidth: '50rem' }}>
                <Column  field="Approve" header="Approve" body={(rowData:any) => approveButton(rowData)} />
                <Column  field="Reject" header="Reject" body={(rowData:any) => rejectButton(rowData)} />
                <Column  field="created_at" header="Requsted Date"  />
                <Column  field="View" header="View" />
                <Column  field="id" header="Tech Id" />
                <Column  field="user_id" header="User Id" />
                <Column  field="userinfo.name" header="User Name" />
                <Column  field="userinfo.userprofileimage" header="photo" body={profileImageTemplate} />
                <Column  field="userinfo.email" header="Mail" />
                <Column  field="mobile" header="Mobile" />
                <Column  field="stateinfo.state" header="State" />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestedList;
