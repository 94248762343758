import React, { useEffect, useState } from 'react'
import * as Icon from 'react-feather'
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath'
import { Link } from 'react-router-dom';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { base_url, image_base_url } from '../../../../constant';
// import { Dropdown } from 'primereact/dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Modal, Button, Row, Col, Form  } from 'react-bootstrap';
import axios from 'axios';

const routes = all_routes;

const HomeScreenProvider = () => {
//   const [selectedValue, setSelectedValue] = useState(null);
//   const value = [{ name: 'A - Z' }, { name: 'Most Helpful' }];
const [activeTab, setActiveTab] = useState('technician-profile');
  const [activeSubTab, setActiveSubTab] = useState('personal-details');
  const [users, setUser] = useState<any>(null);
  const [countries, setCountries] = useState([]);
  const [states, setState] = useState([]);
  const [cities, setCities] = useState([]);
  const [pincode, setPincode] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isProfileExist, setIsProfileExist] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [errors, setErrors] = useState({ name_of_verification_id: '' });
  const [formData, setFormData] = useState({
    city: 0,
    communication_address: '',
    country: 0,
    current_occupation: '',
    date_of_birth: '',
    education: '',
    gender: '',
    is_active: false,
    joined_date: '',
    language_known: '',
    marital_status: '',
    mobile: '',
    pincode: '',
    state: 0,
    user_id: 0,
  });
  const [fileData, setFileData] = useState({
    name_of_verification_id: '', // Default value for testing
    attachment_1: '',
    attachment_2: '',
    attachment_3: '',
  });
  const [fileShowModalDelete, setfileShowModalDelete] = useState(false);
  const [deletefileId, setdeletefileId] = useState<any>('');
  const [deletefilename, setdeletefilename] = useState<any>('');
  const [verificationData, setverificationData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editItemId, setEditItemId] = useState(null);
  const [skillset, setSkillset] = useState([]);
  const [skillsetdropdown, setSkillsetdropdown] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currency, setCurrency] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedTechnicianId, setSelectedTechnicianId] = useState<any>(null);
  const [selectedTechnicianUserId, setSelectedTechnicianUserId] = useState<any>(null);
  const [selectedTechnicianStatus, setSelectedTechnicianStatus] = useState<any>(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [experienceModal, setexperienceModal] = useState(false);
  const [experienceModalEdit, setexperienceModalEdit] = useState(false);
  const [experienceData, setexperienceData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [cityShowModal, setCityShowModal] = useState(false);
  const [cityShowModalEdit, setCityShowModalEdit] = useState(false);
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedCityEdit, setSelectedCityEdit] = useState<any>('');
  const [selectedEditCity, setSelectedEditCity] = useState<any>('');
  const [cityShowModalDelete, setCityShowModalDelete] = useState(false);
  const [selectedCityDeleteId, setSelectedCityDeleteId] = useState('');
  const [selectedDeleteCityName, setselectedDeleteCityName] = useState('');
  const [editFormData, setEditFormData] = useState({
    specialization: '',
    upload_certificate_1: '',
    upload_certificate_2: '',
    upload_certificate_3: '',
    currency: '',
    wages: '',
    description: '',
    is_active: false,
    sub_specialization: '',
    wages_type: ''
  });

  const handleEditClick = (item:any) => {
    setEditMode(true);
    setEditItemId(item.id);
    setFileData({
      name_of_verification_id: item.name_of_verification_id,
      attachment_1: '',
    attachment_2: '',
    attachment_3: '',
    });
  };

  const resetfile = () => {
    setFileData({
      name_of_verification_id: '',
    attachment_1: '',
    attachment_2: '',
    attachment_3: '',
    })
  };

  const handleDeleteCity = (item:any) => {
    setselectedDeleteCityName(item.cityinfo.city);
    setSelectedCityDeleteId(item.id);
    setCityShowModalDelete(true);
  };
  
const [formDataexperience, setFormDataexperience] = useState({
  company_name: '',
  department: '',
  designation: '',
  experience: '',
  others: ''
});

const [editDataexperience, setEditDataexperience] = useState({
  company_name: '',
  department: '',
  designation: '',
  experience: '',
  others: '',
  id:'',
  user_id:'',
  created_at:'',
  updated_at:''
});

const handleEditClickExperience = (technician:any) => {
  setEditDataexperience({
    company_name: technician.company_name || '',
    department: technician.department || '',
    designation: technician.designation || '',
    experience: technician.experience || '',
    others: technician.others || '',
    id:technician.id,
    user_id:technician.user_id,
    created_at:technician.created_at,
    updated_at:technician.updated_at
  });
  setexperienceModalEdit(true);
};

  useEffect(() => {
    fetchUser();
    fetchCountries();
    fetchState();
    fetchCity();
    fetchPincode();
    fetchtechnician();
    fetchTechnicianUserId();
    TechnicianSkillSet();
    skillsetDropdown();
    CurrencyMaster();
    TechnicianExperience();
    techidlist();
  }, []);
//get api user 
  const fetchUser = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${base_url}api/user`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      localStorage.setItem("userId", data.id);
      setUser(data); // Assuming data is an array of countries
      setIsDisabled(true);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };
//get countries
  const fetchCountries = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${base_url}api/CountryMasterr`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setCountries(data); // Assuming data is an array of countries
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

 //get state 
  const fetchState = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${base_url}api/StateMasterr`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setState(data); // Assuming data is an array of countries
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

//get city
  const fetchCity = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${base_url}api/CityMaster`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setCities(data); // Assuming data is an array of countries
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

//pincode
const fetchPincode= async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await fetch(`${base_url}api/PincodeMaster`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    setPincode(data); // Assuming data is an array of countries
  } catch (error) {
    console.error('Error fetching countries:', error);
  }
};

//post
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    // Determine API endpoint based on userType
  
    try {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      setFormData({
        ...formData,
        user_id: userId ? parseInt(userId) : 0, // Convert userId to number if available
      });
      const response = await fetch(`${base_url}api/TechnicianPersonalProfile`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      if (response.status === 200) {
        const result = await response.json();
        console.log('result',result);
        setIsLoading(false);

      } else {
        const result = await response.json();
        setIsLoading(false);
        alert(result.message)
        throw new Error('Failed to sign up');
        
      }
    } catch (error) {
      console.error('Error:', error);
      setIsLoading(false);
    }
  };

  //getchecktechnicianprofileexistornot
  const fetchtechnician = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${base_url}api/getchecktechnicianprofileexistornot`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      if (data.message === "Profile Already exist") {
        setFormData(() => ({
          ...data.result,
        }));
        setIsProfileExist(true);
      } else {
        setIsProfileExist(false);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching technician profile:', error);
      setIsLoading(false);
    }
  };

//put
const handleUpdate = async (e: React.FormEvent<HTMLFormElement>) => {
  e.preventDefault();
  setIsLoading(true);
  // Determine API endpoint based on userType

  try {
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    setFormData({
      ...formData,
      user_id: userId ? parseInt(userId) : 0, // Convert userId to number if available
    });
    const response = await fetch(`${base_url}api/TechnicianPersonalProfile`, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    if (response.status === 200) {
      const result = await response.json();
      console.log('result',result);
      await fetchtechnician();
    } else {
      const result = await response.json();
        alert(result.message)
        throw new Error('Failed to update profile');
    }
    setIsLoading(false);
  } catch (error) {
    console.error('Error:', error);
    setIsLoading(false);
  }
};

const validate = () => {
  let valid = true;
  const errors = { name_of_verification_id: '' };

  if (!fileData.name_of_verification_id) {
    errors.name_of_verification_id = 'Name of Verification Id is required';
    valid = false;
  }

  setErrors(errors);
  return valid;
};


const handleFileSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
  e.preventDefault();
  if (validate()) {
  try {
    const token = localStorage.getItem('token');
    // const userId = localStorage.getItem('userId');

    // Create FormData object
    const data = new FormData();
    data.append('name_of_verification_id', fileData.name_of_verification_id);
    data.append('attachment_1', fileData.attachment_1 || '');
    data.append('attachment_2', fileData.attachment_2 || '');
    data.append('attachment_3', fileData.attachment_3 || '');
    // if (fileData.attachment_1) {
    //   data.append('attachment_1', fileData.attachment_1);
    // }
    // if (fileData.attachment_2) {
    //   data.append('attachment_2', fileData.attachment_2);
    // }
    // if (fileData.attachment_3) {
    //   data.append('attachment_3', fileData.attachment_3);
    // }
    // Send POST request
    const response = await fetch(`${base_url}api/TechnicianUserId`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: data,
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    resetfile();
    fetchTechnicianUserId();
    const result = await response.json();
    console.log('Result:', result);

    // Optionally handle success feedback or state updates
  } catch (error) {
    console.error('Error:', error);
    // Optionally handle error feedback or state updates
  }
}
};

//update post
const handleFileUpdate = async (e: React.FormEvent<HTMLFormElement>) => {
  e.preventDefault();
  if (validate()) {
  try {
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');

    // Create FormData object
    const data = new FormData();
    data.append('name_of_verification_id', fileData.name_of_verification_id);
    if (editItemId) {
      data.append('id', editItemId);
    }
    if (userId) {
      data.append('user_id', userId);
    }
    data.append('attachment_1', fileData.attachment_1 || '');
    data.append('attachment_2', fileData.attachment_2 || '');
    data.append('attachment_3', fileData.attachment_3 || '');

    // Send POST request
    const response = await fetch(`${base_url}api/TechnicianUserIdupdate`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: data,
    });

    if (!response.ok) {
      await fetchTechnicianUserId();
      throw new Error('Network response was not ok');
      
    }
    resetfile();
    setEditMode(false);
    fetchTechnicianUserId();
    const result = await response.json();
    console.log('Result:', result);

    // Optionally handle success feedback or state updates
  } catch (error) {
    console.error('Error:', error);
    // Optionally handle error feedback or state updates
  }
}
};
const handleDeletefile = (item:any)=> {
  setfileShowModalDelete(true);
  setdeletefileId(item.id);
  setdeletefilename(item.name_of_verification_id);
};

const TechnicianUserIdDeletefile = async () => {

  try {
    const token = localStorage.getItem('token');
    // Send POST request
    const response = await fetch(`${base_url}api/TechnicianUserId/${deletefileId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      await fetchTechnicianUserId();
      throw new Error('Network response was not ok');
      
    }
    fetchTechnicianUserId();
    const result = await response.json();
    console.log('Result:', result);

    // Optionally handle success feedback or state updates
  } catch (error) {
    console.error('Error:', error);
    // Optionally handle error feedback or state updates
  }
};

//get api/TechnicianUserId 
const fetchTechnicianUserId= async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await fetch(`${base_url}api/TechnicianUserId`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    setverificationData(data.reverse()); // Assuming data is an array of countries
  } catch (error) {
    console.error('Error fetching countries:', error);
  }
};

//get api for skillset 
const TechnicianSkillSet = async () => {
  try {
    const token = localStorage.getItem('tech_token');
    const response = await fetch(`${base_url}api/TechnicianSkillSet`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    setSkillset(data.reverse()); // Assuming data is an array of countries
  } catch (error) {
    console.error('Error fetching countries:', error);
  }
};
//get api for skillset 
const skillsetDropdown = async () => {
  try {
    const token = localStorage.getItem('tech_token');
    const response = await fetch(`${base_url}api/SpecializationMasterList`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    setSkillsetdropdown(data); // Assuming data is an array of countries
  } catch (error) {
    console.error('Error fetching countries:', error);
  }
};
//get api for skillset 
const CurrencyMaster = async () => {
  try {
    const token = localStorage.getItem('tech_token');
    const response = await fetch(`${base_url}api/CurrencyMaster`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    setCurrency(data); // Assuming data is an array of countries
  } catch (error) {
    console.error('Error fetching countries:', error);
  }
};

const [formDataskillset, setFormDataskillset] = useState({
  specialization: '',
  upload_certificate_1: '',
  upload_certificate_2: '',
  upload_certificate_3: '',
  currency: '',
  wages: '',
  description: '',
  is_active: false,
  sub_specialization:'0',
  wages_type:""
});

const handleChange = (e:any) => {
  const { name, value, type, checked } = e.target;
  setFormDataskillset(prevState => ({
    ...prevState,
    [name]: type === 'checkbox' ? checked : value
  }));
};

const handleFileChange = (e:any) => {
  const { name, files } = e.target;
  setFormData({
    ...formData,
    [name]: files[0]
  });
};

const handleSubmitskillset = async (e:any) => {
  e.preventDefault();
  setIsLoading(true);
  const token = localStorage.getItem('tech_token');
  const data = new FormData();
  data.append('specialization', formDataskillset.specialization);
  data.append('upload_certificate_1', formDataskillset.upload_certificate_1);
  data.append('upload_certificate_2', formDataskillset.upload_certificate_2);
  data.append('upload_certificate_3', formDataskillset.upload_certificate_3);
  data.append('currency', formDataskillset.currency);
  data.append('wages', formDataskillset.wages);
  data.append('description', formDataskillset.description);
  data.append('is_active', formDataskillset.is_active ? '1' : '0');
  data.append('sub_specialization', '0');
  data.append('wages_type', '');
  try {
    const response = await axios.post(`${base_url}api/TechnicianSkillSet`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
      }
    });
    if (response.status === 200) {
      resetFormSkillset();
      setShowModal(false);
      TechnicianSkillSet();
      setIsLoading(false);
    }
    console.log('Response:', response.data);
  } catch (error) {
    console.error('Error:', error);
    setIsLoading(false);
  }

  setShowModal(false);
};
const resetFormSkillset = () => {
  setFormDataskillset({
    specialization: '',
    upload_certificate_1: '',
    upload_certificate_2: '',
    upload_certificate_3: '',
    currency: '',
    wages: '',
    description: '',
    is_active: false,
    sub_specialization: '0', // Default value
    wages_type: '' // Default value
  });
};

const handleDeleteClickskillset = (id:any) => {
  setSelectedTechnicianId(id);
  setShowDeleteModal(true);
};

const handleDeleteConfirm = async () => {
  const token = localStorage.getItem('tech_token');
  setIsLoading(true);
  if (selectedTechnicianId !== null) {
    try {
      const response = await axios.delete(`${base_url}api/TechnicianSkillSet/${selectedTechnicianId}`, {
        headers: {
          'Authorization': `Bearer ${token}` // assuming you store your token in localStorage
        }
      });
      console.log('Response:', response.data); // Call the onDelete function to update the UI
    } catch (error) {
      console.error('Error deleting technician:', error);
      setIsLoading(false);
    } finally {
      TechnicianSkillSet();
      setShowDeleteModal(false);
      setSelectedTechnicianId(null);
      setIsLoading(false);
    }
  }
};

const handleDeleteCancel = () => {
  setShowDeleteModal(false);
  setSelectedTechnicianId(null);
};

const handleEditChange = (e:any) => {
  const { name, value, type, files } = e.target;
  
  if (type === 'file') {
    setEditFormData((prevData) => ({
      ...prevData,
      [name]: files[0] // or `files` if handling multiple files
    }));
  } else {
    setEditFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  }
};

const handleEditClickSkillset = (technician:any) => {
  setEditFormData({
    specialization: technician.specialization || '',
    upload_certificate_1: technician.upload_certificate_1 || null,
    upload_certificate_2: technician.upload_certificate_2 || null,
    upload_certificate_3: technician.upload_certificate_3 || null,
    currency: technician.currency || '',
    wages: technician.wages || '',
    description: technician.description || '',
    is_active: technician.is_active || false,
    sub_specialization: technician.sub_specialization || '',
    wages_type: technician.wages_type || ''
  });
  setSelectedTechnicianId(technician.id);
  setSelectedTechnicianUserId(technician.user_id);
  setSelectedTechnicianStatus(technician.status);
    setShowEditModal(true);
};

const handleEditSubmit = async (e: any) => {
  e.preventDefault();
  setIsLoading(true);
  const data = new FormData();
  data.append('id', selectedTechnicianId);
  data.append('user_id', selectedTechnicianUserId);
  data.append('status', selectedTechnicianStatus);
  data.append('specialization', editFormData.specialization);
  data.append('upload_certificate_1', editFormData.upload_certificate_1);
  data.append('upload_certificate_2', editFormData.upload_certificate_2);
  data.append('upload_certificate_3', editFormData.upload_certificate_3);
  data.append('currency', editFormData.currency);
  data.append('wages', editFormData.wages);
  data.append('description', editFormData.description);
  data.append('is_active', editFormData.is_active ? '1' : '0');
  data.append('sub_specialization', '0');
  data.append('wages_type', '');

  try {
    const response = await axios.post(`${base_url}api/TechnicianSkillSetupdate`, data, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('tech_token')}`,
        'Content-Type': 'multipart/form-data'
      }
    });
    console.log('Response:', response.data);
    TechnicianSkillSet();
    setShowEditModal(false);
    setIsLoading(false);
  } catch (error) {
    console.error('Error updating technician:', error);
    setIsLoading(false);
  }
};

//get api/TechnicianExperience 
const TechnicianExperience= async () => {
  try {
    const token = localStorage.getItem('tech_token');
    const response = await fetch(`${base_url}api/TechnicianExperience`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    setexperienceData(data); // Assuming data is an array of countries
  } catch (error) {
    console.error('Error fetching countries:', error);
  }
};

//post
const handleSubmitExperience = async (e: React.FormEvent<HTMLFormElement>) => {
  e.preventDefault();
  setIsLoading(true);
  // Determine API endpoint based on userType

  try {
    const token = localStorage.getItem('tech_token');
    setFormDataexperience({
      ...formDataexperience,
    });
    
    const response = await fetch(`${base_url}api/TechnicianExperience`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formDataexperience),
    });

    if (response.status === 200) {
      const result = await response.json();
      console.log('result',result);
      setIsLoading(false);
      setexperienceModal(false);
      TechnicianExperience();
    } else {
      const result = await response.json();
      setIsLoading(false);
      alert(result.message)
      throw new Error('Failed to sign up');
      
    }
  } catch (error) {
    console.error('Error:', error);
    setIsLoading(false);
  }
};

//put experience
const updateTechnicianExperience = async (e: React.FormEvent<HTMLFormElement>) => {
  e.preventDefault();
  setIsLoading(true);
  // Determine API endpoint based on userType

  try {
    const token = localStorage.getItem('tech_token');
    setEditDataexperience({
      ...editDataexperience,

    });
    
    const response = await fetch(`${base_url}api/TechnicianExperience`, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(editDataexperience),
    });

    if (response.status === 200) {
      const result = await response.json();
      console.log('result',result);
      setIsLoading(false);
      TechnicianExperience();
      setexperienceModalEdit(false)
    } else {
      const result = await response.json();
      setIsLoading(false);
      alert(result.message)
      throw new Error('Failed to sign up');
      
    }
  } catch (error) {
    console.error('Error:', error);
    setIsLoading(false);
  }
};

const handleDeleteExperience = async (id:any) => {

  try {
    const token = localStorage.getItem('tech_token');
    // Send DELETE request
    const response = await fetch(`${base_url}api/TechnicianExperience/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      // await TechnicianExperience();
      throw new Error('Network response was not ok');
      
    }
    TechnicianExperience();
    const result = await response.json();
    console.log('Result:', result);

    // Optionally handle success feedback or state updates
  } catch (error) {
    console.error('Error:', error);
    // Optionally handle error feedback or state updates
  }
};

//get city list 
const techidlist= async () => {
  try {
    const token = localStorage.getItem('tech_token');
    const response = await fetch(`${base_url}api/techidlist`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    setCityData(data); // Assuming data is an array of countries
  } catch (error) {
    console.error('Error fetching countries:', error);
  }
};

const TechSerAreacreate = async (e: React.FormEvent<HTMLFormElement>) => {
  e.preventDefault();
  setIsLoading(true);
  const token = localStorage.getItem('tech_token');
    try {
      const response = await axios.post(`${base_url}api/TechSerAreacreate`, {
        city: parseInt(selectedCity),
      }, {
        headers: {
           'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
        }
      });

      if (response.status === 200) {
        setCityShowModal(false);
        setIsLoading(false);
        techidlist(); // Close modal after adding country
      } else {
        setIsLoading(false);
        console.error('Failed to add State.');
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error adding State:', error);
    }
};

const handleEditCity = (item:any) => {
  console.log('Selected city ID:', item.cityinfo.id); // Debug log
  setSelectedCityEdit({ id: item.cityinfo.id, name: item.cityinfo.city });
  setSelectedEditCity(item);
  setCityShowModalEdit(true);
};

const handleCityChange = (e:any) => {
  const selectedCityId = e.target.value;
  const selectedCity = cities.find((city:any) => city.id === selectedCityId);

  if (selectedCity) {
    setSelectedCityEdit({ id: selectedCityId, name: selectedCity.city });
  } else {
    setSelectedCityEdit({ id: selectedCityId, name: '' });
  }
};

const TechSerAreaupdate = async (e: React.FormEvent<HTMLFormElement>) => {
  e.preventDefault();
  setIsLoading(true);
  const token = localStorage.getItem('tech_token');
    try {
      const payload = {
        ...selectedEditCity,
        city: parseInt(selectedCityEdit.id)
        // Add other necessary fields here
      };
      const response = await axios.put(`${base_url}api/TechSerAreaupdate`, payload, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      // const response = await axios.put(`${base_url}api/TechSerAreaupdate`, {
      //   city:selectedCityEdit,
      //   selectedEditCity
      // }, {
      //   headers: {
      //      'Authorization': `Bearer ${token}`,
      //   'Content-Type': 'application/json'
      //   }
      // });

      if (response.status === 200) {
        setIsLoading(false);
        setCityShowModalEdit(false);
        techidlist(); // Close modal after adding country
      } else {
        setIsLoading(false);
        console.error('Failed to add State.');
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error adding State:', error);
    }
};

const TechSerAreadelete = async () => {

  try {
    const token = localStorage.getItem('tech_token');
    // Send DELETE request
    const response = await fetch(`${base_url}api/TechSerAreadelete/${selectedCityDeleteId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      // await TechnicianExperience();
      throw new Error('Network response was not ok');
      
    }
    techidlist();
    setCityShowModalDelete(false);
    const result = await response.json();
    console.log('Result:', result);

    // Optionally handle success feedback or state updates
  } catch (error) {
    console.error('Error:', error);
    // Optionally handle error feedback or state updates
  }
};

  return (
    <div className="page-wrapper">
    <div className="content container-fluid">
    {isLoading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <>
      {/* Page Header */}
      <div className="page-header">
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <h6 className="card-title">Profile</h6>
              <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <div className="col">
                          <span >
                            <img 
                              src={`${image_base_url}${users?.userprofileimage}`}
                              alt="Profile"
                              style={{width: '100px', height: '100px', borderRadius: '50%', objectFit: 'cover',}}
                            />
                                        {/* <button className="btn btn-primary btn-block mt-4" >Change Profile Images</button> */}
                          </span>
                        <button className="btn btn-primary btn-block mt-4" >Change Profile Image</button>
                      </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label>User id</label>
                            <input
                            type="userid"
                            id="emailInput"
                            className="form-control"
                            placeholder=""
                            value={users ? users.id : ''}
                            disabled={isDisabled}
                            />
                            <br />
                            <label>Joined date</label>
                            <input
                            type="text"
                            id="textField"
                            className="form-control"
                            placeholder=""
                            disabled={isDisabled}
                            />
                            <br />
                            <label>Badge</label>
                            <input
                            type="text"
                            id="textField"
                            className="form-control"
                            placeholder=""
                            disabled={isDisabled}
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label>Name</label>
                            <input
                            type="email"
                            id="emailInput"
                            className="form-control"
                            placeholder=""
                            value={users ? users.name : ''}
                            disabled={isDisabled}
                            />
                            <br />
                            <label>Email</label>
                            <input
                            type="text"
                            id="textField"
                            className="form-control"
                            placeholder=""
                            value={users ? users.email : ''}
                            disabled={isDisabled}
                            />
                        </div>
                    </div>
                </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="col-md-12">
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <div className="tab-list">
                <ul className="nav">
                  <li>
                    <Link
                      to="#"
                      className={activeTab === 'technician-profile' ? 'active' : ''}
                      onClick={() => setActiveTab('technician-profile')}
                    >
                      Technician Profile
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className={activeTab === 'skill-set' ? 'active' : ''}
                      onClick={() => setActiveTab('skill-set')}
                    >
                      Skill Set
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="#"
                      className={activeTab === 'city' ? 'active' : ''}
                      onClick={() => setActiveTab('city')}
                    >
                      City
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="tab-content pt-0">
            {activeTab === 'technician-profile' && (
              <div className="tab-pane fade show active" id="technician-profile">
                <div className="row">
                  <div className="col-md-12">
                    <div className="tab-list">
                      <ul className="nav">
                        <li>
                          <Link
                            to="#"
                            className={activeSubTab === 'personal-details' ? 'active' : ''}
                            onClick={() => setActiveSubTab('personal-details')}
                          >
                            Personal Details
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="#"
                            className={activeSubTab === 'user-verify' ? 'active' : ''}
                            onClick={() => setActiveSubTab('user-verify')}
                          >
                            User Id Verification
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="#"
                            className={activeSubTab === 'experience' ? 'active' : ''}
                            onClick={() => setActiveSubTab('experience')}
                          >
                            Experience
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="tab-content pt-0">
                  {activeSubTab === 'personal-details' && (
                    <div className="tab-pane fade show active" id="personal-details">
                      <div className="container">
                        <form onSubmit={isProfileExist ? handleUpdate : handleSubmit}>
                        <div className="row align-items-center">
                          <div className="col-md-3">
                            <label htmlFor="userId" className="form-label">Gender</label>
                          </div>
                          <div className="col-md-3">
                          <select id="gender" className="form-control" onChange={(e) => setFormData({ ...formData, gender: e.target.value })}>
                            <option value=""></option>
                            <option value="Female">Female</option>
                            <option value="Male">Male</option>
                            </select>
                          </div>
                          <div className="col-md-3">
                            <label htmlFor="dob" className="form-label">Date of Birth</label>
                          </div>
                          <div className="col-md-3">
                            <input type="date" id="dob" className="form-control" placeholder="" value={formData.date_of_birth} onChange={(e) => setFormData({ ...formData, date_of_birth: e.target.value })} />
                          </div>
                        </div>
                        <br />
                        <div className="row align-items-center">
                          <div className="col-md-3">
                            <label htmlFor="mobile" className="form-label">Mobile</label>
                          </div>
                          <div className="col-md-3">
                            <input type="text" id="mobile" className="form-control" placeholder="" value={formData.mobile} onChange={(e) => setFormData({ ...formData, mobile: e.target.value })} />
                          </div>
                          <div className="col-md-3">
                            <label htmlFor="education" className="form-label">Education</label>
                          </div>
                          <div className="col-md-3">
                            <input type="text" id="education" className="form-control" placeholder="" value={formData.education} onChange={(e) => setFormData({ ...formData, education: e.target.value })} />
                          </div>
                        </div>
                        <br />
                        <div className="row align-items-center">
                          <div className="col-md-3">
                            <label htmlFor="occupation" className="form-label">Current Occupation</label>
                          </div>
                          <div className="col-md-3">
                            <input type="text" id="occupation" className="form-control" placeholder="" value={formData.current_occupation} onChange={(e) => setFormData({ ...formData, current_occupation: e.target.value })} />
                          </div>
                          <div className="col-md-3">
                            <label htmlFor="status" className="form-label">Marital Status</label>
                          </div>
                          <div className="col-md-3">
                            {/* <input type="text" id="status" className="form-control" placeholder="" /> */}
                            <select id="status" className="form-control" onChange={(e) => setFormData({ ...formData, marital_status: e.target.value })}>
                                <option value=""></option>
                                <option value="Single">Single</option>
                                <option value="Married">Married</option>
                            </select>
                          </div>
                        </div>
                        <br />
                        <div className="row align-items-center">
                          <div className="col-md-3">
                            <label htmlFor="language" className="form-label">Language Known</label>
                          </div>
                          <div className="col-md-3">
                            <input type="text" id="language" className="form-control" placeholder="" value={formData.language_known} onChange={(e) => setFormData({ ...formData, language_known: e.target.value })}  />
                          </div>
                          <div className="col-md-3">
                            <label htmlFor="address" className="form-label">Communication Address</label>
                          </div>
                          <div className="col-md-3">
                            <input type="text" id="address" className="form-control" placeholder="" value={formData.communication_address} onChange={(e) => setFormData({ ...formData, communication_address: e.target.value })} />
                          </div>
                        </div>
                        <br />
                        <div className="row align-items-center">
                          <div className="col-md-3">
                            <label htmlFor="country" className="form-label">Country</label>
                          </div>
                          <div className="col-md-3">
                          <select id="country" className="form-control" onChange={(e:any) => setFormData({ ...formData, country: parseInt(e.target.value) })} >
                          <option value=""></option>
                              {countries.map((country: any) => (
                                <option key={country.id} value={country.id}>
                                  {country.country}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col-md-3">
                            <label htmlFor="state" className="form-label">State</label>
                          </div>
                          <div className="col-md-3">
                          {/* <input type="text" id="state" className="form-control" placeholder="" />  */}
                          <select id="state" className="form-control" onChange={(e:any) => setFormData({ ...formData, state: parseInt(e.target.value) })}>
                          <option value=""></option>
                          {states.map((state: any) => (
                                <option key={state.id} value={state.id}>
                                  {state.state}
                                </option>
                              ))}
                              </select>
                          </div>
                        </div>
                        <br />
                        <div className="row align-items-center">
                          <div className="col-md-3">
                            <label htmlFor="city" className="form-label">City</label>
                          </div>
                          <div className="col-md-3">
                          {/* <input type="text" id="city" className="form-control" placeholder="" /> */}
                          <select id="city" className="form-control" onChange={(e:any) => setFormData({ ...formData, city: parseInt(e.target.value) })}>
                          <option value=""></option>
                          {cities.map((city: any) => (
                                <option key={city.id} value={city.id}>
                                  {city.city}
                                </option>
                              ))}
                              </select>
                          </div>
                          <div className="col-md-3">
                            <label htmlFor="pincode" className="form-label">Pincode</label>
                           
                          </div>
                          <div className="col-md-3">
                            {/* <input type="text" id="pincode" className="form-control" placeholder="" value={formData.pincode} onChange={(e) => setFormData({ ...formData, pincode: e.target.value })}  /> */}
                            <select id="city" className="form-control" onChange={(e:any) => setFormData({ ...formData, pincode: e.target.value })}>
                          <option value=""></option>
                          {pincode.map((pin: any) => (
                                <option key={pin.id} value={pin.id}>
                                  {pin.city}
                                </option>
                              ))}
                              </select>
                          </div>
                        </div>
                        {/* <button className="btn btn-primary">Submit</button> */}
                        <button className="btn btn-primary" type="submit">
        {isProfileExist ? 'Update' : 'Submit'}
      </button>
                        </form>
                      </div>
                    </div>
                  )}
                  {activeSubTab === 'user-verify' && (
                    <div className="tab-pane fade show active" id="user-verify">
                      <div className="container">
                        <form onSubmit={editMode ? handleFileUpdate : handleFileSubmit}>
                          <div className="mb-3">
                          <div className="col-md-5">
                            <label htmlFor="verificationId" className="form-label">Name Of Verification Id</label>
                            <input type="text"  className={`form-control ${errors.name_of_verification_id ? 'is-invalid' : ''}`} id="verificationId" placeholder="Enter verification ID" value={fileData.name_of_verification_id}
                    onChange={(e) => setFileData({ ...fileData, name_of_verification_id: e.target.value })} />
                    {errors.name_of_verification_id && (
            <div className="invalid-feedback">{errors.name_of_verification_id}</div>
          )}
                          </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-md-4">
                              <input type="file" className="form-control" id="file1" value={fileData.attachment_1}
                    onChange={(e) => setFileData({ ...fileData, attachment_1: e.target.value })}/>
                            </div>
                            <div className="col-md-4">
                              <input type="file" className="form-control" id="file2" value={fileData.attachment_2}
                    onChange={(e) => setFileData({ ...fileData, attachment_2: e.target.value })}/>
                            </div>
                            <div className="col-md-4">
                              <input type="file" className="form-control" id="file3" value={fileData.attachment_3}
                    onChange={(e) => setFileData({ ...fileData, attachment_3: e.target.value })}/>
                            </div>
                          </div>
                          <div className="d-flex justify-content-center">
                          <button type="submit" className="btn btn-primary ">
                            {editMode ? 'Update' : 'Add'}
                          </button>
                          {editMode && (
                            <button className="btn btn-warning ms-2" onClick={resetfile}>Reset</button>
                          )}
                          </div>
                        </form>
                        <br />
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Name Of Verification Id</th>
                              <th>Attachment 1</th>
                              <th>Attachment 2</th>
                              <th>Attachment 3</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                          {verificationData.map((item : any) => (
                            <tr key={item.id}>
                              <td>{item.name_of_verification_id}</td>
                              <td>
                                <img src={`${base_url}${item.attachment_1}`} alt="Attachment 1" style={{ maxWidth: '100px' }} />
                              </td>
                              <td>
                                <img src={`${base_url}${item.attachment_2}`} alt="Attachment 2" style={{ maxWidth: '100px' }} />
                              </td>
                              <td>
                                <img src={`${base_url}${item.attachment_3}`} alt="Attachment 3" style={{ maxWidth: '100px' }} />
                              </td>
                              <td>
                              <button className="btn btn-sm btn-success"  onClick={() => handleEditClick(item)}><FontAwesomeIcon icon={faEdit} /></button>
                              <button className="btn btn-sm btn-danger"><FontAwesomeIcon icon={faTrash} onClick={() => handleDeletefile(item)} /></button>
                              </td>
                            </tr>
                          ))}
                          </tbody>
                        </table>
                      </div>
                       {/* Delete Confirmation Modal */}
<div
        className={`modal fade ${fileShowModalDelete ? 'show' : ''}`}
        id="delete-city"
        style={{ display: fileShowModalDelete ? 'block' : 'none' }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Delete City</h5>
              <button type="button" className="btn-close" onClick={() => setfileShowModalDelete(false)} aria-label="Close"></button>
            </div>
            <div className="modal-body">
            <p>Are you sure you want to delete &quot;{deletefilename}&quot;?</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-danger" onClick={() => TechnicianUserIdDeletefile()}>Delete</button>
              <button type="button" className="btn btn-secondary" onClick={() => setfileShowModalDelete(false)}>Cancel</button>
            </div>
          </div>
        </div>
      </div>
                    </div>
                  )}

                  {activeSubTab === 'experience' && (
                    <div className="tab-pane fade show active" id="experience">
                      {/* Content for Experience */}
                      <div className='container'>
                      <button className="btn btn-primary" onClick={() => setexperienceModal(true)}>Add Experience</button>
                      <div className="row mt-4">
              {experienceData.map((exp:any,index:number) => (
                <div className="col-md-6 mb-4" key={exp.id}>
                  <div className="card">
                    <div className="card-body">
                    <p className="card-text"><strong>S No:</strong> {index+1}</p>
                      <p className="card-text"><strong>Company Name:</strong> {exp.company_name?exp.company_name:'-'}</p>
                      <p className="card-text"><strong>Department:</strong> {exp.department?exp.department:'-'}</p>
                      <p className="card-text"><strong>Designation:</strong> {exp.designation?exp.designation:'-'}</p>
                      <p className="card-text"><strong>Experience:</strong> {exp.experience?exp.experience:'-'}</p>
                      <p className="card-text"><strong>Others:</strong> {exp.others?exp.others:'-'}</p>
                      <p className="card-text">
                        <strong>Action:</strong>
                        <div className="d-flex mt-2">
                          <button className="btn btn-primary me-2" onClick={() => handleEditClickExperience(exp)}><Icon.Edit className="react-feather-custom" /></button>
                          <button className="btn btn-danger"><Icon.Trash2 className="react-feather-custom"  onClick={() => handleDeleteExperience(exp.id)}/></button>
                        </div>
                      </p>

                    </div>
                  </div>
                </div>
              ))}
            </div>
                      </div>
                    </div>
                  )}
                  <Modal show={experienceModal} onHide={() => setexperienceModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Add Experience</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmitExperience}>
          <Col>
          <Form.Group controlId="formCompanyname">
                <Form.Label>Company Name</Form.Label>
                <Form.Control type="text" name="companyname" value={formDataexperience.company_name} onChange={(e) => setFormDataexperience({ ...formDataexperience, company_name: e.target.value })} placeholder="" />
              </Form.Group>
              <Form.Group controlId="formDepartment">
                <Form.Label>Department</Form.Label>
                <Form.Control type="text" name="Department" value={formDataexperience.department} onChange={(e) => setFormDataexperience({ ...formDataexperience, department: e.target.value })} placeholder="" />
              </Form.Group>
              <Form.Group controlId="formDesignation">
                <Form.Label>Designation</Form.Label>
                <Form.Control type="text" name="designation" value={formDataexperience.designation} onChange={(e) => setFormDataexperience({ ...formDataexperience, designation: e.target.value })} placeholder="" />
              </Form.Group>
              <Form.Group controlId="formExperience">
                <Form.Label>Experience(years)</Form.Label>
                <Form.Control type="text" name="experience" value={formDataexperience.experience} onChange={(e) => setFormDataexperience({ ...formDataexperience, experience: e.target.value })} placeholder="" />
              </Form.Group>
              <Form.Group controlId="formOther">
                <Form.Label>Other</Form.Label>
                <Form.Control type="text" name="other" value={formDataexperience.others} onChange={(e) => setFormDataexperience({ ...formDataexperience, others: e.target.value })} placeholder="" />
              </Form.Group>
          </Col>
          <Modal.Footer>
            <Button variant="outline-primary" type="submit">
              Add Experience
            </Button>
            <Button variant="outline-warning" type="reset" >
              Reset
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>

    <Modal show={experienceModalEdit} onHide={() => setexperienceModalEdit(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Update Experience</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form  onSubmit={() => updateTechnicianExperience}>
          <Col>
          <Form.Group controlId="formCompanyname">
                <Form.Label>Company Name</Form.Label>
                <Form.Control type="text" name="companyname" value={editDataexperience.company_name} onChange={(e) => setEditDataexperience({ ...editDataexperience, company_name: e.target.value })} placeholder="" />
              </Form.Group>
              <Form.Group controlId="formDepartment">
                <Form.Label>Department</Form.Label>
                <Form.Control type="text" name="Department" value={editDataexperience.department} onChange={(e) => setEditDataexperience({ ...editDataexperience, department: e.target.value })} placeholder="" />
              </Form.Group>
              <Form.Group controlId="formDesignation">
                <Form.Label>Designation</Form.Label>
                <Form.Control type="text" name="designation" value={editDataexperience.designation} onChange={(e) => setEditDataexperience({ ...editDataexperience, designation: e.target.value })} placeholder="" />
              </Form.Group>
              <Form.Group controlId="formExperience">
                <Form.Label>Experience(years)</Form.Label>
                <Form.Control type="text" name="experience" value={editDataexperience.experience} onChange={(e) => setEditDataexperience({ ...editDataexperience, experience: e.target.value })} placeholder="" />
              </Form.Group>
              <Form.Group controlId="formOther">
                <Form.Label>Other</Form.Label>
                <Form.Control type="text" name="other" value={editDataexperience.others} onChange={(e) => setEditDataexperience({ ...editDataexperience, others: e.target.value })} placeholder="" />
              </Form.Group>
          </Col>
          <Modal.Footer>
            <Button variant="outline-primary" type='submit'>
              Update Experience
            </Button>
            <Button variant="outline-warning" type="reset" >
              Reset
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
                </div>
              </div>
            )}
            {activeTab === 'skill-set' && (
              <div className="tab-pane fade show active" id="skill-set">
                <button className="btn btn-primary" onClick={() => setShowModal(true)}>Add Specialization</button>
                <div className="table-responsive">
      <table className="table ">
        <thead>
          <tr>
            <th>Specialization</th>
            <th>Sub-Specialization</th>
            <th>Certificate Upload 1</th>
            <th>Certificate Upload 2</th>
            <th>Certificate Upload 3</th>
            <th>Wages Type</th>
            <th>Currency</th>
            <th>Wages</th>
            <th>Description</th>
            <th>Is Active</th>
            <th>Status</th>
            <th>Edit</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {skillset.map((technician:any) => (
            <tr key={technician.id}>
              <td>{technician.specializationinfo.specialization}</td>
              <td>{technician.subspecializationinfo?.sub_specialization ? technician.subspecializationinfo.sub_specialization : '-'}</td>
              <td>
                {technician.upload_certificate_1 && (
                  // <ImageWithBasePath src={technician.upload_certificate_1} height={50} />
                  <img
                    src={`${base_url}${technician.upload_certificate_1}`}
                    alt="Certificate 1"
                    style={{ maxHeight: '50px' }}
                  />
                )}
              </td>
              <td>
                {technician.upload_certificate_2 && (
                  <img
                    src={`${base_url}${technician.upload_certificate_2}`}
                    alt="Certificate 2"
                    style={{ maxHeight: '50px' }}
                  />
                )}
              </td>
              <td>
                {technician.upload_certificate_3 && (
                 <img
                 src={`${base_url}${technician.upload_certificate_3 }`}
                 alt="Certificate 3"
                 style={{ maxHeight: '50px' }}
               />
                )}
              </td>
              <td>{technician.wages_type}</td>
              <td>{technician.currencyinfo?.currency}</td>
              <td>{technician.wages}</td>
              <td>{technician.description}</td>
              <td>{technician.is_active}</td>
              <td>{technician.status === 1 ? "Approved" : technician.status === 2 ? "Rejected" : "Pending"}</td>
              <td>
                <button className="btn btn-primary" onClick={() => handleEditClickSkillset(technician)}><Icon.Edit className="react-feather-custom" /></button>
              </td>
              <td>
                <button
                  className="btn btn-danger"
                  onClick={() => handleDeleteClickskillset(technician.id)}
                >
                  <Icon.Trash2 className="react-feather-custom" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    <Modal show={showModal} onHide={() => setShowModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Skill Set</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Choose Your Skill Set</p>
        <p style={{ color: 'green' }}>[Status: Rejected Skill Set]</p>
        <Form onSubmit={handleSubmitskillset}>
          <Row>
            <Col>
              <Form.Group controlId="formSpecialization">
                <Form.Label>Specialization</Form.Label>
                <Form.Control as="select" name="specialization" value={formDataskillset.specialization} onChange={handleChange}>
                  <option value=""></option>
                  {skillsetdropdown.map((specialization:any) => (
                    <option key={specialization.id} value={specialization.id}>
                      {specialization.specialization}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formUploadCertificate1">
                <Form.Label>Upload Certificate 1</Form.Label>
                <Form.Control type="file" name="upload_certificate_1" onChange={handleFileChange} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="formUploadCertificate2">
                <Form.Label>Upload Certificate 2</Form.Label>
                <Form.Control type="file" name="upload_certificate_2" onChange={handleFileChange} />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formUploadCertificate3">
                <Form.Label>Upload Certificate 3</Form.Label>
                <Form.Control type="file" name="upload_certificate_3" onChange={handleFileChange} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="formCurrency">
                <Form.Label>Currency</Form.Label>
                <Form.Control as="select" name="currency" value={formDataskillset.currency} onChange={handleChange}>
                  <option value=""></option>
                  {currency.map((curr:any) => (
                    <option key={curr.id} value={curr.id}>
                      {curr.currency}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formWages">
                <Form.Label>Wages</Form.Label>
                <Form.Control type="text" name="wages" value={formDataskillset.wages} onChange={handleChange} placeholder="Enter wages" />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="formDescription">
                <Form.Label>Description</Form.Label>
                <Form.Control type="text" name="description" value={formDataskillset.description} onChange={handleChange} placeholder="Enter description" />
              </Form.Group>
            </Col>
            <Col>
              <br />
              <Form.Group controlId="formIsActive">
                <Form.Check type="checkbox" name="is_active" checked={formDataskillset.is_active} onChange={handleChange} label="Is Active" />
              </Form.Group>
            </Col>
          </Row>
          <Modal.Footer>
            <Button variant="outline-primary" type="submit" onClick={() => handleSubmitskillset}>
              Add Specialization
            </Button>
            <Button variant="outline-warning" type="reset" onClick={() =>resetFormSkillset}>
              Reset
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>

     {/* Delete Confirmation Modal */}
     <Modal show={showDeleteModal} onHide={handleDeleteCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete &quot;{selectedTechnicianId}&quot;?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleDeleteConfirm}>
            Delete
          </Button>
          <Button variant="secondary" onClick={handleDeleteCancel}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

        {/* Edit Technician Modal */}
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Skill Set</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleEditSubmit}>
            <Row>
              <Col>
              <Form.Group controlId="formSpecialization">
                <Form.Label>Specialization</Form.Label>
                <Form.Control
                as="select"
                name="specialization"
                value={editFormData.specialization}
                onChange={handleEditChange}
                >
                <option value=""></option>
                {skillsetdropdown.map((specialization:any) => (
                <option key={specialization.id} value={specialization.id}>
                  {specialization.specialization}
                </option>
               ))}
              </Form.Control>
              </Form.Group>
              </Col>
                <Col>
                <Form.Group controlId="formUploadCertificate1">
                  <Form.Label>Upload Certificate 1</Form.Label>
                  <Form.Control
                    type="file"
                    name="upload_certificate_1"
                    onChange={handleEditChange}
                  />
                </Form.Group>
                </Col>
                </Row>
                <Row>
                  <Col>
                <Form.Group controlId="formUploadCertificate2">
                  <Form.Label>Upload Certificate 2</Form.Label>
                  <Form.Control
                    type="file"
                    name="upload_certificate_2"
                    onChange={handleEditChange}
                  />
                </Form.Group>
                </Col>
                <Col>
                <Form.Group controlId="formUploadCertificate3">
                  <Form.Label>Upload Certificate 3</Form.Label>
                  <Form.Control
                    type="file"
                    name="upload_certificate_3"
                    onChange={handleEditChange}
                  />
                </Form.Group>
                </Col>
                </Row>
                <Row>
                  <Col>
                  <Form.Group controlId="formCurrency">
                    <Form.Label>Currency</Form.Label>
                    <Form.Control as="select"
                    name="currency"
                    value={editFormData.currency}
                    onChange={handleEditChange}
                    >
                      <option value=""></option>
                      {currency.map((currencyOption:any) => (
                        <option key={currencyOption.id} value={currencyOption.id}>
                          {currencyOption.currency}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  </Col>
                <Col>
                <Form.Group controlId="formWages">
                  <Form.Label>Wages</Form.Label>
                  <Form.Control
                    type="text"
                    name="wages"
                    value={editFormData.wages}
                    onChange={handleEditChange}
                    placeholder="Enter wages"
                  />
                </Form.Group>
                </Col>
                </Row>
                <Row>
                  <Col>
                <Form.Group controlId="formDescription">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    type="text"
                    name="description"
                    value={editFormData.description}
                    onChange={handleEditChange}
                    placeholder="Enter description"
                  />
                </Form.Group>
                </Col>
                <Col>
                <Form.Group controlId="formIsActive">
                  <Form.Check
                    type="checkbox"
                    label="Is Active"
                    name="is_active"
                    checked={editFormData.is_active}
                    onChange={handleEditChange}
                  />
                </Form.Group>
                </Col>
                </Row>
            <Modal.Footer>
            <Button variant="outline-primary" type="submit">
                Update Specialization
              </Button>
              <Button variant="outline-warning" onClick={() => setShowEditModal(false)}>
                Reset
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
              </div>
              
            )}
            {activeTab === 'city' && (
              <div className="tab-pane fade show active" id="city">
                {/* Content for City */}
                <div className='container'>
                      <button className="btn btn-primary"
                       onClick={() => setCityShowModal(true)}
                       >Add City</button>
                      <div className="row mt-4">
                      <table className="table">
                          <thead>
                            <tr>
                              <th>City</th>
                              <th>Edit</th>
                              <th>Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                          {cityData.map((item : any) => (
                            <tr key={item.id}>
                              <td>{item.cityinfo?.city}</td>
                              <td>
                              <button className="btn btn-sm btn-success"  onClick={() => handleEditCity(item)}><FontAwesomeIcon icon={faEdit} /></button>
                              </td>
                              <td>
                              <button className="btn btn-sm btn-danger"><FontAwesomeIcon icon={faTrash} onClick={() => handleDeleteCity(item)} /></button>
                              </td>
                            </tr>
                          ))}
                          </tbody>
                        </table>
            </div>
                      </div>
              </div>
            )}
          </div>
        </div>
      </div>


      <Modal show={cityShowModal} onHide={() => setCityShowModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Add City</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={TechSerAreacreate}>
          <Col>
          <p>Choose your city</p>
          <Form.Group>
      <Form.Label>City</Form.Label>
      <Form.Control
        as="select"
        name="city"
        value={selectedCity}
        onChange={(e) => setSelectedCity(e.target.value)}
      >
        <option value=""></option>
        {cities.map((city:any) => (
          <option key={city.id} value={city.id}>{city.city}</option>
        ))}
      </Form.Control>
    </Form.Group>
          </Col>
          <Modal.Footer>
            <Button variant="outline-primary" type='submit' >
              Add City
            </Button>
            <Button variant="outline-warning" type="reset" >
              Reset
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>

{/* Edit city */}
    <Modal show={cityShowModalEdit} onHide={() => setCityShowModalEdit(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Update City</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={TechSerAreaupdate}>
          <Col>
          <p>Choose your city</p>
          <Form.Group>
                <Form.Label>City</Form.Label>
                <Form.Control
                  as="select"
                  name="city"
                  value={selectedCityEdit.id}
                  onChange={handleCityChange}
                >
                  <option value=""></option>
                  {cities.map((city:any) => (
                    <option key={city.id} value={city.id}>{city.city}</option>
                  ))}
                </Form.Control>
              </Form.Group>
          </Col>
          <Modal.Footer>
            <Button variant="outline-primary" type='submit'>
              Update City
            </Button>
            <Button variant="outline-warning" type="reset" >
              Reset
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>


    {/* Delete Confirmation Modal */}
<div
        className={`modal fade ${cityShowModalDelete ? 'show' : ''}`}
        id="delete-city"
        style={{ display: cityShowModalDelete ? 'block' : 'none' }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Delete City</h5>
              <button type="button" className="btn-close" onClick={() => setCityShowModalDelete(false)} aria-label="Close"></button>
            </div>
            <div className="modal-body">
            <p>Are you sure you want to delete &quot;{selectedDeleteCityName}&quot;?</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-danger" onClick={() => TechSerAreadelete()}>Delete</button>
              <button type="button" className="btn btn-secondary" onClick={() => setCityShowModalDelete(false)}>Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
        )}
    </div>
  </div>
  )
}

export default HomeScreenProvider




// import React, { useEffect, useState } from 'react'
// import * as Icon from 'react-feather'
// import ImageWithBasePath from '../../../../core/img/ImageWithBasePath'
// import { Link } from 'react-router-dom';
// import { all_routes } from '../../../../core/data/routes/all_routes';
// import { base_url } from '../../../../constant';
// import PageLoader from '../../../../core/loader';

// const routes = all_routes;

// const HomeScreenProvider = () => {
//     const [today, settoday] = useState([]);
//     const [tomorrow, settomorrow] = useState([]);
//     const [dayafter, setdayafter] = useState([]);
//     const [isLoading, setIsLoading] = useState(false);

//     useEffect(() => {
//         gettechorderOnDate();
//         gettomorrowtechorders();
//         getdayaftertomorroworders();
//     }, []);

//     const formatDate = (date:any) => {
//         const day = String(date.getDate()).padStart(2, '0');
//         const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
//         const year = date.getFullYear();
//         return `${day}/${month}/${year}`;
//       };

//       const todayDate = new Date();
//       const tomorrowDate = new Date();
//       tomorrowDate.setDate(todayDate.getDate() + 1);
//       const dayAfterTomorrow = new Date();
//       dayAfterTomorrow.setDate(todayDate.getDate() + 2);

//     const gettechorderOnDate = async () => {
//         setIsLoading(true);
//         try {
//           const token = localStorage.getItem('token');
//           const response = await fetch(`${base_url}api/gettechorderOnDate`, {
//             method: 'GET',
//             headers: {
//               'Authorization': `Bearer ${token}`,
//               'Content-Type': 'application/json'
//             }
//           });
//           if (!response.ok) {
//             setIsLoading(false);
//             throw new Error('Network response was not ok');
//           }
//           const data = await response.json();
//           setIsLoading(false);
//           settoday(data); // Assuming data is an array of countries
//         } catch (error) {
//             setIsLoading(false);
//           console.error('Error fetching countries:', error);
//         }
//       };

//       const gettomorrowtechorders = async () => {
//         setIsLoading(true);
//         try {
//           const token = localStorage.getItem('token');
//           const response = await fetch(`${base_url}api/gettomorrowtechorders`, {
//             method: 'GET',
//             headers: {
//               'Authorization': `Bearer ${token}`,
//               'Content-Type': 'application/json'
//             }
//           });
//           if (!response.ok) {
//             setIsLoading(false);
//             throw new Error('Network response was not ok');
//           }
//           const data = await response.json();
//           setIsLoading(false);
//           settomorrow(data); // Assuming data is an array of countries
//         } catch (error) {
//             setIsLoading(false);
//           console.error('Error fetching countries:', error);
//         }
//       };

//       const getdayaftertomorroworders = async () => {
//         setIsLoading(true);
//         try {
//           const token = localStorage.getItem('token');
//           const response = await fetch(`${base_url}api/getdayaftertomorroworders`, {
//             method: 'GET',
//             headers: {
//               'Authorization': `Bearer ${token}`,
//               'Content-Type': 'application/json'
//             }
//           });
//           if (!response.ok) {
//             setIsLoading(false);
//             throw new Error('Network response was not ok');
//           }
//           const data = await response.json();
//           setIsLoading(false);
//           setdayafter(data); // Assuming data is an array of countries
//         } catch (error) {
//             setIsLoading(false);
//           console.error('Error fetching countries:', error);
//         }
//       };


//     return (
//         <>
//         {isLoading ? (
//           <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
//             <div className="spinner-border text-primary" role="status">
//               <span className="visually-hidden">Loading...</span>
//             </div>
//           </div>
//         ) : (
//             <>
//         <div className="page-wrapper">
//         <div className="content container-fluid">
//             {/* /Page Header */}
//       <div className="row">
//         <div className="col-md-12">
//           <div className="tab-list">
//             <ul className="nav">
//               <li>
//                 <Link to="#" className="active" data-bs-toggle="tab" data-bs-target="#today-orders">Today Orders</Link>
//               </li>
//               <li>
//                 <Link to="#" data-bs-toggle="tab" data-bs-target="#tomorrow-orders">Tomorrow Orders</Link>
//               </li>
//               <li>
//                 <Link to="#" data-bs-toggle="tab" data-bs-target="#dayafter-tomorrow">Day After Tomorrow Orders</Link>
//               </li>
//             </ul>
//           </div>
//         </div>
//       </div>

//       <div className="tab-content pt-0">				
//         <div className="tab-pane active" id="today-orders">				
//           <div className="row">	
//             {/* content today order */}
//             <div className='card'>
//                 <br />
//                 <h6>View Orders</h6>
//                 <p>Today Date: {formatDate(todayDate)} & Orders Count: {today.length}</p>
//                 <div className="table-responsive">
//                     <table className="table">
//                         <thead>
//                             <tr>
//                               <th>View</th>
//                               <th>Details</th>
//                               <th>Process Status</th>
//                               <th>Issue Title</th>
//                               <th>Specialization</th>
//                               <th>Sub Specialization</th>
//                               <th>Address_on</th>
//                               <th>Contact Number</th>
//                               <th>Technician Name</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                           {today.map((item : any) => (
//                             <tr key={item.id}>
//                               <td>{item.view}</td>
//                               <td>{item.cityinfo?.city}</td>
//                               <td>{item.cityinfo?.city}</td>
//                               <td>{item.cityinfo?.city}</td>
//                               <td>{item.cityinfo?.city}</td>
//                               <td>{item.cityinfo?.city}</td>
//                               <td>{item.cityinfo?.city}</td>
//                               <td>{item.cityinfo?.city}</td>
//                               <td>{item.cityinfo?.city}</td>
//                             </tr>
//                           ))}
//                           </tbody>
//                         </table>
//                         </div>
//             </div>
//           </div>
//           </div>
//           <div className="tab-pane fade" id="tomorrow-orders">				
//           <div className="row">
//           {/* content tomorrow order	 */}
//           <div className='card'>
//                 <br />
//                 <h6>View Orders</h6>
//                 <p>Tomorrow Date: {formatDate(tomorrowDate)} & Orders Count: {tomorrow.length}</p>
//                 <div className="table-responsive">
//                     <table className="table">
//                         <thead>
//                             <tr>
//                               <th>View</th>
//                               <th>Details</th>
//                               <th>Process Status</th>
//                               <th>Issue Title</th>
//                               <th>Specialization</th>
//                               <th>Sub Specialization</th>
//                               <th>Address_on</th>
//                               <th>Contact Number</th>
//                               <th>Technician Name</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                           {tomorrow.map((item : any) => (
//                             <tr key={item.id}>
//                               <td>{item.view}</td>
//                               <td>{item.cityinfo?.city}</td>
//                               <td>{item.cityinfo?.city}</td>
//                               <td>{item.cityinfo?.city}</td>
//                               <td>{item.cityinfo?.city}</td>
//                               <td>{item.cityinfo?.city}</td>
//                               <td>{item.cityinfo?.city}</td>
//                               <td>{item.cityinfo?.city}</td>
//                               <td>{item.cityinfo?.city}</td>
//                             </tr>
//                           ))}
//                           </tbody>
//                         </table>
//                         </div>
//             </div>
//           </div>
//           </div>
//           <div className="tab-pane fade" id="dayafter-tomorrow">				
//           <div className="row">
//           {/* content day after tomorrow order	 */}
//           <div className='card'>
//             <br />
//                 <h6>View Orders</h6>
//                 <p>Day After Tomorrow Date: {formatDate(dayAfterTomorrow)} & Orders Count: {dayafter.length}</p>
//                 <div className="table-responsive">
//                     <table className="table">
//                         <thead>
//                             <tr>
//                               <th>View</th>
//                               <th>Details</th>
//                               <th>Process Status</th>
//                               <th>Issue Title</th>
//                               <th>Specialization</th>
//                               <th>Sub Specialization</th>
//                               <th>Address_on</th>
//                               <th>Contact Number</th>
//                               <th>Technician Name</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                           {dayafter.map((item : any) => (
//                             <tr key={item.id}>
//                               <td>{item.view}</td>
//                               <td>{item.cityinfo?.city}</td>
//                               <td>{item.cityinfo?.city}</td>
//                               <td>{item.cityinfo?.city}</td>
//                               <td>{item.cityinfo?.city}</td>
//                               <td>{item.cityinfo?.city}</td>
//                               <td>{item.cityinfo?.city}</td>
//                               <td>{item.cityinfo?.city}</td>
//                               <td>{item.cityinfo?.city}</td>
//                             </tr>
//                           ))}
//                           </tbody>
//                         </table>
//                         </div>
//             </div>
//           </div>
//           </div>
//           </div>			
//     </div>
//     </div>
//     </>
// )}
// </>
//     )
// }

// export default HomeScreenProvider