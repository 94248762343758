import React, { useState, useEffect } from 'react';
import * as Icon from 'react-feather';
import { Dropdown } from 'primereact/dropdown';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { Link } from 'react-router-dom';
import { base_url, image_base_url } from '../../../../constant';

const ProviderDashboard = () => {
  const routes = all_routes;
  const [selectedValue, setSelectedValue] = useState(null);
  const value = [{ name: 'A - Z' }, { name: 'Most Helpful' }];
  const [selectedItems, setSelectedItems] = useState(Array(10).fill(false));

  const handleItemClick = (index: number) => {
    setSelectedItems((prevSelectedItems) => {
      const updatedSelectedItems = [...prevSelectedItems];
      updatedSelectedItems[index] = !updatedSelectedItems[index];
      return updatedSelectedItems;
    });
  };

  // /technician/statistics/{tech_id}

  const [isLoading, setIsLoading] = useState(false);
  const [totalEarning, settotalEarnings] = useState([]);
  const [totalBooking, settotalBooking] = useState([]);
  const [totalServices, settotalServices] = useState([]);
  const [recentBooking, setRecentBooking] = useState([]);

  useEffect(() => {
    getlisttechnician();
    getrecentbookingtechnician();
  }, []);

  const getlisttechnician = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('tech_token');
      const user_id = localStorage.getItem('technician_id');
      const response = await fetch(`${base_url}api/technician/statistics/${user_id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      settotalEarnings(data.total_earnings);
      settotalServices(data.total_services_completed);
      settotalBooking(data.total_bookings);
    } catch (error) {
      console.error('Error fetching technicians:', error);
    } finally {
      setIsLoading(false);
    }
  };
  
  const getrecentbookingtechnician = async () => {
    const user_id = localStorage.getItem("technician_id");
    setIsLoading(true);
    try {
      const token = localStorage.getItem('tech_token');
      const response = await fetch(`${base_url}api/technician-recent-appointments/${user_id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setRecentBooking((data.appointments || []).reverse());
    } catch (error) {
      console.error('Error fetching technicians:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const formatDate = (dateString:any) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
  const getStatusText = (status:any) => {
    switch (status) {
      case 0:
        return 'New';
      case 1:
        return 'Pending';
      case 2:
        return 'Completed';
      case 3:
        return 'Cancelled';
      case 4:
        return 'Completed';
      default:
        return 'Unknown';
    }
  };
  
  const getStatusBadgeClass = (status:any) => {
    switch (status) {
      case 0:
        return 'badge-info';
      case 1:
        return 'badge-warning';
      case 2:
        return 'badge-success';
      case 3:
        return 'badge-danger';
      case 4:
        return 'badge-success';
      default:
        return 'badge-secondary';
    }
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-md-4">
                <div className="provider-subtitle">
                  <h6>Provider Dashboard</h6>
                </div>
              </div>
              <div className="col-md-8 d-flex align-items-center justify-content-md-end flex-wrap">
                <div className="review-sort me-2">
                  <p>Sort</p>
                  <Dropdown
                    value={selectedValue}
                    onChange={(e) => setSelectedValue(e.value)}
                    options={value}
                    optionLabel="name"
                    placeholder="A - Z"
                    className="select provider-select select-space"
                  />
                </div>
                <div className="grid-listview">
                  <ul>
                    <li>
                      <Link to="#">
                        <Icon.Filter className="react-feather-custom" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-xl-3 col-sm-6 d-flex">
              <div className="dash-card flex-fill">
                <div className="dash-header">
                  <div className="dash-widget-header">
                    <div className="d-flex align-items-center">
                      <span className="dash-widget-icon">
                        <ImageWithBasePath
                          src="assets/img/icons/pro-icon-04.svg"
                          alt="img"
                        />
                      </span>
                      <div className="dash-widget-info">
                        <h6>Earnings</h6>
                        <h5>{totalEarning}</h5>
                      </div>
                    </div>
                    <select>
                      <option>2023</option>
                      <option>2022</option>
                    </select>
                  </div>
                  <div className="dash-total">
                    <p>
                      Total Sales: <span>{totalBooking}</span>
                    </p>
                    <div className="badge-success">+2.58</div>
                  </div>
                </div>
                <div className="dash-widget-img">
                  <ImageWithBasePath
                    src="assets/img/icons/pro-chart-01.svg"
                    className="img-fluid"
                    alt="img"
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12 d-flex">
              <div className="dash-card flex-fill">
                <div className="dash-header">
                  <div className="dash-widget-header">
                    <div className="d-flex align-items-center">
                      <span className="dash-widget-icon">
                        <ImageWithBasePath
                          src="assets/img/icons/pro-icon-03.svg"
                          alt="img"
                        />
                      </span>
                      <div className="dash-widget-info">
                        <h6>Bookings</h6>
                        <h5>{totalBooking}</h5>
                      </div>
                    </div>
                    <select>
                      <option>2023</option>
                      <option>2023</option>
                    </select>
                  </div>
                  <div className="dash-total">
                    <p>
                      Over All: <span>{totalBooking}</span>
                    </p>
                    <div className="badge-success">+2.58</div>
                  </div>
                </div>
                <div className="dash-widget-img">
                  <ImageWithBasePath
                    src="assets/img/icons/pro-chart-02.svg"
                    className="img-fluid"
                    alt="img"
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-12 d-flex">
              <div className="dash-card flex-fill">
                <div className="dash-header">
                  <div className="dash-widget-header">
                    <div className="d-flex align-items-center">
                      <span className="dash-widget-icon">
                        <ImageWithBasePath
                          src="assets/img/icons/pro-icon-02.svg"
                          alt="img"
                        />
                      </span>
                      <div className="dash-widget-info">
                        <h6>Services</h6>
                        <h5>{totalServices}</h5>
                      </div>
                    </div>
                    <select>
                      <option>2023</option>
                      <option>2023</option>
                    </select>
                  </div>
                  <div className="dash-total">
                    <p>
                      Providers: <span>{totalServices}</span>
                    </p>
                    <div className="badge-success">+2.58</div>
                  </div>
                </div>
                <div className="dash-widget-img">
                  <ImageWithBasePath
                    src="assets/img/icons/pro-chart-03.svg"
                    className="img-fluid"
                    alt="img"
                  />
                </div>
              </div>
            </div>
            {/* <div className="col-xl-3 col-sm-6 col-12 d-flex">
              <div className="dash-card flex-fill">
                <div className="dash-header">
                  <div className="dash-widget-header">
                    <div className="d-flex align-items-center">
                      <span className="dash-widget-icon">
                        <ImageWithBasePath
                          src="assets/img/icons/pro-icon-01.svg"
                          alt="img"
                        />
                      </span>
                      <div className="dash-widget-info">
                        <h6>Balance</h6>
                        <h5>$200</h5>
                      </div>
                    </div>
                    <select>
                      <option>2023</option>
                      <option>2023</option>
                    </select>
                  </div>
                  <div className="dash-total">
                    <Link to="#" className="btn btn-primary">
                      Withdraw
                    </Link>
                  </div>
                </div>
                <div className="dash-widget-img">
                  <ImageWithBasePath
                    src="assets/img/icons/pro-chart-04.svg"
                    className="img-fluid"
                    alt="img"
                  />
                </div>
              </div>
            </div> */}
          </div>
          <div className="row">
            {/* <div className="col-md-6 d-flex flex-column">
              <h6 className="subhead-title">Current Plan</h6>
              <div className="card flex-fill">
                <div className="card-body">
                  <div className="plan-info">
                    <div className="plan-term">
                      <h5>Standard</h5>
                      <p>Our most popular plan for small teams.</p>
                      <h6>
                        <span>Renew Date:</span> Jan 22, 2023
                      </h6>
                    </div>
                    <div className="plan-price">
                      <h4>$291</h4>
                      <p>per user/Year</p>
                    </div>
                  </div>
                  <div className="plan-btns">
                    <Link to="#" className="btn btn-danger-light">
                      Cancel Subscription
                    </Link>
                    <Link
                      to={routes.providerSubscription}
                      className="btn btn-secondary-outline"
                    >
                      Upgrade
                    </Link>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="col-md-6 d-flex flex-column">
              <h6 className="subhead-title">Payment</h6>
              <div className="card flex-fill">
                <div className="card-body pay-next">
                  <div className="plan-info">
                    <div className="plan-term">
                      <h5>Last Payment</h5>
                      <h6>January 22, 2023</h6>
                    </div>
                    <div className="plan-price">
                      <Link to="#" className="btn btn-light-success">
                        Active
                      </Link>
                    </div>
                  </div>
                  <div className="plan-info">
                    <div className="plan-term">
                      <h5>Next Payment</h5>
                      <h6>January 22, 2023</h6>
                    </div>
                    <div className="plan-price">
                      <Link to="#" className="btn btn-primary">
                        Manage Payment
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="row">
          <div className="col-md-12">
          <h6>Recent Booking</h6>
          {isLoading && (
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
    )}
          {!isLoading && (recentBooking.map((booking:any) => (
            <div className="booking-list" key={booking.id}>
              <div className="booking-widget">
                <div className="booking-img">
                  <Link to={routes.serviceDetails1}>
                    <img
                      src={booking.service?.service_image ? `${image_base_url}${booking.service?.service_image}` : '/assets/img/services/service-06.jpg'}
                      // src="assets/img/services/service-06.jpg"
                      alt="User Image" className={''} />
                  </Link>
                  <div
                    className="fav-item"
                    key={5}
                    onClick={() => handleItemClick(5)}
                  >
                    <Link
                      to="#"
                      className={`fav-icon ${selectedItems[5] ? 'selected' : ''}`}
                    >
                      <Icon.Heart size={15} />
                    </Link>
                  </div>
                </div>
                <div className="booking-det-info">
                  <h3>
                    <Link to={routes.serviceDetails1}>
                      {booking.service?.service_title ? booking.service?.service_title : ""}
                    </Link>{' '}
                    <span className={`badge ${getStatusBadgeClass(booking.status)}`}>
                      {getStatusText(booking.status)}
                    </span>
                  </h3>
                  <ul className="booking-details">
                    <li>
                      <span className="book-item">Booking Date</span> : {formatDate(booking.appointment_date)}, {booking.from_time} - {booking.to_time}
                    </li>
                    <li>
                      <span className="book-item">Amount</span> : ${booking.service_amount}{' '}
                      {/* <span className="badge-grey">COD</span> */}
                    </li>
                    <li>
                      <span className="book-item">Location</span> : {booking.address}
                    </li>
                    <li>
                      <span className="book-item">Customer</span> : {booking.customer.name} 
                      <div className="user-book">
                        {/* <div className="avatar avatar-xs">
                          <ImageWithBasePath
                            className="avatar-img rounded-circle"
                            alt="User Image"
                            src="assets/img/profiles/avatar-02.jpg" />
                        </div> */}
                      </div>
                      <p>{booking.customer.email}</p>
                      <p>{booking.customer.mobileno}</p>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <div className="booking-action">
                <Link to={routes.booking1} className="btn btn-primary">
                  Rebook
                </Link>
                <Link
                  to="#"
                  className="btn btn-secondary"
                  data-bs-toggle="modal"
                  data-bs-target="#add-review"
                >
                  <Icon.PlusCircle className="react-feather-custom" /> Add
                  Review
                </Link>
                <div className="view-action">
                  <div className="rating">
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                  </div>
                  <Link to={routes.serviceDetails1} className="view-btn">
                    View Details
                  </Link>
                </div>
              </div> */}
            </div>)))}
            </div>
            <div className="view-history">
                <Link to={routes.providerBooking} className="btn btn-primary">
                  View All History
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProviderDashboard;
