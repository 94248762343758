import React, { useEffect, useState } from 'react';
import { base_url, image_base_url } from '../../../constant';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';
import axios from 'axios';


const CategoryMaster = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false); // State for edit modal
  const [formData, setFormData] = useState({
    specialization: '',
    description: '',
    image: '',
    is_featured: false,
    is_active: false,
  });
  const [editFormData, setEditFormData] = useState({
    specialization: '',
    description: '',
    image: '',
    is_featured: false,
    is_active: false,
  }); // State for edit form data
  const [imagePreview, setImagePreview] = useState('');
  const [error, setError] = useState(null);
  const [validationError, setValidationError] = useState('');
  const [deleteModalShow, setDeleteModalShow] = useState(false); // State for delete confirmation modal
  const [deleteCategory, setDeleteCategory] = useState('');
  const [deleteCategoryID, setDeleteCategoryID] = useState('');

  useEffect(() => {
    SpecializationMaster();
  }, []);

  const SpecializationMaster = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${base_url}api/SpecializationMaster`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setIsLoading(false);
      // Reverse the array to display in reverse order
      setUsers(data.reverse());
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching technicians:', error);
    }
  };
  const profileImageTemplate = (users:any) => {
    const imageSrc = users?.image;
    return <img src={`${image_base_url}${imageSrc}`} alt="Profile" style={{ width: '50px', height: 'auto' }} />;
  };

  const handleSubmit = async (e:any) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    // FormData instance to gather all the form data
    const data = new FormData();
    data.append('specialization', formData.specialization);
    data.append('description', formData.description);
    data.append('image', formData.image);
    data.append('is_active', formData.is_active ? 1 : 0);
    data.append('is_featured', formData.is_featured ? 1 : 0);

    try {
      const response = await axios.post(`${base_url}api/SpecializationMaster`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      // Handle success response
      console.log('Response:', response.data);
      SpecializationMaster();
      setModalShow(false);
    } catch (error) {
      // Handle error response
      console.error('Error:', error);
    }
  };

  const handleReset = () => {
    setFormData({
      specialization: '',
      description: '',
      image: '',  // Use null for file input; file objects are typically managed differently
      is_featured: false,
      is_active: false,
    });
  };
  

  const editButton = (rowData:any) => {
    return (
      <div className="table-actions ">
        <button className="edit-table border-none"
         onClick={() => handleEdit(rowData)}
         >
          <Icon.Edit className="react-feather-custom" />
        </button>
      </div>
    );
  };
  const handleEdit = (rowData:any) => {
    setEditFormData(rowData);
    setEditModalShow(true);
    setImagePreview(rowData.image || '');
  };
  const handleFileChange = (e:any) => {
    const file = e.target.files[0];
    if (file) {
      setEditFormData({ ...editFormData, image: file });
      setImagePreview(file.image);
    }
  };

  const handleUpdate = async (e:any) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    // FormData instance to gather all the form data
    const data = new FormData();
    data.append('id', editFormData.id);
    data.append('specialization', editFormData.specialization);
    data.append('description', editFormData.description);
    data.append('image', editFormData.image);
    data.append('is_active', editFormData.is_active ? 1 : 0);
    data.append('is_featured', editFormData.is_featured ? 1 : 0);

    try {
      const response = await axios.post(`${base_url}api/SpecializationMasterUpdate`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      // Handle success response
      console.log('Response:', response.data);
      SpecializationMaster();
      setEditModalShow(false);
    } catch (error) {
      // Handle error response
      console.error('Error:', error);
    }
  };
  const handleEditReset = () => {
    setEditFormData({
      specialization: '',
      description: '',
      image: '',  // Use null for file input; file objects are typically managed differently
      is_featured: false,
      is_active: false,
    });
  };

  const deleteButton = (rowData:any) => {
    return (
      <div className="table-actions ">
        <button className="delete-table border-none" 
        onClick={() => handleDelete(rowData)}
        >
          <Icon.Trash2 className="react-feather-custom" />
        </button>
      </div>
    );
  };
  const handleDelete = (rowData:any) => {
    setDeleteCategoryID(rowData.id); 
    setDeleteCategory(rowData.specialization); // Set category name to delete
    setDeleteModalShow(true); // Show delete confirmation modal
  };
  const cancelDelete = () => {
    setDeleteModalShow(false);
    setDeleteCategory(''); // Clear delete category state
  };
  const confirmDelete = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${base_url}api/SpecializationMaster/${deleteCategoryID}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      console.log('Category deleted successfully');
      setDeleteModalShow(false);
      setIsLoading(false);
      SpecializationMaster(); // Refresh data after deletion
    } catch (error) {
      setIsLoading(false);
      console.error('Error deleting category:', error);
    }
  };

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        {isLoading && (
          <div className="d-flex justify-content-center mt-4">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="text-center flex-grow-1">Specialization</h5>
              <button className="btn btn-primary" onClick={() => setModalShow(true)}>
                <Icon.Plus className="react-feather-custom" />
              </button>
            </div>

            <div className="table-responsive table-div">
              <DataTable value={users} showGridlines tableStyle={{ minWidth: '50rem' }}>
                <Column field="edit" header="Edit" body={(rowData) => editButton(rowData)} />
                <Column field="delete" header="Delete" body={(rowData) => deleteButton(rowData)} />
                <Column field="image" header="Image" body={profileImageTemplate} />        
                <Column field="specialization" header="Specialization" />
                <Column field="description" header="Description" />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal fade ${modalShow ? 'show' : ''}`}
        id="add-category"
        style={{ display: modalShow ? 'block' : 'none' }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Add Specialization</h5>
              <button type="button" className="btn-close" onClick={() => setModalShow(false)} aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label htmlFor="category-name" className="form-label">Specialization</label>
                <input type="text" className={`form-control ${validationError ? 'is-invalid' : ''}`} id="category-name" value={formData.specialization} onChange={(e) => setFormData({ ...formData, specialization: e.target.value })} />
                {validationError && (
                  <div className="invalid-feedback">
                    {validationError}
                  </div>
                )}
              </div>
              <div className='mb-3'>
              <label htmlFor="category-name" className="form-label">Description</label>
              <input type="text" className={`form-control`} id="category-name" value={formData.description} onChange={(e) => setFormData({ ...formData, description: e.target.value })} />
              </div>
              <div className='mb-3'>
              {/* <label htmlFor="category-name" className="form-label">Description</label> */}
              <input type="file" className={`form-control`} id="category-name" value={formData.image} onChange={(e) => setFormData({ ...formData, image: e.target.value })} />
              </div>
              <div className="row mb-3">
                <div className="col">
                  <input 
                  type="checkbox" 
                  className="form-check-input" 
                  id="is-active" 
                  checked={formData.is_active} 
                  onChange={(e) => setFormData({ ...formData, is_active: e.target.checked })} 
                  style={{ transform: 'scale(1.5)', marginRight: '10px' }} 
                  />
                  <label htmlFor="is-active" className="form-label">Is Active</label>
                </div>
                <div className="col">
                  <input 
                  type="checkbox" 
                  className="form-check-input" 
                  id="is-featured" 
                  checked={formData.is_featured} 
                  onChange={(e) => setFormData({ ...formData, is_featured: e.target.checked })}
                  style={{ transform: 'scale(1.5)', marginRight: '10px' }}  
                  />
                  <label htmlFor="is-featured" className="form-label">Is Featured</label>
                </div>
              </div>
              </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-success" onClick={handleSubmit}>Create</button>
              <button type="button" className="btn btn-secondary" onClick={handleReset}>Reset</button>
            </div>
          </div>
        </div>
      </div>

      {/* Edit Modal */}
    <div
      className={`modal fade ${editModalShow ? 'show' : ''}`}
      id="edit-category"
      style={{ display: editModalShow ? 'block' : 'none' }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Edit Specialization</h5>
            <button type="button" className="btn-close" onClick={() => setEditModalShow(false)} aria-label="Close"></button>
          </div>
          <div className="modal-body">
              <div className="mb-3">
                <label htmlFor="category-name" className="form-label">Specialization</label>
                <input type="text" className={`form-control ${validationError ? 'is-invalid' : ''}`} id="category-name" value={editFormData.specialization} onChange={(e) => setEditFormData({ ...editFormData, specialization: e.target.value })} />
                {validationError && (
                  <div className="invalid-feedback">
                    {validationError}
                  </div>
                )}
              </div>
              <div className='mb-3'>
              <label htmlFor="category-name" className="form-label">Description</label>
              <input type="text" className={`form-control`} id="category-name" value={editFormData.description} onChange={(e) => setEditFormData({ ...editFormData, description: e.target.value })} />
              </div>
              <div className='mb-3'>
            <input
              type="file"
              className="form-control"
              id="category-image"
              onChange={handleFileChange}
            />
            {imagePreview && (
              <div className="mt-3">
                {/* <img src={imagePreview} alt="Selected" style={{ maxWidth: '100%', height: 'auto' }} /> */}
                <p>{imagePreview}</p>
              </div>
            )}
              </div>
              <div className="row mb-3">
                <div className="col">
                  <input 
                  type="checkbox" 
                  className="form-check-input" 
                  id="is-active" 
                  checked={editFormData.is_active} 
                  onChange={(e) => setEditFormData({ ...editFormData, is_active: e.target.checked })} 
                  style={{ transform: 'scale(1.5)', marginRight: '10px' }} 
                  />
                  <label htmlFor="is-active" className="form-label">Is Active</label>
                </div>
                <div className="col">
                  <input 
                  type="checkbox" 
                  className="form-check-input" 
                  id="is-featured" 
                  checked={editFormData.is_featured} 
                  onChange={(e) => setEditFormData({ ...editFormData, is_featured: e.target.checked })}
                  style={{ transform: 'scale(1.5)', marginRight: '10px' }}  
                  />
                  <label htmlFor="is-featured" className="form-label">Is Featured</label>
                </div>
              </div>
              </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-success" onClick={handleUpdate}>Update</button>
            <button type="button" className="btn btn-secondary" onClick={handleEditReset}>Reset</button>
          </div>
        </div>
      </div>
    </div>

{/* Delete Confirmation Modal */}
<div
        className={`modal fade ${deleteModalShow ? 'show' : ''}`}
        id="delete-category"
        style={{ display: deleteModalShow ? 'block' : 'none' }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Delete Specialization</h5>
              <button type="button" className="btn-close" onClick={() => cancelDelete()} aria-label="Close"></button>
            </div>
            <div className="modal-body">
            <p>Are you sure you want to delete &quot;{deleteCategory}&quot;?</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-danger" onClick={() => confirmDelete()}>Delete</button>
              <button type="button" className="btn btn-secondary" onClick={() => cancelDelete()}>Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryMaster;
