import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as Icon from 'react-feather';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import PagesAuthHeader from './common/header';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { base_url } from '../../../../constant';
import PhoneInput from 'react-phone-input-2';

const UserSignup = () => {
const routes = all_routes;
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirm] = useState('');
  const [mobile, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordConfirmError, setPasswordConfirmError] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleOnChange = (value: string, country: string) => {
    console.log(value, country);
    setPhone(value);
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const validateName = () => {
    if (!name) {
      setNameError('Name is required.');
      return false;
    }
    setNameError('');
    return true;
  };

  const validateEmail = () => {
    if (!email) {
      setEmailError('Email is required.');
      return false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError('Invalid email format.');
      return false;
    }
    setEmailError('');
    return true;
  };

  const validatePhone = () => {
    if (!mobile) {
      setPhoneError('Phone number is required.');
      return false;
    }
    setPhoneError('');
    return true;
  };

  const validatePassword = () => {
    if (!password) {
      setPasswordError('Password is required.');
      return false;
    }
    setPasswordError('');
    return true;
  };

  const validatePasswordConfirmation = () => {
    if (!passwordConfirmation) {
      setPasswordConfirmError('Password confirmation is required.');
      return false;
    }
    if (password !== passwordConfirmation) {
      setPasswordConfirmError('Passwords do not match.');
      return false;
    }
    setPasswordConfirmError('');
    return true;
  };

  const validateForm = () => {
    const isNameValid = validateName();
    const isEmailValid = validateEmail();
    const isPhoneValid = validatePhone();
    const isPasswordValid = validatePassword();
    const isPasswordConfirmValid = validatePasswordConfirmation();
    return (
      isNameValid &&
      isEmailValid &&
      isPhoneValid &&
      isPasswordValid &&
      isPasswordConfirmValid
    );
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    setLoading(true);
    try {
      const response = await fetch(`${base_url}api/auth/otpcreationcust`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          name,
          mobile,
          password,
          password_confirmation: passwordConfirmation,
        }),
      });

      if (response.status === 200) {
        const result = await response.json();
        console.log(result)
        localStorage.setItem('email', email);
        // console.log(email)
        navigate(routes.userOtpverify);
      } else {
        throw new Error('Failed to sign up');
      }
    } catch (error) {
      console.error('Error:', error);
      setError('Failed to sign up. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <PagesAuthHeader />
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 col-lg-6 mx-auto">
              <div className="login-wrap">
                <div className="login-header">
                  <h3>User Signup</h3>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label className="col-form-label">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      onBlur={validateName}
                    />
                    {nameError && <div style={{ color: 'red' }}>{nameError}</div>}
                  </div>
                  <div className="form-group">
                    <label className="col-form-label">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="johndoe@example.com"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      onBlur={validateEmail}
                    />
                    {emailError && <div style={{ color: 'red' }}>{emailError}</div>}
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <label className="col-form-label">Phone Number</label>
                      <div className="form-group">
                        <PhoneInput
                          country={'in'}
                          value={mobile}
                          onChange={handleOnChange}
                          onBlur={validatePhone}
                        />
                        {phoneError && <div style={{ color: 'red' }}>{phoneError}</div>}
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-form-label d-block">Password</label>
                    <div className="pass-group">
                      <input
                        type={'password'}
                        className="form-control pass-input"
                        placeholder="*************"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onBlur={validatePassword}
                      />
                      <span
                        className="toggle-password"
                        onClick={togglePasswordVisibility}
                      >
                        {/* {passwordVisible ? (
                          <Icon.EyeOff className="react-feather-custom" />
                        ) : (
                          <Icon.Eye className="react-feather-custom" />
                        )} */}
                      </span>
                    </div>
                    {passwordError && <div style={{ color: 'red' }}>{passwordError}</div>}
                  </div>
                  <div className="form-group">
                    <label className="col-form-label d-block">Confirm Password</label>
                    <div className="pass-group">
                      <input
                        type={passwordVisible ? 'text' : 'password'}
                        className="form-control pass-input"
                        placeholder="*************"
                        value={passwordConfirmation}
                        onChange={(e) => setPasswordConfirm(e.target.value)}
                        onBlur={validatePasswordConfirmation}
                      />
                      <span
                        className="toggle-password"
                        onClick={togglePasswordVisibility}
                      >
                        {passwordVisible ? (
                          <Icon.EyeOff className="react-feather-custom" />
                        ) : (
                          <Icon.Eye className="react-feather-custom" />
                        )}
                      </span>
                    </div>
                    {passwordConfirmError && <div style={{ color: 'red' }}>{passwordConfirmError}</div>}
                  </div>
                  {error && <div style={{ color: 'red' }}>{error}</div>}
                  <button className="btn btn-primary" style={{ width: '420px' }}>
                    {loading ? 'Signing Up...' : 'Register'}
                  </button>
                  <div className="login-or">
                    <span className="or-line" />
                    <span className="span-or">Or, log in with your email</span>
                  </div>
                  {/* <div className="social-login">
                    <Link to="#" className="btn btn-google w-100">
                      <ImageWithBasePath
                        src="assets/img/icons/google.svg"
                        className="me-2"
                        alt="img"
                      />
                      Log in with Google
                    </Link>
                    <Link to="#" className="btn btn-google w-100">
                      <ImageWithBasePath
                        src="assets/img/icons/fb.svg"
                        className="me-2"
                        alt="img"
                      />
                      Log in with Facebook
                    </Link>
                  </div> */}
                  <p className="no-acc">
                    Already have an account ?{' '}
                    <Link to={routes.login}> Sign In</Link>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserSignup;
