import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { base_url, image_base_url } from '../../../../constant';
import axios from 'axios';
import { Dropdown } from 'primereact/dropdown';
import { TagsInput } from 'react-tag-input-component';
import { Calendar } from 'primereact/calendar';
import * as Icon from 'react-feather';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';

const CustomerProfile = () => {
  const [selectedGender, setGender] = useState(null);
  const [selectedCurrency, setCurrency] = useState(null);
  const [tags] = useState(['English', 'French']);
  const [date, setDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [recentBooking, setRecentBooking] = useState([]);
  const [name, setName] = useState('');
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [profileImage, setProfileImage] = useState(null); 
  const [selectedFile, setSelectedFile] = useState(null);
  const [buttonText, setButtonText] = useState('Update'); 


  const gender = [
    { name: 'Select Gender' },
    { name: 'Male' },
    { name: 'Female' },
  ];
  const currency = [
    { name: 'Choose Currency' },
    { name: 'Eur' },
    { name: 'Aud' },
  ];

  useEffect(() => {
    userget();
  }, []);

  const userget = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const user_id = localStorage.getItem('user_id');
      const response = await fetch(`${base_url}api/getbyuserid/${user_id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setRecentBooking(data.data); // Set the state with the 'data' array from the response
      const userDetails = data[0]; // Assuming the first object in the array contains user details

      // Set state values from API response
      setName(userDetails.name);
      setEmail(userDetails.email);
      setMobile(userDetails.mobile || '');
      setUserName(userDetails.username || '');
      setProfileImage(userDetails.userprofileimage || null);
    } catch (error) {
      console.error('Error fetching user details:', error);
    } finally {
      setIsLoading(false);
    }
  };
//   // Function to handle image upload (POST request)
// const handleImageUpload = async (file:any) => {
//   if (!file) return;
//   const token = localStorage.getItem('token');
//   const user_id = localStorage.getItem('user_id')??'';
//   const formData = new FormData();
//   formData.append('is', user_id);
//   formData.append('userprofileimage', file); // Assuming 'profile_image' is the correct key for the file

//   try {
//     // Post request to update user profile image
//     const response = await axios.post(`${base_url}api/updateuserprofileimage`, formData, {
//       headers: {
//         'Authorization': `Bearer ${token}`,
//         'Content-Type': 'multipart/form-data',
//       },
//     });
    
//     // Check if the response was successful
//     if (response.status === 200) {
//       alert('Profile image updated successfully!');
//     } else {
//       alert('Failed to update profile image.');
//     }
//   } catch (error) {
//     console.error('Error updating profile image:', error);
//     alert('Error uploading the profile image.');
//   }
// };


const handleImageSelect = (file:any) => {
  if (!file) return;
  setSelectedFile(file); // Store the selected file
};

const handleImageUpload = async () => {
  if (!selectedFile) return; // Ensure file is selected before making the API call
  const token = localStorage.getItem('token');
  const user_id = localStorage.getItem('user_id') ?? '';
  const formData = new FormData();
  formData.append('id', user_id);
  formData.append('userprofileimage', selectedFile); // Assuming 'userprofileimage' is the correct key for the file

  try {
    // Post request to update user profile image
    const response = await axios.post(`${base_url}api/updateuserprofileimage`, formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });

    // Check if the response was successful
    if (response.status === 200) {
      alert('Profile image updated successfully!');
      // Update the profile image in the state to show the new uploaded image
      const updatedImage = response.data.userprofileimage; // Adjust based on the actual response data
      setProfileImage(updatedImage);

      // Reset button text and selected file
      setButtonText('Upload');
      setSelectedFile(null);
    } else {
      alert('Failed to update profile image.');
    }
  } catch (error) {
    console.error('Error updating profile image:', error);
    alert('Error uploading the profile image.');
  }
};

const handleRemoveFile = () => {
  setSelectedFile(null); // Clear the selected file
};

  return (
    <div className="col-lg-9">
      <div className="widget-title">
        <h4>Account Settings</h4>
      </div>
      <h6 className="user-title">Profile Picture</h6>
      <div className="pro-picture">
        <div className="pro-img">
          {/* <ImageWithBasePath src="assets/img/profiles/avatar-02.jpg" alt="user" /> */}
          <img
            src={profileImage ? `${image_base_url}${profileImage}` : 'assets/img/profiles/avatar-02.jpg'}
            alt="user"
          />
        </div>
        <div className="pro-info">
          <div className="d-flex">
            {!selectedFile &&(
          <div className="img-upload">
        <Icon.UploadCloud className="react-feather-custom me-1" />
        Upload
        <input
          type="file"
          accept="image/*"
          onChange={(e) => {
            const file = e.target.files && e.target.files[0];
            handleImageSelect(file); // Call the image select function when a file is selected
          }}
        />
      </div>)}

      {selectedFile && (
        <div className="img-upload"
          onClick={handleImageUpload} // Call the upload API when the button is clicked
          // className="btn btn-primary mt-2"
        >
          {buttonText}
        </div>
      )}
            <Link to="#" className="btn btn-remove" onClick={handleRemoveFile}>
              Remove
            </Link>
          </div>
          <p>
            *image size should be at least 320px big,and less then 500kb.
            Allowed files .png and .jpg.
          </p>
        </div>
      </div>
      <h6 className="user-title">General Information</h6>
      <div className="general-info">
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="col-form-label">
                Name <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Your Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                disabled
              />
            </div>
          </div>
          {/* <div className="col-md-6">
            <div className="form-group">
              <label className="col-form-label">User Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Your User Name"
              />
            </div>
          </div> */}
          <div className="col-md-6">
            <div className="form-group">
              <label className="col-form-label">
                Email <span className="text-danger">*</span>
              </label>
              <input
                type="email"
                className="form-control"
                placeholder="Enter Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="col-form-label">
                Mobile Number <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Mobile Number"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                disabled
              />
            </div>
          </div>
          {/* <div className="col-md-6">
            <div className="form-group">
              <label className="col-form-label">Gender</label>
              <Dropdown
                value={selectedGender}
                onChange={(e) => setGender(e.value)}
                options={gender}
                optionLabel="name"
                placeholder="English"
                className="select w-100"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="col-form-label">Date of birth</label>
              <div className="form-icon cus-profile">
                <Calendar
                  value={date}
                  onChange={(e:any) => setDate(e.value)}
                  placeholder="DD/MM/YYYY" 
                />
                <span className="cus-icon">
                  <Icon.Calendar className="react-feather-custom" />
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label className="col-form-label d-block">
                Your Bio{' '}
                <span className="brief-bio float-end">
                  Brief description for your profile. URLs are hyperlinked.
                </span>
              </label>
              <textarea
                className="form-control"
                rows={5}
                placeholder="Add a Short Bio....."
                defaultValue={''}
              />
            </div>
          </div> */}
        </div>
      </div>
      {/* <h6 className="user-title">Address </h6>
      <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <label className="col-form-label">Address</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Your Address"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="col-form-label">Country</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Your Country"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="col-form-label">State</label>
            <input
              type="email"
              className="form-control"
              placeholder="Enter Your State"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="col-form-label">City</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Your City"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="col-form-label">Postal Code</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Your Postal Code"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="col-form-label">Currency Code</label>
            <Dropdown
              value={selectedCurrency}
              onChange={(e) => setCurrency(e.value)}
              options={currency}
              optionLabel="name"
              placeholder="Choose Currency"
              className="select w-100"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="col-form-label">Language</label>
            <TagsInput
            //  eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //    @ts-expect-error
              className="input-tags form-control"
              tags={tags}
              value={tags}
            />
            <input
                      className="input-tags form-control"
                      type="text"
                      data-role="tagsinput"
                      name="specialist"
                      defaultValue="English,French"
                      id="specialist"
                    />
          </div>
        </div>
      </div> */}
      {/* <div className="acc-submit">
        <Link to="#" className="btn btn-secondary">
          Cancel
        </Link>
        <Link to="#" className="btn btn-primary">
          Save Changes
        </Link>
      </div> */}
    </div>
  );
};

export default CustomerProfile;
