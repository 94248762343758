import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';
import AOS from 'aos';
import 'aos/dist/aos.css';
import HeaderNine from './header-nine';
import FooterNine from './footer-nine';
import Slider from 'react-slick';
import CountUp from 'react-countup';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';

const HomeNine = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);
  const settings = {
    dots: true,
    autoplay: false,
    arrows: false,
    slidesToShow: 4,
    speed: 500,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 776,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const reviewSettings = {
    dots: true,
    autoplay: false,
    arrows: false,
    slidesToShow: 1,
    speed: 500,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 776,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const blogSettings = {
    dots: true,
    autoplay: false,
    arrows: false,
    slidesToShow: 3,
    speed: 500,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 776,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const parterSettings = {
    dots: false,
    autoplay: true,
    slidesToShow: 5,
    arrows: false,
    speed: 500,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 776,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div className="main-wrapper">
      <div className="top-bar-nine">
        <div className="container">
          <div className="top-bar-nine-all">
            <div className="navbar-header">
              <Link className="navbar-brand logo" to="/index-9">
                <ImageWithBasePath
                  src="assets/img/logo.svg"
                  className="img-fluid"
                  alt="Logo"
                />
              </Link>
            </div>
            <div className="top-bar-ryt">
              <div className="top-address">
                <span>
                  <Icon.PhoneCall className="standard-feather" />
                </span>
                <div>
                  <h6>Get Support</h6>
                  <p>310-437-2766</p>
                </div>
              </div>
              <div className="top-address">
                <span>
                  <ImageWithBasePath src="assets/img/icons/global-nine.svg" alt="image" />
                </span>
                <div className="top-addres-nine">
                  <h6>Language</h6>
                  <p>
                    <ImageWithBasePath
                      src="assets/img/icons/dropdown-nine.svg"
                      className="footer-dropdown"
                      alt="img"
                    />
                    <select className="top-select">
                      <option>English</option>
                      <option>Mexico</option>
                      <option>American</option>
                    </select>
                  </p>
                </div>
              </div>
              <div className="top-btn">
                <Link to="#" className="btn btn-primary">
                  <Icon.User className="standard-feather" /> Login
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Header */}
      <HeaderNine />
      {/* /Header */}
      {/* Hero Section */}
      <section className="hero-section-nine">
        <div className="container">
          <div className="home-banner home-banner-nine">
            <div className="row align-items-center w-100">
              <div className="col-lg-6">
                <div className="banner-imgs banner-imgs-nine">
                  <div className="banner-1 shape-1">
                    <ImageWithBasePath
                      className="img-fluid"
                      alt="banner"
                      src="assets/img/banner-img-04.jpg"
                    />
                  </div>
                  <div className="banner-2 shape-3">
                    <ImageWithBasePath
                      className="img-fluid"
                      alt="banner"
                      src="assets/img/banner-img-03.jpg"
                    />
                  </div>
                  <div className="banners-3 shape-3">
                    <ImageWithBasePath
                      className="img-fluid"
                      alt="banner"
                      src="assets/img/banner-img-02.jpg"
                    />
                  </div>
                  <div className="banner-4 shape-2">
                    <ImageWithBasePath
                      className="img-responsive"
                      alt="banner"
                      src="assets/img/banner-img-01.jpg"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-10 mx-auto">
                <div
                  className="section-search section-search-nine aos"
                  data-aos="fade-up"
                >
                  <div className="arrow-ryt-all">
                    <h6>Welcome to Truelysell Mechanic</h6>
                    <div className="arrow-ryt">
                      <ImageWithBasePath src="assets/img/icons/arrow-nine.svg" alt="image" />
                    </div>
                  </div>
                  <h1>Providing A Professional Reliable service</h1>
                  <p>
                    Our professional cleaning service comes up with a complete
                    solution that makes your space sparkle!
                  </p>
                  <Link to="#" className="btn btn-primary appoints-btn">
                    Book Appointment
                  </Link>
                  <div className="banner-appointment-nine">
                    <ImageWithBasePath src="assets/img/profiles/avatar-15.jpg" alt="User" />
                    <div className="banner-appointment-content">
                      <p>Got a question about our service?</p>
                      <h5>Call us: 092837644</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /Hero Section */}
      {/* Safety is out first Priority */}
      <section className="safety-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-12">
              <div className="safety-first-all aos" data-aos="fade-up">
                <div
                  className="section-heading section-heading-nine safety-heading-nine aos"
                  data-aos="fade-up"
                >
                  <p>About Truelysell Mechanic</p>
                  <h2>Safety is out first Priority</h2>
                </div>
                <p className="safety-para">
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alteration in some
                  form
                </p>
                <p className="safety-para-two">
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alteration in some
                  form, by injected humour, or randomised words which don&apos;t look
                  even slightly believable. If you are going to use a passage of
                  Lorem Ipsum,
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                  <div className="safety-ryt-main aos" data-aos="fade-up">
                    <ImageWithBasePath
                      src="assets/img/blog/blog-13.jpg"
                      alt="image"
                      className="img-fluid"
                    />
                    <ul>
                      <li>
                        <Icon.Check className="standard-feather" />
                        <span>Highly Professional Staff</span>
                      </li>
                      <li>
                        <Icon.Check className="standard-feather" />
                        <span>100% Satisfaction Guarantee</span>
                      </li>
                      <li>
                        <Icon.Check className="standard-feather" />
                        <span>Quality Control System</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                  <div
                    className="safety-ryt-main safety-ryt-two aos"
                    data-aos="fade-up"
                  >
                    <ImageWithBasePath
                      src="assets/img/blog/blog-12.jpg"
                      alt="image"
                      className="img-fluid"
                    />
                    <ul>
                      <li>
                        <Icon.Check className="standard-feather" />
                        <span>Accurate Testing Process</span>
                      </li>
                      <li>
                        <Icon.Check className="standard-feather" />
                        <span>Unrivalled Workmanship</span>
                      </li>
                      <li>
                        <Icon.Check className="standard-feather" />
                        <span>Timely Delivery</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /Safety is out first Priority */}
      {/* Our Services */}
      <section className="service-nine-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div
                className="section-heading section-heading-nine aos"
                data-aos="fade-up"
              >
                <p>TRUSTED &amp; QUALITY SERVICE</p>
                <h2>Our Services</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Slider
                {...settings}
                className="owl-carousel service-nine-slider custom-slick"
              >
                <div
                  className="service-widget service-widget-nine aos"
                  data-aos="fade-up"
                >
                  <div className="service-img">
                    <Link to="/service-details">
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service Image"
                        src="assets/img/services/service-70.jpg"
                      />
                    </Link>
                  </div>
                  <div className="service-content service-feature-nine">
                    <div className="shop-content-logo">
                      <ImageWithBasePath
                        src="assets/img/icons/car-service-icon-1.svg"
                        alt="image"
                      />
                    </div>
                    <span>25 Mechanics</span>
                    <p>Performance Upgrade and Customization</p>
                  </div>
                </div>
                <div
                  className="service-widget service-widget-nine aos"
                  data-aos="fade-up"
                >
                  <div className="service-img">
                    <Link to="/service-details">
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service Image"
                        src="assets/img/services/service-71.jpg"
                      />
                    </Link>
                  </div>
                  <div className="service-content service-feature-nine">
                    <div className="shop-content-logo">
                      <ImageWithBasePath
                        src="assets/img/icons/car-service-icon-2.svg"
                        alt="image"
                      />
                    </div>
                    <span>15 Mechanics</span>
                    <p>Car Audio Repair and Maintenance customers</p>
                  </div>
                </div>
                <div
                  className="service-widget service-widget-nine aos"
                  data-aos="fade-up"
                >
                  <div className="service-img">
                    <Link to="/service-details">
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service Image"
                        src="assets/img/services/service-72.jpg"
                      />
                    </Link>
                  </div>
                  <div className="service-content service-feature-nine">
                    <div className="shop-content-logo">
                      <ImageWithBasePath
                        src="assets/img/icons/car-service-icon-1.svg"
                        alt="image"
                      />
                    </div>
                    <span>30 Mechanics</span>
                    <p>Performance Upgrade and Customization</p>
                  </div>
                </div>
                <div
                  className="service-widget service-widget-nine aos"
                  data-aos="fade-up"
                >
                  <div className="service-img">
                    <Link to="/service-details">
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service Image"
                        src="assets/img/services/service-73.jpg"
                      />
                    </Link>
                  </div>
                  <div className="service-content service-feature-nine">
                    <div className="shop-content-logo">
                      <ImageWithBasePath
                        src="assets/img/icons/car-service-icon-3.svg"
                        alt="image"
                      />
                    </div>
                    <span>17 Mechanics</span>
                    <p>Engine Service and repair Maintenance</p>
                  </div>
                </div>
                <div
                  className="service-widget service-widget-nine aos"
                  data-aos="fade-up"
                >
                  <div className="service-img">
                    <Link to="/service-details">
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service Image"
                        src="assets/img/services/service-70.jpg"
                      />
                    </Link>
                  </div>
                  <div className="service-content service-feature-nine">
                    <div className="shop-content-logo">
                      <ImageWithBasePath
                        src="assets/img/icons/car-service-icon-1.svg"
                        alt="image"
                      />
                    </div>
                    <span>25 Mechanics</span>
                    <p>Performance Upgrade and Customization</p>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
          <div className="btn-sec btn-service-nine aos" data-aos="fade-up">
            <Link to="/search" className="btn btn-primary btn-view">
              VIEW ALL 255 SERVICES
            </Link>
          </div>
        </div>
      </section>
      {/* /Our Services */}
      {/* Featured Services */}
      <section className="feature-service-nine">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div
                className="section-heading section-heading-nine aos"
                data-aos="fade-up"
              >
                <p>TRUSTED &amp; QUALITY SERVICE</p>
                <h2>Featured Services</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="service-widget service-widget-nine">
                <div className="service-img">
                  <Link to="/service-details">
                    <ImageWithBasePath
                      className="img-fluid serv-img"
                      alt="Service Image"
                      src="assets/img/services/service-78.jpg"
                    />
                  </Link>
                  <div className="item-info items-nine">
                    <ImageWithBasePath src="assets/img/profiles/avatar-01.jpg" alt="user" />
                    <div className="rating">
                      <i className="fas fa-star filled" />
                      <i className="fas fa-star filled" />
                      <i className="fas fa-star filled" />
                      <i className="fas fa-star filled" />
                      <i className="fas fa-star filled" />
                      <span>(198)</span>
                    </div>
                  </div>
                </div>
                <div className="service-content service-content-nine">
                  <span className="sub-title">Car Repair</span>
                  <h3 className="title">
                    <Link to="#">Wheel Alignment and Installation</Link>
                  </h3>
                  <div className="main-saloons-profile">
                    <div className="saloon-profile-left">
                      <div className="saloon-content">
                        <div className="saloon-content-top">
                          <Icon.Clock className="standard-feather" />
                          <span>07:00 AM - 11:00 PM </span>
                        </div>
                        <div className="saloon-content-btn">
                          <span>
                            <Icon.MapPin className="standard-feather" />
                          </span>
                          <span>Main Boulevard, Lahore,</span>
                        </div>
                      </div>
                    </div>
                    <div className="saloon-right">
                      <span>$70.00</span>
                    </div>
                  </div>
                  <div className="service-bottom-nine">
                    <Link to="#" className="btn btn-primary">
                      Book Appointment
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="service-widget service-widget-nine">
                <div className="service-img">
                  <Link to="/service-details">
                    <ImageWithBasePath
                      className="img-fluid serv-img"
                      alt="Service Image"
                      src="assets/img/services/service-75.jpg"
                    />
                  </Link>
                  <div className="item-info items-nine">
                    <ImageWithBasePath src="assets/img/profiles/avatar-02.jpg" alt="User" />
                    <div className="rating">
                      <i className="fas fa-star filled" />
                      <i className="fas fa-star filled" />
                      <i className="fas fa-star filled" />
                      <i className="fas fa-star filled" />
                      <i className="fas fa-star filled" />
                      <span>(198)</span>
                    </div>
                  </div>
                </div>
                <div className="service-content service-content-nine">
                  <span className="sub-title">Two Wheeler Service</span>
                  <h3 className="title">
                    <Link to="#">General Auto Repair &amp; Maintenance</Link>
                  </h3>
                  <div className="main-saloons-profile">
                    <div className="saloon-profile-left">
                      <div className="saloon-content">
                        <div className="saloon-content-top">
                          <Icon.Clock className="standard-feather" />
                          <span>07:00 AM - 11:00 PM </span>
                        </div>
                        <div className="saloon-content-btn">
                          <span>
                            <Icon.MapPin className="standard-feather" />
                          </span>
                          <span>Main Boulevard, Lahore,</span>
                        </div>
                      </div>
                    </div>
                    <div className="saloon-right">
                      <span>$50.00</span>
                    </div>
                  </div>
                  <div className="service-bottom-nine">
                    <Link to="#" className="btn btn-primary">
                      Book Appointment
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="service-widget service-widget-nine">
                <div className="service-img">
                  <Link to="/service-details">
                    <ImageWithBasePath
                      className="img-fluid serv-img"
                      alt="Service Image"
                      src="assets/img/services/service-74.jpg"
                    />
                  </Link>
                  <div className="item-info items-nine">
                    <ImageWithBasePath src="assets/img/profiles/avatar-03.jpg" alt="image" />
                    <div className="rating">
                      <i className="fas fa-star filled" />
                      <i className="fas fa-star filled" />
                      <i className="fas fa-star filled" />
                      <i className="fas fa-star filled" />
                      <i className="fas fa-star filled" />
                      <span>(198)</span>
                    </div>
                  </div>
                </div>
                <div className="service-content service-content-nine">
                  <span className="sub-title">Engine Service</span>
                  <h3 className="title">
                    <Link to="#">Engine Service &amp; Repair Service</Link>
                  </h3>
                  <div className="main-saloons-profile">
                    <div className="saloon-profile-left">
                      <div className="saloon-content">
                        <div className="saloon-content-top">
                          <Icon.Clock className="standard-feather" />
                          <span>07:00 AM - 11:00 PM </span>
                        </div>
                        <div className="saloon-content-btn">
                          <span>
                            <Icon.MapPin className="standard-feather" />
                          </span>
                          <span>Main Boulevard, Lahore,</span>
                        </div>
                      </div>
                    </div>
                    <div className="saloon-right">
                      <span>$78.00</span>
                    </div>
                  </div>
                  <div className="service-bottom-nine">
                    <Link to="#" className="btn btn-primary">
                      Book Appointment
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="service-widget service-widget-nine">
                <div className="service-img">
                  <Link to="/service-details">
                    <ImageWithBasePath
                      className="img-fluid serv-img"
                      alt="Service Image"
                      src="assets/img/services/service-76.jpg"
                    />
                  </Link>
                  <div className="item-info items-nine">
                    <ImageWithBasePath src="assets/img/profiles/avatar-05.jpg" alt="User" />
                    <div className="rating">
                      <i className="fas fa-star filled" />
                      <i className="fas fa-star filled" />
                      <i className="fas fa-star filled" />
                      <i className="fas fa-star filled" />
                      <i className="fas fa-star filled" />
                      <span>(158)</span>
                    </div>
                  </div>
                </div>
                <div className="service-content service-content-nine">
                  <span className="sub-title">Upgrade</span>
                  <h3 className="title">
                    <Link to="#">General Auto Repair &amp; Maintenance</Link>
                  </h3>
                  <div className="main-saloons-profile">
                    <div className="saloon-profile-left">
                      <div className="saloon-content">
                        <div className="saloon-content-top">
                          <Icon.Clock className="standard-feather" />
                          <span>07:00 AM - 11:00 PM </span>
                        </div>
                        <div className="saloon-content-btn">
                          <span>
                            <Icon.MapPin className="standard-feather" />
                          </span>
                          <span>Main Boulevard, Lahore,</span>
                        </div>
                      </div>
                    </div>
                    <div className="saloon-right">
                      <span>$10.00</span>
                    </div>
                  </div>
                  <div className="service-bottom-nine">
                    <Link to="#" className="btn btn-primary">
                      Book Appointment
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="service-widget service-widget-nine">
                <div className="service-img">
                  <Link to="/service-details">
                    <ImageWithBasePath
                      className="img-fluid serv-img"
                      alt="Service Image"
                      src="assets/img/services/service-77.jpg"
                    />
                  </Link>
                  <div className="item-info items-nine">
                    <ImageWithBasePath src="assets/img/profiles/avatar-01.jpg" alt="user" />
                    <div className="rating">
                      <i className="fas fa-star filled" />
                      <i className="fas fa-star filled" />
                      <i className="fas fa-star filled" />
                      <i className="fas fa-star filled" />
                      <i className="fas fa-star filled" />
                      <span>(198)</span>
                    </div>
                  </div>
                </div>
                <div className="service-content service-content-nine">
                  <span className="sub-title">inspection</span>
                  <h3 className="title">
                    <Link to="#">Electrical Diagnostics Service</Link>
                  </h3>
                  <div className="main-saloons-profile">
                    <div className="saloon-profile-left">
                      <div className="saloon-content">
                        <div className="saloon-content-top">
                          <Icon.Clock className="standard-feather" />
                          <span>07:00 AM - 11:00 PM </span>
                        </div>
                        <div className="saloon-content-btn">
                          <span>
                            <Icon.MapPin className="standard-feather" />
                          </span>
                          <span>Main Boulevard, Lahore,</span>
                        </div>
                      </div>
                    </div>
                    <div className="saloon-right">
                      <span>$30.00</span>
                    </div>
                  </div>
                  <div className="service-bottom-nine">
                    <Link to="#" className="btn btn-primary">
                      Book Appointment
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="service-widget service-widget-nine">
                <div className="service-img">
                  <Link to="/service-details">
                    <ImageWithBasePath
                      className="img-fluid serv-img"
                      alt="Service Image"
                      src="assets/img/services/service-79.jpg"
                    />
                  </Link>
                  <div className="item-info items-nine">
                    <ImageWithBasePath src="assets/img/profiles/avatar-01.jpg" alt="user" />
                    <div className="rating">
                      <i className="fas fa-star filled" />
                      <i className="fas fa-star filled" />
                      <i className="fas fa-star filled" />
                      <i className="fas fa-star filled" />
                      <i className="fas fa-star filled" />
                      <span>(198)</span>
                    </div>
                  </div>
                </div>
                <div className="service-content service-content-nine">
                  <span className="sub-title">Car repair</span>
                  <h3 className="title">
                    <Link to="#">Wheel Alignment and Installation</Link>
                  </h3>
                  <div className="main-saloons-profile">
                    <div className="saloon-profile-left">
                      <div className="saloon-content">
                        <div className="saloon-content-top">
                          <Icon.Clock className="standard-feather" />
                          <span>07:00 AM - 11:00 PM </span>
                        </div>
                        <div className="saloon-content-btn">
                          <span>
                            <Icon.MapPin className="standard-feather" />
                          </span>
                          <span>Main Boulevard, Lahore,</span>
                        </div>
                      </div>
                    </div>
                    <div className="saloon-right">
                      <span>$70.00</span>
                    </div>
                  </div>
                  <div className="service-bottom-nine">
                    <Link to="#" className="btn btn-primary">
                      Book Appointment
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="btn-sec btn-service-nine aos" data-aos="fade-up">
              <Link to="/search" className="btn btn-primary btn-view">
                VIEW ALL 255 SERVICES
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* /Featured Services */}
      <section className="journey-nine-section">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-4">
              <div
                className="section-heading section-heading-nine journey-heading-nine aos"
                data-aos="fade-up"
              >
                <p>Activity</p>
                <h2>It’s Our journey</h2>
              </div>
            </div>
            <div className="col-md-2 col-sm-6 d-flex justify-content-center align-items-center">
              <div className="journey-client-all aos" data-aos="fade-up">
                <div className="journey-client-main">
                  <div className="journey-client-counter">
                    <h2 className="counter">
                      <CountUp end={644} duration={500} />
                    </h2>
                    <span>+</span>
                  </div>
                  <h5>Active Clients</h5>
                </div>
              </div>
            </div>
            <div className="col-md-2 col-sm-6 d-flex justify-content-center align-items-center">
              <div className="journey-client-all aos" data-aos="fade-up">
                <div className="journey-client-main">
                  <div className="journey-client-counter">
                    <h2 className="counter">
                      <CountUp end={290} duration={500} />
                    </h2>
                    <span>+</span>
                  </div>
                  <h5>Expert Mechanics</h5>
                </div>
              </div>
            </div>
            <div className="col-md-2 col-sm-6 d-flex justify-content-center align-items-center">
              <div className="journey-client-all aos" data-aos="fade-up">
                <div className="journey-client-main">
                  <div className="journey-client-counter">
                    <h2 className="counter">
                      <CountUp end={100} duration={500} />
                    </h2>
                    <span>+</span>
                  </div>
                  <h5>Total Rewards</h5>
                </div>
              </div>
            </div>
            <div className="col-md-2 col-sm-6 d-flex justify-content-center align-items-center">
              <div className="journey-client-all aos" data-aos="fade-up">
                <div className="journey-client-main">
                  <div className="journey-client-counter">
                    <h2 className="counter">
                      <CountUp end={24} duration={500} />
                    </h2>
                    <span>+</span>
                  </div>
                  <h5>Years Reputation</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Why Choose Us */}
      <section className="choose-nine-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div
                className="section-heading section-heading-nine aos"
                data-aos="fade-up"
              >
                <p>TRUSTED &amp; QUALITY SERVICE</p>
                <h2>Why Choose Us?</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center aos" data-aos="fade-up">
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="reasonable-all">
                <ImageWithBasePath src="assets/img/icons/choose-icon-4.svg" alt="image" />
                <h5>Reasonable Price</h5>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="reasonable-all expert-all">
                <ImageWithBasePath src="assets/img/icons/choose-icon-3.svg" alt="image" />
                <h5>Expert Mechanics</h5>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="reasonable-all delivery-all">
                <ImageWithBasePath src="assets/img/icons/choose-icon-2.svg" alt="image" />
                <h5>fast feature</h5>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="reasonable-all workshop-all">
                <ImageWithBasePath src="assets/img/icons/choose-icon-1.svg" alt="image" />
                <h5>Awarded Workshop</h5>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /Why Choose Us? */}
      {/* our Mechanics */}
      <section className="mechanics-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div
                className="section-heading section-heading-nine aos"
                data-aos="fade-up"
              >
                <p>OUR TEAM</p>
                <h2>Our Mechanics</h2>
              </div>
            </div>
          </div>
          <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-home"
                type="button"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
              >
                Car Mechanic
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-profile"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
              >
                Commercial Mechanic
              </button>
            </li>
          </ul>
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
            >
              <div className="row aos" data-aos="fade-up">
                <div className="col-md-12">
                  <Slider
                    {...settings}
                    className="owl-carousel provider-nine-slider custom-slick"
                  >
                    <div className="providerset providerset-nine">
                      <div className="providerset-img ">
                        <Link to="/provider-details">
                          <ImageWithBasePath
                            src="assets/img/gallery/gallery-18.jpg"
                            alt="img"
                          />
                        </Link>
                      </div>
                      <div className="providerset-content">
                        <h6>Wheel Alignment Specialist</h6>
                        <h4>
                          <Link to="/provider-details">John Smith</Link>
                        </h4>
                        <div className="provider-rating provider-rating-nine">
                          <div className="rating">
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fa-solid fa-star-half-stroke filled" />
                            <span>(320)</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="providerset providerset-nine">
                      <div className="providerset-img ">
                        <Link to="/provider-details">
                          <ImageWithBasePath
                            src="assets/img/gallery/gallery-19.jpg"
                            alt="img"
                          />
                        </Link>
                      </div>
                      <div className="providerset-content">
                        <h6>Hatchback mechanic</h6>
                        <h4>
                          <Link to="/provider-details">Daniale Joe</Link>
                        </h4>
                        <div className="provider-rating provider-rating-nine">
                          <div className="rating">
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fa-solid fa-star-half-stroke filled" />
                            <span>(320)</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="providerset providerset-nine">
                      <div className="providerset-img ">
                        <Link to="/provider-details">
                          <ImageWithBasePath
                            src="assets/img/gallery/gallery-20.jpg"
                            alt="img"
                          />
                        </Link>
                      </div>
                      <div className="providerset-content">
                        <h6>Mechanic</h6>
                        <h4>
                          <Link to="/provider-details">John Smith</Link>
                        </h4>
                        <div className="provider-rating provider-rating-nine">
                          <div className="rating">
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fa-solid fa-star-half-stroke filled" />
                            <span>(320)</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="providerset providerset-nine">
                      <div className="providerset-img ">
                        <Link to="/provider-details">
                          <ImageWithBasePath
                            src="assets/img/gallery/gallery-18.jpg"
                            alt="img"
                          />
                        </Link>
                      </div>
                      <div className="providerset-content">
                        <h6>Wheel Alignment Specialist</h6>
                        <h4>
                          <Link to="/provider-details">John Smith</Link>
                        </h4>
                        <div className="provider-rating provider-rating-nine">
                          <div className="rating">
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fa-solid fa-star-half-stroke filled" />
                            <span>(320)</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="providerset providerset-nine">
                      <div className="providerset-img ">
                        <Link to="/provider-details">
                          <ImageWithBasePath
                            src="assets/img/gallery/gallery-18.jpg"
                            alt="img"
                          />
                        </Link>
                      </div>
                      <div className="providerset-content">
                        <h6>Wheel Alignment Specialist</h6>
                        <h4>
                          <Link to="/provider-details">John Smith</Link>
                        </h4>
                        <div className="provider-rating provider-rating-nine">
                          <div className="rating">
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fa-solid fa-star-half-stroke filled" />
                            <span>(320)</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              <div className="row aos" data-aos="fade-up">
                <div className="col-md-12">
                  <Slider
                    {...settings}
                    className="owl-carousel provider-nine-slider custom-slick"
                  >
                    <div className="providerset providerset-nine">
                      <div className="providerset-img ">
                        <Link to="/provider-details">
                          <ImageWithBasePath
                            src="assets/img/gallery/gallery-18.jpg"
                            alt="img"
                          />
                        </Link>
                      </div>
                      <div className="providerset-content">
                        <h6>Wheel Alignment Specialist</h6>
                        <h4>
                          <Link to="/provider-details">John Smith</Link>
                        </h4>
                        <div className="provider-rating provider-rating-nine">
                          <div className="rating">
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fa-solid fa-star-half-stroke filled" />
                            <span>(320)</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="providerset providerset-nine">
                      <div className="providerset-img ">
                        <Link to="/provider-details">
                          <ImageWithBasePath
                            src="assets/img/gallery/gallery-19.jpg"
                            alt="img"
                          />
                        </Link>
                      </div>
                      <div className="providerset-content">
                        <h6>Hatchback mechanic</h6>
                        <h4>
                          <Link to="/provider-details">Daniale Joe</Link>
                        </h4>
                        <div className="provider-rating provider-rating-nine">
                          <div className="rating">
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fa-solid fa-star-half-stroke filled" />
                            <span>(320)</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="providerset providerset-nine">
                      <div className="providerset-img ">
                        <Link to="/provider-details">
                          <ImageWithBasePath
                            src="assets/img/gallery/gallery-20.jpg"
                            alt="img"
                          />
                        </Link>
                      </div>
                      <div className="providerset-content">
                        <h6>Mechanic</h6>
                        <h4>
                          <Link to="/provider-details">John Smith</Link>
                        </h4>
                        <div className="provider-rating provider-rating-nine">
                          <div className="rating">
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fa-solid fa-star-half-stroke filled" />
                            <span>(320)</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="providerset providerset-nine">
                      <div className="providerset-img ">
                        <Link to="/provider-details">
                          <ImageWithBasePath
                            src="assets/img/gallery/gallery-18.jpg"
                            alt="img"
                          />
                        </Link>
                      </div>
                      <div className="providerset-content">
                        <h6>Wheel Alignment Specialist</h6>
                        <h4>
                          <Link to="/provider-details">John Smith</Link>
                        </h4>
                        <div className="provider-rating provider-rating-nine">
                          <div className="rating">
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fa-solid fa-star-half-stroke filled" />
                            <span>(320)</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="providerset providerset-nine">
                      <div className="providerset-img ">
                        <Link to="/provider-details">
                          <ImageWithBasePath
                            src="assets/img/gallery/gallery-18.jpg"
                            alt="img"
                          />
                        </Link>
                      </div>
                      <div className="providerset-content">
                        <h6>Wheel Alignment Specialist</h6>
                        <h4>
                          <Link to="/provider-details">John Smith</Link>
                        </h4>
                        <div className="provider-rating provider-rating-nine">
                          <div className="rating">
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fas fa-star filled" />
                            <i className="fa-solid fa-star-half-stroke filled" />
                            <span>(320)</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /our Mechanics */}
      {/* Customer Reviews */}
      <section className="customer-review-section">
        <div className="customer-side-main-all">
          <div className="customer-side-left-img">
            <ImageWithBasePath src="assets/img/customer-review-after.png" alt="image" />
          </div>
          <div className="customer-side-right-img">
            <ImageWithBasePath src="assets/img/customer-review-before.png" alt="image" />
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div
                className="section-heading section-heading-nine aos"
                data-aos="fade-up"
              >
                <p>OUR LATEST ARTICLES</p>
                <h2>Customer Reviews</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Slider
                {...reviewSettings}
                className="owl-carousel customer-review-slider custom-slick"
              >
                <div className="customer-reviews-all aos" data-aos="fade-up">
                  <div className="customer-reviews-main">
                    <ImageWithBasePath src="assets/img/blog/blog-14.jpg" alt="image" />
                    <div className="customer-quote">
                      <ImageWithBasePath
                        src="assets/img/icons/customer-quote.svg"
                        alt="image"
                      />
                    </div>
                  </div>
                  <h4>Vincent Cooper</h4>
                  <span>Alaska, USA</span>
                  <div className="rating">
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fa-solid fa-star-half-stroke filled" />
                  </div>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry&apos;s
                    standard dummy text ever since.Lorem Ipsum is simply dummy
                    text of the printing and typesetting industry.
                  </p>
                </div>
                <div className="customer-reviews-all">
                  <div className="customer-reviews-main">
                    <ImageWithBasePath src="assets/img/blog/blog-14.jpg" alt="image" />
                    <div className="customer-quote">
                      <ImageWithBasePath
                        src="assets/img/icons/customer-quote.svg"
                        alt="image"
                      />
                    </div>
                  </div>
                  <h4>Vincent Cooper</h4>
                  <span>Alaska, USA</span>
                  <div className="rating">
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fa-solid fa-star-half-stroke filled" />
                  </div>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry&apos;s
                    standard dummy text ever since.Lorem Ipsum is simply dummy
                    text of the printing and typesetting industry.
                  </p>
                </div>
                <div className="customer-reviews-all">
                  <div className="customer-reviews-main">
                    <ImageWithBasePath src="assets/img/blog/blog-14.jpg" alt="image" />
                    <div className="customer-quote">
                      <ImageWithBasePath
                        src="assets/img/icons/customer-quote.svg"
                        alt="image"
                      />
                    </div>
                  </div>
                  <h4>Vincent Cooper</h4>
                  <span>Alaska, USA</span>
                  <div className="rating">
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fas fa-star filled" />
                    <i className="fa-solid fa-star-half-stroke filled" />
                  </div>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry&apos;s
                    standard dummy text ever since.Lorem Ipsum is simply dummy
                    text of the printing and typesetting industry.
                  </p>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
      {/* /Customer Reviews */}
      {/* Additional Services */}
      <section className="additional-service-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div
                className="section-heading section-heading-nine aos"
                data-aos="fade-up"
              >
                <p>TRUSTED &amp; QUALITY SERVICE</p>
                <h2>Additional Services</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Slider
                {...settings}
                className="owl-carousel service-nine-slider custom-slick"
              >
                <div
                  className="service-widget service-widget-nine service-widget-nine-two aos"
                  data-aos="fade-up"
                >
                  <div className="service-img">
                    <Link to="/service-details">
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service Image"
                        src="assets/img/services/service-70.jpg"
                      />
                    </Link>
                    <div className="item-info items-nine items-nine-two">
                      <ImageWithBasePath src="assets/img/profiles/avatar-05.jpg" alt="User" />
                    </div>
                  </div>
                  <div className="service-content service-feature-nine">
                    <span>25 Mechanics</span>
                    <h3 className="title">
                      General Auto Repair &amp; Maintenance
                    </h3>
                  </div>
                </div>
                <div
                  className="service-widget service-widget-nine service-widget-nine-two aos"
                  data-aos="fade-up"
                >
                  <div className="service-img">
                    <Link to="/service-details">
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service Image"
                        src="assets/img/services/service-71.jpg"
                      />
                    </Link>
                    <div className="item-info items-nine items-nine-two">
                      <ImageWithBasePath src="assets/img/profiles/avatar-20.jpg" alt="User" />
                    </div>
                  </div>
                  <div className="service-content service-feature-nine">
                    <span>25 Mechanics</span>
                    <h3 className="title">
                      Transmission Repair &amp; Replacement
                    </h3>
                  </div>
                </div>
                <div
                  className="service-widget service-widget-nine service-widget-nine-two aos"
                  data-aos="fade-up"
                >
                  <div className="service-img">
                    <Link to="/service-details">
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service Image"
                        src="assets/img/services/service-72.jpg"
                      />
                    </Link>
                    <div className="item-info items-nine items-nine-two">
                      <ImageWithBasePath
                        src="assets/img/profiles/avatar-07.jpg"
                        alt="image"
                      />
                    </div>
                  </div>
                  <div className="service-content service-feature-nine">
                    <span>25 Mechanics</span>
                    <h3 className="title">
                      Emission Repair Facility Service Performance
                    </h3>
                  </div>
                </div>
                <div
                  className="service-widget service-widget-nine service-widget-nine-two aos"
                  data-aos="fade-up"
                >
                  <div className="service-img">
                    <Link to="/service-details">
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service Image"
                        src="assets/img/services/service-73.jpg"
                      />
                    </Link>
                    <div className="item-info items-nine items-nine-two">
                      <ImageWithBasePath
                        src="assets/img/profiles/avatar-07.jpg"
                        alt="image"
                      />
                    </div>
                  </div>
                  <div className="service-content service-feature-nine">
                    <span>25 Mechanics</span>
                    <h3 className="title">
                      Performance Upgrade and Customization
                    </h3>
                  </div>
                </div>
                <div
                  className="service-widget service-widget-nine service-widget-nine-two aos"
                  data-aos="fade-up"
                >
                  <div className="service-img">
                    <Link to="/service-details">
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service Image"
                        src="assets/img/services/service-73.jpg"
                      />
                    </Link>
                    <div className="item-info items-nine items-nine-two">
                      <ImageWithBasePath
                        src="assets/img/profiles/avatar-07.jpg"
                        alt="image"
                      />
                    </div>
                  </div>
                  <div className="service-content service-feature-nine">
                    <span>25 Mechanics</span>
                    <h3 className="title">
                      Performance Upgrade and Customization
                    </h3>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
          <div className="btn-sec btn-service-nine aos" data-aos="fade-up">
            <Link to="/search" className="btn btn-primary btn-view">
              VIEW ALL 255 SERVICES
            </Link>
          </div>
        </div>
      </section>
      {/* /Additional Services */}
      {/* Our Blogs */}
      <section className="blogs-nine-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div
                className="section-heading section-heading-nine aos"
                data-aos="fade-up"
              >
                <p>OUR LATEST ARTICLES</p>
                <h2>Our Blogs</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Slider
                {...blogSettings}
                className="blogs-nine-slider custom-slick"
              >
                <div className="blog blog-new flex-fill">
                  <div className="blog-image">
                    <Link to="/blog-details">
                      <ImageWithBasePath
                        className="img-fluid"
                        src="assets/img/services/service-77.jpg"
                        alt="Post Image"
                      />
                    </Link>
                    <div className="date">
                      25<span>Dec</span>
                    </div>
                  </div>
                  <div className="blog-content">
                    <div className="usefull-bottom useful-bottom-eight useful-bottom-nine">
                      <div className="useful-img">
                        <ImageWithBasePath
                          src="assets/img/profiles/avatar-10.jpg"
                          alt="User"
                        />
                        <span>Admin</span>
                      </div>
                      <h6>
                        <Icon.Tag className="standard-feather" />
                        Services
                      </h6>
                    </div>
                    <h3 className="blog-title">
                      <Link to="/blog-details">
                        Lorem ipsum dolor sit amet, adipiscing elit, sed do
                        eiusmod
                      </Link>
                    </h3>
                    <p>
                      Sed ut perspiciatis unde omnis iste natus error sit
                      voluptatem accusantium doloremque.
                    </p>
                    <div className="service-bottom-nine">
                      <Link to="#" className="btn btn-primary">
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="blog blog-new flex-fill">
                  <div className="blog-image">
                    <Link to="/blog-details">
                      <ImageWithBasePath
                        className="img-fluid"
                        src="assets/img/services/service-78.jpg"
                        alt="Post Image"
                      />
                    </Link>
                    <div className="date">
                      25<span>Dec</span>
                    </div>
                  </div>
                  <div className="blog-content">
                    <div className="usefull-bottom useful-bottom-eight useful-bottom-nine">
                      <div className="useful-img">
                        <ImageWithBasePath
                          src="assets/img/profiles/avatar-10.jpg"
                          alt="User"
                        />
                        <span>Admin</span>
                      </div>
                      <h6>
                        <Icon.Tag className="standard-feather" />
                        Services
                      </h6>
                    </div>
                    <h3 className="blog-title">
                      <Link to="/blog-details">
                        Lorem ipsum dolor sit amet, adipiscing elit, sed do
                        eiusmod
                      </Link>
                    </h3>
                    <p>
                      Sed ut perspiciatis unde omnis iste natus error sit
                      voluptatem accusantium doloremque.
                    </p>
                    <div className="service-bottom-nine">
                      <Link to="#" className="btn btn-primary">
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="blog blog-new flex-fill">
                  <div className="blog-image">
                    <Link to="/blog-details">
                      <ImageWithBasePath
                        className="img-fluid"
                        src="assets/img/services/service-79.jpg"
                        alt="Post Image"
                      />
                    </Link>
                    <div className="date">
                      25<span>Dec</span>
                    </div>
                  </div>
                  <div className="blog-content">
                    <div className="usefull-bottom useful-bottom-eight useful-bottom-nine">
                      <div className="useful-img">
                        <ImageWithBasePath
                          src="assets/img/profiles/avatar-10.jpg"
                          alt="User"
                        />
                        <span>Admin</span>
                      </div>
                      <h6>
                        <Icon.Tag className="standard-feather" />
                        Services
                      </h6>
                    </div>
                    <h3 className="blog-title">
                      <Link to="/blog-details">
                        Lorem ipsum dolor sit amet, adipiscing elit, sed do
                        eiusmod
                      </Link>
                    </h3>
                    <p>
                      Sed ut perspiciatis unde omnis iste natus error sit
                      voluptatem accusantium doloremque.
                    </p>
                    <div className="service-bottom-nine">
                      <Link to="#" className="btn btn-primary">
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="blog blog-new flex-fill">
                  <div className="blog-image">
                    <Link to="/blog-details">
                      <ImageWithBasePath
                        className="img-fluid"
                        src="assets/img/services/service-77.jpg"
                        alt="Post Image"
                      />
                    </Link>
                    <div className="date">
                      25<span>Dec</span>
                    </div>
                  </div>
                  <div className="blog-content">
                    <div className="usefull-bottom useful-bottom-eight useful-bottom-nine">
                      <div className="useful-img">
                        <ImageWithBasePath
                          src="assets/img/profiles/avatar-10.jpg"
                          alt="User"
                        />
                        <span>Admin</span>
                      </div>
                      <h6>
                        <Icon.Tag className="standard-feather" />
                        Services
                      </h6>
                    </div>
                    <h3 className="blog-title">
                      <Link to="/blog-details">
                        Lorem ipsum dolor sit amet, adipiscing elit, sed do
                        eiusmod
                      </Link>
                    </h3>
                    <p>
                      Sed ut perspiciatis unde omnis iste natus error sit
                      voluptatem accusantium doloremque.
                    </p>
                    <div className="service-bottom-nine">
                      <Link to="#" className="btn btn-primary">
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
            <div className="btn-sec btn-service-nine aos" data-aos="fade-up">
              <Link to="/search" className="btn btn-primary btn-view">
                VIEW ALL BLOGS
              </Link>
            </div>
          </div>
          <div className="free-service-all">
            <div className="row aos" data-aos="fade-up">
              <div className="col-lg-6 col-12">
                <div className="free-service-nine">
                  <div className="free-service-img-one">
                    <ImageWithBasePath src="assets/img/service-img-9.jpg" alt="image" />
                  </div>
                  <div className="free-service-img-two">
                    <ImageWithBasePath src="assets/img/service-img-10.jpg" alt="image" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="free-service-bottom-content">
                  <div
                    className="section-heading section-heading-nine free-heading-nine aos"
                    data-aos="fade-up"
                  >
                    <p>Quick Booking</p>
                    <h2>Get A Free Service</h2>
                  </div>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    ypesetting industry. Lorem Ipsum has been the industry&apos;s
                    standard.
                  </p>
                  <Link to="#" className="btn btn-primary">
                    Book Appointment
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /Our Blogs */}
      <div className="blog-section partner-section-nine">
        <div className="container">
          <div className="row">
            <Slider
              {...parterSettings}
              className="owl-carousel quote-slider aos custom-slick"
              data-aos="fade-up"
            >
              <div className="partner-img">
                <ImageWithBasePath src="assets/img/partner/partner1.svg" alt="img" />
              </div>
              <div className="partner-img">
                <ImageWithBasePath src="assets/img/partner/partner2.svg" alt="img" />
              </div>
              <div className="partner-img">
                <ImageWithBasePath src="assets/img/partner/partner3.svg" alt="img" />
              </div>
              <div className="partner-img">
                <ImageWithBasePath src="assets/img/partner/partner4.svg" alt="img" />
              </div>
              <div className="partner-img">
                <ImageWithBasePath src="assets/img/partner/partner5.svg" alt="img" />
              </div>
              <div className="partner-img">
                <ImageWithBasePath src="assets/img/partner/partner6.svg" alt="img" />
              </div>
            </Slider>
          </div>
        </div>
      </div>
      {/* Footer */}
      <FooterNine />
      {/* /Footer */}
    </div>
  );
};

export default HomeNine;
