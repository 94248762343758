import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as Icon from 'react-feather';
import {
  set_is_mobile_sidebar,
  set_toggleSidebar_data_2,
} from '../../../../core/data/redux/action';
import { useDispatch, useSelector } from 'react-redux';
import { all_routes } from '../../../../core/data/routes/all_routes';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { AppState } from '../../../../core/models/interface';
import { image_base_url } from '../../../../constant';
import { Modal, Button } from 'react-bootstrap';

const ProviderHeader = () => {
  const routes = all_routes;
  const toggle_data = useSelector((state: AppState) => state.toggleSidebar2);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user_name = localStorage.getItem("tech_user_name");
  const user_email = localStorage.getItem("tech_user_email");
  const user_userprofileimage = localStorage.getItem("tech_user_userprofileimage");
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const toggleFullscreen = () => {
    if (!isFullscreen) {
      // Request fullscreen
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } 
    } else {
      // Exit fullscreen
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }

    // Toggle the state
    setIsFullscreen(!isFullscreen);
  };
  const toogle = () => {
    dispatch(set_toggleSidebar_data_2(toggle_data ? false : true));
  };
  const mobileSidebar = useSelector((state: AppState) => state.mobileSidebar);

  const handleClick = () => {
    dispatch(set_is_mobile_sidebar(!mobileSidebar));
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleConfirmLogout = () => {
    handleLogout();
    handleCloseModal();
  };

  const handleLogout = () => {
    // Remove the token from localStorage
    navigate(routes.homeOne);
    localStorage.removeItem('token');
    localStorage.removeItem('user_id'); 
    localStorage.removeItem('user_name'); 
    localStorage.removeItem('user_email'); 
    localStorage.removeItem('user_userprofileimage');  
  };
  return (
    <div className="header">
      <div className="header-left">
        <div className="sidebar-logo">
          <Link to={routes.homeOne}>
            <ImageWithBasePath
              src="assets/img/logo.jpg"
              className="img-fluid logo"
              alt="Logo"
              width={30}
              height={30}
            />
          </Link>
          <Link to={routes.homeOne}>
            <ImageWithBasePath
              src="assets/img/logo.jpg"
              className="img-fluid logo-small"
              alt="Logo"
              width={30}
              height={30}
            />
          </Link>
        </div>
        <div className="siderbar-toggle">
          <Link to="#" onClick={toogle}>
            <label className="switch" id="toggle_btn">
              <input type="checkbox" />
              <span className="slider round" />
            </label>
          </Link>
        </div>
      </div>
      <Link
        className="mobile_btns"
        id="mobile_btns"
        to="#"
        onClick={handleClick}
      >
        <i className="fas fa-align-left" />
      </Link>
      <div className="header-split">
        <div className="page-headers">
          <div className="search-bar">
            <span>
              <Icon.Search size={15} />
            </span>
            <input type="text" placeholder="Search" className="form-control" />
          </div>
        </div>
        <ul className="nav user-menu noti-pop-detail ">
          {/* Notifications */}
          <li className="nav-item">
            <Link to={routes.homeOne} className="viewsite">
              <Icon.Globe className="me-2" size={18} />
              View Site
            </Link>
          </li>
          <li className="nav-item dropdown has-arrow dropdown-heads flag-nav">
            <Link
              className="nav-link"
              data-bs-toggle="dropdown"
              to="#"
              role="button"
            >
              <ImageWithBasePath
                src="assets/img/flags/us1.png"
                alt="Flag"
                height={20}
              />
            </Link>
            <div className="dropdown-menu dropdown-menu-right">
              <Link to="#" className="dropdown-item">
                <ImageWithBasePath
                  src="assets/img/flags/us.png"
                  className="me-2"
                  alt="Flag"
                  height={16}
                />{' '}
                English
              </Link>
              <Link to="#" className="dropdown-item">
                <ImageWithBasePath
                  src="assets/img/flags/fr.png"
                  className="me-2"
                  alt="Flag"
                  height={16}
                />{' '}
                French
              </Link>
              <Link to="#" className="dropdown-item">
                <ImageWithBasePath
                  src="assets/img/flags/es.png"
                  className="me-2"
                  alt="Flag"
                  height={16}
                />{' '}
                Spanish
              </Link>
              <Link to="#" className="dropdown-item">
                <ImageWithBasePath
                  src="assets/img/flags/de.png"
                  className="me-2"
                  alt="Flag"
                  height={16}
                />{' '}
                German
              </Link>
            </div>
          </li>
          <li className="nav-item  has-arrow dropdown-heads ">
            <Link to="#">
              <i className="feather-moon">
                <Icon.Moon className="react-feather-custom" />
              </i>
            </Link>
          </li>
          {/* Notifications */}
          <li className="nav-item  has-arrow dropdown-heads dropdown logged-item noti-nav noti-wrapper">
            <Link
              to="#"
              className="dropdown-toggled nav-link notify-link"
              data-bs-toggle="dropdown"
            >
              <span className="noti-message">1</span>
              <ImageWithBasePath
                src="assets/img/icons/bell-icon.svg"
                alt="Bell"
              />
            </Link>
            <div className="dropdown-menu notify-blk notifications">
              <div className="topnav-dropdown-header">
                <div>
                  <p className="notification-title">
                    Notifications <span> 3 </span>
                  </p>
                </div>
                <Link to="#" className="clear-noti">
                  <i className="fa-regular fa-circle-check" /> Mark all as read{' '}
                </Link>
              </div>
              <div className="noti-content">
                <ul className="notification-list">
                  <li className="notification-message">
                    <Link to={routes.commonNotification}>
                      <div className="media noti-img d-flex">
                        <span className="avatar avatar-sm flex-shrink-0">
                          <ImageWithBasePath
                            className="avatar-img rounded-circle img-fluid"
                            alt="User Image"
                            src="assets/img/notifications/avatar-01.jpg"
                          />
                        </span>
                        <div className="media-body flex-grow-1">
                          <p className="noti-details">
                            Lex Murphy left 6 comments on Isla Nublar SOC2
                            compliance report
                          </p>
                          <p className="noti-time">
                            <span className="notification-time">1m ago</span>
                          </p>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li className="notification-message">
                    <div className="media noti-img d-flex">
                      <Link to={routes.commonNotification}>
                        <span className="avatar avatar-sm flex-shrink-0">
                          <ImageWithBasePath
                            className="avatar-img rounded-circle img-fluid"
                            alt="User Image"
                            src="assets/img/notifications/avatar-02.jpg"
                          />
                        </span>
                      </Link>
                      <div className="media-body flex-grow-1">
                        <Link to={routes.commonNotification}>
                          <p className="noti-details">
                            Ray Arnold requested access to UNIX directory tree
                            hierarchy
                          </p>
                        </Link>
                        <p className="noti-time">
                          <span className="notification-time">1 min</span>
                        </p>
                        <div className="notify-btns">
                          <button className="btn btn-secondary">Decline</button>
                          <button className="btn btn-primary">Accept</button>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="notification-message">
                    <Link to={routes.commonNotification}>
                      <div className="media noti-img d-flex">
                        <span className="avatar avatar-sm flex-shrink-0">
                          <ImageWithBasePath
                            className="avatar-img rounded-circle img-fluid"
                            alt="User Image"
                            src="assets/img/notifications/avatar-03.jpg"
                          />
                        </span>
                        <div className="media-body flex-grow-1">
                          <p className="noti-details">
                            Dennis Nedry commented on Isla Nublar SOC2
                            compliance report
                          </p>
                          <p className="noti-time">
                            <span className="notification-time">1m ago</span>
                          </p>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li className="notification-message">
                    <Link to={routes.commonNotification}>
                      <div className="media noti-img d-flex">
                        <span className="avatar avatar-sm flex-shrink-0">
                          <ImageWithBasePath
                            className="avatar-img rounded-circle img-fluid"
                            alt="User Image"
                            src="assets/img/notifications/avatar-04.jpg"
                          />
                        </span>
                        <div className="media-body flex-grow-1">
                          <p className="noti-details">
                            John Hammond created Isla Nublar SOC2 compliance
                            report
                          </p>
                          <p className="noti-time">
                            <span className="notification-time">1m ago</span>
                          </p>
                        </div>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="topnav-dropdown-footer">
                <Link to={routes.commonNotification}>
                  View All Notifications{' '}
                  <ImageWithBasePath
                    src="assets/img/icons/arrow-right-01.svg"
                    alt="Arrow"
                  />
                </Link>
              </div>
            </div>
          </li>
          {/* /Notifications */}
          <li className="nav-item  has-arrow dropdown-heads ">
            <Link onClick={toggleFullscreen} to="#" className="win-maximize">
              <i className="feather-maximize">
                <Icon.Maximize className="react-feather-custom" />
              </i>
            </Link>
          </li>
          {/* User Menu */}
          <li className="nav-item dropdown has-arrow account-item">
            <Link
              to="#"
              className="dropdown-toggle nav-link"
              data-bs-toggle="dropdown"
            >
              <div className="user-infos">
                <span className="user-img">
                  <img
                    // src="assets/img/profiles/avatar-02.jpg"
                    src={`${image_base_url}${user_userprofileimage}`}
                    className="rounded-circle"
                    alt="User Image"
                  />
                </span>
                <div className="user-info">
                  <h6>{user_name}</h6>
                  <p>{user_email}</p>
                </div>
              </div>
            </Link>
            <div className="dropdown-menu dropdown-menu-end emp">
              <Link
                className="dropdown-item"
                to={routes.providerProfile}
              >
                <i className="feather-user me-2" /> Profile
              </Link>
              <Link className="dropdown-item" to='#' onClick={handleShowModal}>
                <i className="feather-log-out me-2" /> Logout
              </Link>
            </div>
          </li>
          {/* /User Menu */}
        </ul>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to logout?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirmLogout}>
            Logout
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ProviderHeader;
