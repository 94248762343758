import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import * as Icon from 'react-feather';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import FooterOne from './footer-one';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import { all_routes } from '../../../../core/data/routes/all_routes';
import HomeHeader from '../header/home-header';
import { base_url, image_base_url } from '../../../../constant';

const HomeOne = () => {
  const routes = all_routes;
  const [users, setUsers] = useState([]);
  const [sublist, setsublist] = useState([]);
  const [searchCity, setSearchCity] = useState('');
  const [specializationFilter, setSpecializationFilter] = useState('');

  AOS.init();
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  const handleScroll = () => {
    AOS.refresh();
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const [selectedItems, setSelectedItems] = useState(Array(10).fill(false));
  const handleItemClick = (index: number) => {
    setSelectedItems((prevSelectedItems) => {
      const updatedSelectedItems = [...prevSelectedItems];
      updatedSelectedItems[index] = !updatedSelectedItems[index];
      return updatedSelectedItems;
    });
  };

  useEffect(() => {
    SpecializationMasterList();
    getSubSpecializationlist
  }, []);

  const SpecializationMasterList = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${base_url}api/SpecializationMasterList`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      // Reverse the array to display in reverse order
      setUsers(data);
    } catch (error) {
      console.error('Error fetching technicians:', error);
    }
  };


  const getSubSpecializationlist = async () => {
    try {
      const response = await fetch(`${base_url}api/getSubSpecializationlist`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      // Reverse the array to display in reverse order
      setsublist(data);
    } catch (error) {
      console.error('Error fetching technicians:', error);
    }
  };

  const settings = {
    dots: false,
    autoplay: false,
    slidesToShow: 3,
    speed: 500,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 776,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const clientSlider = {
    dots: false,
    autoplay: false,
    slidesToShow: 2,
    speed: 500,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 776,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const partnerSlider = {
    dots: false,
    autoplay: false,
    slidesToShow: 5,
    speed: 500,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 776,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const handleLinkClick = (id:any) => {
    localStorage.setItem("specializationId", id);
  };

  return (
    <>
      <HomeHeader type={1} />
      {/* Hero Section */}
      <section className="hero-section">
        <div className="container">
          <div className="home-banner">
            <div className="row align-items-center w-100">
              <div className="col-lg-7 col-md-10 mx-auto">
                <div className="section-search aos" data-aos="fade-up">
                  <h1>Professional Services For Your Home &amp; Commercial </h1>
                  <p>Search From 100 Awesome Verified Ads!</p>
                  <div className="search-box">
                    <form>
                      <div className="search-input line">
                        <div className="search-group-icon">
                          <Icon.MapPin className="standard-feather" />
                        </div>
                        <div className="form-group mb-0">
                          <label>Your Location</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="America"
                            value={searchCity}
      onChange={(e) => setSearchCity(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="search-input">
                        <div className="search-group-icon search-icon">
                          <Icon.Search className="react-feather-custom" />
                        </div>
                        <div className="form-group mb-0">
                          <label>What are you looking for?</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Car Repair Services"
                            value={specializationFilter}
      onChange={(e) => setSpecializationFilter(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="search-btn">
                        <Link className="btn btn" style={{ backgroundColor: "#fe7c02", borderColor: "#fe7c02", color:"white"}}   to={`${routes.search}?city=${searchCity}&specialization=${specializationFilter}`}>
                          <Icon.Search className="react-feather-custom me-2" />
                          Search
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="banner-imgs">
                  <div className="banner-1 shape-1">
                    <ImageWithBasePath
                      className="img-fluid"
                      alt="banner"
                      src="assets/img/banner1.png"
                    />
                  </div>
                  <div className="banner-2 shape-3">
                    <ImageWithBasePath
                      className="img-fluid"
                      alt="banner"
                      src="assets/img/banner2.png"
                    />
                  </div>
                  <div className="banner-3 shape-3">
                    <ImageWithBasePath
                      className="img-fluid"
                      alt="banner"
                      src="assets/img/banner3.png"
                    />
                  </div>
                  <div className="banner-4 shape-2">
                    <ImageWithBasePath
                      className="img-responsive"
                      alt="banner"
                      src="assets/img/banner4.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /Hero Section */}
      {/* Feature Section */}
      <section className="feature-section">
        <div className="container">
          <div className="section-heading">
            <div className="row align-items-center">
              <div className="col-md-6 aos" data-aos="fade-up">
                <h2>Featured Categories</h2>
                <p>What do you need to find?</p>
              </div>
              <div className="col-md-6 text-md-end aos" data-aos="fade-up">
                <Link
                  // to={routes.categories}
                  // to={routes.search}
                  to={`${routes.search}?city=${searchCity}&specialization=${specializationFilter}`}
                  className="btn btn-primary btn-view"
                >
                  View All
                  <Icon.ArrowRightCircle className="standard-feather ms-2" />
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
      {users.map((service:any) => (
        <div key={service.id} className="col-md-6 col-lg-3">
          <Link
            // to={routes.search} // Assuming routes.serviceDetails is defined in your routes
            to={`${routes.search}?city=${searchCity}&specialization=${encodeURIComponent(service.specialization)}`}
            className="feature-box aos"
            data-aos="fade-up"
            onClick={() => handleLinkClick(service.id)}
          >
            <div className="feature-icon">
              <span>
                <img
                  src={`${image_base_url}${service.image}`}
                  alt="Service Icon"
                  width={200}
                  height={100}
                />
              </span>
            </div>
            <h5>{service.specialization}</h5>
            <div className="feature-overlay">
              <img
                src={`${image_base_url}${service.image}`}
                alt="Service Image"
              />
            </div>
          </Link>
        </div>
      ))}
    </div>
          {/* <div className="row">
            <div className="col-md-6 col-lg-3">
              <Link
                // to={routes.serviceDetails1}
                to={routes.search}
                className="feature-box aos"
                data-aos="fade-up"
              >
                <div className="feature-icon">
                  <span>
                    <ImageWithBasePath
                      src="assets/img/icons/feature-icon-01.svg"
                      alt="img"
                    />
                  </span>
                </div>
                <h5>Construction</h5>
                <div className="feature-overlay">
                  <ImageWithBasePath
                    src="assets/img/services/service-02.jpg"
                    alt="img"
                  />
                </div>
              </Link>
            </div>
            <div className="col-md-6 col-lg-3">
              <Link
                to={routes.serviceDetails1}
                className="feature-box aos"
                data-aos="fade-up"
              >
                <div className="feature-icon">
                  <span>
                    <ImageWithBasePath
                      src="assets/img/icons/feature-icon-02.svg"
                      alt="img"
                    />
                  </span>
                </div>
                <h5>Car Wash</h5>
                <div className="feature-overlay">
                  <ImageWithBasePath src="assets/img/feature.jpg" alt="img" />
                </div>
              </Link>
            </div>
            <div className="col-md-6 col-lg-3">
              <Link
                to={routes.serviceDetails1}
                className="feature-box aos"
                data-aos="fade-up"
              >
                <div className="feature-icon">
                  <span>
                    <ImageWithBasePath
                      src="assets/img/icons/feature-icon-03.svg"
                      alt="img"
                    />
                  </span>
                </div>
                <h5>Electrical</h5>
                <div className="feature-overlay">
                  <ImageWithBasePath
                    src="assets/img/services/service-01.jpg"
                    alt="img"
                  />
                </div>
              </Link>
            </div>
            <div className="col-md-6 col-lg-3">
              <Link
                to={routes.serviceDetails1}
                className="feature-box aos"
                data-aos="fade-up"
              >
                <div className="feature-icon">
                  <span>
                    <ImageWithBasePath
                      src="assets/img/icons/feature-icon-04.svg"
                      alt="img"
                    />
                  </span>
                </div>
                <h5>Cleaning</h5>
                <div className="feature-overlay">
                  <ImageWithBasePath
                    src="assets/img/services/service-09.jpg"
                    alt="img"
                  />
                </div>
              </Link>
            </div>
            <div className="col-md-6 col-lg-3">
              <Link
                to={routes.serviceDetails1}
                className="feature-box aos"
                data-aos="fade-up"
              >
                <div className="feature-icon">
                  <span>
                    <ImageWithBasePath
                      src="assets/img/icons/feature-icon-05.svg"
                      alt="img"
                    />
                  </span>
                </div>
                <h5>Interior</h5>
                <div className="feature-overlay">
                  <ImageWithBasePath
                    src="assets/img/services/service-07.jpg"
                    alt="img"
                  />
                </div>
              </Link>
            </div>
            <div className="col-md-6 col-lg-3">
              <Link
                to={routes.serviceDetails1}
                className="feature-box aos"
                data-aos="fade-up"
              >
                <div className="feature-icon">
                  <span>
                    <ImageWithBasePath
                      src="assets/img/icons/feature-icon-06.svg"
                      alt="img"
                    />
                  </span>
                </div>
                <h5>Carpentry</h5>
                <div className="feature-overlay">
                  <ImageWithBasePath
                    src="assets/img/services/service-03.jpg"
                    alt="img"
                  />
                </div>
              </Link>
            </div>
            <div className="col-md-6 col-lg-3">
              <Link
                to={routes.serviceDetails1}
                className="feature-box aos"
                data-aos="fade-up"
              >
                <div className="feature-icon">
                  <span>
                    <ImageWithBasePath
                      src="assets/img/icons/feature-icon-07.svg"
                      alt="img"
                    />
                  </span>
                </div>
                <h5>Computer</h5>
                <div className="feature-overlay">
                  <ImageWithBasePath
                    src="assets/img/services/service-06.jpg"
                    alt="img"
                  />
                </div>
              </Link>
            </div>
            <div className="col-md-6 col-lg-3">
              <Link
                to={routes.serviceDetails1}
                className="feature-box aos"
                data-aos="fade-up"
              >
                <div className="feature-icon">
                  <span>
                    <ImageWithBasePath
                      src="assets/img/icons/feature-icon-08.svg"
                      alt="img"
                    />
                  </span>
                </div>
                <h5>Plumbing</h5>
                <div className="feature-overlay">
                  <ImageWithBasePath
                    src="assets/img/services/service-11.jpg"
                    alt="img"
                  />
                </div>
              </Link>
            </div>
          </div> */}
        </div>
      </section>
      {/* /Feature Section */}
      {/* Service Section */}
      {/* <section className="service-section">
        <div className="container">
          <div className="section-heading">
            <div className="row align-items-center">
              <div className="col-md-6 aos" data-aos="fade-up">
                <h2>Featured Services</h2>
                <p>
                  Explore the greates our services. You won’t be disappointed
                </p>
              </div>
              <div className="col-md-6 text-md-end aos" data-aos="fade-up">
                <div className="owl-nav mynav" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Slider {...settings} className="service-slider">
                <div className="service-widget aos" data-aos="fade-up">
                  <div className="service-img">
                    <Link to={routes.serviceDetails1}>
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service Image"
                        src="assets/img/services/service-01.jpg"
                      />
                    </Link>
                    <div
                      className="fav-item"
                      key={1}
                      onClick={() => handleItemClick(1)}
                    >
                      <Link to={routes.categories}>
                        <span className="item-cat">Cleaning</span>
                      </Link>
                      <Link
                        to="#"
                        className={`fav-icon ${
                          selectedItems[1] ? 'selected' : ''
                        }`}
                      >
                        <Icon.Heart size={15} />
                      </Link>
                    </div>
                    <div className="item-info">
                      <Link to={routes.provider}>
                        <span className="item-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-01.jpg"
                            className="avatar"
                            alt="User"
                          />
                        </span>
                      </Link>
                    </div>
                  </div>
                  <div className="service-content">
                    <h3 className="title">
                      <Link to={routes.serviceDetails1}>
                        Electric Panel Repairing Service
                      </Link>
                    </h3>
                    <p>
                      {' '}
                      <Icon.MapPin className="standard-feather" />
                      New Jersey, USA
                      <span className="rate">
                        <i className="fas fa-star filled" />
                        4.9
                      </span>
                    </p>
                    <div className="serv-info">
                      <h6>
                        $25.00<span className="old-price">$35.00</span>
                      </h6>
                      <Link
                        to={routes.serviceDetails1}
                        className="btn btn-book"
                      >
                        Book Now
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="service-widget aos" data-aos="fade-up">
                  <div className="service-img">
                    <Link to={routes.serviceDetails1}>
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service Image"
                        src="assets/img/services/service-02.jpg"
                      />
                    </Link>
                    <div
                      className="fav-item"
                      key={2}
                      onClick={() => handleItemClick(2)}
                    >
                      <Link to={routes.categories}>
                        <span className="item-cat">Construction</span>
                      </Link>
                      <Link
                        to="#"
                        className={`fav-icon ${
                          selectedItems[2] ? 'selected' : ''
                        }`}
                      >
                        <Icon.Heart size={15} />
                      </Link>
                    </div>
                    <div className="item-info">
                      <Link to={routes.provider}>
                        <span className="item-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-02.jpg"
                            className="avatar"
                            alt="User"
                          />
                        </span>
                      </Link>
                    </div>
                  </div>
                  <div className="service-content">
                    <h3 className="title">
                      <Link to={routes.serviceDetails1}>
                        Toughened Glass Fitting Services
                      </Link>
                    </h3>
                    <p>
                      {' '}
                      <Icon.MapPin className="standard-feather" />
                      Montana, USA
                      <span className="rate">
                        <i className="fas fa-star filled" />
                        4.9
                      </span>
                    </p>
                    <div className="serv-info">
                      <h6>$45.00</h6>
                      <Link
                        to={routes.serviceDetails1}
                        className="btn btn-book"
                      >
                        Book Now
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="service-widget aos" data-aos="fade-up">
                  <div className="service-img">
                    <Link to={routes.serviceDetails1}>
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service Image"
                        src="assets/img/services/service-03.jpg"
                      />
                    </Link>
                    <div
                      className="fav-item"
                      key={3}
                      onClick={() => handleItemClick(3)}
                    >
                      <Link to={routes.categories}>
                        <span className="item-cat">Carpentry</span>
                      </Link>
                      <Link
                        to="#"
                        className={`fav-icon ${
                          selectedItems[3] ? 'selected' : ''
                        }`}
                      >
                        <Icon.Heart size={15} />
                      </Link>
                    </div>
                    <div className="item-info">
                      <Link to={routes.provider}>
                        <span className="item-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-03.jpg"
                            className="avatar"
                            alt="User"
                          />
                        </span>
                      </Link>
                    </div>
                  </div>
                  <div className="service-content">
                    <h3 className="title">
                      <Link to={routes.serviceDetails1}>
                        Wooden Carpentry Work
                      </Link>
                    </h3>
                    <p>
                      {' '}
                      <Icon.MapPin className="standard-feather" />
                      Montana, USA
                      <span className="rate">
                        <i className="fas fa-star filled" />
                        4.9
                      </span>
                    </p>
                    <div className="serv-info">
                      <h6>$45.00</h6>
                      <Link
                        to={routes.serviceDetails1}
                        className="btn btn-book"
                      >
                        Book Now
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="service-widget aos" data-aos="fade-up">
                  <div className="service-img">
                    <Link to={routes.serviceDetails1}>
                      <ImageWithBasePath
                        className="img-fluid serv-img"
                        alt="Service Image"
                        src="assets/img/services/service-11.jpg"
                      />
                    </Link>
                    <div
                      className="fav-item"
                      key={4}
                      onClick={() => handleItemClick(4)}
                    >
                      <Link to={routes.categories}>
                        <span className="item-cat">Construction</span>
                      </Link>
                      <Link
                        to="#"
                        className={`fav-icon ${
                          selectedItems[4] ? 'selected' : ''
                        }`}
                      >
                        <Icon.Heart size={15} />
                      </Link>
                    </div>
                    <div className="item-info">
                      <Link to={routes.provider}>
                        <span className="item-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-04.jpg"
                            className="avatar"
                            alt="User"
                          />
                        </span>
                      </Link>
                    </div>
                  </div>
                  <div className="service-content">
                    <h3 className="title">
                      <Link to={routes.serviceDetails1}>Plumbing Services</Link>
                    </h3>
                    <p>
                      {' '}
                      <Icon.MapPin className="standard-feather" />
                      Georgia, USA
                      <span className="rate">
                        <i className="fas fa-star filled" />
                        4.9
                      </span>
                    </p>
                    <div className="serv-info">
                      <h6>$45.00</h6>
                      <Link
                        to={routes.serviceDetails1}
                        className="btn btn-book"
                      >
                        Book Now
                      </Link>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
            <div className="btn-sec aos" data-aos="fade-up">
              <Link to={routes.search} className="btn btn-primary btn-view">
                View All
                <Icon.ArrowRightCircle className="standard-feather ms-1" />
              </Link>
            </div>
          </div>
        </div>
      </section> */}
      {/* /Service Section */}
      {/* Providers Section */}
      {/* <section className="providers-section">
        <div className="container">
          <div className="section-heading">
            <div className="row align-items-center">
              <div className="col-md-6 aos" data-aos="fade-up">
                <h2>Top Providers</h2>
                <p>Meet Our Experts</p>
              </div>
              <div className="col-md-6 text-md-end aos" data-aos="fade-up">
                <Link to={routes.provider} className="btn btn-primary btn-view">
                  View All
                  <Icon.ArrowRightCircle className="standard-feather ms-1" />
                </Link>
              </div>
            </div>
          </div>
          <div className="row  aos" data-aos="fade-up">
            <div className="col-lg-3 col-sm-6">
              <div className="providerset">
                <div className="providerset-img">
                  <Link to={routes.providerDetails}>
                    <ImageWithBasePath
                      src="assets/img/provider/provider-11.jpg"
                      alt="img"
                    />
                  </Link>
                </div>
                <div className="providerset-content">
                  <div className="providerset-price">
                    <div className="providerset-name">
                      <h4>
                        <Link to={routes.providerDetails}></Link>
                        <i className="fa fa-check-circle" aria-hidden="true" />
                      </h4>
                      <span>Electrician</span>
                    </div>
                    <div className="providerset-prices">
                      <h6>
                        $20.00<span>/hr</span>
                      </h6>
                    </div>
                  </div>
                  <div className="provider-rating">
                    <div className="rating">
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled" />
                      <i className="fa-solid fa-star-half-stroke filled" />
                      <span>(320)</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="providerset">
                <div className="providerset-img">
                  <Link to={routes.providerDetails}>
                    <ImageWithBasePath
                      src="assets/img/provider/provider-12.jpg"
                      alt="img"
                    />
                  </Link>
                </div>
                <div className="providerset-content">
                  <div className="providerset-price">
                    <div className="providerset-name">
                      <h4>
                        <Link to={routes.providerDetails}>Michael</Link>
                        <i className="fa fa-check-circle" aria-hidden="true" />
                      </h4>
                      <span>Carpenter</span>
                    </div>
                    <div className="providerset-prices">
                      <h6>
                        $50.00<span>/hr</span>
                      </h6>
                    </div>
                  </div>
                  <div className="provider-rating">
                    <div className="rating">
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled" />
                      <i className="fa-solid fa-star-half-stroke filled" />
                      <span>(228)</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="providerset">
                <div className="providerset-img">
                  <Link to={routes.providerDetails}>
                    <ImageWithBasePath
                      src="assets/img/provider/provider-13.jpg"
                      alt="img"
                    />
                  </Link>
                </div>
                <div className="providerset-content">
                  <div className="providerset-price">
                    <div className="providerset-name">
                      <h4>
                        <Link to={routes.providerDetails}>Antoinette</Link>
                        <i className="fa fa-check-circle" aria-hidden="true" />
                      </h4>
                      <span>Cleaner</span>
                    </div>
                    <div className="providerset-prices">
                      <h6>
                        $25.00<span>/hr</span>
                      </h6>
                    </div>
                  </div>
                  <div className="provider-rating">
                    <div className="rating">
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled" />
                      <i className="fa-solid fa-star-half-stroke filled" />
                      <span>(130)</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="providerset">
                <div className="providerset-img">
                  <Link to={routes.providerDetails}>
                    <ImageWithBasePath
                      src="assets/img/provider/provider-14.jpg"
                      alt="img"
                    />
                  </Link>
                </div>
                <div className="providerset-content">
                  <div className="providerset-price">
                    <div className="providerset-name">
                      <h4>
                        <Link to={routes.providerDetails}>Thompson</Link>
                        <i className="fa fa-check-circle" aria-hidden="true" />
                      </h4>
                      <span>Mechanic</span>
                    </div>
                    <div className="providerset-prices">
                      <h6>
                        $25.00<span>/hr</span>
                      </h6>
                    </div>
                  </div>
                  <div className="provider-rating">
                    <div className="rating">
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled" />
                      <i className="fa-solid fa-star-half-stroke filled" />
                      <span>(95)</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* Providers Section */}
      {/* Work Section */}
      <section className="work-section pt-0">
        {/* <div className="container">
          <div className="row  aos" data-aos="fade-up">
            <div className="col-md-12">
              <div className="offer-paths">
                <div className="offer-pathimg">
                  <ImageWithBasePath src="assets/img/offer.png" alt="img" />
                </div>
                <div className="offer-path-content">
                  <h3>We Are Offering 14 Days Free Trial</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore minim
                    veniam, quis nostrud exercitation ullamco magna aliqua.{' '}
                  </p>
                  <Link
                    to={routes.freeTrail}
                    className="btn btn-primary btn-views"
                  >
                    Try 14 Days Free Trial
                    <Icon.ArrowRightCircle className="standard-feather ms-1" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="section-heading aos" data-aos="fade-up">
                <h2>How It Works</h2>
                <p>Aliquam lorem ante, dapibus in, viverra quis</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="work-box aos" data-aos="fade-up">
                <div className="work-icon">
                  <span>
                    <ImageWithBasePath
                      src="assets/img/icons/work-icon.svg"
                      alt="img"
                    />
                  </span>
                </div>
                <h5>Choose What To Do</h5>
                <p>
                  Lorem ipsum dolor amet, consectetur adipiscing tempor labore
                  et dolore magna aliqua.
                </p>
                <h4>01</h4>
              </div>
            </div>
            <div className="col-md-4">
              <div className="work-box aos" data-aos="fade-up">
                <div className="work-icon">
                  <span>
                    <ImageWithBasePath
                      src="assets/img/icons/find-icon.svg"
                      alt="img"
                    />
                  </span>
                </div>
                <h5>Find What You Want</h5>
                <p>
                  Lorem ipsum dolor amet, consectetur adipiscing tempor labore
                  et dolore magna aliqua.
                </p>
                <h4>02</h4>
              </div>
            </div>
            <div className="col-md-4">
              <div className="work-box aos" data-aos="fade-up">
                <div className="work-icon">
                  <span>
                    <ImageWithBasePath
                      src="assets/img/icons/place-icon.svg"
                      alt="img"
                    />
                  </span>
                </div>
                <h5>Amazing Places</h5>
                <p>
                  Lorem ipsum dolor amet, consectetur adipiscing tempor labore
                  et dolore magna aliqua.
                </p>
                <h4>03</h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /Work Section */}
      {/* Service Section */}
      {/* <section className="service-section">
        <div className="container">
          <div className="section-heading">
            <div className="row align-items-center">
              <div className="col-md-6 aos" data-aos="fade-up">
                <h2>Most Popular Services</h2>
                <p>
                  Explore the greates our services. You won’t be disappointed
                </p>
              </div>
              <div className="col-md-6 text-md-end aos" data-aos="fade-up">
                <div className="owl-nav mynav1" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="popular-slider">
                <Slider {...settings}>
                  <div className="service-widget aos" data-aos="fade-up">
                    <div className="service-img">
                      <Link to={routes.serviceDetails1}>
                        <ImageWithBasePath
                          className="img-fluid serv-img"
                          alt="Service Image"
                          src="assets/img/services/service-04.jpg"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={5}
                        onClick={() => handleItemClick(5)}
                      >
                        <Link to={routes.categories}>
                          <span className="item-cat">Car Wash</span>
                        </Link>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[5] ? 'selected' : ''
                          }`}
                        >
                          <Icon.Heart size={15} />
                        </Link>
                      </div>
                      <div className="item-info">
                        <Link to={routes.provider}>
                          <span className="item-img">
                            <ImageWithBasePath
                              src="assets/img/profiles/avatar-01.jpg"
                              className="avatar"
                              alt="User"
                            />
                          </span>
                        </Link>
                      </div>
                    </div>
                    <div className="service-content">
                      <h3 className="title">
                        <Link to={routes.serviceDetails1}>
                          Car Repair Services
                        </Link>
                      </h3>
                      <p>
                        {' '}
                        <Icon.MapPin className="standard-feather" />
                        Maryland City, MD, USA
                        <span className="rate">
                          <i className="fas fa-star filled" />
                          4.9
                        </span>
                      </p>
                      <div className="serv-info">
                        <h6>
                          $20.00<span className="old-price">$35.00</span>
                        </h6>
                        <Link
                          to={routes.serviceDetails1}
                          className="btn btn-book"
                        >
                          Book Now
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="service-widget aos" data-aos="fade-up">
                    <div className="service-img">
                      <Link to={routes.serviceDetails1}>
                        <ImageWithBasePath
                          className="img-fluid serv-img"
                          alt="Service Image"
                          src="assets/img/services/service-05.jpg"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={6}
                        onClick={() => handleItemClick(6)}
                      >
                        <Link to={routes.categories}>
                          <span className="item-cat">Cleaning</span>
                        </Link>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[6] ? 'selected' : ''
                          }`}
                        >
                          <Icon.Heart size={15} />
                        </Link>
                      </div>
                      <div className="item-info">
                        <Link to={routes.provider}>
                          <span className="item-img">
                            <ImageWithBasePath
                              src="assets/img/profiles/avatar-02.jpg"
                              className="avatar"
                              alt="User"
                            />
                          </span>
                        </Link>
                      </div>
                    </div>
                    <div className="service-content">
                      <h3 className="title">
                        <Link to={routes.serviceDetails1}>
                          Commercial Painting Services
                        </Link>
                      </h3>
                      <p>
                        {' '}
                        <Icon.MapPin className="standard-feather" />
                        Alabama, USA
                        <span className="rate">
                          <i className="fas fa-star filled" />
                          4.9
                        </span>
                      </p>
                      <div className="serv-info">
                        <h6>$500.00</h6>
                        <Link
                          to={routes.serviceDetails1}
                          className="btn btn-book"
                        >
                          Book Now
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="service-widget aos" data-aos="fade-up">
                    <div className="service-img">
                      <Link to={routes.serviceDetails1}>
                        <ImageWithBasePath
                          className="img-fluid serv-img"
                          alt="Service Image"
                          src="assets/img/services/service-06.jpg"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={7}
                        onClick={() => handleItemClick(7)}
                      >
                        <Link to={routes.categories}>
                          <span className="item-cat">Computer</span>
                        </Link>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[7] ? 'selected' : ''
                          }`}
                        >
                          <Icon.Heart size={15} />
                        </Link>
                      </div>
                      <div className="item-info">
                        <Link to={routes.provider}>
                          <span className="item-img">
                            <ImageWithBasePath
                              src="assets/img/profiles/avatar-03.jpg"
                              className="avatar"
                              alt="User"
                            />
                          </span>
                        </Link>
                      </div>
                    </div>
                    <div className="service-content">
                      <h3 className="title">
                        <Link to={routes.serviceDetails1}>
                          Computer &amp; Server AMC Service
                        </Link>
                      </h3>
                      <p>
                        {' '}
                        <Icon.MapPin className="standard-feather" />
                        California, USA
                        <span className="rate">
                          <i className="fas fa-star filled" />
                          4.9
                        </span>
                      </p>
                      <div className="serv-info">
                        <h6>
                          $80.00<span className="old-price">$96.00</span>
                        </h6>
                        <Link
                          to={routes.serviceDetails1}
                          className="btn btn-book"
                        >
                          Book Now
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="service-widget aos" data-aos="fade-up">
                    <div className="service-img">
                      <Link to={routes.serviceDetails1}>
                        <ImageWithBasePath
                          className="img-fluid serv-img"
                          alt="Service Image"
                          src="assets/img/services/service-08.jpg"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={8}
                        onClick={() => handleItemClick(8)}
                      >
                        <Link to={routes.categories}>
                          <span className="item-cat">Cleaning</span>
                        </Link>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[8] ? 'selected' : ''
                          }`}
                        >
                          <Icon.Heart size={15} />
                        </Link>
                      </div>
                      <div className="item-info">
                        <Link to={routes.provider}>
                          <span className="item-img">
                            <ImageWithBasePath
                              src="assets/img/profiles/avatar-04.jpg"
                              className="avatar"
                              alt="User"
                            />
                          </span>
                        </Link>
                      </div>
                    </div>
                    <div className="service-content">
                      <h3 className="title">
                        <Link to={routes.serviceDetails1}>Steam Car Wash</Link>
                      </h3>
                      <p>
                        {' '}
                        <Icon.MapPin className="standard-feather" />
                        Texas, USA
                        <span className="rate">
                          <i className="fas fa-star filled" />
                          4.9
                        </span>
                      </p>
                      <div className="serv-info">
                        <h6>$500.00</h6>
                        <Link
                          to={routes.serviceDetails1}
                          className="btn btn-book"
                        >
                          Book Now
                        </Link>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
            <div className="btn-sec aos" data-aos="fade-up">
              <Link to={routes.search} className="btn btn-primary btn-view">
                View All
                <Icon.ArrowRightCircle className="standard-feather ms-1" />
              </Link>
            </div>
          </div>
        </div>
      </section> */}
      {/* /Service Section */}
      {/* pricing Section */}
      {/* <section className="service-section pricing-sections pt-0">
        <div className="container">
          <div className="section-heading">
            <div className="row">
              <div className="col-md-12 text-center aos" data-aos="fade-up">
                <h2>Pricing Plans</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
              </div>
            </div>
          </div>
          <div className="row aos" data-aos="fade-up">
            <div className="col-lg-4 col-sm-12">
              <div className="pricing-popular">
                <span className="btn w-100">Popular</span>
              </div>
              <div className="pricing-plans">
                <div className="pricing-planshead">
                  <h4>Basic</h4>
                  <h5>Lorem ipsum dolor sit amet, consectetur</h5>
                  <h6>
                    $50<span>/month</span>
                  </h6>
                </div>
                <div className="pricing-planscontent">
                  <ul>
                    <li>
                      <i className="fa fa-check-circle me-2 text-primary" />
                      <span>Sed perspiciatis unde omnis natus error</span>
                    </li>
                    <li>
                      <i className="fa fa-check-circle me-2 text-primary" />
                      <span>Lorem dolor consecteturadipiscing elit</span>
                    </li>
                    <li>
                      <i className="fa fa-check-circle me-2 text-primary" />
                      <span>Duis irure dolor reprehenderit voluptate</span>
                    </li>
                    <li className="inactive">
                      <i className="fa fa-check-circle me-2 text-primary" />
                      <span>Nemo enim ipsam voluptatem quia </span>
                    </li>
                    <li className="inactive">
                      <i className="fa fa-check-circle me-2 text-primary" />
                      <span>Sed perspiciatis iste natus error </span>
                    </li>
                    <li className="inactive">
                      <i className="fa fa-check-circle me-2 text-primary" />
                      <span>Lorem dolor consecteturadipiscing elit </span>
                    </li>
                  </ul>
                  <div className="pricing-btn">
                    <Link
                      to={routes.search}
                      className="btn btn-primary btn-view"
                    >
                      Get Started
                      <Icon.ArrowRightCircle className="standard-feather ms-1" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12">
              <div className="pricing-popular active">
                <span className="btn w-100">Popular</span>
              </div>
              <div className="pricing-plans active">
                <div className="pricing-planshead">
                  <h4>Standard</h4>
                  <h5>Lorem ipsum dolor sit amet, consectetur</h5>
                  <h6>
                    $100<span>/month</span>
                  </h6>
                </div>
                <div className="pricing-planscontent">
                  <ul>
                    <li>
                      <i className="fa fa-check-circle me-2 text-primary" />
                      <span>Sed perspiciatis unde omnis natus error</span>
                    </li>
                    <li>
                      <i className="fa fa-check-circle me-2 text-primary" />
                      <span>Lorem dolor consecteturadipiscing elit</span>
                    </li>
                    <li>
                      <i className="fa fa-check-circle me-2 text-primary" />
                      <span>Duis irure dolor reprehenderit voluptate</span>
                    </li>
                    <li>
                      <i className="fa fa-check-circle me-2 text-primary" />
                      <span>Nemo enim ipsam voluptatem quia </span>
                    </li>
                    <li>
                      <i className="fa fa-check-circle me-2 text-primary" />
                      <span>Sed perspiciatis iste natus error </span>
                    </li>
                    <li className="inactive">
                      <i className="fa fa-check-circle me-2 text-primary" />
                      <span>Lorem dolor consecteturadipiscing elit </span>
                    </li>
                  </ul>
                  <div className="pricing-btn">
                    <Link
                      to={routes.search}
                      className="btn btn-primary btn-view"
                    >
                      Get Started
                      <Icon.ArrowRightCircle className="standard-feather ms-1" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12">
              <div className="pricing-popular ">
                <span className="btn w-100">Popular</span>
              </div>
              <div className="pricing-plans ">
                <div className="pricing-planshead">
                  <h4>Premium</h4>
                  <h5>Lorem ipsum dolor sit amet, consectetur</h5>
                  <h6>
                    $150<span>/month</span>
                  </h6>
                </div>
                <div className="pricing-planscontent">
                  <ul>
                    <li>
                      <i className="fa fa-check-circle me-2 text-primary" />
                      <span>Sed perspiciatis unde omnis natus error</span>
                    </li>
                    <li>
                      <i className="fa fa-check-circle me-2 text-primary" />
                      <span>Lorem dolor consecteturadipiscing elit</span>
                    </li>
                    <li>
                      <i className="fa fa-check-circle me-2 text-primary" />
                      <span>Duis irure dolor reprehenderit voluptate</span>
                    </li>
                    <li>
                      <i className="fa fa-check-circle me-2 text-primary" />
                      <span>Nemo enim ipsam voluptatem quia </span>
                    </li>
                    <li>
                      <i className="fa fa-check-circle me-2 text-primary" />
                      <span>Sed perspiciatis iste natus error </span>
                    </li>
                    <li>
                      <i className="fa fa-check-circle me-2 text-primary" />
                      <span>Lorem dolor consecteturadipiscing elit </span>
                    </li>
                  </ul>
                  <div className="pricing-btn">
                    <Link
                      to={routes.search}
                      className="btn btn-primary btn-view"
                    >
                      Get Started
                      <Icon.ArrowRightCircle className="standard-feather ms-1" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* /pricing Section */}
      {/* Client Section */}
      {/* <section className="client-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="section-heading aos" data-aos="fade-up">
                <h2>What our client says</h2>
                <p>Lorem ipsum dolor sit amet, consectetur elit</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Slider {...clientSlider}>
                <div className="client-widget aos" data-aos="fade-up">
                  <div className="client-img">
                    <Link to="#">
                      <ImageWithBasePath
                        className="img-fluid"
                        alt="Image"
                        src="assets/img/profiles/avatar-01.jpg"
                      />
                    </Link>
                  </div>
                  <div className="client-content">
                    <div className="rating">
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled" />
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi{' '}
                    </p>
                    <h5>Sophie Moore</h5>
                    <h6>Director</h6>
                  </div>
                </div>
                <div className="client-widget aos" data-aos="fade-up">
                  <div className="client-img">
                    <Link to="#">
                      <ImageWithBasePath
                        className="img-fluid"
                        alt="Image"
                        src="assets/img/profiles/avatar-02.jpg"
                      />
                    </Link>
                  </div>
                  <div className="client-content">
                    <div className="rating">
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled" />
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi{' '}
                    </p>
                    <h5>Mike Hussy</h5>
                    <h6>Lead</h6>
                  </div>
                </div>
                <div className="client-widget aos" data-aos="fade-up">
                  <div className="client-img">
                    <Link to="#">
                      <ImageWithBasePath
                        className="img-fluid"
                        alt="Image"
                        src="assets/img/profiles/avatar-03.jpg"
                      />
                    </Link>
                  </div>
                  <div className="client-content">
                    <div className="rating">
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled me-1" />
                      <i className="fas fa-star filled" />
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi{' '}
                    </p>
                    <h5>John Doe</h5>
                    <h6>CEO</h6>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section> */}
      {/* /Client Section */}
      {/* Blog Section */}
      {/* <section className="blog-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center aos" data-aos="fade-up">
              <div className="section-heading">
                <h2>Latest Blog</h2>
                <p>Lorem ipsum dolor sit amet, consectetur elit</p>
              </div>
            </div>
          </div>
          <div className="row justify-content-between">
            <div className="col-lg-4 col-md-6 d-flex">
              <div className="blog flex-fill aos" data-aos="fade-up">
                <div className="blog-image">
                  <Link to={routes.blogDetails}>
                    <ImageWithBasePath
                      className="img-fluid"
                      src="assets/img/blog/blog-01.jpg"
                      alt="Post Image"
                    />
                  </Link>
                </div>
                <div className="blog-content">
                  <ul className="blog-item">
                    <li>
                      <Icon.Calendar
                        className="react-feather-custom me-2"
                        color="#4c40ed"
                      />
                      09 Aug 2023
                    </li>
                    <li>
                      <div className="post-author">
                        <Link to="#">
                          <Icon.User
                            className="react-feather-custom me-2"
                            color="#4c40ed"
                          />
                          <span>Hal Lewis</span>
                        </Link>
                      </div>
                    </li>
                  </ul>
                  <h3 className="blog-title">
                    <Link to={routes.blogDetails}>
                      How to Choose a Electrical ServiceProvider?
                    </Link>
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 d-flex">
              <div className="blog flex-fill aos" data-aos="fade-up">
                <div className="blog-image">
                  <Link to={routes.blogDetails}>
                    <ImageWithBasePath
                      className="img-fluid"
                      src="assets/img/blog/blog-02.jpg"
                      alt="Post Image"
                    />
                  </Link>
                </div>
                <div className="blog-content">
                  <ul className="blog-item">
                    <li>
                      <Icon.Calendar
                        className="react-feather-custom me-2"
                        color="#4c40ed"
                      />
                      09 Aug 2023
                    </li>
                    <li>
                      <div className="post-author">
                        <Link to="#">
                          <Icon.User
                            className="react-feather-custom me-2"
                            color="#4c40ed"
                          />
                          <span>JohnDoe</span>
                        </Link>
                      </div>
                    </li>
                  </ul>
                  <h3 className="blog-title">
                    <Link to={routes.blogDetails}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit
                    </Link>
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 d-flex">
              <div className="blog flex-fill aos" data-aos="fade-up">
                <div className="blog-image">
                  <Link to={routes.blogDetails}>
                    <ImageWithBasePath
                      className="img-fluid"
                      src="assets/img/blog/blog-03.jpg"
                      alt="Post Image"
                    />
                  </Link>
                </div>
                <div className="blog-content">
                  <ul className="blog-item">
                    <li>
                      <Icon.Calendar
                        className="react-feather-custom me-2"
                        color="#4c40ed"
                      />
                      09 Aug 2023
                    </li>
                    <li>
                      <div className="post-author">
                        <Link to="#">
                          <Icon.User
                            className="react-feather-custom me-2"
                            color="#4c40ed"
                          />
                          <span>Greg Avery</span>
                        </Link>
                      </div>
                    </li>
                  </ul>
                  <h3 className="blog-title">
                    <Link to={routes.blogDetails}>
                      Construction Service Scams: How to Avoid Them
                    </Link>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* /Blog Section */}
      {/* Partners Section */}
      {/* <section className="blog-section pt-0">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center aos " data-aos="fade-up">
              <div className="section-heading">
                <h2>Our Partners</h2>
                <p>Lorem ipsum dolor sit amet, consectetur elit</p>
              </div>
            </div>
            <div className="aos" data-aos="fade-up">
              <Slider {...partnerSlider}>
                <div className="partner-img">
                  <ImageWithBasePath
                    src="assets/img/partner/partner1.svg"
                    alt="img"
                  />
                </div>
                <div className="partner-img">
                  <ImageWithBasePath
                    src="assets/img/partner/partner2.svg"
                    alt="img"
                  />
                </div>
                <div className="partner-img">
                  <ImageWithBasePath
                    src="assets/img/partner/partner3.svg"
                    alt="img"
                  />
                </div>
                <div className="partner-img">
                  <ImageWithBasePath
                    src="assets/img/partner/partner4.svg"
                    alt="img"
                  />
                </div>
                <div className="partner-img">
                  <ImageWithBasePath
                    src="assets/img/partner/partner5.svg"
                    alt="img"
                  />
                </div>
                <div className="partner-img">
                  <ImageWithBasePath
                    src="assets/img/partner/partner6.svg"
                    alt="img"
                  />
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section> */}
      {/* Partners Section */}
      {/* App Section */}
      <section className="app-section">
        <div className="container">
          <div className="app-sec">
            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="col-md-12">
                  <div className="heading aos" data-aos="fade-up">
                    <h2>Download Our App</h2>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </p>
                    <h6>Scan the QR code to get the app now</h6>
                    <div className="scan-img">
                      <ImageWithBasePath
                        src="assets/img/scan-img.png"
                        className="img-fluid"
                        alt="img"
                      />
                    </div>
                  </div>
                </div>
                <div className="downlaod-btn aos" data-aos="fade-up">
                  <Link to="#">
                    <ImageWithBasePath
                      src="assets/img/googleplay.svg"
                      alt="img"
                    />
                  </Link>
                  <Link to="#">
                    <ImageWithBasePath
                      src="assets/img/appstore.svg"
                      alt="img"
                    />
                  </Link>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="appimg-set aos" data-aos="fade-up">
                  <ImageWithBasePath
                    src="assets/img/app-img.png"
                    className="img-fluid"
                    alt="img"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /App Section */}
      <FooterOne />
    </>
  );
};
export default HomeOne;
