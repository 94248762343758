import React, { useState, useEffect } from 'react';
import * as Icon from 'react-feather';
import ImageWithBasePath from '../../../../core/img/ImageWithBasePath';
import PagesAuthHeader from './common/header';
import { Link, useNavigate } from 'react-router-dom';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { base_url } from '../../../../constant';

const Login = () => {
  const routes = all_routes;
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [password, setPassword] = useState('');
  const [userType, setUserType] = useState('customer');
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  useEffect(() => {
    const storedUserType = localStorage.getItem('userType');
    if (storedUserType) {
      setUserType(storedUserType);
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const storedUserType = localStorage.getItem('userType');
      if (storedUserType) {
        switch (storedUserType) {
          case 'User':
            navigate(routes.customerDashboard);
            break;
          case 'Admin':
            // navigate('/admin/dashboard');
            window.location.href = '/admin/dashboard';
            break;
          case 'Provider':
            navigate(routes.providerDashboard);
            break;
          default:
            console.error('Unknown user type');
        }
      }
    }
  }, [navigate, routes]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true); // Enable loader

    let apiEndpoint = '';
    switch (userType) {
      case 'User':
        apiEndpoint = 'api/auth/login';
        break;
      case 'Admin':
        apiEndpoint = 'api/auth/adminlogin';
        break;
      case 'Provider':
        apiEndpoint = 'api/auth/login';
        break;
      default:
        console.error('Unknown user type');
        return;
    }

    try {
      const response = await fetch(`${base_url}${apiEndpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          password,
        }),
      });

      const result = await response.json();
      if (response.ok) {
        setIsLoading(false);
        if (userType === 'User') {
          localStorage.setItem("user_id", result.user_id); // Save user_id for User
          localStorage.setItem("token", result.access_token);
          localStorage.setItem("user_name", result.user_name);
          localStorage.setItem("user_email", result.user_email);
          localStorage.setItem("user_userprofileimage", result.user_userprofileimage);
        } else if (userType === 'Provider') {
          localStorage.setItem("technician_id", result.user_id); // Save user_id for Provider (Technician)
          localStorage.setItem("tech_token", result.access_token);
          localStorage.setItem("tech_user_name", result.user_name);
          localStorage.setItem("tech_user_email", result.user_email);
          localStorage.setItem("tech_user_userprofileimage", result.user_userprofileimage);
        }
  
        // localStorage.setItem("token", result.access_token);
        // localStorage.setItem("user_id", result.user_id);
        // localStorage.setItem("user_name", result.user_name);
        // localStorage.setItem("user_email", result.user_email);
        // localStorage.setItem("user_userprofileimage", result.user_userprofileimage);
        const token = localStorage.getItem('token');
        console.log('token', token);

        switch (userType) {
          case 'User':
            // window.location.href = '/customers/customer-dashboard';
            navigate(routes.customerDashboard);
            // navigate(routes.homeOne);
            break;
          case 'Admin':
            // navigate('/admin/dashboard');
            window.location.href = '/admin/dashboard';
            break;
          case 'Provider':
            navigate(routes.providerDashboard);
            break;
          default:
            console.error('Unknown user type');
        }
        const storedUserlogin = localStorage.getItem('usertype');
        if(storedUserlogin == "users"){
          navigate(routes.customerServices);
        }
      } else {
        alert(result.message);
        console.error(result.message);
        setIsLoading(false); // Disable loader on error
      }
    } catch (error) {
      console.error('Error:', error);
      setIsLoading(false); // Disable loader on error
    }
  };

  return (
    <>
      {isLoading && (
        <div className="d-flex justify-content-center mt-4">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      {!isLoading && (
        <>
          <PagesAuthHeader />
          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6 col-lg-6 mx-auto">
                  <div className="login-wrap">
                    <div className="login-header">
                      <h3>{userType} Login</h3>
                      <p>Please enter your details</p>
                      <h6>
                        Sign in with <Link to={routes.loginphone}>Phone Number</Link>
                      </h6>
                    </div>
                    <form onSubmit={handleSubmit}>
                      <div className="log-form">
                        <div className="form-group">
                          <label className="col-form-label">Email</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="johndoe@example.com"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <div className="col">
                              <label className="col-form-label">Password</label>
                            </div>
                            <div className="col-auto">
                              <Link className="forgot-link" to={routes.passwordRecovery}>
                                Forgot password?
                              </Link>
                            </div>
                          </div>
                          <div className="pass-group">
                            <input
                              type={passwordVisible ? 'text' : 'password'}
                              className="form-control pass-input"
                              placeholder="*************"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            <span className="toggle-password" onClick={togglePasswordVisibility}>
                              {passwordVisible ? (
                                <Icon.EyeOff className="react-feather-custom" />
                              ) : (
                                <Icon.Eye className="react-feather-custom" />
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <label className="custom_check">
                              <input type="checkbox" name="rememberme" className="rememberme" />
                              <span className="checkmark" />
                              Remember Me
                            </label>
                          </div>
                          <div className="col-6 text-end">
                            <label className="custom_check">
                              <input type="checkbox" name="loginotp" className="loginotp" />
                              <span className="checkmark" />
                              Login with OTP
                            </label>
                          </div>
                        </div>
                      </div>
                      <button className="btn btn-primary" style={{ width: '420px' }}>
                        Login
                      </button>
                      <div className="login-or">
                        <span className="or-line" />
                        <span className="span-or">OR</span>
                      </div>
                      {/* <div className="social-login">
                        <Link to="#" className="btn btn-google w-100">
                          <ImageWithBasePath
                            src="assets/img/icons/google.svg"
                            className="me-2"
                            alt="img"
                          />
                          Login with Google
                        </Link>
                        <Link to="#" className="btn btn-google w-100">
                          <ImageWithBasePath
                            src="assets/img/icons/fb.svg"
                            className="me-2"
                            alt="img"
                          />
                          Login with Facebook
                        </Link>
                      </div> */}
                      <p className="no-acc">
                        Donot have an account ? <Link to={routes.chooseSignUp}>Register</Link>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <style>
            {`
              .user-type-selection {
                display: flex;
                justify-content: space-between;
                margin-bottom: 1rem;
              }

              .user-type-selection label {
                display: flex;
                align-items: center;
                font-weight: 500;
              }

              .user-type-selection input[type="radio"] {
                margin-right: 0.5rem;
              }
            `}
          </style>
        </>
      )}
    </>
  );
};

export default Login;
